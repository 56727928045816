import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { Form } from 'antd';
import Typography from '../../components/Typography';
import Input from '../../components/Input';
import Button from '../../components/Button';
import './newLogin.scss';
import Checkbox from '../../components/Checkbox';
import Link from '../../components/Link';
import COLORS from '../../util/constants/colors';
import SignUpIn from '../../assets/img/redesign/signUpIn.png';
import { MicrosoftSvg } from '../../assets/img/redesign/svgPaths';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import {
  login,
  resendOtp as resendOtpAction,
  redirectToLogin as redirectToLoginAction,
} from '../../actions/auth';
import { openModal } from '../../actions/modal';
import { validSendEmail as validEmail } from '../../global/validation';
import PrivacyPolicy from '../PrivacyPolicy';
import NewHeader from '../NewHeader';

const LoginContent = ({
  email,
  setEmail,
  password,
  setPassword,
  rememberMe,
  setRememberMe,
  handleLogin,
  errorStatus,
  loginError,
  message,
  saveCurrentUrl,
}) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      <Typography variant="h3">Log in</Typography>
      {loginError && message !== '' && (
        <Typography variant="subtitleTwo" color={COLORS.ERROR_LITE}>
          {message}
        </Typography>
      )}
      <Form name="login" onFinish={handleLogin} autoComplete="on">
        <div className="fields">
          <Form.Item name="username">
            <Input
              className="email"
              label="Email"
              value={email}
              onChange={e => setEmail(String(e.target.value.replace(' ', '').toLowerCase()))}
              status={errorStatus.emailMsg && 'error'}
              description={errorStatus.emailMsg}
            />
          </Form.Item>
          <Form.Item name="password">
            <Input
              label="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              status={errorStatus.passwordMsg && 'error'}
              description={errorStatus.passwordMsg}
              password
            />
          </Form.Item>
        </div>
        <div className="remember-forgot">
          <Checkbox
            checked={rememberMe}
            onChange={e => setRememberMe(e.target.checked)}
          >
            <Typography variant="text" size="medium">
              Remember me
            </Typography>
          </Checkbox>
          <Link bar href="/app/forgot_password" label="Forgot password?" />
        </div>
        <Button
          block
          type="primary"
          label="Log in"
          size="large"
          htmlType="submit"
        />
      </Form>

      <Typography variant="text" size="small">
        or
      </Typography>
      <Button
        fullWidth
        block
        href="/auth/auth/microsoft_graph_auth"
        type="secondary"
        label="Log in with Microsoft"
        size="large"
        leftIcon={MicrosoftSvg}
        onClick={saveCurrentUrl}
      />
      {breakpoint === 'xsmall' && (
        <>
          <Typography variant="text" size="medium" bold>
            Don't have an account but want HIPAA compliant faxing today?
          </Typography>
          <Button
            label="Create free account"
            href="https://www.medsender.com/sign-up?ref=loginpage"
            size="medium"
          />
        </>
      )}
    </>
  );
};

const LoginFooter = () => (
  <div className="login-footer">
    <Typography variant="text" size="small">
      Have an access code?&nbsp;
      {' '}
      <Link
        href="/app/access_code"
        label="Enter it here."
        style={{ color: COLORS.TEXT_PRIMARY_LITE }}
      />
    </Typography>
    <PrivacyPolicy />
  </div>
);

const OtpContent = ({
  otpCode,
  setOtpCode,
  sentViaEmail,
  otpChecked,
  setOtpChecked,
  message,
  handleSubmit,
  checkPhoneNumber,
}) => {
  return (
    <>
      <Typography variant="subtitleTwo">One Last Step</Typography>
      <Typography variant="text" size="large">
        We have detected that you are signing in from a new browser.
        <br />
        For your security, a verification code has been sent to your
        {' '}
        {sentViaEmail ? 'email' : 'sms'}
        .
        Please enter it below.
      </Typography>
      <Form
        name="otpStep"
        onFinish={handleSubmit}
      >
        <Input
          label="Verification Code:"
          placeholder="Enter verification code here"
          value={otpCode}
          onChange={e => setOtpCode(e.target.value)}
          status={message && 'error'}
          description={message}
        />
        <div className="dont-ask-again">
          <Checkbox
            checked={otpChecked}
            onChange={e => setOtpChecked(e.target.checked)}
          >
            <Typography variant="text" size="medium">
              Don't ask again on this computer
            </Typography>
          </Checkbox>
        </div>
        <Button
          block
          type="primary"
          label="Log in"
          size="large"
          htmlType="submit"
        />
      </Form>
      {checkPhoneNumber()}
    </>
  );
};

const NewLogin = ({ ...props }) => {
  const [email, setEmail] = useState(localStorage.getItem('m8_email') || '');
  const [password, setPassword] = useState('');
  const [errorStatus, setErrorStatus] = useState({});
  const [otpCode, setOtpCode] = useState('');
  const [otpData, setOtpData] = useState({
    email: localStorage.getItem('email'),
    needs2FA: localStorage.getItem('needs2FA'),
    otp_auth_token: localStorage.getItem('otp_auth_token'),
    phone_number: localStorage.getItem('phone_number'),
  });
  const [rememberMe, setRememberMe] = useState(true);
  const [otpChecked, setOtpChecked] = useState(true);
  const breakpoint = useBreakpoint();

  const { location, history } = props;

  const openFailedSSODialog = (invalidDomain) => {
    const { openModal: openDialog } = props;
    const modalType = {
      data: { invalidDomain },
      type: 'EXISTING_ACCOUNT',
    };
    openDialog(modalType);
  };

  useEffect(() => {
    localStorage.removeItem('email');
    localStorage.removeItem('needs2FA');

    const params = parse(location.search, { ignoreQueryPrefix: true });

    if (params.failedSSO) {
      history.replace('/login');
      const { invalidDomain } = params;
      openFailedSSODialog(invalidDomain);
    }
  }, [location, history]);

  const resendOtp = (otpType) => {
    const { resendOtp } = props;
    resendOtp({
      otp_auth_token: otpData.otp_auth_token,
      send_otp_via_sms: otpType === 'sms',
      email,
    });
  };

  const checkPhoneNumber = () => {
    if (otpData.phone_number && otpData.phone_number !== 'null') {
      if (props.sentViaEmail) {
        return (
          <Link
            label={`Send to cell phone number ending with ${otpData.phone_number.slice(-4)} instead`}
            onClick={() => resendOtp('sms')}
          />
        );
      }
      return (
        <Link label="Send email instead" onClick={() => resendOtp('email')} />
      );
    }
    return '';
  };

  const handleLogin = () => {
    const inputValid = validateInput();
    setErrorStatus(inputValid);

    // If input is okay and 2FA is required make the request
    if (inputValid.error === false && otpData.needs2FA) {
      props.login({
        email,
        otp_attempt: otpCode,
        otp_auth_token: otpData.otp_auth_token,
        remember_2fa: otpChecked,
      });

      // If input is okay, continue with request
    } else if (inputValid.error === false) {
      props.login(
        {
          email,
          password,
        },
        rememberMe,
      );
    }
  };

  const validateInput = () => {
    let errorStatus = {
      error: false,
      emailMsg: '',
      passwordMsg: '',
      otpErrorMsg: '',
    };
    // Sets the correct form validation error message
    // => Checks if an otp code was entered, gives an error for an empty string
    // or a string of only white spaces
    if (otpData.needs2FA && (!otpCode || !otpCode.trim().length)) {
      errorStatus = {
        ...errorStatus,
        error: true,
        otpErrorMsg: 'Verification code cannot be empty.',
      };
    } else if (!otpData.needs2FA) {
      if (!validEmail(email)) {
        errorStatus = {
          ...errorStatus,
          error: true,
          emailMsg: 'A valid email is required',
        };
      }
      if (!password) {
        errorStatus = {
          ...errorStatus,
          error: true,
          passwordMsg: 'Password cannot be empty',
        };
      }
    }
    return errorStatus;
  };

  const ImageContent = () => (
    <div className="image-content">
      <img className="image" src={SignUpIn} alt="signUpIn" />
      <div className="testimonial">
        <Typography className="statement" variant="h3" color={COLORS.WHITE}>
          “Everyone should be using Medsender! We've saved thousands of dollars each month.”
          <br />
        </Typography>
        <div className="persona">
          <Typography variant="text" size="large" bold color={COLORS.WHITE}>
            Dr. Adrian L.
          </Typography>
          <Typography variant="text" size="large" color={COLORS.WHITE}>
            Crescent Beach Care
          </Typography>
        </div>
      </div>
    </div>
  );

  const saveCurrentUrl = () => {
    const currentURL = new URL(window.location.href);
    const redirectPath = decodeURIComponent(
      currentURL.searchParams.get('redirect'),
    );
    sessionStorage.setItem('storedUrl', redirectPath);
  };

  return (
    <div className="new-login">
      <Helmet>
        <title>Login - Medsender</title>
        <meta name="description" content="Secure log in to Medsender" />
      </Helmet>
      <div className="login-body">
        {['medium', 'large'].includes(breakpoint) && <ImageContent />}
        <div className="login-content">
          {otpData.needs2FA ? (
            <OtpContent
              otpCode={otpCode}
              setOtpCode={setOtpCode}
              sentViaEmail={props.sentViaEmail}
              otpChecked={otpChecked}
              setOtpChecked={setOtpChecked}
              message={props.message}
              handleSubmit={handleLogin}
              checkPhoneNumber={checkPhoneNumber}
            />
          ) : (
            <LoginContent
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              rememberMe={rememberMe}
              setRememberMe={setRememberMe}
              handleLogin={handleLogin}
              errorStatus={errorStatus}
              loginError={props.error}
              message={props.message}
              saveCurrentUrl={saveCurrentUrl}
            />
          )}
          <div style={{ height: '32px', alignSelf: 'stretch' }} />
          <LoginFooter />
        </div>
      </div>
    </div>
  );
};

NewLogin.propTypes = {
  message: PropTypes.string,
  login: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  resendOtp: PropTypes.func.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
  email: PropTypes.string,
  new_otp_auth_token: PropTypes.string,
  location: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    error: state.auth.error,
    data: state.auth.data,
    message: state.auth.message,
    new_otp_auth_token: state.auth.otp_auth && state.auth.otp_auth.otp_auth_token,
    email: state.auth.otp_auth && state.auth.otp_auth.email,
    sentViaEmail: state.auth.sentViaEmail,
  }),
  {
    login,
    resendOtp: resendOtpAction,
    redirectToLogin: redirectToLoginAction,
    openModal,
  },
)(NewLogin);

export { NewLogin };
