import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SendOutlined,
  EditOutlined,
  MergeCellsOutlined,
  CloudUploadOutlined,
  FolderOpenOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { message, Menu } from 'antd';

import RecordsHOC from '../RecordsHOC';
import { SendRecordsDialog, LoadingDialog, Header } from '../../../components';
import { uploadedColumns } from '../shared/constants';

import {
  getUploadedRecordsByPage,
  getUploadedByQuery,
  mergeRecords,
  duplicateRecord,
  unarchiveRecords,
} from '../../../actions/records';
import {
  sendPatientRecord,
  sendToMultipleRecipients,
} from '../../../actions/faxes';
import searchRecipient from '../../../actions/search';
import { closeSendDialog } from '../../../actions/appActions';
import { openModal } from '../../../actions/modal';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { formatDate } from '../../../global/validation';

class UploadedRecords extends Component {
  static propTypes = {
    // Redux actions
    sendPatientRecord: PropTypes.func.isRequired,
    sendToMultipleRecipients: PropTypes.func.isRequired,
    closeSendDialog: PropTypes.func.isRequired,
    searchRecipient: PropTypes.func.isRequired,
    mergeRecords: PropTypes.func.isRequired,
    duplicateRecord: PropTypes.func.isRequired,

    // Stores the array of results from search autocomplete
    dataSource: PropTypes.array.isRequired,
    openModal: PropTypes.func.isRequired,

    // Data from Redux store
    /* Send records dialog visible */
    // This is kept globally to close and allow the <LoadingDialog>
    // to take its place
    loadingDialogOpen: PropTypes.bool.isRequired,

    /* Whether a fax is currently in transmission from <SendRecordsDialog /> */
    faxSending: PropTypes.bool.isRequired,

    // Checks whether autocomplete search is request is still loading
    isLoading: PropTypes.bool.isRequired,

    /* Error message encountered while sending a record */
    faxErrorMessage: PropTypes.string,

    // Checks whether an error was encountered while sending a record
    faxError: PropTypes.bool,

    /* Data selected on inbox page */
    selectedData: PropTypes.arrayOf(PropTypes.shape({
      file_token: PropTypes.string,
      patient_dob: PropTypes.string,
      patient_name: PropTypes.string,
      upload_date: PropTypes.string,
      privacy_level: PropTypes.string,
      document_title: PropTypes.string,
      document_category: PropTypes.string,
      document_categories: PropTypes.array,
    })),

    defaultFaxNumber: PropTypes.string,
    associatedFaxNumbers: PropTypes.array.isRequired,
    // Checks whether user can record without access code sheet
    userCanSendFaxRecordsOnly: PropTypes.bool.isRequired,
    fileToken: PropTypes.string,
  }

  static defaultProps = {
    selectedData: [],
    faxError: false,
    faxErrorMessage: null,
    defaultFaxNumber: null,
    fileToken: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      sendDialogOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ errorMessage: nextProps.faxErrorMessage });
  }

  /**
   * Hide/show the <SendRecordsDialog>
   * @param  {Boolean} bool whether to hide or show
   * @return {Void}
   */
  sendDialogVisible = (bool) => {
    this.setState({ sendDialogOpen: bool });
  };

  /**
   * When the Send button is clicked, check if the patient name and dob isn't present.
   * The Edit Patient dialog will be opened if either is missing
   * The send dialog will be opened if both are present
   */
  checkPatientDetails = async () => {
    const {
      file_token,
      patient_name,
      patient_dob,
      privacy_level,
      can_edit_patient_info,
      can_edit_privacy_level,
      document_title,
      document_category,
      document_categories,
    } = this.props.selectedData[0];
    const canSend = await verifyFeatureAccess('send_record');
    let titleText;
    if (!document_title && !patient_name) {
      titleText = 'patient name or document title';
    }

    const patient = {
      file_token,
      patient_name,
      patient_dob,
      privacy_level,
      can_edit_patient_info,
      can_edit_privacy_level,
      document_title,
      document_category,
      document_categories,
    };

    if (titleText) {
      const modalType = {
        type: 'EDIT_PATIENT',
        data: {
          patient,
          showSendDialog: this.sendDialogVisible,
          title: `To send this record please update the ${titleText}.`,
          buttonText: 'Continue',
          fromPage: 'uploaded',
        },
      };
      this.props.openModal(modalType);
    } else if (canSend) {
      this.setState({ sendDialogOpen: true });
    } else {
      const modalType = {
        type: 'RAISE_FLAG',
        featureFlag: 'send_record',
      };
      this.props.openModal(modalType);
    }
  }

  editPatientOpen = () => {
    const selectedData = this.props.selectedData[0];
    const modalType = {
      type: 'EDIT_PATIENT',
      data: {
        patient: selectedData,
        fromPage: 'uploaded',
      },
    };
    this.props.openModal(modalType);
  };

  openUploadModal = async () => {
    const canUpload = await verifyFeatureAccess('upload_record');
    const modal = canUpload ? 'UPLOAD_RECORD' : 'RAISE_FLAG';
    const modalType = {
      type: modal,
      ...(!canUpload && { featureFlag: 'upload_record' }),
    };
    this.props.openModal(modalType);
  }

  handleMergeRecords = async (tokens) => {
    const canMergeRecords = await verifyFeatureAccess('merge_record');
    if (canMergeRecords) return this.props.mergeRecords(tokens);
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: 'merge_record',
    };
    this.props.openModal(modalType);
  }

  handleMergeAndArchiveRecords = async (tokens) => {
    const mergeResponse = await this.handleMergeRecords(tokens);
    if (!mergeResponse?.error) {
      this.archiveRecordOpen(tokens, '/app/uploads', true);
    }
  }

  cloneSelectedRecord = async () => {
    const {
      selectedData,
      duplicateRecord: cloneRecord,
    } = this.props;
    await cloneRecord(selectedData[0].file_token);

    const { fileToken } = this.props;
    if (fileToken !== '') {
      // If we got a new file token for the copied file, let's open it in the viewer.
      window.open(`${window.location.origin}/app/view/${fileToken}?from=uploaded`, '_self');
      message.success({
        // eslint-disable-next-line quotes
        content: "You've successfully copied the record.",
      });
    } else {
      // There was a problem copying the file, let's display the error.
      message.error({
        content: 'There was an issue copying the record, please try again or contact Medsender support.',
      });
    }
  }

  /**
   * If <SendRecordsDialog> data is valid, transmit the record via
   * email or fax, as selected by the user
   * @param  {String} org     organization name for a fax
   * @param  {String} contact organization contact for a fax
   * @param  {String} email   email address of Medsender user if sending thru
   *                          medsender
   * @param  {Boolean} canSend whether to allow the user to send the record
   *                           to others when sending thru Medsender
   * @return {Void}
   */
  handleSendDialogSubmit = async (org, contact, callerId, canSend, emailValid, isPatient, patient, documentType, memo, subject, signatureRequired, removeCoversheet, multipleProviderRecipients, allowPatientEdits, disableSecureReplies, scheduledDateTime) => {
    // To make an API call to send a record, we need to first get its
    // file token, which contains the record ID
    const recordId = this.props.selectedData.file_token || this.props.selectedData[0].file_token;
    // If email field is checked, send the record via Medsender
    // to another user's account
    const recipients = multipleProviderRecipients.map(recipientObj => recipientObj.recipient);
    if (emailValid && !isPatient && multipleProviderRecipients.length >= 1) {
      // If our params takes in an array of recipients which are just email addresses, we call the same endpoint with lesser params inputs.
      await this.props.sendToMultipleRecipients(recordId, recipients, canSend, documentType, subject, memo, signatureRequired, callerId, allowPatientEdits, org, contact, false, scheduledDateTime);
    } else if (isPatient) {
      await this.props.sendPatientRecord(recordId, patient, memo, disableSecureReplies);
    } else {
      // If our params takes in recipients containing both emails as well as faxes, we add two more inputs to our params while calling the same endpoint.
      await this.props.sendToMultipleRecipients(recordId, recipients, canSend, documentType, subject, memo, signatureRequired, callerId, allowPatientEdits, org, contact, removeCoversheet, scheduledDateTime);
    }
    // Close dialog after sending record
    await this.sendDialogVisible(false);
    const { faxError, faxErrorMessage } = this.props;
    if (!faxError) {
      message.success({
        content: 'Selected record(s) have been queued and will be sent shortly.',
      });
    } else {
      message.error({
        content: faxErrorMessage,
      });
    }
    const { selectedData } = this.props;
    const dateHasBeenChanged = formatDate(selectedData[0].patient_dob) !== formatDate(patient.dob);
    const nameHasBeenChanged = selectedData[0].patient_name !== patient.name;
    if (dateHasBeenChanged || nameHasBeenChanged) {
      setTimeout(() => { window.location.reload(); }, 800);
    }
  };

  archiveRecordOpen = (tokens, inboxLink, autoArchive) => {
    const { openModal } = this.props;
    const recordArrayData = { tokens };
    const modalType = {
      type: 'ARCHIVE_RECORD',
      data: recordArrayData,
      inboxLink,
      autoArchive,
    };

    openModal(modalType);
  }

  unarchiveRecordOpen = async (tokens) => {
    const {
      unarchiveRecords,
    } = this.props;
    await unarchiveRecords(tokens);

    const { archiveErrorMessage } = this.props;
    if (archiveErrorMessage) {
      message.error({
        content: 'We were unable to unarchive the selected records. Please try again',
      });
    } else {
      message.success({
        content: 'Successfully unarchived selected record(s)',
      });
    }
    window.location.reload();
  }

  render() {
    const { selectedData } = this.props;
    // To edit, selectedData array should have a single record
    // Also, can_edit_patient_info of that record must be true.
    const canEdit = (selectedData.length === 1 && (selectedData[0].can_edit_patient_info));
    const {
      defaultFaxNumber,
      associatedFaxNumbers,
      userCanSendFaxRecordsOnly,
    } = this.props;
    const viewingArchivedRecords = window.location.search.includes('archived');

    const mergeDropdownMenu = (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            const tokens = this.props.selectedData.map(x => x.file_token);
            this.handleMergeRecords(tokens);
          }}
        >
          Merge Records
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            const tokens = this.props.selectedData.map(x => x.file_token);
            this.handleMergeAndArchiveRecords(tokens);
          }}
        >
          Merge and Archive Records
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Helmet>
          <title>Uploaded Records - Medsender</title>
        </Helmet>
        <Header
          main="Uploaded Records"
          subtext="Choose a record below"
          locationPath={[
            { icon: 'home', text: 'Home', link: '/app' },
            { icon: 'inbox', text: 'Uploads', link: '/app/uploads' },
          ]}
          buttons={[
            {
              text: 'Send',
              icon: <SendOutlined />,
              disabled: selectedData.length !== 1,
              func: () => (this.checkPatientDetails()),
              secondary: true,
            },
            {
              text: 'Edit',
              icon: <EditOutlined />,
              disabled: !canEdit || selectedData.length !== 1,
              func: this.editPatientOpen,
            },
            {
              type: 'dropdown',
              text: 'Merge Records',
              icon: <MergeCellsOutlined />,
              disabled: selectedData.length <= 1,
              menu: mergeDropdownMenu,
            },
            {
              text: 'Upload',
              icon: <CloudUploadOutlined />,
              disabled: false,
              func: this.openUploadModal,
              secondary: true,
            },
            {
              text: viewingArchivedRecords ? 'Unarchive' : 'Archive',
              icon: <FolderOpenOutlined />,
              disabled: selectedData.length === 0,
              func: () => {
                const tokens = selectedData.map(x => x.file_token);
                viewingArchivedRecords ? this.unarchiveRecordOpen(tokens) : this.archiveRecordOpen(tokens, '/app/uploads');
              },
            },
            {
              text: 'Create Copy',
              icon: <CopyOutlined />,
              disabled: selectedData.length !== 1,
              func: () => this.cloneSelectedRecord(),
            },
          ]}
        />
        {this.state.sendDialogOpen
          && (
          <SendRecordsDialog
            faxSending={this.props.faxSending}
            handleSubmit={this.handleSendDialogSubmit}
            handleCancel={this.sendDialogVisible}
            open={this.state.sendDialogOpen}
            searchRecipient={this.props.searchRecipient}
            dataSource={this.props.dataSource}
            searchLoading={this.props.isLoading}
            patientName={this.props.selectedData.length === 1 ? this.props.selectedData[0].patient_name : ''}
            patientDOB={this.props.selectedData.length === 1 ? this.props.selectedData[0].patient_dob : ''}
            selectedRecord={this.props.selectedData[0]}
            defaultFaxNumber={defaultFaxNumber}
            associatedFaxNumbers={associatedFaxNumbers}
            userCanSendFaxRecordsOnly={userCanSendFaxRecordsOnly}
          />
          )}
        {
          this.props.loadingDialogOpen
            ? (
              <LoadingDialog
                loading={this.props.faxSending}
                error={this.state.errorMessage}
                cancel={this.props.closeSendDialog}
              />
            ) : null
        }
      </div>
    );
  }
}

const passedProps = {
  getSelectedPageData: getUploadedRecordsByPage,
  getPageDataWithQuery: getUploadedByQuery,
  data: 'uploaded_data',
  mode: 'Uploaded',
  enableSelectAll: true,
  columnTitles: uploadedColumns,
};

export default RecordsHOC(passedProps)(
  // Connect is needed here again so that we can pass and stay
  // subscribed to errors and state for both the header and dialogs
  connect(state => ({
    faxSending: state.faxes.isLoading,
    faxErrorMessage: state.faxes.errorMessage,
    faxError: state.faxes.error,
    selectedData: state.inboxTable.recordData,
    loadingDialogOpen: state.faxes.sendingDialogVisible,
    fileToken: state.records.fileToken,
    dataSource: state.search.results,
    isLoading: state.search.isLoading,
    associatedFaxNumbers: state.auth.data.associatedFaxNumbers,
    defaultFaxNumber: state.auth.data.defaultFaxNumber,
    userCanSendFaxRecordsOnly: state.auth.userCanSendFaxRecordsOnly,
    archiveErrorMessage: state.records.archiveErrorMessage,
    uploadedRecords: state.records.uploaded_data,
    documentTitle: state.records.documentTitle,
    documentCategory: state.records.documentCategory,
    documentCategories: state.records.documentCategories,
  }), {
    sendPatientRecord,
    sendToMultipleRecipients,
    closeSendDialog,
    searchRecipient,
    openModal,
    mergeRecords,
    duplicateRecord,
    unarchiveRecords,
  })(UploadedRecords),
);
