import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import Typography from '../../../components/Typography';
import Link from '../../../components/Link';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import PrivacyPolicy from '../../PrivacyPolicy';
import { accessCodeAuth } from '../../../actions/auth';
import { validFaxNumber } from '../../../global/validation';
import './accessCode.scss';
import COLORS from '../../../util/constants/colors';
import NewHeader from '../../NewHeader';

const AccessCode = ({ ...props }) => {
  const [accessCode, setAccessCode] = useState('');
  const [faxCode, setFaxCode] = useState(localStorage.getItem('m8_fax') || '');
  const [checked, setChecked] = useState(true);
  const [valuesWereReset, setValuesWereReset] = useState(false);
  const recaptchaRef = useRef();
  const {
    error,
    message,
  } = props;

  useEffect(() => {
    const { error } = props;
    if (error && !valuesWereReset) {
      setValuesWereReset(true);
    }
  }, [props.error]);


  const runCaptchaValidation = () => {
    recaptchaRef.current.execute();
  };

  /**
   * Submits data to backend. This function only executes once the captcha value
   * has been obtained
   * @param  {String} gcaptcha captcha value
   * @return {Void}
   */
  const captchaChange = (gcaptcha = '') => {
    if (!accessCode || !faxCode) return;
    const { accessCodeAuth } = props;

    try {
      // Removes any non-numeric chars in fax number
      const sanitizedFaxNumber = validFaxNumber(faxCode).rawNumber;
      const payload = {
        receiver_fax: sanitizedFaxNumber,
        access_code: accessCode,
      };
      accessCodeAuth(payload, gcaptcha, checked);
    } catch (e) {
      window.raven.captureException(e);
    }
    recaptchaRef.current.reset();
  };

  return (
    <div>
      <Helmet>
        <title>Access Code - Medsender</title>
      </Helmet>
      <NewHeader />
      <div className="access-code-form">
        <Form
          name="access-code-form"
          fields={[
            {
              name: ['faxCode'],
              value: faxCode,
            },
            {
              name: ['accessCode'],
              value: accessCode,
            },
          ]}
          onFinish={() => {
            runCaptchaValidation();
            setValuesWereReset(false);
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            onChange={captchaChange}
            size="invisible"
            sitekey="6LdHIDUUAAAAAOo8pYlwLxgEpDA3YD4bgGWRTHAA"
          />
          <Typography className="access-title" variant="h3">Enter your access code</Typography>
          {
            error
            && <Typography className="access-title" variant="subtitleTwo" color={COLORS.ERROR_LITE}>{message}</Typography>
          }
          <Form.Item
            name="accessCode"
            rules={[
              {
                required: true,
                message: 'Access Code is required',
              },
            ]}
          >
            <Input
              label="Access Code"
              placeholder="ABC123"
              value={accessCode}
              onChange={e => setAccessCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="faxCode"
            rules={[
              {
                required: true,
                message: 'Fax Code is required',
              },
              {
                /* eslint-disable-next-line no-confusing-arrow */
                validator: (_, value) => validFaxNumber(value).valid
                  ? Promise.resolve()
                  : Promise.reject('Invalid Fax Code'),
              },
            ]}
          >
            <Input
              label="Fax Code"
              placeholder="555555555"
              value={faxCode}
              onChange={e => setFaxCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>
              <Typography variant="text" size="medium">Remember me</Typography>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              label="Submit"
              htmlType="submit"
            />
          </Form.Item>
        </Form>
        <Typography variant="text" size="medium">
          Existing user?&nbsp;
          <Link href="/login" label="Log in here" size="medium" />
        </Typography>
      </div>
      <PrivacyPolicy />
    </div>
  );
};

AccessCode.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  accessCodeAuth: PropTypes.func.isRequired,
};

AccessCode.defaultProps = {
  message: '',
  loading: false,
};

export default connect(
  state => ({
    error: state.auth.error,
    data: state.auth.data,
    loading: state.auth.isGuestLoading,
    message: state.auth.accessCodeErrorMessage,
  }),
  { accessCodeAuth },
)(AccessCode);

export {
  AccessCode,
};
