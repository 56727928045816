// Displays modal for user to edit file name before auto uploading

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AutoUpload from '../../components/modal/AutoUpload';
import { triggerAutoUpload, triggerAutoUploadAndTaskCreation } from '../../actions/records';
import { getDocumentCategories, getEmaTaskProviders, getTaskTemplatesByQuery, getEmaPatients } from '../../actions/userProfile';

class AutoUploadDialog extends Component {
  render() {
    return (
      <div>
        <AutoUpload
          {...this.props}
        />
      </div>
    );
  }
}

AutoUploadDialog.defaultProps = {
  fileName: '',
};

AutoUploadDialog.propTypes = {
  fileName: PropTypes.string,
  triggerAutoUpload: PropTypes.func.isRequired,
  triggerAutoUploadAndTaskCreation: PropTypes.func.isRequired,
};

export default connect(state => ({
  sendToken: state.auth.data.sendToken,
  fileName: state.auth.data.fileName,
}), {
  triggerAutoUpload,
  getDocumentCategories,
  getEmaTaskProviders,
  triggerAutoUploadAndTaskCreation,
  getTaskTemplatesByQuery,
  getEmaPatients,
})(AutoUploadDialog);
