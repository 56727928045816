import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { snakeCase } from 'snake-case';
import { Menu, message } from 'antd';
import { parseFullName } from 'parse-full-name';
import {
  SendOutlined,
  EditOutlined,
  PrinterOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  LinkOutlined,
  DesktopOutlined,
  FormOutlined,
  BellOutlined,
  TagOutlined,
  SplitCellsOutlined,
  FolderOpenOutlined,
  SyncOutlined,
  DoubleRightOutlined,
  CheckCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import {
  Header,
  LoadingDialog,
  SendRecordsDialog,
  Viewer,
} from '../components';
import download from '../actions/helpers/download';
import {
  getRecordById,
  printUserRecord,
  saveAnnotationsOnRecord,
  archiveRecords,
  archiveReceivedRecords,
  unarchiveReceivedRecords,
  unarchiveRecords,
  triggerAutoUpload,
  triggerGoogleDriveUpload,
  triggerSFTPUpload,
  triggerSharepointUpload,
  getNextRecord,
  assignDocument,
  markDocumentAsCompleted,
  getAssignedRecord,
  clearDownloadUrl,
} from '../actions/records';
import { extendUserSession } from '../actions/auth';
import { sendPatientRecord, sendToMultipleRecipients } from '../actions/faxes';
import { closeSendDialog, removeSendDialogParam } from '../actions/appActions';
import { getDefaultSmsTemplate } from '../actions/userProfile';
import searchRecipient from '../actions/search';
import { openModal, hideModal } from '../actions/modal';
import { verifyFeatureAccess } from '../global/featureFlags';
import ErrorDisplay from '../components/viewer/errorDisplay';
import '../components/main.scss';
import { isOnUnsupportedBrowser } from '../global/browser.js';
import ChatTab from './ChatTab/ChatTab';

// See comment in app/containers/RecordViewerPatient.jsx for more explanation
let printedPdfWindow;

class RecordViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false, // Is the send fax dialog open on the page or not?
      patientName: props.patientName || '',
      patientDOB: props.patientDOB || '',
      patientGender: props.patientGender || '',
      patientPhoneNumber: props.patientPhoneNumber || '',
      senderName: props.senderName || '',
      senderOrganization: props.senderOrganization || '',
      organization: props.organization || '',
      senderContact: props.senderContact || '',
      canForward: false,
      moveToNextRecord: false,
      secureLink: false,
      autoSendEnabled: false, // Did the user check the open send dialog option in Gibraltar
      inboxLink: '/app/uploads', // Link to go back to previous inbox page.
      downloadDisabled: false, // Disables the download button when download is in progress
      recordBlobObject: null, // stores the current state of the file blob, see updateRecordBlob()
      saveLoading: false, // User is in process of trying to save changes to the document
      isReplyButtonClicked: false, // Did user click on reply or send?
      autoUploadToEMR: false,
      createPatientChart: false,
      hideTaskButton: false,
      taskCreation: false,
      uploadToGoogleDrive: false,
      uploadToSFTPFolder: false,
      uploadToSharepointFolder: false,
      sendSmsMessage: false,
      referralReplyTemplates: false,
      receivedAt: props.receivedAt,
      createdAt: props.createdAt,
      callerName: props.callerName,
      documentTitle: props.documentTitle || '',
      documentCategory: props.documentCategory || '',
      documentCategories: props.documentCategories || '',
      unsavedSignatures: false,
      markCompleteButtonOption: localStorage.mark_complete_reassign_option || 'Mark Complete',
      referralNote: props.referralNote || '',
      referringProvider: props.referringProvider || '',
      referringProviderOffice: props.referringProviderOffice || '',
      scheduledDateTime: null,
    };
  }

  componentWillMount() {
    const { match, getRecord } = this.props;
    // GETs record by its ID
    const { id } = match.params;

    // Check for existence of query params in the route
    // if ?send=true query param is in the URL, open the dialog immediately,
    // which may be needed by Gibraltar, if the user has enabled this setting
    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (queryParams.send === 'true') {
      this.checkPatientDetails(true);
    }

    // No need to check if it's equal to 'Uploaded' as that as the default inboxLink
    // edit: 6/1/2020 the additional ternary is a temporary fix for being able to return
    // to received records inbox in the new UI which we can't do currently because the old header component
    // is not being used in the new UI and thus queryParams weren't getting passed in. Works for now
    // but will need to be changed when we convert a new inbox into the new UI
    const fromPage = !queryParams.from
      ? 'received'
      : queryParams.from.toLowerCase();
    // The endpoint now takes a boolean variable in addition to the record ID. This is b/c the backend
    // needs to know if the original record should be returned or the edited version. If it's from the sent
    // inbox, the original unedited record will be returned.
    this.getNewRecord(id, fromPage === 'sent');

    if (['sent', 'received', 'assigned', 'referred'].includes(fromPage)) {
      this.setState({ inboxLink: `/app/${fromPage}` });
    }
  }

  /**
   * Display the appropriate toast messages
   * if record is "saved to medsender", display success message
   * If record already exists in user's account, display warning toast message
   * Hides the loading modal
   */
  componentDidMount = async () => {
    const canForward = await verifyFeatureAccess('fwd_to_emr');
    const secureLink = await verifyFeatureAccess('secure_link');
    const autoUploadToEMR = await verifyFeatureAccess('manual_upload_emr');
    const createPatientChart = await verifyFeatureAccess('create_patient_chart');
    const hideTaskButton = await verifyFeatureAccess('hide_task_creation_button');
    const taskCreation = await verifyFeatureAccess('task_creation');
    const uploadToGoogleDrive = await verifyFeatureAccess('upload_google_drive');
    const uploadToSFTPFolder = await verifyFeatureAccess('sftp_upload');
    const uploadToSharepointFolder = await verifyFeatureAccess('sharepoint_upload');
    const moveToNextRecord = await verifyFeatureAccess('move_to_next_record');
    const sendSmsMessage = await verifyFeatureAccess('referral_sms');
    const referralReplyTemplates = JSON.parse(localStorage.getItem('referral_reply_templates')) || [];
    this.setState({
      canForward,
      secureLink,
      autoUploadToEMR,
      createPatientChart,
      hideTaskButton,
      taskCreation,
      uploadToGoogleDrive,
      uploadToSFTPFolder,
      uploadToSharepointFolder,
      moveToNextRecord,
      sendSmsMessage,
      referralReplyTemplates,
    });
    // On subsequent refreshes, remove the ?send=true param so that the dialog
    // does not open on page load
    // See comment in app/javascript/src/actions/appActions.js for more
    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.props.removeSendDialogParam(queryParams.from, queryParams.sendToken, queryParams.assignedDocumentId);
    if (this.props.modalLogin) {
      const { errorSaving, recordSaved } = this.props;
      if (errorSaving.error) {
        toastr.warning(errorSaving.errorMessage);
      } else if (recordSaved) {
        toastr.success('Record has been saved to your Medsender account.');
      }
      return this.props.hideModal();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.documentTitle) {
      this.setState({ documentTitle: this.props.documentTitle });
    }
    this.setState({ errorMessage: nextProps.faxErrorMessage });
    if (nextProps.senderName !== this.state.senderName || nextProps.senderContact !== this.state.senderContact || nextProps.senderOrganization !== this.state.senderOrganization) {
      this.setState({
        senderContact: nextProps.senderContact,
        senderName: nextProps.senderName,
        senderOrganization: nextProps.senderOrganization,
      });
    }
    // Update the patient name and dob only if its different form the current values.
    if (nextProps.patientDOB !== this.state.patientDOB || nextProps.patientName !== this.state.patientName) {
      this.setState({
        patientName: nextProps.patientName,
        patientDOB: nextProps.patientDOB,
        patientGender: nextProps.patientGender,
        patientPhoneNumber: nextProps.patientPhoneNumber,
        patientAddress: nextProps.patientAddress,
        patientZipCode: nextProps.patientZipCode,
        patientCity: nextProps.patientCity,
        practitionerName: nextProps.practitionerName,
      }, () => {
        // if the open send dialog option was selected in Gibraltar this will call the
        // checkPatientDetails function only after the patient data has been set to state.
        if (this.state.autoSendEnabled) {
          this.checkPatientDetails(false);
        }
      });
    }

    // eslint-disable-next-line react/prop-types
    const { canEditAfterSend, match: { params: { id } } } = this.props;

    if (nextProps.canEditAfterSend !== canEditAfterSend) {
      this.getNewRecord(id);
    }
  }

  componentWillUnmount() {
    this.props.clearDownloadUrl();
  }

  getNewRecord = async (id, fromSentInbox = false) => {
    // The endpoint now takes a boolean variable in addition to the record ID. This is b/c the backend
    // needs to know if the original record should be returned or the edited version. If it's from the sent
    // inbox, the original unedited record will be returned.
    await this.props.getRecord(id, fromSentInbox);
  }

  /**
   * Open send record dialog
   */
  sendRecord = async (autoSendEnabled) => {
    if (autoSendEnabled) {
      this.setState({
        autoSendEnabled,
      });
      return;
    }

    const {
      patientDOB, patientName, documentTitle, documentCategory, referralNote,
    } = this.state;
    let titleText;
    if (!patientName && !documentTitle) {
      titleText = 'patient name or document title';
    }

    const {
      canEditPrivacyLevel,
      canEditPatientInfo,
      privacyLevel,
      aiStatus,
      hasWrongExtractionBeenReported,
      patientInfoEdited,
      failedColumn,
      match,
      documentCategories,
    } = this.props;

    if (titleText) {
      const patient = {
        file_token: match.params.id,
        patient_name: patientName,
        patient_dob: patientDOB,
        can_edit_patient_info: canEditPatientInfo,
        can_edit_privacy_level: canEditPrivacyLevel,
        privacy_level: privacyLevel,
        patient_info_edited: patientInfoEdited,
        has_wrong_extraction_been_reported: hasWrongExtractionBeenReported,
        ai_status: aiStatus,
        failed_column: failedColumn,
        document_title: documentTitle,
        document_category: documentCategory,
        document_categories: documentCategories,
        referral_note: referralNote,
      };
      const modalType = {
        type: 'EDIT_PATIENT',
        data: {
          patient,
          showSendDialog: this.dialogVisible,
          title: `To send this record please update the ${titleText}.`,
          buttonText: 'Continue',
        },
      };
      this.props.openModal(modalType);
    } else {
      this.setState({ dialogOpen: true });
    }
  }

  /**
  * When the Send button is clicked, check if the record has a patient name and dob.
  * The Edit Patient dialog will be opened if either is missing
  * The send dialog will be opened if both are present
  * @param  {Boolean} autoSendEnabled Shows if user selected to open the send dialog.
  * @return {Void}
  */
  checkPatientDetails = async (autoSendEnabled, isReplyButtonClicked) => {
    this.setState({ isReplyButtonClicked });
    if (this.state.recordBlobObject) {
      if (this.state.unsavedSignatures) {
        await this.flattenAnnotations(false);
        return;
      }
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    this.sendRecord(autoSendEnabled);
  }

  /**
   * Hide/show the <SendRecordsDialog>
   * @param  {Boolean} bool whether to hide or show
   * @return {Void}
   */
  dialogVisible = async (bool) => {
    const canSend = await verifyFeatureAccess('send_record');
    if (canSend) {
      return this.setState({ dialogOpen: bool });
    }
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: 'send_record',
    };
    this.props.openModal(modalType);
  }

  editPatientOpen = () => {
    const {
      patientName,
      patientDOB,
      privacyLevel,
      canEditPrivacyLevel,
      canEditPatientInfo,
      aiStatus,
      hasWrongExtractionBeenReported,
      patientInfoEdited,
      failedColumn,
      match,
      selectedLabels,
      documentTitle,
      documentCategory,
      documentCategories,
      referralNote,
      referringProvider,
      referringProviderOffice,
    } = this.props;
    const patient = {
      file_token: match.params.id,
      patient_name: patientName,
      patient_dob: patientDOB,
      privacy_level: privacyLevel,
      can_edit_privacy_level: canEditPrivacyLevel,
      can_edit_patient_info: canEditPatientInfo,
      patient_info_edited: patientInfoEdited,
      has_wrong_extraction_been_reported: hasWrongExtractionBeenReported,
      ai_status: aiStatus,
      failed_column: failedColumn,
      labels: selectedLabels,
      document_title: documentTitle,
      document_category: documentCategory,
      document_categories: documentCategories,
      referral_note: referralNote,
      referring_provider: referringProvider,
      provider_office: referringProviderOffice,
    };
    const { receiverType, sendToken } = this.props;
    // Get fromPage params
    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });
    const fromPage = !queryParams.from ? queryParams.from : queryParams.from.toLowerCase();
    const modalType = {
      type: 'EDIT_PATIENT',
      data: {
        patient,
        fromPage,
        selectedLabelsRecordViewer: selectedLabels,
        receiverType,
        record: sendToken,
      },
    };
    this.props.openModal(modalType);
  };

  splitSelectedRecord = () => {
    const {
      match,
      patientName,
      patientDOB,
      privacyLevel,
      canEditPrivacyLevel,
      canEditPatientInfo,
      location,
      numPages,
      documentTitle,
      documentCategory,
    } = this.props;
    const patient = {
      file_token: match.params.id,
      patient_name: patientName,
      patient_dob: patientDOB,
      privacy_level: privacyLevel,
      can_edit_privacy_level: canEditPrivacyLevel,
      can_edit_patient_info: canEditPatientInfo,
      document_title: documentTitle,
      document_category: documentCategory,
    };
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const fromPage = !queryParams.from ? queryParams.from : queryParams.from.toLowerCase();

    const modalType = {
      type: 'SPLIT_SELECTED_RECORD',
      data: {
        patient,
        fromPage,
        token: queryParams.sendToken,
        numPages,
      },
    };
    this.props.openModal(modalType);
  };

  moveToNextRecord = async () => {
    if (this.state.recordBlobObject) {
      if (this.state.unsavedSignatures) {
        await this.flattenAnnotations(false);

        const email = localStorage.getItem('m8_uid');
        if (email !== 'mvennamaneni@ahcpllc.com') {
          return;
        }
        await this.handleDialogSubmit('', '', null, true, true, false, {}, 'medical_record', '', '', false, false, [{ recipient: this.props.senderContact }], true, false);
      }
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    const { sendToken } = this.props;
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    await this.props.getNextRecord(sendToken || searchParams.sendToken);

    const { nextSendToken, nextFileToken } = this.props;
    if (nextSendToken && nextFileToken) {
      await this.context.router.history.push(`/app/view/${nextFileToken}?send=false&from=received&sendToken=${nextSendToken}`);
      window.location.reload();
    } else {
      message.info({
        content: 'There are no more records to view. We will redirect you back to the inbox page.',
      });

      // After 3 seconds go to inbox page.
      setTimeout(() => { this.context.router.history.push('/app/received'); }, 3000);
    }
  };

  handleDialogSubmit = async (org, contact, callerId, canSend, emailValid, isPatient, patient, documentType, memo, subject, signatureRequired, removeCoversheet, multipleProviderRecipients, allowPatientEdits, disableSecureReplies, scheduledDateTime) => {
    // Get record id from url
    const recordId = this.props.fileToken;
    const recipients = multipleProviderRecipients.map(recipientObj => recipientObj.recipient);
    if (emailValid && !isPatient && multipleProviderRecipients.length >= 1) {
      await this.props.sendToMultipleRecipients(recordId, recipients, canSend, documentType, subject, memo, signatureRequired, callerId, allowPatientEdits, org, contact, false, scheduledDateTime);
    } else if (isPatient) {
      await this.props.sendPatientRecord(recordId, patient, memo, disableSecureReplies);
    } else {
      await this.props.sendToMultipleRecipients(recordId, recipients, canSend, documentType, subject, memo, signatureRequired, callerId, allowPatientEdits, org, contact, removeCoversheet, scheduledDateTime);
    }
    const { faxError, faxErrorMessage } = this.props;
    if (!faxError) {
      message.success({
        content: 'Selected record(s) have been queued and will be sent shortly.',
      });
    } else {
      message.error({
        content: faxErrorMessage,
      });
    }
    // Close dialog after sending record
    this.dialogVisible(false);
  }

  linkDialogVisible = async (canPublishForm = false) => {
    const flag = canPublishForm ? 'publish_forms' : 'secure_link';
    const canCopyLink = await verifyFeatureAccess(flag);
    const { fileToken, openModal: handleOpenModal, isFormDocument } = this.props;
    if (canCopyLink) {
      const modalType = {
        type: 'SECURE_LINK',
        data: {
          fileToken,
          isFormDocument,
        },
      };
      return handleOpenModal(modalType);
    }
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: flag,
    };
    handleOpenModal(modalType);
  }

  openAddTagModal = () => {
    const {
      sendToken,
      openModal: handleOpenModal,
      receiverType,
    } = this.props;

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendToken,
        receiverType,
      },
    };
    return handleOpenModal(modalType);
  }

  openNotifyModal = () => {
    const { sendToken, openModal: handleOpenModal } = this.props;
    const modalType = {
      type: 'NOTIFY',
      data: {
        record: sendToken,
      },
    };
    return handleOpenModal(modalType);
  }

  createAssignedDocument = async () => {
    if (this.state.recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    const { sendToken, openModal: handleOpenModal, receiverType } = this.props;
    let identifier = sendToken;
    // Pass in either email attachment id
    if (receiverType === 'email_attachment') {
      identifier = this.props.location.search.match(/sendToken=([^&]+)/)[1].toString();
    }
    const modalType = {
      type: 'ASSIGN_RECORD',
      data: {
        record: identifier,
        receiverType,
      },
    };
    return handleOpenModal(modalType);
  }

  editLabel = () => {
    const {
      sendToken,
      openModal: handleOpenModal,
      selectedLabels,
      receiverType,
      hasWrongLabelingBeenReported,
      isLabelEdited,
      aiStatus,
    } = this.props;

    const reportAiDetails = {
      hasWrongLabelingBeenReported,
      aiStatus,
      isLabelEdited,
    };
    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendToken,
        selectedLabelsRecordViewer: selectedLabels,
        receiverType,
        reportAiDetails,
      },
    };
    return handleOpenModal(modalType);
  }

  openBrowserUpgradeModal = () => {
    const { openModal: handleOpenModal } = this.props;
    const modalType = {
      type: 'UPGRADE_BROWSER',
    };
    return handleOpenModal(modalType);
  }

  downloadRecord = async (archive, inboxLink) => {
    const { printUserRecord: printRecord, patientName, fileToken } = this.props;
    try {
      this.toggleDownloadButton();
      const response = await printRecord(fileToken);
      // snakeCase package doesn't allow passing undefined, so we need to pass an empty string if so.
      const patientNameSnakeCase = snakeCase(patientName || '');
      const customFileName = `${patientNameSnakeCase}-${fileToken.slice(-7).toLowerCase()}`;
      await download(this.props.downloadUrl, customFileName);
      if (archive) this.beginArchiveRecord(inboxLink);
      this.toggleDownloadButton();
    } catch (e) {
      toastr.error('Unable to download this record. Try refreshing and downloading again.');
    }
  }

  beginArchiveRecord = async (inboxLink) => {
    const {
      archiveErrorMessage, sendToken, fileToken, archiveRecords, archiveReceivedRecords, match,
    } = this.props;
    // for the edge case of archiving records sent to the user by the user, we need to get the sendToken from the url
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    const oldUneditedFileToken = match.params.id;
    // get sendToken from the searchparams for received records sent to self
    // The file token changes to edited record when in record viewer if there are edits; if that's the case, send in both tokens so it disappears from the inbox too
    const tokensToArchive = oldUneditedFileToken !== fileToken ? [fileToken, oldUneditedFileToken] : [fileToken];
    if (inboxLink === '/app/uploads') {
      await archiveRecords(tokensToArchive);
    } else {
      await archiveReceivedRecords([sendToken || searchParams.sendToken]);
    }
    if (!archiveErrorMessage) message.success({ content: 'Record successfully archived' });
    else { message.error({ content: 'We were unable to archive the selected records. Please try again' }); }
  }

  beginUnarchiveRecord = async (inboxLink) => {
    const {
      sendToken, unarchiveReceivedRecords, unarchiveRecords, match, fileToken, error,
    } = this.props;
    // for the edge case of unarchiving records sent to the user by the user, we need to get the sendToken from the url
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    const oldUneditedFileToken = match.params.id;
    // get sendToken from the searchparams for received records sent to self
    // The file token changes to edited record when in record viewer if there are edits; if that's the case, send in both tokens so it disappears from the inbox too
    const tokensToArchive = oldUneditedFileToken !== fileToken ? [fileToken, oldUneditedFileToken] : [fileToken];
    if (inboxLink === '/app/uploads') {
      await unarchiveRecords(tokensToArchive);
    } else {
      await unarchiveReceivedRecords([sendToken || searchParams.sendToken]);
    }
    if (!error) message.success({ content: 'Record successfully unarchived' });
    else { message.error({ content: 'We were unable to unarchive the record. Please try again' }); }
  }

  printRecord = async () => {
    // Wait for backend to complete audit log
    const canPrint = await verifyFeatureAccess('send_record');
    if (!canPrint) {
      const modalType = {
        type: 'RAISE_FLAG',
        featureFlag: 'send_record',
      };
      return this.props.openModal(modalType);
    }
    printedPdfWindow = window.open();
    const { printUserRecord: printRecord, fileToken } = this.props;
    const response = await printRecord(fileToken);
    if (response === 0) {
      printedPdfWindow.location.href = this.props.downloadUrl;
      printedPdfWindow.opener = null;
    } else {
      toastr.error('Unable to print this record. Try refreshing and printing again.');
    }
  }

  toggleDownloadButton = () => {
    this.setState(prevState => ({
      downloadDisabled: !prevState.downloadDisabled,
    }));
  }

  forwardToEMR = async (recordId, action) => {
    const canFwdToEMR = await verifyFeatureAccess('fwd_to_emr');
    const type = canFwdToEMR ? action : 'RAISE_FLAG';
    const { openModal } = this.props;
    const modalType = {
      type,
      data: recordId,
      inboxLink: '/app/received',
      featureFlag: 'fwd_to_emr',
    };
    openModal(modalType);
  }

  beginSaveAnnotationsOnRecord = async (file, recordId) => {
    // Save edits to the record, sets the state on recordHasunsavedEdits to false to allow
    // the record to be sent. We don't allow users to send files that have unsaved edits.
    const { saveAnnotationsOnRecord, error } = this.props;
    this.setState({ loading: true });
    await saveAnnotationsOnRecord(file, recordId);
    this.setState({ loading: false });
    if (!error) return toastr.success('Changes saved');
    toastr.error('Unable to save changes. Please try again');
  }

  updateRecordBlob = (file) => {
    // Updates the file blob saved in state, run on initial load and any when changes are made
    // after that. Passed into beginSaveAnnotationsOnRecord() when document is autosaved when
    // user tries to save.
    this.setState({
      recordBlobObject: file,
    });
  }

  updateDocumentOpen = async () => {
    if (this.state.recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    const { openModal, getAssignedRecord } = this.props;
    const { assignedDocumentId } = parse(this.props.location.search, { ignoreQueryPrefix: true });

    await getAssignedRecord(assignedDocumentId);

    const { assignedDocument } = this.props;

    const modalType = {
      type: 'UPDATE_ASSIGNED_DOCUMENT',
      data: {
        selectedDocumentStatus: assignedDocument.document_status_id,
        assignedDocumentId,
        assignee: { label: assignedDocument.assignee, value: assignedDocument.assignee_email, key: assignedDocument.assignee_email },
      },
    };
    openModal(modalType);
  };

  updateReferralOpen = async () => {
    if (this.state.recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    const {
      sendToken,
      receiverType,
      openModal,
      match,
      referralNote,
    } = this.props;

    const modalType = {
      type: 'UPDATE_REFERRAL',
      data: {
        sendToken,
        parentType: receiverType,
        fileToken: match.params.id,
        referralNote,
      },
    };
    openModal(modalType);
  };

  uploadToGoogleDrive = async (sendToken) => {
    const { triggerGoogleDriveUpload: triggerDriveUpload } = this.props;
    await triggerDriveUpload(sendToken);
    const { error, errorCode } = this.props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to Google Drive.',
      });
    } else if (errorCode === 400) {
      message.error({
        content: 'A Google Drive Folder does not exist for the selected Fax Number. Please contact a Medsender admin to create a Fax Folder.',
      });
    } else {
      message.error({
        content: 'We were unable to upload to Google Drive. Please try again.',
      });
    }
  }

  newChart = async () => {
    const { openModal: handleOpenModal, receiverType } = this.props;
    const token = this.props.location.search.match(/sendToken=([^&]+)/)[1];
    const modalType = {
      type: 'CREATE_NEW_CHART',
      data: {
        name: this.props.patientName,
        dob: this.props.patientDOB,
        gender: this.props.patientGender,
        phoneNumber: this.props.patientPhoneNumber,
        address: this.props.patientAddress,
        zipCode: this.props.patientZipCode,
        city: this.props.patientCity,
        record: receiverType === 'email_attachment' ? token : this.props.sendToken,
        practitionerName: this.props.practitionerName,
        fileName: this.props.documentTitle,
        documentCategory: this.props.documentCategory,
        receiverType,
        state: this.props.patientState,
        patient_email: this.props.patientEmail,
        patient_first_name: this.props.patientFirstName,
        patient_last_name: this.props.patientLastName,
        referring_provider: this.props.referringProvider,
        provider_office: this.props.referringProviderOffice,
        primary_plan: this.props.primaryPlan,
        groupNumber: this.props.groupNumber,
        primaryMemberId: this.props.primaryMemberId,
        payerName: this.props.payerName,
        secondaryPayerName: this.props.secondaryPayerName,
        secondaryMemberId: this.props.secondaryMemberId,
        secondaryGroupNumber: this.props.secondaryGroupNumber,
        apiCredentials: this.props.apiCredentials,
      },
    };

    handleOpenModal(modalType);
  }

  createTask = async () => {
    const { openModal: handleOpenModal, receiverType } = this.props;
    const token = this.props.location.search.match(/sendToken=([^&]+)/)[1];
    const modalType = {
      type: 'CREATE_TASK',
      data: {
        name: this.props.patientName,
        dob: this.props.patientDOB,
        record: receiverType === 'email_attachment' ? token : this.props.sendToken,
        practitionerName: this.props.practitionerName,
        fileName: this.props.documentTitle,
        receiverType,
      },
    };

    handleOpenModal(modalType);
  }

  uploadToSFTPFolder = async (sendToken) => {
    const { triggerSFTPUpload: triggerUpload } = this.props;
    await triggerUpload(sendToken);
    const { error, errorCode, errorMessage } = this.props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to SFTP Server.',
      });
    } else if (errorCode === 400) {
      message.error({
        content: 'A SFTP Folder does not exist for the selected Fax Number. Please contact a Medsender admin to create a Fax Folder.',
      });
    } else if (errorCode === 403) {
      if (errorMessage === 'No SFTP Credential found on your account.') {
        message.error({
          content: 'Your SFTP service is missing credentials. Please contact Medsender support to add credentials.',
        });
      } else {
        message.error({
          content: 'You do not have access to this feature.',
        });
      }
    } else {
      message.error({
        content: 'We were unable to upload to the SFTP Server. Please try again.',
      });
    }
  }

  uploadToSharepointFolder = async (sendToken) => {
    const { triggerSharepointUpload: triggerUpload } = this.props;
    await triggerUpload(sendToken);
    const { error, errorCode } = this.props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to Microsoft Sharepoint',
      });
    } else if (errorCode === 422) {
      message.error({
        content: 'There is an existing file with the same name as the document you are trying to upload.',
      });
    } else {
      message.error({
        content: 'We were unable to upload to Microsoft Sharepoint. Please try again.',
      });
    }
  }

  smsMessage = async (selectedRecord, smsMessageFeature) => {
    const {
      sendToken,
      receiverType,
      patientName,
      patientDOB,
      patientPhoneNumber,
      openModal,
    } = this.props;
    if (!patientName || !patientDOB) {
      message.error({
        content: 'Cannot send a message without a valid patient name and date of birth. Edit record details and try again.',
      });
    } else {
      const parsedName = parseFullName(patientName)?.first;
      const firstName = parsedName ? parsedName : patientName;

      try {        
        // Call endpoint to get the default SMS template
        await this.props.getDefaultSmsTemplate(sendToken, receiverType);
  
        const allMessageTemplates = JSON.parse(localStorage.getItem('referral_sms_template'));
        // Only display templates for departments current user is in
        const userDepartmentIds = JSON.parse(localStorage.getItem('departments')).map(dept => dept.id);
        const userTemplates = allMessageTemplates.filter(template => 
          template.owner_type === "Organization" ||
          (template.owner_type === "Department" && userDepartmentIds.includes(template.owner_id))
        );        
  
  
        const modalType = {
          type: 'SMS_MESSAGE',
          data: {
            sendToken,
            receiverType,
            patientName,
            messageTemplates: userTemplates,
            messageText: `Hello ${firstName}, ${this.props.defaultSmsTemplate.message}`,
            url: this.props.defaultSmsTemplate.short_url,
            originalUrl: this.props.defaultSmsTemplate.url,
            patientPhoneNumber,
            smsMessageFeature,
          },
        };
        return openModal(modalType);
      } catch (error) {
        console.error('Error fetching default template:', error);
        message.error('Failed to load default message template');
      }
    }
  }

  referralReply = async () => {
    const {
      openModal,
      sendToken,
      patientName,
      patientDOB,
    } = this.props;
    const modalType = {
      type: 'REFERRAL_REPLY',
      data: {
        sendToken,
        caller_name: this.props.senderContact,
        patientDOB,
        patientName,
        referringProvider: this.props.referringProvider,
      },
    };
    return openModal(modalType);
  }

  autoUploadRecord = async (token, patientDOB, patientName, autoUploadEmr) => {
    // If EMR is EMA, we will allow Send Chart Modal to open as Patient MRN is an option to send with
    // chart instead of Patient Name and DOB.
    if (autoUploadEmr !== 'EMA' && (!patientName || !patientDOB)) {
      message.error({
        content: 'Cannot upload a document without a valid name and date of birth. Edit record details and try again.',
      });
    } else {
      const {
        triggerAutoUpload,
        receivedAt,
        receiverType,
        callerName,
        openModal,
        autoUploadEmr,
        createdAt,
        senderName,
        documentTitle,
        currentOrganizationId,
        apiCredentials,
      } = this.props;
      let sendToken;
      if (!token) {
        sendToken = this.props.location.search.match(/sendToken=([^&]+)/)[1].toString();
      } else {
        sendToken = token;
      }

      const fileNameSupported = ['Kareo', 'OncoEMR', 'Nymbl', 'EMA', 'AllegianceMD', 'Elation'];

      // We want to enable modal support for new ECW practices without affect the old practices' workflow
      if (fileNameSupported.includes(autoUploadEmr) || (autoUploadEmr === 'ECW' && currentOrganizationId > 4206) || (autoUploadEmr === 'CareTracker' && currentOrganizationId === 4477)) {
        let recordFileName = documentTitle;
        if (!recordFileName && receiverType !== 'email_attachment') {
          recordFileName = `${receivedAt?.replace(/ /g, '_')}_${callerName?.replace(/ /g, '_')}`;
        } else if (!recordFileName) {
          recordFileName = `${createdAt?.replace(/ /g, '_')}_${senderName?.replace(/ /g, '_')}`;
        }
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            fileName: recordFileName,
            providerName: this.props.practitionerName,
            patientName: this.props.patientName,
            patientDOB: this.props.patientDOB,
            documentCategory: this.props.documentCategory,
            sendToken,
            receiverType: !(this.state.inboxLink === '/app/sent') ? receiverType : 'receiver',
            noTaskCreation: this.state.inboxLink === '/app/sent',
            apiCredentials,
          },
        };
        await openModal(modalType);
      } else if (autoUploadEmr === 'AdvancedMD') {
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            providerName: this.props.practitionerName, // Once we have AI extraction, this will be replaced with the extracted value
            sendToken,
            autoUploadEmr,
            receiverType,
          },
        };
        openModal(modalType);
      } else {
        await triggerAutoUpload({ sendToken, receiverType });
        const { error } = this.props;
        if (!error) {
          message.success({
            content: 'Record will be uploaded shortly. We will notify you of any issues via email.',
          });
        } else {
          message.error({
            content: 'Unable to autoupload document.',
          });
        }
      }
    }
  }

  markComplete = async () => {
    if (this.state.recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    localStorage.setItem('mark_complete_reassign_option', 'Mark Complete');
    this.setState({ markCompleteButtonOption: 'Mark Complete' });


    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });

    const { markDocumentAsCompleted } = this.props;
    await markDocumentAsCompleted(queryParams.assignedDocumentId);

    const { error } = this.props;
    if (!error) {
      message.success({
        content: 'Your record has been marked as completed. You\'ll be redirected back to the assigned records page.',
      });

      // After 3 seconds, go to assigned records page.
      setTimeout(() => { this.context.router.history.push('/app/assigned'); }, 3000);
    } else {
      message.error({
        content: 'Unable to mark as completed. Please try again.',
      });
    }
  }

  markCompleteAndReassign = async () => {
    if (this.state.recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    const { openModal } = this.props;

    localStorage.setItem('mark_complete_reassign_option', 'Mark Complete and Reassign');
    this.setState({ markCompleteButtonOption: 'Mark Complete and Reassign' });

    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });

    const { sendToken, receiverType } = this.props;

    const modalType = {
      type: 'ASSIGN_RECORD',
      data: {
        record: sendToken,
        receiverType,
        markComplete: true,
        assignedDocumentId: queryParams.assignedDocumentId,
      },
    };
    openModal(modalType);
  }

  markCompleteAndAssignBack = async () => {
    if (this.state.recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(this.state.recordBlobObject, this.props.match.params.id);
    }

    localStorage.setItem('mark_complete_reassign_option', 'Mark Complete and Assign Back');
    this.setState({ markCompleteButtonOption: 'Mark Complete and Assign Back' });

    const { assignedDocumentId } = parse(this.props.location.search, { ignoreQueryPrefix: true });

    const { getAssignedRecord, assignDocument } = this.props;

    await getAssignedRecord(assignedDocumentId);

    const { assignedDocument } = this.props;

    // Let's reassign this document back to the assignee.
    const { sendToken, receiverType } = this.props;

    // Figure out the parent type
    let parent = '';
    if (receiverType === 'received_fax') {
      parent = 'ReceivedFax';
    } else if (receiverType === 'receiver') {
      parent = 'Receiver';
    } else {
      parent = 'EmailAttachment';
    }

    const newAssignedDocument = {
      send_token: sendToken,
      assignee_id: assignedDocument.assigned_from_email,
      parent_type: parent,
      document_status_id: assignedDocument.document_status_id,
      force_assign: true,
    };
    await assignDocument(newAssignedDocument);

    const { markDocumentAsCompleted } = this.props;
    await markDocumentAsCompleted(assignedDocumentId);

    const { error } = this.props;
    if (!error) {
      message.success({
        content: 'The document has been assigned back to the assigner. You will be redirected back to the assigned records page.',
      });

      // After 3 seconds, go to assigned records page.
      setTimeout(() => { this.context.router.history.push('/app/assigned'); }, 3000);
    } else {
      message.error({
        content: 'Unable to assign the document back to the assigner. Please try again.',
      });
    }
  }

  promptUserToSaveChanges = () => {
    toastr.warning('Cannot send document with unsaved edits');
  }

  onPagePress = () => {
    const { extendUserSession: extendUserSessionAction, loggedIn, sessionExtendedAt } = this.props;
    const sessionWasNotExtended = !sessionExtendedAt;
    const minutesSinceExtension = Math.floor((Date.now() - sessionExtendedAt) / 1000 / 60);
    const minutesToSessionEnd = 30 - minutesSinceExtension;
    const shouldExtendSession = sessionWasNotExtended || (minutesToSessionEnd <= 20);
    if (loggedIn && shouldExtendSession) {
      extendUserSessionAction();
    }
  }

  setUnsavedSignatures = (unsavedSignatures) => {
    this.setState({ unsavedSignatures });
  };

  closeFaxSendingDialog() {
    this.props.closeSendDialog();
  }

  isUploadHidden(receiverType) {
    if (receiverType === 'received_fax') {
      return false;
    }
    if (receiverType === 'email_attachment') {
      return false;
    }
    if (receiverType === 'receiver' && this.state.inboxLink !== '/app/received') {
      return false;
    }
    return true;
  }

  render() {
    const {
      sendToken,
      record,
      canEditPatientInfo,
      canEditPrivacyLevel,
      defaultFaxNumber,
      associatedFaxNumbers,
      canSendRecord,
      dialogVisible,
      loading,
      error,
      canEditRecord,
      canEditAfterSend,
      location,
      recordHasUnsavedEdits,
      userCanSendFaxRecordsOnly,
      isFormDocument,
      isFormSubmission,
      isEmailSubmission,
      archived,
      receiverType,
      patientName,
      patientDOB,
      organization,
      autoUploadEmr,
      loggedIn,
    } = this.props;

    const isForm = isFormDocument || isFormSubmission || isEmailSubmission;

    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    const {
      inboxLink,
      downloadDisabled,
      canForward,
      moveToNextRecord,
      secureLink,
      autoUploadToEMR,
      createPatientChart,
      hideTaskButton,
      taskCreation,
      uploadToGoogleDrive,
      uploadToSFTPFolder,
      uploadToSharepointFolder,
      sendSmsMessage,
      referralReplyTemplates,
    } = this.state;

    let downloadButtonText = 'Download And Archive';
    let downloadDropdownMenu = (
      <Menu>
        <Menu.Item key="0" onClick={() => this.downloadRecord(false, inboxLink)}>
          Download
        </Menu.Item>
        <Menu.Item key="1" onClick={() => this.downloadRecord(true, inboxLink)}>
          Download and Archive
        </Menu.Item>
      </Menu>
    );

    if (inboxLink === '/app/assigned') {
      downloadButtonText = 'Download';
      downloadDropdownMenu = (
        <Menu>
          <Menu.Item key="0" onClick={() => this.downloadRecord(false, inboxLink)}>
            Download
          </Menu.Item>
        </Menu>
      );
    }

    const markCompletedMenu = (
      <Menu>
        <Menu.Item key="0" onClick={() => this.markComplete()}>
          Mark Complete
        </Menu.Item>
        <Menu.Item key="1" onClick={() => this.markCompleteAndReassign()}>
          Mark Complete and Reassign
        </Menu.Item>
        <Menu.Item key="2" onClick={() => this.markCompleteAndAssignBack()}>
          Mark Complete and Assign Back
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Helmet>
          <title>Record Viewer - Medsender</title>
        </Helmet>
        <div
          className="record-viewer"
        >
          <Header
            main="Record Viewer"
            subtext=""
            locationPath={[
              { icon: 'home', text: 'Home', link: '/app' },
              { icon: 'inbox', text: 'Inbox', link: inboxLink },
              { icon: 'list', text: 'View record', link: '/app' },
            ]}
            buttons={[
              {
                type: 'FlatButton',
                text: 'Send',
                icon: <SendOutlined />,
                disabled: false,
                hide: !canSendRecord,
                secondary: true,
                func: () => this.checkPatientDetails(false, false),
              },
              {
                type: 'FlatButton',
                text: 'Reply',
                icon: <SyncOutlined />,
                disabled: false,
                hide: !canSendRecord || (queryParams.from === 'uploaded' || queryParams.from === 'sent' || queryParams.from === 'assigned') || isEmailSubmission,
                secondary: true,
                func: () => this.checkPatientDetails(false, true),
              },
              {
                text: 'Print',
                icon: <PrinterOutlined />,
                disabled: false,
                hide: !record,
                func: () => this.printRecord(),
              },
              {
                type: 'dropdown',
                text: downloadButtonText,
                icon: <CloudDownloadOutlined />,
                disabled: downloadDisabled,
                hide: !record,
                menu: downloadDropdownMenu,
              },
              {
                text: 'Edit Record Info',
                icon: <EditOutlined />,
                hide: !record,
                disabled: (!canEditPrivacyLevel && !canEditPatientInfo) || !record,
                func: this.editPatientOpen,
              },
              {
                type: 'FlatButton',
                text: 'Secure Link',
                disabled: false,
                hide: !record || isForm || !secureLink,
                icon: <LinkOutlined />,
                func: () => this.linkDialogVisible(),
              },
              {
                type: 'FlatButton',
                text: 'Publish Form',
                disabled: false,
                hide: !isFormDocument,
                icon: <LinkOutlined />,
                func: () => this.linkDialogVisible({ canPublishForm: true }),
              },
              {
                type: 'FlatButton',
                text: 'Notify',
                disabled: false,
                hide: !sendToken || !record || isFormDocument || inboxLink === '/app/assigned',
                icon: <BellOutlined />,
                func: () => this.openNotifyModal(),
              },
              {
                text: 'Assign Document',
                icon: <BellOutlined />,
                hide: (receiverType !== 'received_fax' && receiverType !== 'receiver' && receiverType !== 'email_attachment') || inboxLink === '/app/assigned',
                func: () => this.createAssignedDocument(),
              },
              {
                text: 'Add/Edit Label',
                hide: !sendToken || !record || isFormDocument,
                icon: <TagOutlined />,
                func: this.editLabel,
              },
              {
                type: 'FlatButton',
                text: 'Forward to EMR',
                icon: <DesktopOutlined />,
                hide: inboxLink !== '/app/received' || !canForward || autoUploadToEMR,
                func: () => this.forwardToEMR(sendToken, 'FORWARD_RECORD'),
              },
              {
                text: 'Send to Chart',
                icon: <CloudUploadOutlined />,
                hide: !autoUploadToEMR || inboxLink === '/app/uploads' || (!(inboxLink === '/app/sent') && this.isUploadHidden(receiverType)),
                func: () => this.autoUploadRecord(sendToken, patientDOB, patientName, autoUploadEmr),
              },
              {
                text: 'Upload to Google Drive',
                icon: <CloudUploadOutlined />,
                hide: !uploadToGoogleDrive || inboxLink !== '/app/received' || receiverType !== 'received_fax',
                func: () => this.uploadToGoogleDrive(sendToken),
              },
              {
                text: 'Create New Chart',
                icon: <CloudUploadOutlined id="createChart" />,
                hide: !createPatientChart || inboxLink === '/app/uploads' || inboxLink === '/app/sent' || this.isUploadHidden(receiverType),
                func: () => this.newChart(sendToken),
              },
              {
                text: 'Create Task',
                icon: <CloudUploadOutlined id="createTask" />,
                hide: !taskCreation || hideTaskButton || inboxLink === '/app/uploads' || inboxLink === '/app/sent' || this.isUploadHidden(receiverType),
                func: () => this.createTask(sendToken),
              },
              {
                text: 'Send SMS',
                icon: <MessageOutlined />,
                hide: inboxLink !== '/app/referred',
                func: () => this.smsMessage(sendToken, sendSmsMessage),
              },
              {
                text: 'Referral Replies',
                icon: <SendOutlined />,
                hide: inboxLink !== '/app/referred' || !referralReplyTemplates || receiverType !== 'received_fax',
                func: () => this.referralReply(sendToken),
              },
              {
                text: 'Upload to SFTP Folder',
                icon: <CloudUploadOutlined />,
                hide: !uploadToSFTPFolder || inboxLink !== '/app/received' || receiverType !== 'received_fax',
                func: () => this.uploadToSFTPFolder(sendToken),
              },
              {
                text: 'Upload to Sharepoint Folder',
                icon: <CloudUploadOutlined />,
                hide: !uploadToSharepointFolder || inboxLink !== '/app/received' || receiverType !== 'received_fax',
                func: () => this.uploadToSharepointFolder(sendToken),
              },
              {
                type: 'FlatButton',
                secondary: true,
                text: 'Sign Document',
                icon: <FormOutlined />,
                hide: !isOnUnsupportedBrowser(),
                func: () => this.openBrowserUpgradeModal(),
              },
              {
                text: 'Split Record',
                icon: <SplitCellsOutlined />,
                hide: inboxLink === '/app/sent' || isForm || inboxLink === '/app/assigned',
                disabled: !record,
                func: () => this.splitSelectedRecord(),
              },
              {
                text: archived ? 'Unarchive' : 'Archive',
                icon: <FolderOpenOutlined />,
                hide: inboxLink === '/app/sent' || inboxLink === '/app/assigned',
                disabled: !record,
                func: () => (archived ? this.beginUnarchiveRecord(inboxLink) : this.beginArchiveRecord(inboxLink)),
              },
              {
                text: 'Next Record',
                icon: <DoubleRightOutlined />,
                func: () => this.moveToNextRecord(),
                hide: !moveToNextRecord || inboxLink !== '/app/received',
              },
              {
                text: 'Update Assigned Document',
                icon: <EditOutlined />,
                hide: inboxLink !== '/app/assigned',
                func: () => this.updateDocumentOpen(),
              },
              {
                text: 'Update Referral Status',
                icon: <EditOutlined />,
                hide: inboxLink !== '/app/referred',
                func: () => this.updateReferralOpen(),
              },
              {
                type: 'dropdown',
                text: this.state.markCompleteButtonOption,
                icon: <CheckCircleOutlined />,
                hide: inboxLink !== '/app/assigned',
                menu: markCompletedMenu,
              },
            ]}
            recordId={this.props.match.params.id}
          />
          {
            dialogVisible
              && (
                <LoadingDialog
                  loading={this.props.faxSending}
                  error={this.props.faxError}
                  cancel={this.closeFaxSendingDialog.bind(this)}
                />
              )
          }
          { error && !loading
            && (
              <div style={{ textAlign: 'center' }}>
                <ErrorDisplay message={this.props.errorMessage} error={this.props.errorCode} />
              </div>
            )
          }
          {
             !error
              && (
                <Viewer
                  documentUrl={record}
                  saveAnnotationsOnRecord={this.beginSaveAnnotationsOnRecord}
                  recordId={this.props.match.params.id}
                  canEditRecord={canEditRecord && canEditAfterSend}
                  recordNeedsToBeSaved={recordHasUnsavedEdits}
                  saveButton={this.saveButton}
                  fromPage={!queryParams.from ? 'received' : queryParams.from.toLowerCase()}
                  updateRecordBlob={this.updateRecordBlob}
                  saveLoading={this.state.loading}
                  saveSignature={this.props.saveSignature}
                  unsavedSignatures={this.state.unsavedSignatures}
                  setUnsavedSignatures={this.setUnsavedSignatures}
                  signatureData={this.props.signatureData}
                  sendRecord={this.sendRecord}
                  attachmentsData={this.props.attachmentsData}
                  onPagePress={this.onPagePress}
                  user={this.props.name}
                  saveChangesButtonName="Save Changes"
                  viewer="User"
                  setClick={click => this.flattenAnnotations = click}
                  canGetandUpdateSignatures={true}
                />
              )
          }
          {
            dialogVisible
              && (
                <LoadingDialog
                  loading={this.props.faxSending}
                  error={this.state.errorMessage}
                  cancel={this.closeFaxSendingDialog.bind(this)}
                />
              )
          }
          {
            this.state.dialogOpen && (
            <SendRecordsDialog
              faxSending={this.props.faxSending}
              handleSubmit={this.handleDialogSubmit}
              handleCancel={this.dialogVisible}
              open={this.state.dialogOpen}
              searchRecipient={this.props.searchRecipient}
              dataSource={this.props.dataSource}
              searchLoading={this.props.isLoading}
              patientName={this.state.patientName}
              patientDOB={this.state.patientDOB}
              defaultFaxNumber={defaultFaxNumber}
              associatedFaxNumbers={associatedFaxNumbers}
              userCanSendFaxRecordsOnly={userCanSendFaxRecordsOnly}
              senderNameReply={this.state.senderName}
              senderOrganizationReply={this.state.senderOrganization}
              senderContactReply={this.state.senderContact}
              isReplyButtonClicked={this.state.isReplyButtonClicked}
              documentTitle={this.state.documentTitle}
              documentCategory={this.state.documentCategory}
            />
            )}
        </div>
        {loggedIn
        && (
          <ChatTab
            loggedIn={loggedIn}
            sendToken={parse(this.props.location.search).sendToken}
            user={this.props.user}
            cable={this.props.cable}
          />
        )
        }
      </div>
    );
  }
}

RecordViewer.contextTypes = {
  router: PropTypes.object.isRequired,
};

RecordViewer.defaultProps = {
  patientName: '',
  patientDOB: '',
  patientGender: '',
  patientPhoneNumber: '',
  patientAddress: '',
  patientZipCode: '',
  patientCity: '',
  practitionerName: '',
  privacyLevel: '',
  referralNote: '',
  referringProvider: '',
  referringProviderOffice: '',
  patientState: '',
  patientEmail: '',
  patientFirstName: '',
  patientLastName: '',
  primaryPlan: '',
  primaryMemberId: '',
  sendToken: null,
  receiverType: null,
  loading: false,
  error: false,
  canEditRecord: true,
  canEditAfterSend: false,
  dialogVisible: false,
  record: '',
  recordHasUnsavedEdits: false,
  archiveErrorMessage: null,
  errorMessage: null,
  senderName: '',
  senderContact: '',
  senderOrganization: '',
  sessionExtendedAt: null,
  loggedIn: false,
  autoUploadEmr: '',
  documentTitle: '',
  documentCategory: '',
  documentCategories: {},
  faxError: false,
  faxErrorMessage: null,
  nextSendToken: null,
  nextFileToken: null,
};

RecordViewer.propTypes = {
  archived: PropTypes.bool.isRequired,
  getRecord: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  printUserRecord: PropTypes.func.isRequired,
  faxSending: PropTypes.bool.isRequired,
  canSendRecord: PropTypes.bool.isRequired,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  patientGender: PropTypes.string,
  patientPhoneNumber: PropTypes.string,
  patientAddress: PropTypes.string,
  patientZipCode: PropTypes.string,
  patientCity: PropTypes.string,
  practitionerName: PropTypes.string,
  referralNote: PropTypes.string,
  referringProvider: PropTypes.string,
  referringProviderOffice: PropTypes.string,
  patientState: PropTypes.string,
  patientEmail: PropTypes.string,
  patientFirstName: PropTypes.string,
  patientLastName: PropTypes.string,
  primaryPlan: PropTypes.string,
  primaryMemberId: PropTypes.string,
  errorSaving: PropTypes.object.isRequired,
  modalLogin: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  removeSendDialogParam: PropTypes.func.isRequired,
  recordSaved: PropTypes.bool.isRequired,
  sendPatientRecord: PropTypes.func.isRequired,
  sendToMultipleRecipients: PropTypes.func.isRequired,
  getNextRecord: PropTypes.func.isRequired,
  searchRecipient: PropTypes.func.isRequired,
  dataSource: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  privacyLevel: PropTypes.string,
  canEditPrivacyLevel: PropTypes.bool.isRequired,
  canEditPatientInfo: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  defaultFaxNumber: PropTypes.object.isRequired,
  associatedFaxNumbers: PropTypes.object.isRequired,
  record: PropTypes.string,
  sendToken: PropTypes.string,
  receiverType: PropTypes.string,
  dialogVisible: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorCode: PropTypes.number.isRequired,
  canEditRecord: PropTypes.bool,
  canEditAfterSend: PropTypes.bool,
  recordHasUnsavedEdits: PropTypes.bool,
  fileToken: PropTypes.string.isRequired,
  userCanSendFaxRecordsOnly: PropTypes.bool.isRequired,
  isFormDocument: PropTypes.bool.isRequired,
  isFormSubmission: PropTypes.bool.isRequired,
  isEmailSubmission: PropTypes.bool.isRequired,
  archiveRecords: PropTypes.func.isRequired,
  archiveReceivedRecords: PropTypes.func.isRequired,
  archiveErrorMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  aiStatus: PropTypes.string.isRequired,
  patientInfoEdited: PropTypes.bool.isRequired,
  hasWrongExtractionBeenReported: PropTypes.bool.isRequired,
  hasWrongLabelingBeenReported: PropTypes.bool.isRequired,
  isLabelEdited: PropTypes.bool.isRequired,
  failedColumn: PropTypes.string.isRequired,
  numPages: PropTypes.number.isRequired,
  senderName: PropTypes.string,
  senderContact: PropTypes.string,
  senderOrganization: PropTypes.string,
  organization: PropTypes.string.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  receivedAt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  callerName: PropTypes.string.isRequired,
  triggerGoogleDriveUpload: PropTypes.func.isRequired,
  triggerSFTPUpload: PropTypes.func.isRequired,
  triggerSharepointUpload: PropTypes.func.isRequired,
  sessionExtendedAt: PropTypes.number,
  loggedIn: PropTypes.bool,
  extendUserSession: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  autoUploadEmr: PropTypes.string,
  attachmentsData: PropTypes.array.isRequired,
  documentTitle: PropTypes.string,
  documentCategory: PropTypes.string,
  documentCategories: PropTypes.object,
  faxError: PropTypes.bool,
  faxErrorMessage: PropTypes.string,
  nextSendToken: PropTypes.string,
  nextFileToken: PropTypes.string,
  currentOrganizationId: PropTypes.string.isRequired,
  markDocumentAsCompleted: PropTypes.func.isRequired,
  getAssignedRecord: PropTypes.func.isRequired,
  assignedDocument: PropTypes.object.isRequired,
};

export default connect(state => ({
  user: state.auth.data,
  defaultFaxNumber: state.auth.data.defaultFaxNumber,
  associatedFaxNumbers: state.auth.data.associatedFaxNumbers,
  fileToken: state.records.fileToken,
  sendToken: state.records.sendToken,
  nextSendToken: state.records.nextSendToken,
  nextFileToken: state.records.nextFileToken,
  loading: state.records.isLoading,
  record: state.records.currentRecord,
  downloadUrl: state.records.downloadUrl,
  error: state.records.error,
  archiveErrorMessage: state.records.archiveErrorMessage,
  errorMessage: state.records.errorMessage,
  faxSending: state.faxes.isLoading,
  faxError: state.faxes.error,
  dialogVisible: state.faxes.sendingDialogVisible,
  canSendRecord: state.records.canSend,
  patientName: state.records.patientName,
  patientDOB: state.records.patientDOB,
  patientGender: state.records.patientGender,
  patientPhoneNumber: state.records.patientPhoneNumber,
  patientAddress: state.records.patientAddress,
  patientZipCode: state.records.patientZipCode,
  patientState: state.records.patientState,
  patientEmail: state.records.patientEmail,
  patientFirstName: state.records.patientFirstName,
  patientLastName: state.records.patientLastName,
  primaryPlan: state.records.primaryPlan,
  primaryMemberId: state.records.primaryMemberId,
  payerName: state.records.payerName,
  groupNumber: state.records.groupNumber,
  secondaryPayerName: state.records.secondaryPayerName,
  secondaryMemberId: state.records.secondaryMemberId,
  secondaryGroupNumber: state.records.secondaryGroupNumber,
  patientCity: state.records.patientCity,
  practitionerName: state.records.practitionerName,
  selectedLabels: state.records.selectedLabels,
  referralNote: state.records.referralNote,
  referringProvider: state.records.referringProvider,
  referringProviderOffice: state.records.referringProviderOffice,
  modalLogin: state.modal.loggedIn,
  errorSaving: state.modal.errorStatus,
  recordSaved: state.modal.recordSaved,
  dataSource: state.search.results,
  isLoading: state.search.isLoading,
  privacyLevel: state.records.privacyLevel,
  canEditPatientInfo: state.records.canEditPatientInfo,
  canEditPrivacyLevel: state.records.canEditPrivacyLevel,
  hasWrongExtractionBeenReported: state.records.hasWrongExtractionBeenReported,
  hasWrongLabelingBeenReported: state.records.hasWrongLabelingBeenReported,
  aiStatus: state.records.aiStatus,
  apiCredentials: state.records.apiCredentials,
  failedColumn: state.records.failedColumn,
  patientInfoEdited: state.records.patientInfoEdited,
  isLabelEdited: state.records.isLabelEdited,
  errorCode: state.records.errorCode,
  canEditRecord: state.records.canEditRecord,
  isFormDocument: state.records.isFormDocument,
  isFormSubmission: state.records.isFormSubmission,
  isEmailSubmission: state.records.isEmailSubmission,
  canEditAfterSend: state.faxes.canEditAfterSend,
  recordHasUnsavedEdits: state.records.recordHasUnsavedEdits,
  signatureData: state.records.signatureData,
  attachmentsData: state.records.attachmentsData,
  userCanSendFaxRecordsOnly: state.auth.userCanSendFaxRecordsOnly,
  receiverType: state.records.receiverType,
  numPages: state.records.numPages,
  archived: state.records.archived,
  senderName: state.records.senderName,
  senderContact: state.records.senderContact,
  senderOrganization: state.records.senderOrganization,
  organization: state.auth.data.organization,
  currentOrganizationId: state.auth.currentOrganizationId,
  receivedAt: state.records.receivedAt,
  createdAt: state.records.createdAt,
  callerName: state.records.callerName,
  sessionExtendedAt: state.auth.sessionExtendedAt,
  loggedIn: state.auth.hasAuthCreds,
  name: state.userProfile.name,
  autoUploadEmr: state.auth.data.autoUploadEmr,
  documentTitle: state.records.documentTitle,
  documentCategory: state.records.documentCategory,
  documentCategories: state.records.documentCategories,
  faxErrorMessage: state.faxes.errorMessage,
  assignedDocument: state.records.assignedRecord,
  defaultSmsTemplate: state.userProfile.defaultSmsTemplate,
}),
{
  getRecord: getRecordById,
  sendPatientRecord,
  sendToMultipleRecipients,
  getNextRecord,
  closeSendDialog,
  printUserRecord,
  removeSendDialogParam,
  hideModal,
  openModal,
  searchRecipient,
  saveAnnotationsOnRecord,
  archiveRecords,
  archiveReceivedRecords,
  unarchiveRecords,
  unarchiveReceivedRecords,
  triggerAutoUpload,
  triggerGoogleDriveUpload,
  triggerSFTPUpload,
  triggerSharepointUpload,
  extendUserSession,
  assignDocument,
  markDocumentAsCompleted,
  getAssignedRecord,
  clearDownloadUrl,
  getDefaultSmsTemplate,
})(RecordViewer);
export { RecordViewer };
