import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as AntTooltip } from 'antd';
import './tooltip.scss';

const Tooltip = ({ children, ...props }) => {
  return (
    <AntTooltip className="redesign-tooltip" {...props}>
      {children}
    </AntTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Tooltip;
