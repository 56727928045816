import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'qs';

import {
  Sidebar,
  InboxTable,
  PageLoading,
  ErrorDisplay,
} from '../../components';
import ReplyMessageSidebar from '../../components/inbox/replyMessageSidebar';
import SmsMessageSidebar from '../../components/inbox/smsMessageSidebar';
import {
  checkTriggered,
  changeSidebarDate,
  changeSidebarMenu,
} from '../../actions/sidebar';
import { changeInboxPage } from '../../actions/inbox';
import {
  clearCurrentRecordId,
  markRecordAsViewed,
  clearCurrentTransmission,
  clearDownloadUrl,
} from '../../actions/records';
import { getRecordResponseById, getAttachmentUrl } from '../../actions/replies';
import { rowSelected } from '../../actions/inboxTableActions';
import { getLabelsByQuery } from '../../actions/labels';
import { getLoginInfo } from '../../actions/auth';
import { changeDefaultOrganization } from '../../actions/appActions';

import '../../components/main.scss';
import NewRecordViewer from '../NewRecordViewer';

const parseOptions = {
  ignoreQueryPrefix: true,
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  arrayFormat: 'comma',
  encode: true,
  sort: (a, b) => (a.localeCompare(b)),
};

const RecordsHOC = PassedProps => (PassedComponent) => {
  class Records extends Component {
    static propTypes = {
      docked: PropTypes.bool.isRequired,
      loading: PropTypes.bool.isRequired,
      errorMessage: PropTypes.string,
      selectedData: PropTypes.array.isRequired,
      departments: PropTypes.array.isRequired,

      columnTitles: PropTypes.arrayOf(PropTypes.object).isRequired,

      headers: PropTypes.shape({
        page: PropTypes.string,
        'per-page': PropTypes.string,
        total: PropTypes.string,
        'total-pages': PropTypes.string,
      }),

      data: PropTypes.arrayOf(PropTypes.shape({
        file_token: PropTypes.string,
        patient_name: PropTypes.string,
        patient_dob: PropTypes.string,
      })).isRequired,

      // Static redux actions (same for each parent using this HOC)
      checkTriggered: PropTypes.func.isRequired,
      changeSidebarDate: PropTypes.func.isRequired,
      changeSidebarMenu: PropTypes.func.isRequired,
      rowSelected: PropTypes.func.isRequired,

      // Dynamic redux actions
      // The parent component passes in the function in their PassedProps
      getPageDataWithQuery: PropTypes.func.isRequired,
      getSelectedPageData: PropTypes.func.isRequired,

      revokeToggle: PropTypes.func,
      organizationDetails: PropTypes.shape({
        organizations: PropTypes.array.isRequired,
        current_organization: PropTypes.string,
      }).isRequired,
      changeDefaultOrganization: PropTypes.func.isRequired,
      getLoginInfo: PropTypes.func.isRequired,
    }

    static defaultProps = {
      headers: {
        page: '1',
        'per-page': '10',
        total: '1',
        'total-pages': '1',
      },
      errorMessage: null,
      revokeToggle: () => {},
    }

    constructor(props) {
      super(props);
      const { organizationDetails: { current_organization: currentOrganization } } = this.props;
      this.state = {
        mode: PassedProps.mode,
        viewReplySidebar: false,
        viewSmsSidebar: false,
        sidebarActions: {
          checkTriggered: props.checkTriggered,
          changeSidebarDate: props.changeSidebarDate,
          changeSidebarMenu: props.changeSidebarMenu,
        },
        inboxActions: {
          changeInboxPage,
          getRecordsByPage: props.getSelectedPageData,
          getRecords: props.getPageDataWithQuery,
          rowSelected: props.rowSelected,
        },
        openOrganizationFilterMenu: false,
        currentOrganization,
      };
    }

    componentDidMount() {
      this.getQueryData(this.props);
      localStorage.removeItem('send_token');
      localStorage.removeItem('from_page');
    }

    getQueryData(props) {
      const { location: { search } } = props;
      props.getPageDataWithQuery(search);
    }

    handleChangeDefaultOrganization = async (org) => {
      const {
        changeDefaultOrganization: handleChangeDefaultOrganization,
      } = this.props;
      this.setState({ currentOrganization: org });
      await handleChangeDefaultOrganization(org, this.reloadPage);
    }

    handleOrganizationMenu = async (value) => {
      this.setState({ openOrganizationFilterMenu: value });
    }

    toggleReplySidebar = () => {
      this.setState(prevState => ({
        viewReplySidebar: !prevState.viewReplySidebar,
      }));
    }

    toggleSmsSidebar = () => {
      this.setState(prevState => ({
        viewSmsSidebar: !prevState.viewSmsSidebar,
      }));
    }

    getReplyData = async (uri, sendToken) => {
      const { getRecordResponseById, markRecordAsViewed } = this.props;
      await getRecordResponseById(uri);
      this.toggleReplySidebar();
      await markRecordAsViewed(sendToken);
    }

    getSmsData = async (uri) => {
      const { getRecordResponseById } = this.props;
      await getRecordResponseById(uri);
      this.toggleSmsSidebar();
    }

    reloadPage = async () => {
      const {
        getLoginInfo: handleGetLoginInfo,
      } = this.props;
      await handleGetLoginInfo();
      window.location.reload();
    }

    render() {
      const {
        headers, history, location, departments,
      } = this.props;
      const totalPages = (headers ? parseInt(headers['total-pages'], 10) : 1);
      const totalResults = (headers ? parseInt(headers.total, 10) : 0);
      const onMobile = window.innerWidth < 800;

      let page = 1;
      let params = {};
      if (location.search) {
        params = parse(location.search, parseOptions);
        page = parseInt((params.page <= totalPages ? params.page : 1), 10);
      }
      const paramTools = {
        params,
        stringify,
        stringifyOptions,
        push: history.push,
      };

      const filterData = {
        departments,
      };

      const {
        docked,
        sidebar,
        getPageDataWithQuery,
        error,
        loading,
        errorMessage,
        errorCode,
        data,
        selectedData,
        rowSelected,
        columnTitles,
        retrying,
        retryAttemptNumber,
        replyData,
        smsData,
        getAttachmentUrl,
        attachmentUrl,
        getLabelsByQuery,
        labels,
        organizationDetails,
      } = this.props;
      const {
        sidebarActions, inboxActions, mode, viewReplySidebar, viewSmsSidebar, currentOrganization, openOrganizationFilterMenu,
      } = this.state;

      const setNewRecordViewerOpen = () => {
        this.setState({ newRecordViewerOpen: true });
      };

      const closeNewRecordViewer = async () => {
        await this.props.clearCurrentRecordId();
        await this.props.clearCurrentTransmission();
        await this.props.clearDownloadUrl();
        this.setState({ newRecordViewerOpen: false });
      };

      return (
        <div>
          <Sidebar
            page="inbox"
            docked={docked}
            sidebar={sidebar}
            actions={sidebarActions}
            inboxActions={inboxActions}
            paramTools={paramTools}
            mode={mode}
            getPageDataWithQuery={getPageDataWithQuery}
            filterData={filterData}
            location={location}
            getLabelsByQuery={getLabelsByQuery}
            data={data}
            labels={labels}
          />
          <div
            style={docked === true ? { marginLeft: '256px' } : { marginLeft: '0' }}
            className="record-viewer"
          >
            { /* The PassedComponent is the parent that plugs into the HOC */ }
            <PassedComponent
              location={location}
              error={error}
              errorCode={errorCode}
              currentOrganization={currentOrganization}
              openOrganizationFilterMenu={openOrganizationFilterMenu}
              handleOrganizationMenu={this.handleOrganizationMenu}
              organizationDetails={organizationDetails}
              changeDefaultOrganization={this.handleChangeDefaultOrganization}
            />
            {
              // If the page is loading records data, show circle loader in middle
              loading && <PageLoading />
            }
            {
              loading && retrying && (
                <div style={{ marginTop: '10px', textAlign: 'center', fontSize: '20px' }}>
                  Something went wrong, we are automatically retrying...
                  { retryAttemptNumber > 0 && (
                  <p>
                    Retry attempt #
                    {retryAttemptNumber}
                  </p>
                  )}
                </div>
              )
            }
            {
              // Loading has stopped but there's an error, display it in the
              // center of the page
            !loading && errorMessage && <ErrorDisplay message={errorMessage} error={errorCode} />
            }
            {
              // Not loading and no error message, so it is safe to
              // render the records in the table
              !errorMessage && !loading
              && (
              <InboxTable
                rows={data}
                actions={inboxActions}
                push={history.push}
                page={page}
                totalPages={totalPages}
                totalResults={totalResults}
                selectedData={selectedData}
                paramTools={paramTools}
                rowSelected={rowSelected}
                columnTitles={columnTitles}
                enableSelectAll={PassedProps.enableSelectAll}
                mode={mode}
                getReplyData={this.getReplyData}
                getSmsData={this.getSmsData}
                setNewRecordViewerOpen={setNewRecordViewerOpen}
                markRecordAsReceived={PassedProps.markRecordAsReceived}
                disableRowSelection={PassedProps.disableRowSelection}
              />
              )
            }
            {
              viewReplySidebar && (
                <ReplyMessageSidebar
                  error={error}
                  toggleReplySidebar={this.toggleReplySidebar}
                  data={replyData}
                  getAttachmentUrl={getAttachmentUrl}
                  attachmentUrl={attachmentUrl}
                />
              )
            }
            {
              viewSmsSidebar && (
                <SmsMessageSidebar
                  error={error}
                  toggleSmsSidebar={this.toggleSmsSidebar}
                  data={smsData}
                />
              )
            }
          </div>
          {!onMobile && (
            <NewRecordViewer
              open={this.state.newRecordViewerOpen}
              closeViewer={closeNewRecordViewer}
            />
          )}
        </div>
      );
    }
  }

  Records.propTypes = {
    clearCurrentRecordId: PropTypes.func.isRequired,
    clearCurrentTransmission: PropTypes.func.isRequired,
    clearDownloadUrl: PropTypes.func.isRequired,
  };

  return connect(state => ({
    columnTitles: PassedProps.columnTitles,
    error: state.records.error,
    data: state.records[PassedProps.data],
    docked: state.appState.sidebarVisible,
    departments: state.auth.departments,
    errorMessage: state.records.errorMessage,
    header: PassedProps.header,
    headers: state.records.headers,
    loading: state.records.isLoading,
    retrying: state.records.retrying,
    retryAttemptNumber: state.records.retryAttemptNumber,
    locationPath: PassedProps.locationPath,
    selectedData: state.inboxTable.recordData,
    sendData: state.inboxTable.sendData,
    sidebar: state.sidebar,
    errorCode: state.records.errorCode,
    replyData: state.replies.data.reply,
    smsData: state.replies.data,
    attachmentUrl: state.replies.attachmentUrl,
    labels: state.labels.data,
    organizationDetails: state.auth.organizationDetails,
  }), {
    checkTriggered,
    changeSidebarDate,
    changeSidebarMenu,
    rowSelected,
    changeInboxPage,
    getRecordResponseById,
    getAttachmentUrl,
    markRecordAsViewed,
    getSelectedPageData: PassedProps.getSelectedPageData,
    getPageDataWithQuery: PassedProps.getPageDataWithQuery,
    revokeToggle: PassedProps.revokeToggle,
    getLabelsByQuery,
    changeDefaultOrganization,
    getLoginInfo,
    clearCurrentRecordId,
    clearCurrentTransmission,
    clearDownloadUrl,
  })(Records);
};

export default RecordsHOC;
