import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Drawer, message } from 'antd';
import { getRecordById, saveAnnotationsOnRecord } from '../../actions/records';
import { extendUserSession } from '../../actions/auth';
import {
  sendPatientRecord,
  sendToMultipleRecipients,
} from '../../actions/faxes';
import { openModal } from '../../actions/modal';
import { verifyFeatureAccess } from '../../global/featureFlags';
import Typography from '../../components/Typography';
import { LoadingDialog, SendRecordsDialog, Viewer } from '../../components';
import COLORS from '../../util/constants/colors';
import './newRecordViewer.scss';
import ViewerToolbar from './ViewerToolbar';
import RecordDetails from './RecordDetails';
import searchRecipient from '../../actions/search';
import { closeSendDialog } from '../../actions/appActions';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { PAGES } from '../shared/constants';

const NewRecordViewer = ({
  open, closeViewer, ...props
}) => {
  // redux state
  const {
    currentTransmission,
    currentRecordId,
    record,
    recordHasUnsavedEdits,
    error,
    canEditRecord,
    canEditAfterSend,
    signatureData,
    attachmentsData,
    loggedIn,
    sessionExtendedAt,
    sendDialogVisible,
    fileToken,
    sendToken,
    patientName,
    patientDOB,
    documentTitle,
    documentCategory,
  } = props;

  // functions
  const {
    saveAnnotationsOnRecord,
    extendUserSession: extendUserSessionAction,
    openModal,
    searchRecipient,
  } = props;

  const [loading, setLoading] = useState(false);
  const [unsavedSignatures, setUnsavedSignatures] = useState(false);
  const [autoSendEnabled, setAutoSendEnabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [recordBlobObject, setRecordBlobObject] = useState(null); // stores the current state of the file blob, see updateRecordBlob()
  const [isReplyButtonClicked, setIsReplyButtonClicked] = useState(false);
  const breakpoint = useBreakpoint();
  const [drawerWidth, setDrawerWidth] = useState(null);
  const currentPage = window.location.pathname.split('/')[2];
  const flattenAnnotationRef = useRef(null);

  useEffect(() => {
    if (breakpoint === 'small') {
      setDrawerWidth(800);
    } else {
      setDrawerWidth(1100);
    }
  }, [breakpoint]);

  useEffect(() => {
    const getRecord = async () => {
      const isSent = currentPage === PAGES.SENT;
      await props.getRecord(currentRecordId, isSent);
    };

    if (currentRecordId) {
      getRecord();
    }
  }, [currentRecordId]);

  // Reset state when the viewer is closed
  useEffect(() => {
    if (!open) {
      setRecordBlobObject(null);
      setUnsavedSignatures(false);
      setAutoSendEnabled(false);
      setDialogOpen(false);
      setIsReplyButtonClicked(false);
    }
  }, [open]);

  const {
    receiver,
    receiver_email,
    receiver_fax,
    receiver_name,
    receiver_type,
    referral_note,
    sender,
    sender_name,
    sender_email,
  } = currentTransmission;

  const isUpload = Boolean(currentTransmission?.upload_date);
  const isInboxPage = window.location.pathname.includes('/received');

  const beginSaveAnnotationsOnRecord = async (file, recordId) => {
    // Save edits to the record, sets the state on recordHasunsavedEdits to false to allow
    // the record to be sent. We don't allow users to send files that have unsaved edits.
    setLoading(true);
    await saveAnnotationsOnRecord(file, recordId);
    setLoading(false);
    if (!error) return toastr.success('Changes saved');
    toastr.error('Unable to save changes. Please try again');
  };

  const updateRecordBlob = (file) => {
    // Updates the file blob saved in state, run on initial load and any when changes are made
    // after that. Passed into beginSaveAnnotationsOnRecord() when document is autosaved when
    // user tries to save.
    setRecordBlobObject(file);
  };

  const onPagePress = () => {
    const sessionWasNotExtended = !sessionExtendedAt;
    const minutesSinceExtension = Math.floor(
      (Date.now() - sessionExtendedAt) / 1000 / 60,
    );
    const minutesToSessionEnd = 30 - minutesSinceExtension;
    const shouldExtendSession = sessionWasNotExtended || minutesToSessionEnd <= 20;
    if (loggedIn && shouldExtendSession) {
      extendUserSessionAction();
    }
  };

  /**
   * Hide/show the <SendRecordsDialog>
   * @param  {Boolean} bool whether to hide or show
   * @return {Void}
   */
  const dialogVisible = async (bool) => {
    const canSend = await verifyFeatureAccess('send_record');
    if (canSend) {
      return setDialogOpen(bool);
    }
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: 'send_record',
    };
    openModal(modalType);
  };

  const sendRecord = async (autoSend) => {
    if (autoSend) {
      setAutoSendEnabled(autoSend);
    }

    let titleText;
    if (!patientName && !documentTitle) {
      titleText = 'patient name or document title';
    }

    const {
      canEditPrivacyLevel,
      canEditPatientInfo,
      privacyLevel,
      aiStatus,
      hasWrongExtractionBeenReported,
      patientInfoEdited,
      failedColumn,
      documentCategories,
    } = props;

    if (titleText) {
      const patient = {
        file_token: fileToken,
        patient_name: patientName,
        patient_dob: patientDOB,
        can_edit_patient_info: canEditPatientInfo,
        can_edit_privacy_level: canEditPrivacyLevel,
        privacy_level: privacyLevel,
        patient_info_edited: patientInfoEdited,
        has_wrong_extraction_been_reported: hasWrongExtractionBeenReported,
        ai_status: aiStatus,
        failed_column: failedColumn,
        document_title: documentTitle,
        document_category: documentCategory,
        document_categories: documentCategories,
        referral_note,
      };
      const modalType = {
        type: 'EDIT_PATIENT',
        data: {
          patient,
          showSendDialog: dialogVisible,
          title: `To send this record please update the ${titleText}.`,
          buttonText: 'Continue',
        },
      };
      openModal(modalType);
    } else {
      setDialogOpen(true);
    }
  };

  const checkPatientDetails = async (autoSendEnabled, isReplyButtonClicked) => {
    setIsReplyButtonClicked(isReplyButtonClicked);
    if (recordBlobObject) {
      if (unsavedSignatures) {
        await flattenAnnotationRef.current(false);
        return;
      }
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    sendRecord(autoSendEnabled);
  };

  const handleDialogSubmit = async (
    org,
    contact,
    callerId,
    canSend,
    emailValid,
    isPatient,
    patient,
    documentType,
    memo,
    subject,
    signatureRequired,
    removeCoversheet,
    multipleProviderRecipients,
    allowPatientEdits,
    disableSecureReplies,
    scheduledDateTime,
  ) => {
    // Get record id from url
    const recordId = fileToken;
    const recipients = multipleProviderRecipients.map(
      recipientObj => recipientObj.recipient,
    );
    const { sendPatientRecord, sendToMultipleRecipients } = props;
    if (emailValid && !isPatient && multipleProviderRecipients.length >= 1) {
      await sendToMultipleRecipients(
        recordId,
        recipients,
        canSend,
        documentType,
        subject,
        memo,
        signatureRequired,
        callerId,
        allowPatientEdits,
        org,
        contact,
        false,
        scheduledDateTime,
      );
    } else if (isPatient) {
      await sendPatientRecord(recordId, patient, memo, disableSecureReplies);
    } else {
      await sendToMultipleRecipients(
        recordId,
        recipients,
        canSend,
        documentType,
        subject,
        memo,
        signatureRequired,
        callerId,
        allowPatientEdits,
        org,
        contact,
        removeCoversheet,
        scheduledDateTime,
      );
    }
    const { faxError, faxErrorMessage } = props;
    if (!faxError) {
      message.success({
        content:
          'Selected record(s) have been queued and will be sent shortly.',
      });
    } else {
      message.error({
        content: faxErrorMessage,
      });
    }
    // Close dialog after sending record
    dialogVisible(false);
  };

  const createAssignedDocument = async () => {
    if (recordBlobObject) {
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    const modalType = {
      type: 'ASSIGN_RECORD',
      data: {
        record: sendToken,
        receiverType: receiver_type,
      },
    };
    return openModal(modalType);
  };

  const FromTo = () => {
    return (
      <div className={classNames('from-to', { upload: isUpload })}>
        {!isUpload && (
          <>
            <div className="from">
              <Typography variant="text" size="small">
                From:
              </Typography>
              <Typography
                bold
                variant="text"
                size="small"
                color={COLORS.TEXT_TERTIARY_LITE}
              >
                {sender_name}
              </Typography>
              <Typography
                className="email"
                variant="text"
                size="small"
                color={COLORS.TEXT_TERTIARY_LITE}
                ellipsis
              >
                {sender_email || sender}
              </Typography>
            </div>
            <div className="to">
              <Typography variant="text" size="small">
                To:
              </Typography>
              {receiver_name && (
                <Typography
                  bold
                  variant="text"
                  size="small"
                  color={COLORS.TEXT_TERTIARY_LITE}
                >
                  {receiver_name}
                </Typography>
              )}
              <Typography
                className="email"
                variant="text"
                size="small"
                color={COLORS.TEXT_TERTIARY_LITE}
                ellipsis
              >
                {receiver || receiver_email || receiver_fax}
              </Typography>
            </div>
          </>
        )}
        <Typography
          bold
          variant="text"
          size="small"
          color={COLORS.TEXT_TERTIARY_LITE}
        >
          {currentTransmission?.date_sent || currentTransmission?.upload_date}
        </Typography>
      </div>
    );
  };

  return (
    <Drawer
      placement="right"
      onClose={closeViewer}
      visible={open}
      closable={false}
      width={drawerWidth}
      zIndex={901}
    >
      <div className="new-record-viewer">
        <div className="record-viewer-left">
          <ViewerToolbar
            closeViewer={closeViewer}
            currentTransmission={currentTransmission}
            currentRecordId={currentRecordId}
            currentPage={currentPage}
            openModal={openModal}
            recordBlobObject={recordBlobObject}
            unsavedSignatures={unsavedSignatures}
            saveAnnotationsOnRecord={beginSaveAnnotationsOnRecord}
            checkPatientDetails={checkPatientDetails}
            patientDOB={patientDOB}
            patientName={patientName}
            documentTitle={documentTitle}
            documentCategory={documentCategory}
            beginSaveAnnotationsOnRecord={beginSaveAnnotationsOnRecord}
          />
          <FromTo />
          {sendDialogVisible && (
            <LoadingDialog
              loading={props.faxSending}
              error={props.faxError || props.errorMessage}
              cancel={props.closeSendDialog}
            />
          )}
          {record && (
            <Viewer
              documentUrl={record}
              fileToken={fileToken}
              saveAnnotationsOnRecord={beginSaveAnnotationsOnRecord}
              recordId={currentRecordId}
              canEditRecord={canEditRecord && canEditAfterSend}
              recordNeedsToBeSaved={recordHasUnsavedEdits}
              fromPage={currentPage}
              updateRecordBlob={updateRecordBlob}
              saveLoading={loading}
              unsavedSignatures={unsavedSignatures}
              setUnsavedSignatures={setUnsavedSignatures}
              signatureData={signatureData}
              sendRecord={sendRecord}
              attachmentsData={attachmentsData}
              onPagePress={onPagePress}
              saveChangesButtonName="Save Edits"
              viewer="User"
              setClick={(click) => {
                flattenAnnotationRef.current = click;
              }}
              canGetandUpdateSignatures={true}
              newRecordViewer={true}
            />
          )}
        </div>
        <RecordDetails
          currentTransmission={currentTransmission}
          isInboxPage={isInboxPage}
          createAssignedDocument={createAssignedDocument}
          currentPage={currentPage}
        />
      </div>
      {dialogOpen && (
        <SendRecordsDialog
          faxSending={props.faxSending}
          handleSubmit={handleDialogSubmit}
          handleCancel={dialogVisible}
          open={dialogOpen}
          searchRecipient={searchRecipient}
          dataSource={props.dataSource}
          searchLoading={props.isLoading}
          patientName={patientName}
          patientDOB={patientDOB}
          defaultFaxNumber={props.defaultFaxNumber}
          associatedFaxNumbers={props.associatedFaxNumbers}
          userCanSendFaxRecordsOnly={props.userCanSendFaxRecordsOnly}
          senderNameReply={sender_name}
          senderOrganizationReply={props.senderOrganization}
          senderContactReply={sender_email}
          isReplyButtonClicked={isReplyButtonClicked}
          documentTitle={documentTitle}
          documentCategory={documentCategory}
        />
      )}
    </Drawer>
  );
};

NewRecordViewer.defaultProps = {
  currentRecordId: '',
  currentTransmission: {},
  record: '',
  recordHasUnsavedEdits: false,
  error: false,
  canEditRecord: true,
  canEditAfterSend: false,
  loggedIn: false,
  sessionExtendedAt: null,
  archiveErrorMessage: null,
  errorMessage: null,
  errorCode: null,
  privacyLevel: '',
  documentCategories: {},
  faxError: false,
  faxErrorMessage: null,
  senderOrganization: '',
  failedColumn: '',
  patientInfoEdited: false,
  aiStatus: '',
  hasWrongExtractionBeenReported: false,
  patientName: '',
  patientDOB: '',
  documentTitle: '',
  documentCategory: '',
  sendToken: '',
};

NewRecordViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  closeViewer: PropTypes.func.isRequired,
  currentRecordId: PropTypes.string,
  currentTransmission: PropTypes.object,
  record: PropTypes.string,
  recordHasUnsavedEdits: PropTypes.bool,
  error: PropTypes.bool,
  errorCode: PropTypes.number,
  canEditRecord: PropTypes.bool,
  canEditAfterSend: PropTypes.bool,
  attachmentsData: PropTypes.array.isRequired,
  extendUserSession: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  sessionExtendedAt: PropTypes.number,
  archiveErrorMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  canEditPrivacyLevel: PropTypes.bool.isRequired,
  canEditPatientInfo: PropTypes.bool.isRequired,
  privacyLevel: PropTypes.string,
  aiStatus: PropTypes.string,
  hasWrongExtractionBeenReported: PropTypes.bool,
  patientInfoEdited: PropTypes.bool,
  failedColumn: PropTypes.string,
  documentCategories: PropTypes.object,
  faxSending: PropTypes.bool.isRequired,
  searchRecipient: PropTypes.func.isRequired,
  dataSource: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  defaultFaxNumber: PropTypes.string.isRequired,
  associatedFaxNumbers: PropTypes.array.isRequired,
  userCanSendFaxRecordsOnly: PropTypes.bool.isRequired,
  senderOrganization: PropTypes.string,
  faxError: PropTypes.bool,
  faxErrorMessage: PropTypes.string,
  fileToken: PropTypes.string.isRequired,
  sendToken: PropTypes.string,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  documentTitle: PropTypes.string,
  documentCategory: PropTypes.string,
};

export default connect(
  state => ({
    currentTransmission: state.records.currentTransmission,
    currentRecordId: state.records.currentRecordId,
    fileToken: state.records.fileToken,
    sendToken: state.records.sendToken,
    patientName: state.records.patientName,
    patientDOB: state.records.patientDOB,
    documentTitle: state.records.documentTitle,
    documentCategory: state.records.documentCategory,
    record: state.records.currentRecord,
    recordHasUnsavedEdits: state.records.recordHasUnsavedEdits,
    error: state.records.error,
    errorCode: state.records.errorCode,
    canEditRecord: state.records.canEditRecord,
    canEditAfterSend: state.faxes.canEditAfterSend,
    signatureData: state.records.signatureData,
    attachmentsData: state.records.attachmentsData,
    loggedIn: state.auth.hasAuthCreds,
    sessionExtendedAt: state.auth.sessionExtendedAt,
    archiveErrorMessage: state.records.archiveErrorMessage,
    errorMessage: state.records.errorMessage,
    canEditPrivacyLevel: state.records.canEditPrivacyLevel,
    canEditPatientInfo: state.records.canEditPatientInfo,
    privacyLevel: state.records.privacyLevel,
    aiStatus: state.records.aiStatus,
    hasWrongExtractionBeenReported:
      state.records.hasWrongExtractionBeenReported,
    patientInfoEdited: state.records.patientInfoEdited,
    failedColumn: state.records.failedColumn,
    documentCategories: state.records.documentCategories,
    faxSending: state.faxes.isLoading,
    dataSource: state.search.results,
    loading: state.records.isLoading,
    isLoading: state.search.isLoading,
    defaultFaxNumber: state.auth.data.defaultFaxNumber,
    associatedFaxNumbers: state.auth.data.associatedFaxNumbers,
    userCanSendFaxRecordsOnly: state.auth.userCanSendFaxRecordsOnly,
    senderOrganization: state.records.senderOrganization,
    faxError: state.faxes.error,
    faxErrorMessage: state.faxes.errorMessage,
    sendDialogVisible: state.faxes.sendingDialogVisible,
  }),
  {
    getRecord: getRecordById,
    sendPatientRecord,
    sendToMultipleRecipients,
    closeSendDialog,
    saveAnnotationsOnRecord,
    extendUserSession,
    openModal,
    searchRecipient,
  },
)(NewRecordViewer);
