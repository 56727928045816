import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Table,
  Button,
  Popconfirm,
  message,
  Tag,
  Tooltip,
} from 'antd';
import { parse, stringify } from 'qs';

import { UserAddOutlined, ThunderboltTwoTone } from '@ant-design/icons';
import {
  getAddresses,
  addNewAddress,
  updateAddress,
  deleteAddress,
  getAddressesByQuery,
} from '../../../actions/addresses';
import { getLabelsByPage, getLabelsByQuery } from '../../../actions/labels';
import AddAddress from '../modals/AddRecipient';
import { formatValidFaxNumber, validFaxNumber } from '../../../global/validation';
import '../../../assets/stylesheets/components/_header.scss';
// eslint-disable-next-line import/no-useless-path-segments
import { Header } from '../..';
import { verifyFeatureAccess } from '../../../global/featureFlags';

// The typing timer is used in the search bar. It waits until the user's
// keystrokes are idle for 1 second before sending the request
// to Rails
let typingTimer = null;

const parseOptions = {
  ignoreQueryPrefix: true,
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressModal: false,
      currentPage: 1,
      searchLoading: false,
      sortedInfo: {
        columnKey: 'organization_name',
      },
      canModify: false,
      selectedAddress: [],
    };
  }

  componentWillMount() {
    this.getQueryData();
    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (queryParams.add === 'true') {
      this.setState({ showAddressModal: true });
    }
  }

  componentDidMount = async () => {
    const { isAdmin } = this.props;
    const canModify = !(await verifyFeatureAccess('admin_lock_address_book') && !isAdmin);
    this.setState({ canModify });
  }

  getQueryData(page = 1) {
    const { location, headers, getAddressesByQuery } = this.props;
    const totalPages = (headers ? parseInt(headers['total_pages'], 10) : 1);
    let params = {};
    if (location.search) {
      params = parse(location.search, parseOptions);
      page = parseInt((params.page <= totalPages ? params.page : 1), 10);
    }
    params.page = page;
    params.sort = 'contact_name';
    const query = stringify(params, stringifyOptions);
    getAddressesByQuery(query);
  }

  renderSelection = (params) => {
    getAddressesByQuery(stringify(params, stringifyOptions));
  };

  addNewRecipient = async (recipient, isEditingAddressID) => {
    const recipientObj = { recipient };
    const { addNewAddress, getAddresses, updateAddress } = this.props;
    // if isEditingAddressID is defined; user is editing an existing address so run updateAddress
    if (isEditingAddressID) await updateAddress(recipientObj, isEditingAddressID);
    else await addNewAddress(recipientObj);
    const { error, errorMessage } = this.props;

    if (!error) {
      message.success({
        content: isEditingAddressID ? 'Contact information successfully changed' : 'New contact successfully created',
      });
    } else {
      let msg = isEditingAddressID ? 'Duplicate contact already exists.' : 'Error adding contact. Please try again.';
      if (errorMessage === 'You do not have access to this feature.') {
        msg = 'Only admins can make changes to the contacts.';
      }
      if (errorMessage.includes('already set a provider office name for this fax number.')) {
        msg = errorMessage;
      }

      message.error({
        content: msg,
      });
    }
    getAddresses();
    this.showModal();
  }

  showModal = (address) => {
    if (address) this.setState({ selectedAddress: address });
    else this.setState({ selectedAddress: null });
    this.setState(prevState => ({
      showAddressModal: !prevState.showAddressModal,
    }));
  }

  beginDeleteAddress = async (recordID) => {
    const { deleteAddress, getAddresses } = this.props;
    await deleteAddress(recordID);

    const { error, errorMessage } = this.props;
    if (error) {
      let msg = 'Unable to delete contact. Please try again.';
      if (errorMessage === 'You do not have access to this feature.') {
        msg = 'Only admins can make changes to the contacts.';
      }

      message.error({
        content: msg,
      });
    } else {
      message.success({
        content: 'Contact successfully deleted',
      });
    }
    getAddresses();
  }

  searchQuery = async (q) => {
    // eslint-disable-next-line react/destructuring-assignment
    await this.props.getAddressesByQuery(`?contains=${q}`);
    this.setState({ searchLoading: false });
  }

  handleTableChange = async (pages, filters, sorter) => {
    const { order } = sorter;
    const sortDirMap = {
      'ascend': 'asc',
      'descend': 'desc',
    };
    this.setState({
      sortedInfo: sorter,
      currentPage: pages.current,
    });
    const sortDir = sortDirMap[order];
    const { location, getAddressesByQuery } = this.props;
    let params = {};
    if (location.search) {
      params = parse(location.search, parseOptions);
    }
    params.sort = sorter.field;
    params.sort_dir = sortDir;
    params.page = pages.current;
    await getAddressesByQuery(stringify(params, stringifyOptions));
  };

  searchKeyUp = (event) => {
    this.setState({ searchLoading: true });
    // Maintain a 1 second timer before sending request
    // If any new key is pressed during the course of that
    // timer, reset the timer
    clearTimeout(typingTimer);
    const query = event.target.value;
    typingTimer = setTimeout(() => {
      this.searchQuery(query);
    }, 1000);
  }

  render() {
    const {
      showAddressModal,
      searchLoading,
      currentPage,
      sortedInfo,
      selectedAddress,
      canModify,
    } = this.state;
    const { headers, data } = this.props;
    const totalResults = headers ? headers.total_count : 1;

    let columns = [
      {
        title: 'Organization Name',
        dataIndex: 'organization_name',
        align: 'left',
        key: 'organization_name',
        sortOrder: sortedInfo.columnKey === 'organization_name' && sortedInfo.order,
        sorter: true,
      },
      {
        title: 'Contact Name',
        dataIndex: 'contact_name',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'contact_name' && sortedInfo.order,
        key: 'contact_name',
      },
      {
        title: 'Contact Information',
        render: contact => (<>{validFaxNumber(contact).valid ? formatValidFaxNumber(contact) : contact}</>),
        dataIndex: 'contact',
        key: 'address',
      },
      {
        title: 'Provider Office',
        dataIndex: 'provider_office',
        key: 'provider_office',
      },
    ];

    const actionColumn = {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <Button onClick={() => this.showModal(record)} style={{ marginRight: 10 }}>
              Edit
            </Button>
            <Popconfirm title="Delete this contact? (This action is permanent and cannot be reversed)" onConfirm={() => this.beginDeleteAddress(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </>
        );
      },
    };

    const medsenderUserColumn = {
      title: '',
      key: 'medsender_user',
      render: (_, record) => {
        const medsender_user = record.is_a_medsender_user
        return (
          medsender_user
            && (
            <Tooltip title={`Our records indicate that this contact is a medsender user. Sending a record to a Medsender user guarantees instant delivery, gives recipients the ability to search through the record, and the ability to message the sender/recipient via Medsender Connect Chat!`} placement="left">
              <ThunderboltTwoTone twoToneColor="#691B9A"/>
            </Tooltip>
          )
        );
      },
    };

    const labelColumn = {
      title: 'Label',
      key: 'label',
      dataIndex: 'labels',
      render: (labels) => {
        return (
          labels && labels.map(label => (
            <Tag style={{ margin: '5px', backgroundColor: label.color, color: label.is_dark_text ? 'black' : 'white' }}>
              {label.name}
            </Tag>
          ))
        );
      },
      editable: true,
    };
    columns = [...columns, labelColumn];
    if (canModify) {
      columns = columns.concat(actionColumn);
    }

    columns = columns.concat(medsenderUserColumn)

    return (
      <div>
        <Helmet>
          <title>Contacts - Medsender</title>
        </Helmet>
        <div className="page-style">
          {showAddressModal
            && <AddAddress getLabelsByQuery={getLabelsByQuery} showModal={this.showModal} addNewRecipient={this.addNewRecipient} selectedAddress={selectedAddress} {...this.props}/>}
          <div className="content-container">
            <Header
              subtext="View, add and edit your contacts"
              buttons={canModify ? [
                {
                  text: 'Add Contact',
                  icon: <UserAddOutlined />,
                  func: () => (this.showModal()),
                },
              ] : []}
              searchField={true}
              searchProps={{
                placeholder: 'search contacts',
                loading: searchLoading,
                onKeyUp: this.searchKeyUp,
                onSearch: this.searchQuery,
              }}
            />
            {
              <Table
                dataSource={data}
                columns={columns}
                onChange={this.handleTableChange}
                pagination={{
                  position: ['bottomCenter'],
                  total: totalResults,
                  current: currentPage,
                  showSizeChanger: false,
                  showTotal: totalResults => `Total ${totalResults} items`,
                }}
              />
            }
            </div>
          </div>
      </div>
    )
  }
}

Contacts.defaultProps = {
  loading: false,
  errorMessage: null,
  errorCodex: null,
  error: false,
  errorCode: null,
  data: [],
  labelData: [],
  headers: {
    page: 1,
    'per-page': '10',
    total_count: 1,
    'total_pages': '1',
  },
  isAdmin: false,
};
Contacts.propTypes = {
  getAddresses: PropTypes.func.isRequired,
  addNewAddress: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  getAddressesByQuery: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorCode: PropTypes.string,
  labelData: PropTypes.array,
  headers: PropTypes.shape({
    page: PropTypes.number,
    'per-page': PropTypes.string,
    total_count: PropTypes.number,
    'total_pages': PropTypes.string,
  }),
  isAdmin: PropTypes.bool,
};
export default connect(state => ({
  data: state.addresses.data.recipients,
  headers: state.addresses.data.meta,
  loading: state.addresses.isLoading,
  error: state.addresses.error,
  labelData: state.labels.data,
  errorMessage: state.addresses.errorMessage,
  errorCode: state.addresses.errorCode,
  isAdmin: state.auth.isAdmin,
}), {
  getAddresses,
  addNewAddress,
  updateAddress,
  deleteAddress,
  getAddressesByQuery,
  getLabelsByPage,
  getLabelsByQuery,
})(Contacts);
export { Contacts };
