import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  RedoOutlined,
  SendOutlined,
  FilterOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';

import RecordsHOC from '../RecordsHOC';
import { Header } from '../../../components';
import { sentColumns } from '../shared/constants';

import {
  getSentRecordsByPage,
  getSentByQuery,
  resendAllFailedFaxes,
} from '../../../actions/records';
import { openModal } from '../../../actions/modal';
import { verifyFeatureAccess } from '../../../global/featureFlags';

// The filter options that will appear in the filter drop down menu list
const filterOptions = [
  { filter: 'all', text: 'Records sent to providers' },
  { filter: 'fax', text: 'Records sent via fax' },
  { filter: 'email', text: 'Records sent via email' },
  { filter: 'patients', text: 'Records sent to patients' },
];

class SentRecords extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    isResendingUnviewed: PropTypes.bool,
    isResendingFailed: PropTypes.bool,
    totalRecordsResent: PropTypes.number,
    location: PropTypes.object.isRequired,
    resendAllFailedFaxes: PropTypes.func.isRequired,
    getSentByQuery: PropTypes.func.isRequired,

    /* Data selected on inbox page */
    selectedData: PropTypes.shape({
      file_token: PropTypes.string,
      patient_dob: PropTypes.string,
      patient_name: PropTypes.string,
      upload_date: PropTypes.string,
    }),
  }

  static defaultProps = {
    selectedData: {},
    isResendingUnviewed: false,
    isResendingFailed: false,
    totalRecordsResent: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      disableSingleResend: true,
      filterValue: filterOptions[0],
      filterOptions: filterOptions.slice(1), // Removes the first option from the list
      openFilterMenu: false,
      canResendAllFailed: false,
    };
  }

  componentDidMount = async () => {
    const canResendAllFailed = await verifyFeatureAccess('resend_failed_records');
    this.setState({ canResendAllFailed });

    let recordsFilterIndex = 0;
    const { search } = this.props.location;
    filterOptions.map(({ filter }, index) => {
      if (search.includes(filter)) recordsFilterIndex = index;
    })
    this.updateFilterMenu(recordsFilterIndex);
  }

  componentWillReceiveProps(nextProps) {
    // Close drop down menu when a record is selected or unselected
    if ((this.state.openMenu || this.state.openFilterMenu) && (nextProps.selectedData !== this.props.selectedData)) {
      this.setState({
        openMenu: false,
        openFilterMenu: false,
      });
    }
    // Check if resending is successful and display different toastr msgs for bulk
    // and single resend.
    if (!nextProps.isResendingUnviewed && this.props.isResendingUnviewed) {
      /* global toastr */
      let toastrMessage = 'The record has been resent successfully';
      if (this.state.disableSingleResend) {
        if (nextProps.totalRecordsResent === 0) {
          const { batchSize } = this.state;
          toastrMessage = `There are no unviewed records in the past ${batchSize} days to be resent.`;
          return toastr.info(toastrMessage);
        }
        if (nextProps.totalRecordsResent === 1) {
          toastrMessage = '1 record has been resent successfully';
        }
        if (nextProps.totalRecordsResent > 1) {
          toastrMessage = `${nextProps.totalRecordsResent} records have been resent successfully`;
        }
      }
      toastr.success(toastrMessage);
    }
    // Do the same, but for failed records. TODO(jack) Redundant code.
    if (!nextProps.isResendingFailed && this.props.isResendingFailed) {
      /* global toastr */
      let toastrMessage = 'The record has been resent successfully';
      if (this.state.disableSingleResend) {
        if (nextProps.totalRecordsResent === 0) {
          toastrMessage = 'There are no failed records to be resent.';
          return toastr.info(toastrMessage);
        }
        if (nextProps.totalRecordsResent === 1) {
          toastrMessage = '1 record has been resent successfully';
        }
        if (nextProps.totalRecordsResent > 1) {
          toastrMessage = `${nextProps.totalRecordsResent} records have been resent successfully`;
        }
      }
      toastr.success(toastrMessage);
    }
  }

  handleDropDown = async () => {
    const canResendRecord = await verifyFeatureAccess('resend_records');
    if (!canResendRecord) {
      const modalType = {
        type: 'RAISE_FLAG',
        featureFlag: 'resend_records',
      };
      return this.props.openModal(modalType);
    }
    const { selectedData } = this.props;
    this.setState({
      openMenu: true,
      disableSingleResend: Object.keys(selectedData).length === 0,
    });
  }

  // If the page is refreshed and there was an active filter, this will ensure that the
  // filter values remain consistent
  updateFilterMenu = (index) => {
    this.setState({
      filterValue: filterOptions[index],
      filterOptions: filterOptions.filter(option => option !== filterOptions[index]),
    });
  }

  handleResendRecord = (event, child) => {
    const { value } = child.props;
    const data = value === '1' ? { recipient: this.props.selectedData } : { days: value };
    const modalType = {
      type: 'RESEND_RECORD',
      data,
    };
    this.setState({
      batchSize: value,
    });
    this.props.openModal(modalType);
  }

  handleRequestChange = (value) => {
    this.setState({
      openMenu: value,
    });
  }

  // Fetches the correct list of records according to the filter selected.
  // Also updates the filter drop down menu list to show current active filter
  handleFilter = (event, value) => {
    let filterQuery = 0;

    filterOptions.map(({ filter }, index) => {
      if (value.props.primaryText.includes(filter)) filterQuery = index;
    });

    const { filter: selectedFilter } = filterOptions[filterQuery];
    this.props.getSentByQuery(`?${selectedFilter}=true`);
    this.setState({
      filterValue: filterOptions[filterQuery],
      filterOptions: filterOptions.filter(({ filter }) => filter !== selectedFilter),
    });
  }

  /**
   * Handles resending of all records that failed during transmission.
   * This records were sent by fax
   */
  handleResendAllFaxes = async () => {
    const canResendAllFaxes = await verifyFeatureAccess('resend_failed_records');
    if (canResendAllFaxes) return this.props.resendAllFailedFaxes();
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: 'resend_failed_records',
    };
    this.props.openModal(modalType);
  }

  autoUploadRecord = async (selectedRecord) => {
    const {
      patient_name: patientName,
      patient_dob: patientDOB,
      send_token: sendToken,
      receiver_type: receiverType,
    } = selectedRecord;
    const autoUploadEmr = localStorage.getItem('m8_auto_upload_emr');
    // If EMR is EMA, we will allow Send Chart Modal to open as Patient MRN is an option to send with
    // chart instead of Patient Name and DOB.
    if (!patientName || !patientDOB) {
      message.error({
        content: 'Cannot upload a document without a valid name and date of birth. Edit record details and try again.',
      });
    } else {
      let recordFileName = selectedRecord.document_title;
      if (!recordFileName) {
        recordFileName = `${selectedRecord.created_at.replace(/ /g, '_')}_${selectedRecord.sender_name.replace(/ /g, '_')}`;
      }
      const modalType = {
        type: 'AUTO_UPLOAD',
        data: {
          fileName: recordFileName,
          providerName: selectedRecord.practitioner_name,
          patientName: selectedRecord.patient_name,
          patientDOB: selectedRecord.patient_dob,
          documentCategory: selectedRecord.document_category,
          sendToken,
          receiverType,
          autoUploadEmr,
          noTaskCreation: true,
        },
      };
      this.props.openModal(modalType);
    }
  }

  render() {
    const {
      disableSingleResend,
      openMenu,
      openFilterMenu,
      canResendAllFailed,
    } = this.state;
    const {
      selectedData,
    } = this.props;
    return (
      <div>
        <Helmet>
          <title>Sent Records - Medsender</title>
        </Helmet>
        <Header
          main={'Sent records'}
          subtext={'Choose a record below'}
          locationPath={[
            { icon: 'home', text: 'Home', link: '/app' },
            { icon: 'inbox', text: 'Sent', link: '/app/sent' },
          ]}
          buttons={[
            {
              disableSingleResend,
              openMenu,
              hide: this.props.location.search.includes('patients=true') || false,
              text: 'Resend Records',
              icon: <RedoOutlined />,
              resendRecords: true,
              disabled: false,
              func: () => this.handleDropDown(),
              handleResendRecord: this.handleResendRecord,
              handleRequestChange: this.handleRequestChange,
            },
            {
              text: 'Resend All Failed Records',
              icon: <SendOutlined />,
              disabled: false,
              hide: !canResendAllFailed,
              func: this.handleResendAllFaxes,
            },
            {
              openFilterMenu,
              text: `Viewing ${this.state.filterValue.text}`,
              icon: <FilterOutlined />,
              filterRecords: true,
              disabled: false,
              func: () => this.setState({ openFilterMenu: true }),
              handleFilter: this.handleFilter,
              filterOptions: this.state.filterOptions,
              handleRequestChange: value => this.setState({ openFilterMenu: value }),
            },
            {
              text: 'Send to Chart',
              icon: <CloudUploadOutlined />,
              disabled: Object.keys(selectedData).length < 1,
              func: () => this.autoUploadRecord(selectedData),
            },
          ]}
        />
      </div>
    );
  }
}

const passedProps = {
  getSelectedPageData: getSentRecordsByPage,
  getPageDataWithQuery: getSentByQuery,
  data: 'sent_data',
  mode: 'Sent',
  columnTitles: sentColumns,
  resendAllFailedFaxes,
};

export default RecordsHOC(passedProps)(
  connect(state => ({
    selectedData: state.inboxTable.recordData[0],
    isResendingUnviewed: state.records.isResendingUnviewed,
    isResendingFailed: state.records.isResendingFailed,
    totalRecordsResent: state.records.totalRecordsResent,
  }), {
    openModal,
    resendAllFailedFaxes,
    getSentByQuery,
  })(SentRecords),
);
