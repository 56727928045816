import { useEffect } from 'react';

const AnalyticsDashboard = () => {
  useEffect(() => {
    const url = window.location.origin;
    const subdomainUrl = `${url}/analytics`;

    window.location.href = subdomainUrl;
  }, []);

  return null;
};

export default AnalyticsDashboard;
