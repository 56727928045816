import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { PencilSimpleSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { PAGES } from '../../shared/constants';

const UpdateReferralStatusButton = ({
  currentPage,
  recordBlobObject,
  beginSaveAnnotationsOnRecord,
  ...props
}) => {
  // eslint-disable-next-line no-shadow
  const { openModal, currentRecordId } = props;
  const hideButton = currentPage !== PAGES.REFERRED;

  const updateReferralOpen = async () => {
    if (recordBlobObject) {
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    const {
      referralNote, sendToken, receiverType, fileToken,
    } = props;

    const modalType = {
      type: 'UPDATE_REFERRAL',
      data: {
        sendToken,
        parentType: receiverType,
        fileToken,
        referralNote,
      },
    };
    openModal(modalType);
  };

  return (
    <ToolbarButton
      title="Update Referral Status"
      icon={PencilSimpleSvg}
      hide={hideButton}
      onClick={updateReferralOpen}
    />
  );
};

UpdateReferralStatusButton.defaultProps = {
  sendToken: '',
  recordBlobObject: {},
  referralNote: '',
  receiverType: '',
};

UpdateReferralStatusButton.propTypes = {
  currentPage: PropTypes.string.isRequired,
  recordBlobObject: PropTypes.object,
  beginSaveAnnotationsOnRecord: PropTypes.func.isRequired,

  sendToken: PropTypes.string,
  fileToken: PropTypes.string.isRequired,
  currentRecordId: PropTypes.string.isRequired,
  referralNote: PropTypes.string,
  receiverType: PropTypes.string,

  openModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    sendToken: state.records.sendToken,
    fileToken: state.records.fileToken,
    currentRecordId: state.records.currentRecordId,
    referralNote: state.records.referralNote,
    receiverType: state.records.receiverType,
  }),
  { openModal },
)(UpdateReferralStatusButton);
