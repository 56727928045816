import React from 'react';
import PropTypes from 'prop-types';
import ToolbarButton from './ToolbarButton';
import { LinkSimpleSvg } from '../../../assets/img/redesign/svgPaths';

const PublishButton = ({
  isFormDocument, linkDialogVisible,
}) => {
  const hideButton = !isFormDocument;

  return (
    <ToolbarButton
      title="Publish Form"
      icon={LinkSimpleSvg}
      hide={hideButton}
      onClick={() => linkDialogVisible(true)}
    />
  );
};

PublishButton.propTypes = {
  isFormDocument: PropTypes.bool.isRequired,
  linkDialogVisible: PropTypes.func.isRequired,
};

export default PublishButton;
