import crud from './helpers/crud';

const getUserInfo = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_USER_INFO',
      fail: 'FAILED_GET_USER_INFO',
      end: 'FINISHED_GET_USER_INFO',
    },
    method: 'GET',
    url: '/api/v1/users/',
  })
);

const getUserDepartmentsByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_USER_DEPARTMENTS',
      fail: 'FAILED_GET_USER_DEPARTMENTS',
      end: 'FINISHED_GET_USER_DEPARTMENTS',
    },
    method: 'GET',
    url: `/api/v1/department_users${query}`,
  })
);

const getBillingUsage = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_BILLING_USAGE',
      fail: 'FAILED_GET_BILLING_USAGE',
      end: 'FINISHED_GET_BILLING_USAGE',
    },
    method: 'GET',
    url: '/api/v1/billing',
  })
);

const changeEmailPreference = emailPreference => (
  crud({
    dispatch: {
      begin: 'BEGIN_CHANGE_EMAIL_PREFERENCE',
      fail: 'FAILED_CHANGE_EMAIL_PREFERENCE',
      end: 'FINISHED_CHANGE_EMAIL_PREFERENCE',
    },
    method: 'PATCH',
    url: '/api/v1/users/',
    data: {
      users: {
        notification_fax_email: emailPreference,
      },
    },
  })
);
const changeEmailPreferenceForEmailedRecords = emailPreference => (
  crud({
    dispatch: {
      begin: 'BEGIN_CHANGE_EMAIL_PREFERENCE_FOR_EMAILED_RECORDS',
      fail: 'FAILED_CHANGE_EMAIL_PREFERENCE_FOR_EMAILED_RECORDS',
      end: 'FINISHED_CHANGE_EMAIL_PREFERENCE_FOR_EMAILED_RECORDS',
    },
    method: 'PATCH',
    url: '/api/v1/users/',
    data: {
      users: {
        email_notification_emailed_records: emailPreference,
      },
    },
  })
);

const changeEmailPreferenceForAssignedRecords = emailPreference => (
  crud({
    dispatch: {
      begin: 'BEGIN_CHANGE_EMAIL_PREFERENCE_FOR_ASSIGNED_RECORDS',
      fail: 'FAILED_CHANGE_EMAIL_PREFERENCE_FOR_ASSIGNED_RECORDS',
      end: 'FINISHED_CHANGE_EMAIL_PREFERENCE_FOR_ASSIGNED_RECORDS',
    },
    method: 'PATCH',
    url: '/api/v1/users/',
    data: {
      users: {
        email_notification_assigned_records: emailPreference,
      },
    },
  })
);

const changeEmailPreferenceForFailedFaxes = emailPreference => (
  crud({
    dispatch: {
      begin: 'BEGIN_CHANGE_FAILED_FAX_PREFERENCE',
      fail: 'FAILED_CHANGE_FAILED_FAX_PREFERENCE',
      end: 'FINISHED_CHANGE_FAILED_FAX_PREFERENCE',
    },
    method: 'PATCH',
    url: '/api/v1/users/',
    data: {
      users: {
        failed_sent_faxes_notification: emailPreference,
      },
    },
  })
);

const changeModalState = (newState, newPlan = -1, open = false) => {
  return {
    type: 'CHANGE_BILLING_MODAL_STATE',
    state: newState,
    plan: newPlan,
    open,
  };
};

const loginAdmin = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_LOGIN_ADMIN',
      fail: 'FAILED_LOGIN_ADMIN',
      end: 'FINISHED_LOGIN_ADMIN',
    },
    method: 'POST',
    url: '/api/v1/sign_in_admin_user/',
  })
);

const updateUserDetails = details => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_USER_DETAILS',
      fail: 'FAILED_UPDATE_USER_DETAILS',
      end: 'FINISHED_UPDATE_USER_DETAILS',
    },
    method: 'PATCH',
    data: {
      users: details,
    },
    url: '/api/v1/users',
  })
);

const getOrganizationDepartments = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ALL_DEPARTMENTS',
      fail: 'FAILED_GET_ALL_DEPARTMENTS',
      end: 'FINISHED_GET_ALL_DEPARTMENTS',
    },
    method: 'GET',
    url: '/api/v1/departments',
  })
);

const getDocumentCategories = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_DOCUMENT_CATEGORIES',
      fail: 'FAILED_GET_DOCUMENT_CATEGORIES',
      end: 'FINISHED_GET_DOCUMENT_CATEGORIES',
    },
    method: 'GET',
    url: '/api/v1/document_categories?no_pagination=true',
  })
);

const getEmaPatients = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_EMA_PATIENTS',
      fail: 'FAILED_GET_EMA_PATIENTS',
      end: 'FINISHED_GET_EMA_PATIENTS',
    },
    method: 'GET',
    url: `/api/v1/ema_patients${query}`,
  })
);

const getDocumentStatuses = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_DOCUMENT_STATUSES',
      fail: 'FAILED_GET_DOCUMENT_STATUSES',
      end: 'FINISHED_GET_DOCUMENT_STATUSES',
    },
    method: 'GET',
    url: '/api/v1/document_statuses',
  })
);

const getReferredStatuses = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_REFERRED_STATUSES',
      fail: 'FAILED_GET_REFERRED_STATUSES',
      end: 'FINISHED_GET_REFERRED_STATUSES',
    },
    method: 'GET',
    url: '/api/v1/referral_statuses?no_pagination=true',
  })
);

const getTaskTemplates = (page = 1, pageSize = 10) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_EMA_TASK_TEMPLATES',
      fail: 'FAILED_GET_EMA_TASK_TEMPLATES',
      end: 'FINISHED_GET_EMA_TASK_TEMPLATES',
    },
    method: 'GET',
    url: `/api/v1/ema_task_templates?page=${page}&page_size=${pageSize}`,
  })
);

const getTaskTemplatesByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_EMA_TASK_TEMPLATES',
      fail: 'FAILED_GET_EMA_TASK_TEMPLATES',
      end: 'FINISHED_GET_EMA_TASK_TEMPLATES',
    },
    method: 'GET',
    url: `/api/v1/ema_task_templates${query}`,
  })
);

const createNewTaskTemplate = taskTemplateObject => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_TASK_TEMPLATE',
      fail: 'FAILED_CREATE_TASK_TEMPLATE',
      end: 'FINISH_CREATE_TASK_TEMPLATE',
    },
    method: 'POST',
    data: { ema_task_template: taskTemplateObject },
    url: '/api/v1/ema_task_templates',
  })
);

const deleteTaskTemplate = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_DELETE_TASK_TEMPLATE',
      fail: 'FAILED_DELETE_TASK_TEMPLATE',
      end: 'FINISH_DELETE_TASK_TEMPLATE',
    },
    method: 'DELETE',
    url: `/api/v1/ema_task_templates/${id}`,
  })
);

const updateTaskTemplate = (id, taskTemplateObject) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_TASK_TEMPLATE',
      fail: 'FAILED_UPDATE_TASK_TEMPLATE',
      end: 'FINISH_UPDATE_TASK_TEMPLATE',
    },
    method: 'PUT',
    url: `/api/v1/ema_task_templates/${id}`,
    data: { ema_task_template: taskTemplateObject },
  })
);

const getEmaTaskProviders = sendToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_EMA_TASK_PROVIDERS',
      fail: 'FAILED_GET_EMA_TASK_PROVIDERS',
      end: 'FINISHED_GET_EMA_TASK_PROVIDERS',
    },
    method: 'GET',
    url: `/api/v1/receivers/${sendToken}/ema_task_providers`,
  })
);

const getTaskProviders = (page = 1, pageSize = 10) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_TASK_PROVIDERS',
      fail: 'FAILED_GET_TASK_PROVIDERS',
      end: 'FINISHED_GET_TASK_PROVIDERS',
    },
    method: 'GET',
    url: `/api/v1/ema_task_providers?page=${page}&page_size=${pageSize}`,
  })
);

const getTaskProvidersByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_TASK_PROVIDERS',
      fail: 'FAILED_GET_TASK_PROVIDERS',
      end: 'FINISHED_GET_TASK_PROVIDERS',
    },
    method: 'GET',
    url: `/api/v1/ema_task_providers${query}`,
  })
);

const createTaskProvider = providerObject => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_EMA_TASK_PROVIDER',
      fail: 'FAILED_CREATE_EMA_TASK_PROVIDER',
      end: 'FINISH_CREATE_EMA_TASK_PROVIDER',
    },
    method: 'POST',
    data: { provider: providerObject },
    url: '/api/v1/ema_task_providers',
  })
);

const deleteTaskProvider = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_DELETE_EMA_TASK_PROVIDER',
      fail: 'FAILED_DELETE_EMA_TASK_PROVIDER',
      end: 'FINISH_DELETE_EMA_TASK_PROVIDER',
    },
    method: 'DELETE',
    url: `/api/v1/ema_task_providers/${id}`,
  })
);

const updateTaskProvider = (id, providerObject) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_EMA_TASK_PROVIDER',
      fail: 'FAILED_UPDATE_EMA_TASK_PROVIDER',
      end: 'FINISH_UPDATE_EMA_TASK_PROVIDER',
    },
    method: 'PUT',
    url: `/api/v1/ema_task_providers/${id}`,
    data: { provider: providerObject },
  })
);

const sendOtpToSms = phoneNumber => (
  crud({
    dispatch: {
      begin: 'BEGIN_SEND_OTP_TO_SMS',
      fail: 'FAILED_SEND_OTP_TO_SMS',
      end: 'FINISHED_SEND_OTP_TO_SMS',
    },
    method: 'POST',
    data: {
      user_input: phoneNumber,
    },
    url: '/api/v1/verify_phone_number',
  })
);

const verifySmsOtp = (otpCode, phoneNum) => (
  crud({
    dispatch: {
      begin: 'BEGIN_VERIFY_SMS_OTP',
      fail: 'FAILED_VERIFY_SMS_OTP',
      end: 'FINISHED_VERIFY_SMS_OTP',
    },
    method: 'PUT',
    data: {
      inputted_otp: otpCode,
      phone_number: phoneNum,
    },
    url: '/api/v1/verify_phone_number',
  })
);

const getActiveFeatures = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SUBSCRIPTIONS',
      fail: 'FAILED_GET_SUBSCRIPTIONS',
      end: 'FINISHED_GET_SUBSCRIPTIONS',
    },
    method: 'GET',
    url: '/api/v1/subscriptions',
  })
);

const enableFeature = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_SUBSCRIPTION',
      fail: 'FAILED_CREATE_SUBSCRIPTION',
      end: 'FINISHED_CREATE_SUBSCRIPTION',
    },
    method: 'POST',
    data: { feature: { name: id } },
    url: '/api/v1/subscriptions',
  })
);

const disableFeature = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_DELETE_SUBSCRIPTION',
      fail: 'FAILED_DELETE_SUBSCRIPTION',
      end: 'FINISHED_DELETE_SUBSCRIPTION',
    },
    method: 'DELETE',
    url: `/api/v1/subscriptions/${id}`,
  })
);

const getPayerNames = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_PAYER_NAMES',
      fail: 'FAILED_GET_PAYER_NAMES',
      end: 'FINISHED_GET_PAYER_NAMES',
    },
    method: 'GET',
    url: '/api/v1/payer_names',
  })
);

const getDefaultSmsTemplate = (sendToken, receiverType) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_DEFAULT_SMS_TEMPLATE',
      fail: 'FAILED_GET_DEFAULT_SMS_TEMPLATE',
      end: 'FINISHED_GET_DEFAULT_SMS_TEMPLATE',
    },
    method: 'GET',
    url: `/api/v1/sms_templates/default_template?send_token=${sendToken}&receiver_type=${receiverType}`
  })
);

export {
  getUserInfo,
  getUserDepartmentsByQuery,
  getBillingUsage,
  changeModalState,
  loginAdmin,
  changeEmailPreference,
  changeEmailPreferenceForEmailedRecords,
  changeEmailPreferenceForAssignedRecords,
  changeEmailPreferenceForFailedFaxes,
  updateUserDetails,
  getOrganizationDepartments,
  getDocumentCategories,
  getEmaPatients,
  getDocumentStatuses,
  getTaskTemplates,
  getTaskTemplatesByQuery,
  createNewTaskTemplate,
  updateTaskTemplate,
  deleteTaskTemplate,
  getEmaTaskProviders,
  sendOtpToSms,
  verifySmsOtp,
  getReferredStatuses,
  getTaskProviders,
  getTaskProvidersByQuery,
  createTaskProvider,
  deleteTaskProvider,
  updateTaskProvider,
  getActiveFeatures,
  enableFeature,
  disableFeature,
  getPayerNames,
  getDefaultSmsTemplate,
};
