/* eslint-disable no-shadow */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu, message } from 'antd';
import {
  CloudArrowUpSvg,
  FolderSimpleSvg,
  SimpleDriveSvg,
} from '../../../assets/img/redesign/svgPaths';
import Button from '../../../components/Button';
import { PAGES, RECEIVER_TYPES } from '../../shared/constants';
import {
  triggerGoogleDriveUpload,
  triggerSFTPUpload,
  triggerSharepointUpload,
} from '../../../actions/records';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import '../newRecordViewer.scss';

const CloudUploadDropdown = ({ currentPage, ...props }) => {
  const [canUploadToGoogleDrive, setCanUploadToGoogleDrive] = useState(false);
  const [canUploadToSFTPFolder, setCanUploadToSFTPFolder] = useState(false);
  const [canUploadToSharepointFolder, setCanUploadToSharepointFolder] = useState(false);

  const {
    // eslint-disable-next-line react/prop-types
    receiverType,
    sendToken,
  } = props;

  const hide = currentPage !== PAGES.RECEIVED
    || receiverType !== RECEIVER_TYPES.RECEIVED_FAX;

  // useEffect to handle downloading and printing
  useEffect(() => {
    const setPermissions = async () => {
      setCanUploadToGoogleDrive(
        await verifyFeatureAccess('upload_google_drive'),
      );
      setCanUploadToSharepointFolder(
        await verifyFeatureAccess('sharepoint_upload'),
      );
      setCanUploadToSFTPFolder(await verifyFeatureAccess('sftp_upload'));
    };

    setPermissions();
  }, []);

  const uploadToGoogleDrive = async () => {
    const { triggerGoogleDriveUpload: triggerDriveUpload } = props;
    await triggerDriveUpload(sendToken);
    const { error, errorCode } = props;
    if (!error && !errorCode) {
      message.success({
        content: 'Successfully uploaded to Google Drive.',
      });
    } else if (errorCode === 400) {
      message.error({
        content:
          'A Google Drive Folder does not exist for the selected Fax Number. Please contact a Medsender admin to create a Fax Folder.',
      });
    } else {
      message.error({
        content: 'We were unable to upload to Google Drive. Please try again.',
      });
    }
  };

  const uploadToSFTPFolder = async () => {
    const { triggerSFTPUpload: triggerUpload } = props;
    await triggerUpload(sendToken);
    const { error, errorCode, errorMessage } = props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to SFTP Server.',
      });
    } else if (errorCode === 400) {
      message.error({
        content:
          'A SFTP Folder does not exist for the selected Fax Number. Please contact a Medsender admin to create a Fax Folder.',
      });
    } else if (errorCode === 403) {
      if (errorMessage === 'No SFTP Credential found on your account.') {
        message.error({
          content:
            'Your SFTP service is missing credentials. Please contact Medsender support to add credentials.',
        });
      } else {
        message.error({
          content: 'You do not have access to this feature.',
        });
      }
    } else {
      message.error({
        content:
          'We were unable to upload to the SFTP Server. Please try again.',
      });
    }
  };

  const uploadToSharepointFolder = async () => {
    const { triggerSharepointUpload: triggerUpload } = props;
    await triggerUpload(sendToken);
    const { error, errorCode } = props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to Microsoft Sharepoint',
      });
    } else if (errorCode === 422) {
      message.error({
        content:
          'There is an existing file with the same name as the document you are trying to upload.',
      });
    } else {
      message.error({
        content:
          'We were unable to upload to Microsoft Sharepoint. Please try again.',
      });
    }
  };

  const menu = (
    <Menu>
      {canUploadToGoogleDrive && (
        <Menu.Item onClick={uploadToGoogleDrive}>
          <span className="dropdown-icon">{SimpleDriveSvg}</span>
          Upload to Google Drive
        </Menu.Item>
      )}
      {canUploadToSFTPFolder && (
        <Menu.Item onClick={uploadToSFTPFolder}>
          <span className="dropdown-icon">{FolderSimpleSvg}</span>
          Upload to SFTP Folder
        </Menu.Item>
      )}
      {canUploadToSharepointFolder && (
        <Menu.Item onClick={uploadToSharepointFolder}>
          <span className="dropdown-icon">{FolderSimpleSvg}</span>
          Upload to Sharepoint Folder
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    !hide && (
      <Dropdown overlay={menu}>
        <Button className="tertiary medium icon-btn" icon={CloudArrowUpSvg} />
      </Dropdown>
    )
  );
};

CloudUploadDropdown.defaultProps = {
  sendToken: '',
  error: false,
  errorMessage: null,
  errorCode: null,
};

CloudUploadDropdown.propTypes = {
  currentPage: PropTypes.string.isRequired,

  sendToken: PropTypes.string,
  error: PropTypes.bool,
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,

  triggerGoogleDriveUpload: PropTypes.func.isRequired,
  triggerSFTPUpload: PropTypes.func.isRequired,
  triggerSharepointUpload: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    sendToken: state.records.sendToken,
    error: state.records.error,
    errorCode: state.records.errorCode,
    errorMessage: state.records.errorMessage,
  }),
  {
    triggerGoogleDriveUpload,
    triggerSFTPUpload,
    triggerSharepointUpload,
  },
)(CloudUploadDropdown);
