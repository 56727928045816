import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../../util/constants/colors';

const VARIANTS = {
  primary: COLORS.STROKE_LITE,
  secondary: COLORS.PURPLE_TERTIARY_DARK,
};

const Box = ({ children, style, variant, ...props }) => {
  return (
    <div
      style={{
        width: '100%',
        borderRadius: '8px',
        border: `1px solid ${VARIANTS[variant]}`,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

Box.defaultProps = {
  children: null,
  style: {},
  variant: 'primary',
};

Box.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default Box;
