// Connects the Edit patient info modal component with store

import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReferralReply from '../../components/modal/ReferralReply';
import {
  sendReferralReply,
  getReferralReplies,
  getReferralReplyUrl,
  getReferralReplyPreviewUrl,
  getReferralDocumenStatus,
} from '../../actions/records';

class ReferralReplyDialog extends Component {
  render() {
    return (
      <div>
        <ReferralReply
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  sendToken: state.records.sendToken,
}), {
  sendReferralReply,
  getReferralReplies,
  getReferralDocumenStatus,
  getReferralReplyUrl,
  getReferralReplyPreviewUrl,
})(ReferralReplyDialog);
