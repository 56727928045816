import { Drawer } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { getVoiceCall } from '../../actions/calls'
import Transcription from './Transcription'
import Summary from './Summary'
import Button from '../../components/Button'
import Tooltip from '../../components/Tooltip'
import InfoSection from './InfoSection'
import {
  Cake,
  CalendarBlank,
  CaretDoubleRight,
  Clock,
  Phone,
  PhoneX,
  Timer,
  User
} from '@phosphor-icons/react'
import {
  formatDate,
  formatDuration,
  formatPhoneNumber,
  formatTime,
  formatBirthdate,
} from './helpers'
import COLORS from '../../util/constants/colors'
import './voiceSlideOut.scss'

const VoiceSlideOut = ({ visible, onClose, ...props }) => {
  const { voiceCall } = props
  const onMobile = window.innerWidth < 768;
  const drawerWidth = onMobile ? '100%' : 500;

  const overviewFields = [
    {
      label: 'Caller Number',
      value: formatPhoneNumber(voiceCall?.caller_number),
      icon: <Phone size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
    {
      label: 'Caller Name',
      value: voiceCall?.caller_name || 'Not Mentioned',
      icon: <User size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
    {
      label: 'Caller DOB',
      value: voiceCall?.caller_dob && voiceCall?.caller_dob !== 'Not mentioned' ? formatBirthdate(voiceCall?.caller_dob) : 'Not Mentioned',
      icon: <Cake size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
    {
      label: 'Date',
      value: formatDate(voiceCall?.ended_at) || 'N/A',
      icon: <CalendarBlank size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
    {
      label: 'Time',
      value: formatTime(voiceCall?.ended_at) || 'N/A',
      icon: <Clock size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
  ]

  const voiceFields = [
    {
      label: 'Duration',
      value: formatDuration(voiceCall?.duration_seconds),
      icon: <Timer size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
    {
      label: 'Disconnection Reason',
      value: voiceCall?.disconnection_reason || 'N/A',
      icon: <PhoneX size={16} color={COLORS.PURPLE_PRIMARY_LITE} weight="fill" />,
    },
  ]

  return (
    <Drawer
      placement="right"
      visible={visible}
      onClose={onClose}
      width={drawerWidth}
      closable={false}
    >
      <div className="voice-slide-out">
        <div className="voice-slide-out-header">
          <Tooltip title="Close">
            <Button
              className="tertiary medium icon-btn"
              size="medium"
              icon={<CaretDoubleRight size={20} />}
              onClick={onClose}
            />
          </Tooltip>
        </div>
        <div className="voice-slide-out-body">
          <InfoSection title="Overview" fields={overviewFields} />
          <Summary />
          <InfoSection title="Call Details" fields={voiceFields} />
          <Transcription />
        </div>
      </div>
    </Drawer>
  )
}

VoiceSlideOut.defaultProps = {
  visible: false,
  onClose: () => {},

  voiceCall: null,
};

export default connect((state) => ({
  voiceCall: state.calls.currentVoiceCall,
}), {
  getVoiceCall,
})(VoiceSlideOut)
