import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form, Cascader, Checkbox } from 'antd';

const checkboxStyle = {
  marginTop: '10px',
  marginLeft: 0,
};

class FilterSettings extends Component {
  constructor(props) {
    super(props);
    const { params } = props.paramTools;
    this.state = {
      failed: Object.keys(params).includes('failed'),
      views: Object.keys(params).includes('views') && params.views === '0',
      patients: Object.keys(params).includes('patients'),
      archived: (Object.keys(params).includes('archived') && params.archived === 'true') || false,
      myRecordsOnly: Object.keys(params).includes('myRecordsOnly'),
      myDepartmentOnly: Object.keys(params).includes('myDepartmentOnly'),
      currentDepartment: Object.keys(params).includes('department') ? params.department : null,
      myTaggedRecordsOnly: Object.keys(params).includes('myTaggedRecordsOnly'),
      completed: Object.keys(params).includes('completed'),
      scheduled: Object.keys(params).includes('scheduled'),
      unassigned: Object.keys(params).includes('unassigned'),
      document_type: params.document_type || '',
      failedAutoUploaded: Object.keys(params).includes('failedAutoUploaded'),
      formSubmissions: Object.keys(params).includes('formSubmissions'),
      emailSubmissions: Object.keys(params).includes('emailSubmissions'),
    };
    this.options = [
      { key: 0, value: '', name: 'All departments' },
      ...props.departments.map((department) => {
        return { key: department.name, value: encodeURIComponent(department.slug), name: department.name };
      })];
  }

  componentWillReceiveProps(nextProps) {
    const { params } = nextProps.paramTools;
    const { patients } = this.state;
    if (patients !== params.patients) {
      this.setState({
        patients: Object.keys(params).includes('patients'),
      });
    }
  }

  updateCheck = (event) => {
    const { paramTools: { params } } = this.props;
    const isInputChecked = event.target.checked;
    const checkboxToUpdate = event.target.value;
    // The four query parameters handled by this component are
    // "views", "myDepartmentOnly", "myRecordsOnly", and "myTaggedRecordsOnly"
    //
    // The first of these must pass a number ?views=0 instead of a boolean for the
    // other two. That's why we check to see if the "views" checkbox was clicked,
    // so that the query param can use the integer value instead of the boolean
    // in order for Rails to handle it properly
    const queryParamValue = (checkboxToUpdate === 'views' ? 0 : isInputChecked);

    // We'll need to mutate this later based on certain conditions, which is why
    // it's declared here
    let updatedState = { [checkboxToUpdate]: isInputChecked };

    // Create a new query params object so we don't mutate the existing one
    let newParams = params;

    // At any given time, only one of "myDepartmentOnly", "myRecordsOnly", and "myTaggedRecordsOnly" should be checked
    //
    // To enforce this, we'll see if one of them is already checked. If that's true,
    // we need to not only set the new checkbox to true, but set the other two already checked to false
    //
    // Determine if one of the three is already selected
    const limitFilter = ['myDepartmentOnly', 'myRecordsOnly', 'myTaggedRecordsOnly'].filter(filter => filter !== checkboxToUpdate);
    const checkedMyOrMyDepartment = limitFilter.length === 2;

    if (checkedMyOrMyDepartment) {
      // For safety, remove all three params, even if one of them we're supposed to update.
      // The update will be handled in a later block
      delete newParams.department;
      delete newParams.myDepartmentOnly;
      delete newParams.myTaggedRecordsOnly;
      delete newParams.myRecordsOnly;
      // Set the old checkboxes to false
      const firstFilterToUncheck = limitFilter[0];
      const secondFilterToUncheck = limitFilter[1];

      updatedState = {
        currentDepartment: null,
        [firstFilterToUncheck]: false,
        [secondFilterToUncheck]: false,
        ...updatedState,
      };
    }

    // Update URL query params if a checkbox is checked, otherwise delete them
    if (isInputChecked) {
      newParams = {
        [checkboxToUpdate]: queryParamValue,
        ...params,
      };
    } else delete newParams[checkboxToUpdate];

    return this.updateStateAndQueryRecords(newParams, updatedState);
  }

  handleFormFilterCheckBox = (event) => {
    const { paramTools: { params } } = this.props;
    const { document_type: documentType } = this.state;

    let newParams = params;

    const { checked: isInputChecked, value: checkboxToUpdate } = event.target;
    let type;

    // If input is checked type can be form or form,form_submission
    if (isInputChecked) {
      type = !documentType ? checkboxToUpdate : `${documentType},${checkboxToUpdate}`;
    } else {
      // remove type if box is unchecked
      type = ['form_submission', 'form'].filter(filter => filter !== checkboxToUpdate);
      type = documentType.split(',').length === 2 ? type[0] : '';
    }

    // Update URL query params if a checkbox is checked, otherwise delete them
    if (!type) {
      delete newParams.document_type;
    } else {
      newParams = {
        ...newParams,
        document_type: type.split(','),
      };
    }

    return this.updateStateAndQueryRecords(newParams, { document_type: type });
  }

  updateStateAndQueryRecords = (newParams, updatedState) => {
    const { location, paramTools, inboxActions: { getRecords } } = this.props;

    const {
      stringify,
      stringifyOptions,
      push,
    } = paramTools;

    this.setState(updatedState);

    // If there are no query params, default to /app/{mode}
    if (Object.keys(newParams).length === 0) {
      push(location.pathname);
      getRecords('');
      return;
    }

    // Fetch data with new params
    const updatedParams = { ...newParams, page: 1 };
    const query = stringify(updatedParams, stringifyOptions);
    getRecords(query);
  }

  /**
   * @param {Array} selectedOption the data of the object selected from the filter dropdown
   * { key: department, value: department.toLowerCase(), name: department }
   */
  handleDropdown = (_, selectedOption) => {
    const { paramTools, inboxActions: { getRecords } } = this.props;
    const {
      params,
      stringify,
      stringifyOptions,
      push,
    } = paramTools;
    const selectedDepartment = selectedOption[0].value;
    // Delete myRecordsOnly if item in dropdown has been selected
    delete params.myRecordsOnly;
    delete params.myDepartmentOnly;
    delete params.myTaggedRecordsOnly;

    if (!selectedDepartment) {
      delete params.department;
    } else {
      params.department = selectedDepartment;
    }

    this.setState({
      currentDepartment: selectedDepartment,
      myDepartmentOnly: false,
      myRecordsOnly: false,
      myTaggedRecordsOnly: false,
    });

    if (Object.keys(params).length === 0) {
      push(window.location.pathname);
      getRecords('');
      return;
    }

    const newStringifyOptions = {
      ...stringifyOptions,
      encode: false,
    };

    const newParams = { ...params, page: 1 };
    const query = stringify(newParams, newStringifyOptions);
    getRecords(query);
  }

  isPageOneOf = (paths) => {
    const { location } = this.props;
    return paths.some(path => location.pathname.includes(path));
  }

  render() {
    const { departments } = this.props;
    const {
      myRecordsOnly,
      views,
      failed,
      archived,
      currentDepartment,
      myDepartmentOnly,
      document_type: documentType,
      myTaggedRecordsOnly,
      completed,
      scheduled,
      failedAutoUploaded,
      formSubmissions,
      emailSubmissions,
      unassigned,
    } = this.state;
    const isFormsOnlyChecked = documentType.split(',').includes('form');
    const isFormSubmissionsOnlyChecked = documentType.split(',').includes('form_submission');
    return (
      <div>
        {
          this.props && departments && departments.length > 1
          && (
            <Form.Item
              label="Department"
              style={{ marginTop: 10 }}
            >
              <Cascader
                options={this.options}
                allowClear={false}
                fieldNames={{ label: 'name', value: 'value' }}
                onChange={this.handleDropdown}
                value={[currentDepartment || 'All departments']}
              />
            </Form.Item>
          )
        }
        {
          this.isPageOneOf(['sent'])
          && (
            <React.Fragment>
              <Checkbox
                checked={views}
                onChange={this.updateCheck}
                value="views"
                defaultChecked={true}
              >
                Show Unviewed Records Only
              </Checkbox>
              <Checkbox
                checked={failed}
                onChange={this.updateCheck}
                value="failed"
                style={checkboxStyle}
                defaultChecked={failed}
              >
                Show Failed Faxes Only
              </Checkbox>
              <br />
            </React.Fragment>
          )
        }
        {
          !this.isPageOneOf(['sent', 'assigned', 'voice_inbox'])
          && (
            <React.Fragment>
              <Checkbox
                checked={archived}
                onChange={this.updateCheck}
                value="archived"
                style={checkboxStyle}
                defaultChecked={archived}
              >
                Show Archived Records
              </Checkbox>
            </React.Fragment>
          )
        }
        { this.isPageOneOf(['received'])
          && (
            <React.Fragment>
              <Checkbox
                style={checkboxStyle}
                checked={failedAutoUploaded}
                defaultChecked={failedAutoUploaded}
                value="failedAutoUploaded"
                onChange={this.updateCheck}
              >
                View Failed Auto Uploaded Faxes
              </Checkbox>
              <br />
            </React.Fragment>
          )
        }
        {
          this.isPageOneOf(['uploads'])
          && (
            <>
              <Checkbox
                checked={isFormsOnlyChecked}
                onChange={this.handleFormFilterCheckBox}
                value="form"
                style={checkboxStyle}
                defaultChecked={isFormsOnlyChecked}
              >
                Form Templates
              </Checkbox>
              <Checkbox
                checked={isFormSubmissionsOnlyChecked}
                onChange={this.handleFormFilterCheckBox}
                value="form_submission"
                style={checkboxStyle}
                defaultChecked={isFormSubmissionsOnlyChecked}
              >
                Form Submissions
              </Checkbox>
            </>
          )
        }
        {
          !this.isPageOneOf(['assigned', 'referred', 'voice_inbox'])
          && (
          <>
            <Checkbox
              checked={myDepartmentOnly}
              onChange={this.updateCheck}
              value="myDepartmentOnly"
              style={checkboxStyle}
              defaultChecked={myDepartmentOnly}
            >
              Show My Department&apos;s Records Only
            </Checkbox>
            <Checkbox
              checked={myRecordsOnly}
              onChange={this.updateCheck}
              value="myRecordsOnly"
              style={checkboxStyle}
              defaultChecked={myRecordsOnly}
            >
              Show My Records Only
            </Checkbox>
          </>
          )
        }
        {
          this.isPageOneOf(['received'])
          && (
          <>
            <Checkbox
              checked={myTaggedRecordsOnly}
              onChange={this.updateCheck}
              value="myTaggedRecordsOnly"
              style={checkboxStyle}
              defaultChecked={myTaggedRecordsOnly}
            >
              Show Records I'm tagged in
            </Checkbox>
            <Checkbox
              checked={formSubmissions}
              onChange={this.updateCheck}
              value="formSubmissions"
              style={checkboxStyle}
              defaultChecked={formSubmissions}
            >
              Show Form Submissions
            </Checkbox>
            <Checkbox
              checked={emailSubmissions}
              onChange={this.updateCheck}
              value="emailSubmissions"
              style={checkboxStyle}
              defaultChecked={emailSubmissions}
            >
              Show Email Submissions
            </Checkbox>
          </>
          )
        }
        {
          this.isPageOneOf(['received', 'referred'])
          && (<Checkbox
            checked={unassigned}
            onChange={this.updateCheck}
            value="unassigned"
            style={checkboxStyle}
            defaultChecked={unassigned}
          >
            Show Unassigned Documents
          </Checkbox>
          )
        }
        {
          this.isPageOneOf(['assigned'])
          && (
            <>
              <Checkbox
                checked={completed}
                onChange={this.updateCheck}
                value="completed"
                style={checkboxStyle}
                defaultChecked={completed}
              >
                Show completed records only
              </Checkbox>
            </>
          )
        }
        {
          this.isPageOneOf(['referred'])
          && (
            <>
              <Checkbox
                checked={scheduled}
                onChange={this.updateCheck}
                value="scheduled"
                style={checkboxStyle}
                defaultChecked={scheduled}
              >
                Show Scheduled Referrals Only
              </Checkbox>
            </>
          )
        }
      </div>
    );
  }
}

FilterSettings.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.object).isRequired,
  paramTools: PropTypes.shape({
    params: PropTypes.object,
    stringify: PropTypes.func.isRequired,
    stringifyOptions: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  inboxActions: PropTypes.shape({
    getRecords: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

export default FilterSettings;
