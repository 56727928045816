/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';

import {
  message,
  DatePicker,
  Input,
  Form,
  Checkbox,
  Select,
  AutoComplete,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalWrapper from './ModalWrapper';

class CreateTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendToken: props.modalType.data.record,
      patientName: this.props.modalType.data.name || '',
      patientFirstName: '',
      patientMiddleName: '',
      patientLastName: '',
      patientDOB: this.props.modalType.data.dob || '',
      patientMrn: '',
      assignTo: undefined,
      autoUploadEmr: '',
      searchedNames: null,
      task: '',
      searchedTasks: '',
      searchedTemplates: null,
      emaTaskTemplates: null,
      taskDetails: '',
      taskDetailsSearchOptions: null,
      dueDate: '',
      notes: `Task created by ${localStorage.m8_name}`,
      priority: 'Normal',
      associateWithPatient: true,
      associateDoc: true,
      receiverType: this.props.modalType.data.receiverType,
    };
    this.priorityOptions = ['Normal', 'High', 'STAT'];
    this.taskOptions = [
      'Authorization', 'Communication', 'Fax', 'Notify', 'Obtain', 'Order', 'Pathology', 'Prescribe', 'Reassure',
      'Recall', 'Re-Evaluate', 'Refer', 'Return To Clinic', 'Review', 'Schedule', 'Sign', 'Test',
    ];

    this.taskDetailsOptions = [
      'Additional Testing', 'Benign', 'Claim Review', 'Discuss at Follow-up', 'Follow-up Appt', 'Labs', 'PCP',
      'Medication', 'Normal', 'No Further Treatment', 'Procedure', 'Referring Provider', 'Results', 'Sign/Cosign',
      'Surgery',
    ];

    this.state.searchedTasks = this.taskOptions;
    this.state.taskDetailsSearchOptions = this.taskDetailsOptions;
    this.formRef = React.createRef();
  }

  componentDidMount = async () => {
    const { getEmaTaskProviders, getTaskTemplatesByQuery } = this.props;
    const { sendToken } = this.state;
    const autoUploadEmr = localStorage.getItem('m8_auto_upload_emr')
    await getEmaTaskProviders(sendToken);
    if (this.props.allEmaTaskProviders.length === 1) {
      let newAssignTo = this.props.allEmaTaskProviders[0];
      if (autoUploadEmr === 'EMA') {
        // Set assign to as array
        newAssignTo = [newAssignTo];
      }
      this.setState({ assignTo: newAssignTo });
    }
    this.setState({ searchedNames: this.props.allEmaTaskProviders });
    this.setState({ autoUploadEmr });
    const query = `?email=${localStorage.getItem('m8_uid')}`;
    await getTaskTemplatesByQuery(query);
    this.setState({
      emaTaskTemplates: this.props.allEmaTaskTemplates.ema_task_templates,
      searchedTemplates: this.props.allEmaTaskTemplates.ema_task_templates,
    });

    const { patientName } = this.state;
    if (autoUploadEmr === 'EMA' && patientName) {
      // Handle edge case where the patient_name has 2 surnames (this is common for Spanish names)
      if (patientName.includes(',') && patientName.split(',')[0].trim().includes(' ') && patientName.split(',')[0].trim().split(' ').length > 1) {
        const parsedNames = this.parseName(patientName);
        this.setState({
          patientFirstName: parsedNames.firstName,
          patientLastName: parsedNames.lastName,
        });
      } else {
        this.setState({
          patientFirstName: parseFullName(patientName).first,
          patientMiddleName: parseFullName(patientName).middle,
          patientLastName: parseFullName(patientName).last,
        });
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { hideModal, triggerTaskCreation } = this.props;
    const {
      sendToken,
      patientName,
      patientFirstName,
      patientLastName,
      patientMiddleName,
      patientDOB,
      patientMrn,
      assignTo,
      task,
      taskDetails,
      dueDate,
      priority,
      notes,
      receiverType,
      associateWithPatient,
      associateDoc,
    } = this.state;

    let formattedDOB = '';
    let formattedDueDate = '';
    if (patientDOB !== '') {
      const splitDate = patientDOB.split('/');
      formattedDOB = associateWithPatient ? `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}` : '';
    }
    if (dueDate !== '') {
      const splitDate = dueDate.split('/');
      formattedDueDate = `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`;
    }
    if (associateWithPatient) {
      await triggerTaskCreation({
        sendToken, receiverType, patientName, patientFirstName, patientLastName, patientMiddleName, patientDOB: formattedDOB, patientMrn, assignTo, task, taskDetails, priority, notes, associateDoc, dueDate: formattedDueDate,
      });
    } else {
      await triggerTaskCreation({
        sendToken, receiverType, patientName: '', patientFirstName: '', patientLastName: '', patientMiddleName: '', patientDOB: '', patientMrn: '', assignTo, task, taskDetails, priority, notes, associateDoc, dueDate: formattedDueDate,
      });
    }
    const { error, errorMessage } = this.props;
    hideModal();
    if (!error) {
      message.success({
        content: 'Task will be created shortly. We will notify you of any issues via email.',
      });
    } else {
      message.error({
        content: errorMessage,
      });
    }
  };

  parseName(name) {
    let firstName = '';
    let lastName = '';
    const parts = name.split(',');
    lastName = parts[0].trim();
    firstName = parts[1].trim();

    // Handle edge cases for Spanish names with multiple surnames
    if (lastName.split(' ').length > 1 && !lastName.includes('-')) {
      const lastNames = lastName.split(' ');
      lastName = `${lastNames[0]} ${lastNames[1]}`;
    }

    return { firstName, lastName };
  }


  render() {
    const { modalType: { data: { name } }, isLoading, hideModal, allEmaTaskProviders } = this.props;
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    const customContentStyle = {
      width: '`1000px`',
    };
    const {
      patientName, patientDOB, patientMrn, notes, associateWithPatient, patientFirstName, patientLastName, patientMiddleName, dueDate
    } = this.state;

    const disableSubmitButton = () => {
      return (
        (associateWithPatient && ((patientLastName === '' || patientFirstName === '' || patientDOB === '') && (patientName === '' || patientDOB === '') && patientMrn === ''))
        || (this.state.assignTo && this.state.assignTo.length === 0)
        || !this.state.assignTo
        || this.state.task === ''
        || this.state.taskDetails === ''
      );
    };

    const handleAssociateWithPatient = (e) => {
      this.setState({ associateWithPatient: !e.target.checked });
    };

    const handleNameChange = (e) => {
      this.setState({ patientName: e.target.value });
    };

    const handleMiddleNameChange = (e) => {
      this.setState({ patientMiddleName: e.target.value });
    };

    const handleLastNameChange = (e) => {
      this.setState({ patientLastName: e.target.value });
    };

    const handleFirstNameChange = (e) => {
      this.setState({ patientFirstName: e.target.value });
    };

    const handleMrnChange = (e) => {
      this.setState({ patientMrn: e.target.value });
    };

    const handleDateChange = (_, dateString) => {
      this.setState({ patientDOB: dateString });
    };

    const handleDueDateChange = (_, dateString) => {
      this.setState({ dueDate: dateString });
    };

    const handleAssignToChange = (e) => {
      this.setState({ assignTo: e });
    };

    const handleAssignToSearch = (e) => {
      if (e) {
        const filtered = allEmaTaskProviders.filter(provider => provider.toLowerCase().includes(e.toLowerCase()));
        this.setState({ searchedNames: filtered });
      } else {
        this.setState({ searchedNames: allEmaTaskProviders });
      }
    };

    const handleTaskSearch = (e) => {
      if (e) {
        const filtered = this.taskOptions.filter(task => task.toLowerCase().includes(e.toLowerCase()));
        this.setState({ searchedTasks: filtered });
      } else {
        this.setState({ searchedTasks: this.taskOptions });
      }
    };

    const handleTaskChange = (e) => {
      this.setState({ task: e });
    };

    const handleSearchTaskDetails = (e) => {
      this.setState({ taskDetails: e });
      const filteredOptions = this.taskDetailsOptions.filter(option => (
        option.toLowerCase().includes(e.toLowerCase())));
      this.setState({ taskDetailsSearchOptions: filteredOptions });
    };

    const handleTaskDetailsSelection = (e) => {
      this.setState({ taskDetails: e });
    };

    const handleNotesChange = (e) => {
      this.setState({ notes: e.target.value });
    };

    const handleAssociateDoct = (e) => {
      this.setState({ associateDoc: !e.target.checked });
    };

    const handlePriorityChange = (e) => {
      this.setState({ priority: e });
    };

    const handleTaskTemplateSearch = (e) => {
      const unfilteredObjects = this.state.emaTaskTemplates;
      if (e) {
        const filtered = this.state.emaTaskTemplates.map(template => template.task_name).filter(option => (option.toLowerCase().includes(e.toLowerCase())));
        const filteredObject = [];
        filtered.forEach((template) => {
          const index = this.state.emaTaskTemplates.map(t => t.task_name).indexOf(template);
          filteredObject.push(this.state.emaTaskTemplates[index]);
        });
        this.setState({ searchedTemplates: filteredObject });
      } else {
        this.setState({ searchedTemplates: unfilteredObjects });
      }
    };

    const handleTaskTemplateChange = (e) => {
      // if user clears out selection, we will reload the list of templates to choose from.
      if (!e) {
        handleTaskTemplateSearch();
        this.setState({ 
          notes: `Task created by ${localStorage.m8_name}`
        });
        return;
      }
      const index = this.state.emaTaskTemplates.map(template => template.id).indexOf(e);
      const selectedTemplate = this.state.emaTaskTemplates[index];
      const assigtoArray = selectedTemplate.assign_to_as_array;
      if (assigtoArray.includes('Ordering Provider')) {
        assigtoArray.splice(assigtoArray.indexOf('Ordering Provider'), 1);
      }
      handlePriorityChange(selectedTemplate.priority);
      handleTaskDetailsSelection(selectedTemplate.task_details);
      handleTaskChange(selectedTemplate.task_type);
      handleAssignToChange(assigtoArray);

      // Force both the state update and re-render
      const newNotes = selectedTemplate.notes || `Task created by ${localStorage.m8_name}`;
      this.setState({ notes: newNotes }, () => {
        // Get form instance from ref
        this.formRef.current.setFieldsValue({
          notes: newNotes
        });
      });
    };

    const noProviderMessage = 'Contact your Admin to add EMR Providers';

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.handleSubmit}
        actionName="Submit"
        width="700px"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={disableSubmitButton()}
        modalTitle="Create Task in Your EMR"
        form="createTask"
        isDraggable
      >
        <Form
          ref={this.formRef}
          id="createTask"
          onFinish={hideModal}
          layout="vertical"
          initialValues={{
            patientFirstName,
            patientMiddleName,
            patientLastName,
            patientName,
            notes,
            dueDate,
          }}
        >
          <Form.Item
            name="remember"
            valuePropName="checked"
            onChange={handleAssociateWithPatient}
            checked={associateWithPatient}
          >
            <Checkbox>Don't associate task with patient chart</Checkbox>
          </Form.Item>


          <div style={{ display: 'flex', gap: '10px' }}>
            {associateWithPatient && this.state.autoUploadEmr === 'EMA'
            && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Patient First Name"
                  onChange={handleFirstNameChange}
                  style={{ flex: 1 }}
                >
                  <Input
                    name="patientFirstName"
                    placeholder="First name"
                    value={patientFirstName}
                  />
                </Form.Item>
                <Form.Item
                  label="Patient Middle Name"
                  onChange={handleMiddleNameChange}
                  style={{ flex: 1 }}
                >
                  <Input
                    name="patientMiddleName"
                    placeholder="Middle name"
                    value={patientMiddleName}
                  />
                </Form.Item>
                <Form.Item
                  label="Patient Last Name"
                  onChange={handleLastNameChange}
                  style={{ flex: 1 }}
                >
                  <Input
                    name="patientLastName"
                    placeholder="Last name"
                    value={patientLastName}
                  />
                </Form.Item>
              </div>
            )}

            {associateWithPatient && this.state.autoUploadEmr !== 'EMA'
            && (
            <Form.Item
              onChange={handleNameChange}
              name="patientName"
              label="Patient Name"
              style={{ flex: 1 }}
            >
              <Input placeholder="Patient name" />
            </Form.Item>
            )}

            {associateWithPatient
            && (
            <Form.Item
              label="Patient Date of Birth"
            >
              <DatePicker
                onChange={handleDateChange}
                name="patientDOB"
                format={dateFormat}
                value={patientDOB && moment(patientDOB, dateFormat)}
              />
            </Form.Item>
            )}
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {associateWithPatient
            && (
            <Form.Item
              onChange={handleMrnChange}
              name="patientMRN"
              label="Patient MRN (Optional)"
              style={{ flex: 1 }}
            >
              <Input placeholder="Patient MRN" />
            </Form.Item>
            )}
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            {this.state.autoUploadEmr === 'EMA'
            && (
            <Form.Item
              label="Task Templates"
              style={{ flex: 0.5 }}
            >
              <Select
                showSearch
                optionFilterProp="children"
                onChange={handleTaskTemplateChange}
                onSearch={handleTaskTemplateSearch}
                filterOption={false}
                allowClear
              >
                {this.state.searchedTemplates && this.state.searchedTemplates.map(e => (
                  <Select.Option key={e.id} value={e.id}>
                    {e.task_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            )}
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Task"
              style={{ flex: 0.4 }}
            >
              <Select
                showSearch
                placeholder="Task"
                value={this.state.task}
                optionFilterProp="children"
                onChange={handleTaskChange}
                onSearch={handleTaskSearch}
                filterOption={false}
              >
                {this.state.searchedTasks.map(e => (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item
              label="Task Details"
              style={{ flex: 0.4 }}
            >
              <AutoComplete
                placeholder="Task Details"
                options={this.state.taskDetailsSearchOptions.map(option => ({ value: option }))}
                value={this.state.taskDetails}
                onSearch={handleSearchTaskDetails}
                onSelect={handleTaskDetailsSelection}
              />
            </Form.Item>

            <Form.Item
              label="Due Date"
              style={{ flex: 0.2 }}
            >
              <DatePicker
                onChange={handleDueDateChange}
                name="dueDate"
                format={dateFormat}
                value={dueDate && moment(dueDate, dateFormat)}
              />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label={allEmaTaskProviders && allEmaTaskProviders.length === 0
                ? (
                  <div>
                    Assign to EMR Staff or Staff Group
                    <Tooltip title="Please contact your Admin to add Providers">
                      <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    Assign to EMR Staff or Staff Group
                  </div>
                )}
              style={{ flex: 0.5 }}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Select a provider"
                value={this.state.assignTo}
                optionFilterProp="children"
                onChange={handleAssignToChange}
                onSearch={handleAssignToSearch}
                filterOption={false}
                allowClear
                notFoundContent={noProviderMessage}
              >
                {this.state.searchedNames && this.state.searchedNames.map((e, index) => (
                  <Select.Option key={index} value={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item
              label="Priority"
              style={{ flex: 0.5 }}
            >
              <Select
                allowClear={false}
                options={this.priorityOptions.map(option => ({ value: option }))}
                onChange={handlePriorityChange}
                value={this.state.priority}
              />
            </Form.Item>
          </div>

          <Form.Item
            name="notes"
            label="Notes (optional)"
            >
            <Input.TextArea
              onChange={handleNotesChange}
              placeholder="Notes (optional)"
              name="notes"
              value={this.state.notes}
            />
          </Form.Item>

          <Form.Item
            onChange={handleAssociateDoct}
            name="assocDocToTask"
            valuePropName="checked"
          >
            <Checkbox>Don't associate document with task</Checkbox>
          </Form.Item>
        </Form>
      </ModalWrapper>
    );
  }
}

CreateTask.defaultProps = {
  error: false,
  errorMessage: null,
  allEmaTaskTemplates: {},
};

CreateTask.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  triggerTaskCreation: PropTypes.func.isRequired,
  error: PropTypes.bool,
  allEmaTaskProviders: PropTypes.array.isRequired,
  getEmaTaskProviders: PropTypes.func.isRequired,
  getTaskTemplatesByQuery: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  allEmaTaskTemplates: PropTypes.object,
};

export default connect(state => ({
  allEmaTaskTemplates: state.userProfile.allEmaTaskTemplates,
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedLabel: state.records.updatedLabel,
  data: state.labels.data,
  selectedRecord: state.inboxTable.recordData,
  error: state.records.error,
  emaTaskProviders: state.auth,
  allEmaTaskProviders: state.userProfile.allEmaTaskProviders,
  errorMessage: state.records.errorMessage,
}))(CreateTask);
