import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '../../../components/Typography';
import COLORS from '../../../util/constants/colors';
import './aiFields.scss';

const AIFields = ({ ...props }) => {
  const Field = ({ label, value }) => (
    <div className="record-value field-value">
      <div className="value-header">
        <Typography
          variant="text"
          size="xsmall"
          color={COLORS.TEXT_TERTIARY_LITE}
        >
          {label}
        </Typography>
      </div>
      <Typography variant="text" size="xsmall" bold={value}>
        { value || 'N/A' }
      </Typography>
    </div>
  );

  Field.defaultProps = {
    label: '',
    value: '',
  };

  Field.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
  };


  const PatientInfo = () => {
    const {
      patientName, patientDOB, patientGender, patientPhoneNumber, patientEmail, patientAddress, patientCity, patientState, patientZipCode,
    } = props;
    return (
      <div>
        <Typography className="section-header" variant="text" size="medium" bold>
          Patient Info
        </Typography>
        <Field label="Patient Full Name" value={patientName} />
        <Field label="Patient Date of Birth" value={patientDOB} />
        <Field label="Patient Gender" value={patientGender} />
        <Field label="Patient Phone Number" value={patientPhoneNumber} />
        <Field label="Patient Email" value={patientEmail} />
        <Field label="Patient Address" value={patientAddress} />
        <Field label="Patient City" value={patientCity} />
        <Field label="Patient State" value={patientState} />
        <Field label="Patient Zip Code" value={patientZipCode} />
      </div>
    );
  };

  const ProviderInfo = () => {
    const {
      providerOffice, practitionerName, referringProvider,
    } = props;
    return (
      <div>
        <Typography className="section-header" variant="text" size="medium" bold>
          Provider Info
        </Typography>
        <Field label="Provider Office" value={providerOffice} />
        <Field label="Practitioner Name" value={practitionerName} />
        <Field label="Referring Provider" value={referringProvider} />
      </div>
    );
  };

  const InsuranceInfo = () => {
    const {
      primaryPlan, primaryMemberId,
    } = props;
    return (
      <div>
        <Typography className="section-header" variant="text" size="medium" bold>
          Insurance Info
        </Typography>
        <Field label="Primary Plan" value={primaryPlan} />
        <Field label="Primary Member ID" value={primaryMemberId} />
      </div>
    );
  };

  return (
    <div className="ai-fields">
      <div className="field-section">
        <PatientInfo />
        <div className="divider" />
        <ProviderInfo />
        <div className="divider" />
        <InsuranceInfo />
      </div>
    </div>
  );
};

AIFields.defaultProps = {
  patientName: '',
  patientDOB: '',
  patientGender: '',
  patientPhoneNumber: '',
  patientAddress: '',
  patientCity: '',
  patientZipCode: '',

  providerOffice: '',
  primaryPlan: '',
  primaryMemberId: '',
  practitionerName: '',
  referringProvider: '',
  referringProviderOffice: '',
};

AIFields.propTypes = {
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  patientGender: PropTypes.string,
  patientPhoneNumber: PropTypes.string,
  patientAddress: PropTypes.string,
  patientCity: PropTypes.string,
  patientZipCode: PropTypes.string,

  providerOffice: PropTypes.string,
  primaryPlan: PropTypes.string,
  primaryMemberId: PropTypes.string,
  practitionerName: PropTypes.string,
  referringProvider: PropTypes.string,
  referringProviderOffice: PropTypes.string,
};

export default connect(state => ({
  patientName: state.records.patientName,
  patientDOB: state.records.patientDOB,
  patientGender: state.records.patientGender,
  patientPhoneNumber: state.records.patientPhoneNumber,
  patientEmail: state.records.patientEmail,
  patientAddress: state.records.patientAddress,
  patientCity: state.records.patientCity,
  patientState: state.records.patientState,
  patientZipCode: state.records.patientZipCode,

  providerOffice: state.records.providerOffice,
  primaryPlan: state.records.primaryPlan,
  primaryMemberId: state.records.primaryMemberId,
  practitionerName: state.records.practitionerName,
  referringProvider: state.records.referringProvider,
  referringProviderOffice: state.records.referringProviderOffice,
}), {})(AIFields);
