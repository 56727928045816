import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import COLORS from '../../util/constants/colors';
import './label.scss';

const Label = ({ label, required }) => {
  return (
    <Typography
      className="label"
      variant="text"
      color={COLORS.TEXT_SECONDARY_LITE}
      size="xsmall"
      bold
    >
      {label}
      {required && '*'}
    </Typography>
  );
};

Label.defaultProps = {
  required: false,
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default Label;
