import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { SignatureSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { isOnUnsupportedBrowser } from '../../../global/browser';

const SignDocButton = ({
  ...props
}) => {
  // eslint-disable-next-line no-shadow
  const { openModal } = props;
  const [isUnsupportedBrowser, setIsSupportedBrowser] = useState(false);

  useEffect(() => {
    const setPermission = async () => setIsSupportedBrowser(isOnUnsupportedBrowser());

    setPermission();
  }, []);

  const openBrowserUpgradeModal = () => {
    const modalType = {
      type: 'UPGRADE_BROWSER',
    };
    return openModal(modalType);
  };

  return (
    <ToolbarButton
      title="Sign Document"
      icon={SignatureSvg}
      hide={!isUnsupportedBrowser}
      onClick={openBrowserUpgradeModal}
    />
  );
};

SignDocButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-unused-vars
export default connect(state => ({}), { openModal })(SignDocButton);
