import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import EditRecordForm from '../EditRecordForm';
import '../newRecordViewer.scss';
import { PAGES, RECEIVER_TYPES } from '../../shared/constants';
import RecordInfo from './RecordInfo';
import AssignRequest from './AssignRequest';
import LabelsInfo from './LabelsInfo';
import AIFields from './AIFields';
import { verifyFeatureAccess } from '../../../global/featureFlags';

const RecordDetails = ({
  isInboxPage,
  createAssignedDocument,
  currentPage,
  ...props
}) => {
  const {
    currentTransmission: { receiver },
    receiverType,
    patientName,
    patientDOB,
    documentTitle,
    documentCategory,
    isFormSubmission,
    senderContact,
  } = props;

  const [editing, setEditing] = useState(false);
  const [hasAiExtraction, setHasAiExtraction] = useState(false);
  const isLabelPage = isInboxPage || currentPage === PAGES.ASSIGNED || currentPage === PAGES.REFERRED;
  const showAssignDocument = isInboxPage || currentPage === PAGES.REFERRED;
  const fromToMedsender = senderContact?.includes('@medsender.com') && receiver?.includes('@medsender.com');
  const canDocumentLabel = isFormSubmission || receiverType === RECEIVER_TYPES.RECEIVED_FAX || fromToMedsender;
  const showAiFields = hasAiExtraction && (receiverType === RECEIVER_TYPES.RECEIVED_FAX || receiverType === RECEIVER_TYPES.EMAIL_ATTACHMENT);

  useEffect(() => {
    const getAiExtraction = async () => setHasAiExtraction(await verifyFeatureAccess('ai_extraction'));

    getAiExtraction();
  }, []);

  const hideAssignRecord = (receiverType !== RECEIVER_TYPES.RECEIVED_FAX
      && receiverType !== RECEIVER_TYPES.RECEIVER
      && receiverType !== RECEIVER_TYPES.EMAIL_ATTACHMENT)
    || currentPage === PAGES.ASSIGNED;


  const GeneralDetails = () => (
    <>
      {showAssignDocument && !hideAssignRecord && <AssignRequest createAssignedDocument={createAssignedDocument} />}
      <div className="record-info">
        {editing ? (
          <EditRecordForm
            setEditing={setEditing}
            currentPage={currentPage}
          />
        ) : (
          <RecordInfo
            documentTitle={documentTitle}
            patientName={patientName}
            patientDOB={patientDOB}
            documentCategory={documentCategory}
            setEditing={setEditing}
            currentPage={currentPage}
          />
        )}
      </div>
      <div className="divider" />
      {isLabelPage && canDocumentLabel && <LabelsInfo />}
    </>
  );

  return (
    <div className="record-viewer-right">
      {
        showAiFields ? (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="General" key="1">
              <GeneralDetails />
            </Tabs.TabPane>
            <Tabs.TabPane tab="A.I. Fields" key="2">
              <AIFields />
            </Tabs.TabPane>
          </Tabs>
        ) : (<GeneralDetails />)
      }
    </div>
  );
};

RecordDetails.defaultProps = {
  currentTransmission: {},
  isInboxPage: false,

  receiverType: '',
  isFormDocument: false,
  patientName: '',
  patientDOB: '',
  documentTitle: '',
  documentCategory: '',
  isFormSubmission: false,
  senderContact: '',
};

RecordDetails.propTypes = {
  currentPage: PropTypes.string.isRequired,
  isInboxPage: PropTypes.bool,
  createAssignedDocument: PropTypes.func.isRequired,

  currentTransmission: PropTypes.object,
  isFormDocument: PropTypes.bool,
  receiverType: PropTypes.string,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  documentTitle: PropTypes.string,
  documentCategory: PropTypes.string,
  isFormSubmission: PropTypes.bool,
  senderContact: PropTypes.string,
};

export default connect(
  state => ({
    sendToken: state.records.sendToken,
    currentTransmission: state.records.currentTransmission,
    isFormDocument: state.records.isFormDocument,
    receiverType: state.records.receiverType,
    patientName: state.records.patientName,
    patientDOB: state.records.patientDOB,
    documentTitle: state.records.documentTitle,
    documentCategory: state.records.documentCategory,
    isFormSubmission: state.records.isFormSubmission,
    senderContact: state.records.senderContact,
  }),
  {},
)(RecordDetails);
