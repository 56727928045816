import crud from './helpers/crud';

const getVoiceDataByPage = (page, pageSize = 10) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_VOICE_DATA',
      fail: 'FAILED_GET_VOICE_DATA',
      end: 'FINISHED_GET_VOICE_DATA',
    },
    method: 'GET',
    url: `/api/v1/call_logs?page=${page}&per_page=${pageSize}`,
  })
);

const getVoiceDataByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_VOICE_DATA',
      fail: 'FAILED_GET_VOICE_DATA',
      end: 'FINISHED_GET_VOICE_DATA',
    },
    method: 'GET',
    url: `/api/v1/call_logs${query}`,
    query,
  })
);

const setVoiceCall = (call) => {
  return {
    type: 'SET_CURRENT_VOICE_CALL',
    payload: {
      call,
    },
  };
}

const getVoiceCall = (id) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_VOICE_CALL',
      fail: 'FAILED_GET_VOICE_CALL',
      end: 'FINISHED_GET_VOICE_CALL',
    },
    method: 'GET',
    url: `/api/v1/call_logs/${id}`,
  })
);

export {
  getVoiceDataByPage,
  getVoiceDataByQuery,
  setVoiceCall,
  getVoiceCall,
};
