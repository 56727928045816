import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

import { PageLoading } from '../../components';
import './viewer.scss';

const SCALE = {
  mobilePortrait: 0.5,
  mobileLandscape: 0.7,
  tabletPortrait: 1.2,
  tabletLandscape: 1.4,
  desktop: 1.5,
};


class FallbackViewer extends React.Component {
  constructor(props) {
    super(props);
    const screenType = this.screen();
    this.state = {
      pageNum: 1,
      pageTotal: null,
      scale: SCALE[screenType],
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollChange);
    window.addEventListener('orientationchange', this.handleOrientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollChange);
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  }

  /**
   * Invoked when the document loads.
   * A React-pdf method that will load the first 2 pages
  */
  onDocumentLoad = ({ numPages }) => {
    const onePageDoc = numPages === 1;
    this.setState({
      pageTotal: numPages,
      pageNum: onePageDoc ? 1 : 2,
    });
  }

  /**
   * Set states when there's orientation change
   */
  handleOrientationChange = () => {
    const screenType = this.screen(true);
    this.setState({
      scale: SCALE[screenType],
    });
  }

  /**
   * Implements infinite scroll
   * Checks if the device is ios in order to get browser height
   * Loads pdf page by page to save on memory
   */
  handleScrollChange = () => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const height = (iOS) ? window.screen.height : window.innerHeight;
    const { pageNum, pageTotal } = this.state;
    if (height + window.scrollY >= document.documentElement.scrollHeight) {
      if (pageNum + 1 > pageTotal) return;
      this.setState({
        pageNum: pageNum + 1,
      });
    }
  }

  /**
   * Handles change in orientation.
   * Determines device and orientation.
   * @param {Boolean} orientationchange Checks if the function is called as a result of orientation change
   * @return {String} returns string concatenation of device type and orientation
   */
  screen(orientationchange = false) {
    const { innerWidth, innerHeight, orientation } = window;

    const screenBreakpoint = {
      mobile: 512,
      tablet: 1024,
      width: orientationchange ? innerHeight : innerWidth,
    };

    // Change breakpoints if orientation is landscape.
    if (orientation === 90 || orientation === 270) {
      screenBreakpoint.mobile = 1024;
      screenBreakpoint.tablet = 1400;
      screenBreakpoint.width = innerWidth;
    }

    // Checks the screen orientation and width size
    // Then picks the orientation type
    switch (true) {
      case (screenBreakpoint.width < screenBreakpoint.mobile):
        if (orientation === 0) {
          return 'mobilePortrait';
        }
        return 'mobileLandscape';
      case (screenBreakpoint.width < screenBreakpoint.tablet):
        if (orientation === 0) {
          return 'tabletPortrait';
        }
        return 'tabletLandscape';
      default:
        return 'desktop';
    }
  }

  render() {
    const { documentUrl } = this.props;
    const { pageNum, scale } = this.state;
    return (
      <div className="pdf">
        <Document
          file={documentUrl}
          onLoadSuccess={this.onDocumentLoad}
          loading={
            (
              <div>
                <p>Loading Record, Please Wait ...</p>
                <PageLoading />
              </div>
            )
          }
        >
          <div id="viewer" className="pdf-border">
            {
              Array.from(
                new Array(pageNum),
                (el, index) => (
                  <div
                    className="page-container"
                    key={`pages_${index}`}
                  >
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      scale={scale}
                    />
                  </div>
                ),
              )
            }
          </div>
        </Document>
      </div>
    );
  }
}

FallbackViewer.defaultProps = {
  documentUrl: '',
};

FallbackViewer.propTypes = {
  documentUrl: PropTypes.string,
};

export default FallbackViewer;
