// config source: https://pspdfkit.com/api/web/PSPDFKit.ToolbarItem.html
export const customToolbarConfig = [
  { type: 'sidebar-thumbnails' },
  { type: 'sidebar-document-outline' },
  { type: 'sidebar-annotations' },
  { type: 'sidebar-bookmarks' },
  { type: 'pager' },
  { type: 'layout-config' },
  { type: 'pan' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'spacer' },
  { type: 'annotate' },
  { type: 'image' },
  { type: 'stamp' },
  { type: 'ink' },
  { type: 'highlighter' },
  { type: 'text-highlighter' },
  { type: 'ink-eraser' },
  { type: 'text' },
  { type: 'document-editor' },
  { type: 'search' },
  { type: 'debug' },
  { type: 'form-creator' },
];

export const mobileToolbarConfig = [
  { type: 'sidebar-thumbnails' },
  { type: 'sidebar-document-outline' },
  { type: 'sidebar-annotations' },
  { type: 'sidebar-bookmarks' },
  { type: 'pager' },
  { type: 'layout-config' },
  { type: 'pan' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'spacer' },
  { type: 'ink' },
  { type: 'text' },
  { type: 'ink-signature' },
  { type: 'search' },
  { type: 'debug' },
];

export const editingDisabledToolbarConfig = [
  { type: 'sidebar-thumbnails' },
  { type: 'sidebar-document-outline' },
  { type: 'sidebar-annotations' },
  { type: 'sidebar-bookmarks' },
  { type: 'pager' },
  { type: 'layout-config' },
  { type: 'pan' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'spacer' },
  { type: 'search' },
  { type: 'debug' },
];

export const newRecordViewerToolbarConfig = [
  { type: 'pager' },
  { type: 'zoom-out' },
  { type: 'zoom-in' },
  { type: 'spacer' },
  { type: 'annotate' },
  { type: 'image' },
  { type: 'stamp' },
  { type: 'ink' },
  { type: 'highlighter' },
  { type: 'text-highlighter' },
  { type: 'ink-eraser' },
  { type: 'text' },
  { type: 'document-editor', dropdownGroup: 'editor-group' },
  { type: 'search', dropdownGroup: 'editor-group' },
  { type: 'debug' },
  { type: 'form-creator', dropdownGroup: 'editor-group' },
];
