import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SmsMessage from '../../components/modal/SmsMessage';
import { triggerSmsMessage } from '../../actions/records';
import { getDefaultSmsTemplate } from '../../actions/userProfile';

class SmsMessageDialog extends Component {
  render() {
    return (
      <div>
        <SmsMessage
          {...this.props}
        />
      </div>
    );
  }
}

SmsMessageDialog.defaultProps = {
  optedOut: false
};

SmsMessageDialog.propTypes = {
  triggerSmsMessage: PropTypes.func.isRequired,
  getDefaultSmsTemplate: PropTypes.func.isRequired,
  optedOut: PropTypes.bool
};

export default connect(state => ({
  sendToken: state.auth.data.sendToken,
  optedOut: state.records.optedOut
}), {
  triggerSmsMessage,
  getDefaultSmsTemplate,
})(SmsMessageDialog);
