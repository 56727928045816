import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Input,
  Form,
  message,
  DatePicker,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  formatDate,
  validPageSplit,
  invalidPageSplit,
  invalidPageRange,
} from '../../global/validation';
import ModalWrapper from './ModalWrapper';

class SplitSelectedRecord extends Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    modalType: PropTypes.object.isRequired,
    splitUploadedRecord: PropTypes.func.isRequired,
    splitReceivedRecord: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
  }

  state = {
    patientName: this.props.modalType.data.patient.patient_name,
    patientDOB: this.props.modalType.data.patient.patient_dob,
    fileToken: this.props.modalType.data.patient.file_token,
    pageToSplit: '',
    fromPage: this.props.modalType.data.fromPage,
    token: this.props.modalType.data.token,
    numPages: this.props.modalType.data.numPages,
  };

  split = async (event) => {
    event.preventDefault();
    const {
      fileToken,
      patientName,
      patientDOB,
      pageToSplit,
      fromPage,
      token,
    } = this.state;

    const {
      splitUploadedRecord,
      splitReceivedRecord,
    } = this.props;

    if (!patientDOB) {
      // eslint-disable-next-line no-unused-expressions
      ((fromPage === 'uploaded') ? await splitUploadedRecord(fileToken, pageToSplit, patientName, null) : await splitReceivedRecord(token, pageToSplit, patientName, null));
      this.displayMessage();
      return;
    }
    const dob = formatDate(patientDOB);
    // eslint-disable-next-line no-unused-expressions
    ((fromPage === 'uploaded') ? await splitUploadedRecord(fileToken, pageToSplit, patientName, dob) : await splitReceivedRecord(token, pageToSplit, patientName, dob));
    this.displayMessage();
  };

  /**
   * @param {String} dateString format MM/DD/YYYY
   */
  handleDateChange = (_, dateString) => {
    this.setState({ patientDOB: dateString });
  }

  displayMessage() {
    const {
      pageToSplit,
      fromPage,
    } = this.state;

    const {
      hideModal,
      error,
      errorMessage,
    } = this.props;
    if (!(error)) {
      message.success({
        content: `Move to your ${fromPage} inbox to view the split record(s)`,
      });
    } else {
      message.error({
        content: `Unable to split ${pageToSplit} page(s). ${errorMessage}`,
      });
    }
    hideModal();
  }


  render() {
    // eslint-disable-next-line object-curly-newline
    const { patientName, pageToSplit, patientDOB, numPages } = this.state;
    const { isLoading, hideModal, modalType } = this.props;
    const { title, buttonText } = modalType.data;
    const invalidPageRanges = invalidPageRange(numPages, pageToSplit) || !validPageSplit.test(pageToSplit);
    const dialogTitle = title || 'Split Record';
    const dialogButtonText = buttonText || 'Split';
    const customContentStyle = {
      width: '340px',
    };

    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.split}
        actionName={dialogButtonText}
        dismiss="Cancel"
        modalTitle={dialogTitle}
        isLoading={isLoading}
        disabled={invalidPageRanges}
        isDraggable={true}
      >
        <div>
          {modalType.data.patient && (
            <Form
              layout="vertical"
              onFinish={this.split}
            >
              <Form.Item
                label="Patient Name (optional)"
                name="patientName"
              >
                <Input
                  placeholder="Name"
                  onChange={e => this.setState({ patientName: e.target.value })}
                  defaultValue={patientName}
                />
              </Form.Item>
              <Form.Item
                label="Date of Birth MM/DD/YYYY (optional)"
                name="optionalPatientDOB"
              >
                <DatePicker
                  onChange={this.handleDateChange}
                  name="patientDOB"
                  format={dateFormat}
                  defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                />
              </Form.Item>
              <Form.Item
                hasFeedback
                required
                name="pageNumber"
                label={(
                  <Tooltip
                    placement="left"
                    title={(
                      <span style={{ whiteSpace: 'pre-line' }}>
                        {`The document will be split based on the comma delimited list you provide.
                          \n e.g. 5 (i.e will split a document from pages 1-5, and 6 to the last page.)
                          \n e.g. 3, 5 (i.e will split a document from pages 1-3, 4-5, and 6 to the last page.)
                          \n e.g. 3, 5, 7 (i.e will split a document from pages 1-3, 4-5, 6-7, and 8 to the last page.)`
                        }
                      </span>
                )}
                  >
                    Enter Page Numbers to Split at
                    <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
                  </Tooltip>
                )}
                rules={[
                  () => ({
                    validator(_, number) {
                      if (invalidPageRange(numPages, number)) return Promise.reject('Enter a number less than the total number of pages in the record');
                      if (!validPageSplit.test(number)) return Promise.reject(invalidPageSplit);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  name="pageNumber"
                  placeholder="e.g. 3, 5, 7"
                  onChange={e => this.setState({ pageToSplit: e.target.value })}
                  defaultValue={pageToSplit}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Form>
          )}
        </div>
      </ModalWrapper>
    );
  }
}

export default SplitSelectedRecord;
