import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import {
  Input,
  AutoComplete,
  Select,
} from 'antd';

const { Search } = Input;

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class VoiceSearchSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLoading: false,
      selectedName: undefined,
      search: props.paramTools.params.startsWith || '',
      autocompleteNameOptions: [],
      voiceAiNumber: props.paramTools.params.voice_ai_numbers ? props.paramTools.params.voice_ai_numbers.split(',') : [],
    };
  }

  componentWillMount = async () => {
    const { dispatch } = this.props;
  }

  searchTrigger = () => {
    const { paramTools, inboxActions } = this.props;
    const { params } = paramTools;
    const { search: newSearchQuery, voiceAiNumber } = this.state;

    let searchParams = { 
      ...params, 
      page: 1,
      voice_ai_numbers: voiceAiNumber.length > 0 ? voiceAiNumber.join(',') : undefined
    };

    // Add search if there's a value
    if (newSearchQuery && newSearchQuery.trim()) {
      searchParams.search = newSearchQuery.trim();
    } else {
      searchParams.search = undefined;
    }

    const query = stringify(searchParams, stringifyOptions);
    inboxActions.getRecords(query, 'caller_name');
  };

  handleSearchChange = (e) => {
    const value = e?.target?.value ?? '';
    this.setState({ search: value });
  };

  handleVoiceAiNumberChange = (values) => {
    const { paramTools, inboxActions } = this.props;
    const { params } = paramTools;
    const { search } = this.state;

    this.setState({ voiceAiNumber: values });

    const searchParams = { 
      ...params, 
      voice_ai_numbers: values.length > 0 ? values.join(',') : undefined,
      search: search && search.trim() ? search.trim() : undefined,
      page: 1 
    };

    const query = stringify(searchParams, stringifyOptions);
    inboxActions.getRecords(query, 'voice_ai_numbers');
  };

  render() {
    const { autocompleteNameOptions, search, voiceAiNumber } = this.state;
    const { voiceAiNumbers = [] } = this.props;

    const voiceAiNumberOptions = voiceAiNumbers.map(number => ({
      value: number.slug,
      label: number.phone_number.replace('+1', '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    }));

    return (
      <div>
        <AutoComplete
          options={autocompleteNameOptions}
          onSearch={this.runAutocompleteOnSearch}
          value={search}
        >
          <Search
            showSearch
            style={{ width: '220px', marginTop: 0 }}
            placeholder="Search by Caller Number"
            value={search}
            onChange={this.handleSearchChange}
            onSearch={this.searchTrigger}
          />
        </AutoComplete>
      </div>
    );
  }
}

VoiceSearchSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  queryType: PropTypes.string,
  voiceAiNumbers: PropTypes.array,
};

VoiceSearchSettings.defaultProps = {
  queryType: 'caller_name',
  voiceAiNumbers: [],
};

export default connect(state => ({
  voiceAiNumbers: state.userProfile.voiceAiNumbers,
}), dispatch => ({
  dispatch
}))(VoiceSearchSettings);
