import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { formatRole } from './helpers';
import COLORS from '../../util/constants/colors';
import Typography from '../../components/Typography';


const Transcription = ({ ...props }) => {
  const { voiceCall } = props;
  const transcript = voiceCall ? voiceCall.transcript : null;
  const NO_TRANSCRIPTION = "[]";

  return (
    <div className="call-transcript">
      <Typography variant="text" size="large" bold>
        Transcription
      </Typography>
      <div className="transcript-container">
        { transcript !== null && transcript !== NO_TRANSCRIPTION ? 
        JSON.parse(transcript)?.filter(item => item.content).map((item, index) => (
          <div
            className={classNames("transcript-item", {
              "user": item.role === 'user',
            })}
            key={index}
          >
            <div className="role">
              <Typography variant="text" size="small" color={COLORS.TEXT_SECONDARY_LITE}>
                {formatRole(item.role)}:
              </Typography>
            </div>
            <div className="content">
              <Typography variant="text" size="small">
                {item.content}
              </Typography>
            </div>
          </div>
        ))
        :
        <div className="no-transcription">
          <Typography variant="text" size="small">
            No transcription available.
          </Typography>
        </div>
      }
      </div>
      <div className="divider" />
    </div>
  )
}

export default connect((state) => ({
  voiceCall: state.calls.currentVoiceCall,
}), {})(Transcription);
