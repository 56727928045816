const PAGES = {
  HOME: 'home',
  UPLOADS: 'uploads',
  SENT: 'sent',
  RECEIVED: 'received',
  ASSIGNED: 'assigned',
  REFERRED: 'referred',
  VOICE: 'voice',
  SETTINGS: 'user',
};

const RECEIVER_TYPES = {
  RECEIVED_FAX: 'received_fax',
  EMAIL_ATTACHMENT: 'email_attachment',
  RECEIVER: 'receiver',
};

export { PAGES, RECEIVER_TYPES };
