import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { PrinterSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { printUserRecord } from '../../../actions/records';

const PrintButton = ({ isPrinting, setIsPrinting, ...props }) => {
  const { downloadUrl } = props;

  useEffect(() => {
    if (downloadUrl && isPrinting) {
      const printedPdfWindow = window.open();
      printedPdfWindow.location.href = downloadUrl;
      printedPdfWindow.opener = null;
      setIsPrinting(false);
    }
  }, [downloadUrl]);

  const printRecord = async () => {
    // eslint-disable-next-line no-shadow
    const { openModal, fileToken, printUserRecord } = props;

    // Wait for backend to complete audit log
    const canPrint = await verifyFeatureAccess('send_record');
    if (!canPrint) {
      const modalType = {
        type: 'RAISE_FLAG',
        featureFlag: 'send_record',
      };
      return openModal(modalType);
    }
    const response = await printUserRecord(fileToken);
    if (response !== 0) {
      // eslint-disable-next-line no-undef
      toastr.error(
        'Unable to print this record. Try refreshing and printing again.',
      );
    }
  };

  return (
    <ToolbarButton
      title="Print"
      icon={PrinterSvg}
      onClick={() => {
        setIsPrinting(true);
        printRecord();
      }}
    />
  );
};

PrintButton.defaultProps = {
  downloadUrl: '',
};

PrintButton.propTypes = {
  isPrinting: PropTypes.bool.isRequired,
  setIsPrinting: PropTypes.func.isRequired,

  fileToken: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string,

  openModal: PropTypes.func.isRequired,
  printUserRecord: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    fileToken: state.records.fileToken,
    downloadUrl: state.records.downloadUrl,
  }),
  {
    openModal,
    printUserRecord,
  },
)(PrintButton);
