import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReferralReplySidebar from '../../components/modal/ReferralReplySidebar';
import {
  getReferralReplies,
  getReferralReplyUrl,
} from '../../actions/records';

class ReferralReplySidebarDialog extends Component {
  render() {
    return (
      <div>
        <ReferralReplySidebar
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  sendToken: state.records.sendToken,
}), {
  getReferralReplies,
  getReferralReplyUrl,
})(ReferralReplySidebarDialog);
