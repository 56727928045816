/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PSPDFKitWeb from 'pspdfkit';
import CircularProgress from 'material-ui/CircularProgress';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import {
  Modal, Input, Form, Button,
} from 'antd';
import {
  setUnsavedAnnotationsOnRecord,
  updateSignatures,
  getSignature,
  getAttachments,
  saveAttachment,
  deleteAttachment,
} from '../../actions/records';
import { verifyFeatureAccess } from '../../global/featureFlags';
import {
  customToolbarConfig,
  editingDisabledToolbarConfig,
  mobileToolbarConfig,
  newRecordViewerToolbarConfig,
} from './constants';

const { Rect } = PSPDFKitWeb.Geometry;
const { TextAnnotation } = PSPDFKitWeb.Annotations;

// If users choose to edit or annotate the original fileBlob,
// we keep track of it here so it doesn't get reset on pspdfkit load
let newFileBlob = {};
// We need this to access the component's scope within the onPress function in
// the save button
let _this;
// Represents a PSPDFKit.Instance and declared globally so that it is accessible in places
// like the signBtn.onPress() method
let instance = null;

let defaultSignatureSize = 0.5;

function fileToDataURL(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}

class PSPDFKit extends Component {
  static propTypes = {
    documentUrl: PropTypes.string.isRequired,
    fileToken: PropTypes.string.isRequired,
    saveLoading: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    saveChangesButtonName: PropTypes.string,
    onPagePress: PropTypes.func,
  }

  static defaultProps = {
    saveChangesButtonName: 'FINISH',
    onPagePress: () => null,
  };

  constructor(props, context) {
    super(props, context);
    // Parent container div for PSPDFKit
    this.flattenAnnotations = this.flattenAnnotations.bind(this);
    this.container = null;
    this.onRef = this.onRef.bind(this);
    this.load = this.load.bind(this);
    this.unload = this.unload.bind(this);
    this.state = {
      /* eslint-disable-next-line react/no-unused-state */
      recordId: props.recordId,
      addNameAndDateToSignature: false,
      fields: null,
      annotationsUpdated: false,
      documentUpdated: false,
      flattenUponSaving: false,
      isModalOpen: false,
      inputName: '',
    };
    _this = this;
  }

  componentDidMount = async () => {
    this.props.setClick(this.flattenAnnotations);
    const { documentUrl } = this.props;

    if (this.props.viewer === 'User') {
      const addNameAndDateToSignature = await verifyFeatureAccess('add_name_and_date_to_signature');
      const flattenUponSaving = await verifyFeatureAccess('flatten_annotations');
      this.setState({
        addNameAndDateToSignature,
        flattenUponSaving,
      });
    } else {
      this.setState({ flattenUponSaving: this.props.shouldFlatten });
    }

    if (!documentUrl) return;
    this.load(this.props);
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const recordUrlHasChanged = nextProps.documentUrl !== prevProps.documentUrl;
    const recordEditingPermissionsChanged = nextProps.canEditRecord !== prevProps.canEditRecord;
    const fileTokenHasChanged = nextProps.fileToken !== prevProps.fileToken;
    if (recordUrlHasChanged) {
      this.unload();
      this.load(nextProps);
    }
    if (recordEditingPermissionsChanged || fileTokenHasChanged) {
      this.unload();
      this.load(nextProps);
    }
  }

  componentWillUnmount() {
    this.unload();
  }

  onRef(container) {
    this.container = container;
  }

  /**
   * We need to load external assets required by PSPDFKit, namely
   * some WASM files and other JS files. Depending on which environment
   * the files are being accessed from, they should be loaded
   * through a different hostname.
   *
   * @return hostname {String}
   */
  setBaseUrl() {
    // Only localhost is served over http. In this case,
    // access external files through local directory
    const currentProtocol = window.location.protocol;
    if (currentProtocol === 'http:') return `${currentProtocol}//${window.location.host}/`;
    // Use CDN on Cloudfront if accessed via production or staging
    const isProduction = window.location.host.split('.')[0] === 'app';
    if (isProduction) return 'https://static-3.medsender.com/';
    return 'https://static-2.medsender.com/';
  }

  handleFieldChange = (e) => {
    this.setState({ inputName: e.target.value });
  }

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };

  handleOk = () => {
    this.setState({ isModalOpen: false });
    defaultSignatureSize = 0.5;
    const { viewState } = instance;
    instance.setViewState(viewState.set('interactionMode', PSPDFKitWeb.InteractionMode.INK_SIGNATURE));
  };

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  load = async (props) => {
    // PSPDFKit returns a default toolbar at the top, which includes functions
    // to traverse pages within a PDF, a hand tool, zoom, etc.
    // We will be modifying the toolbar to include the specific functions
    // that we'd like to load.
    let toolbarItems = props.newRecordViewer ? newRecordViewerToolbarConfig : editingDisabledToolbarConfig;

    // PSPDFKit doesn't include a save button so we are including our own
    // to support saving the edited file to the backend
    // Documentation for adding a custom button:
    // https://pspdfkit.com/guides/web/current/customizing-the-interface/customizing-the-toolbar/
    const guestViewer = window.location.href.includes('/link/')
    || window.location.href.includes('/app/access') || window.location.href.includes('/app/patient_access');
    const onMobile = window.innerWidth < 800;
    // Limiting annotation tools to ink signature, text, and ink for mobile users on guest viewers to simplify the UI and avoid
    // buttons like 'Save Changes' from being hidden from view
    const limitAnnotationButtonsForViewer = guestViewer && onMobile;

    const signBtn = {
      type: 'custom',
      title: 'Sign Document',
      onPress() {
        if (guestViewer && props.nameToSignatureRequired) {
          _this.setState({ isModalOpen: true });
          return;
        }
        defaultSignatureSize = 0.5;
        const { viewState } = instance;
        instance.setViewState(viewState.set('interactionMode', PSPDFKitWeb.InteractionMode.INK_SIGNATURE));
      },
    };

    const saveButton = {
      type: 'custom',
      id: 'save-pdf',
      className: guestViewer && 'custom-finish-button',
      title: props.saveChangesButtonName,
      onPress: async () => {
        // Code to flatten annotations if there's any signatures.
        const pagesAnnotations = await Promise.all(
          Array.from({ length: instance.totalPageCount }).map((_, pageIndex) => {
            return instance.getAnnotations(pageIndex);
          }),
        );

        // Get all annotations in the document.
        const allAnnotations = pagesAnnotations
          .map(pageList => pageList.toJS())
          .flat();

        // Check if any of the annotations are signatures.
        let hasSignature = false;
        allAnnotations.forEach((annotation) => {
          if (annotation.isSignature) {
            hasSignature = true;
          }
        });

        if (this.state.flattenUponSaving && hasSignature) {
          await instance.exportPDF({ flatten: true }).then((buffer) => {
            newFileBlob = new Blob([buffer], { type: 'application/pdf' });
            const bodyFormData = new FormData();
            bodyFormData.append('file', newFileBlob);
            this.props.updateRecordBlob(bodyFormData);
          });
        }

        const bodyFormData = new FormData();
        bodyFormData.append('file', newFileBlob);
        if (guestViewer) {
          props.guestSignAndSubmitRecord(bodyFormData, _this.state.recordId);
          return;
        }
        props.saveAnnotationsOnRecord(bodyFormData, _this.state.recordId);
      },
    };

    const rotateLeftToolBarItemId = 'Rotate Left';
    const rotateRightToolBarItemId = 'Rotate Right';

    const disableToolBarItem = disabled => (
      instance.setToolbarItems((items) => {
        return items.map((item) => {
          if (item.title === rotateLeftToolBarItemId || item.title === rotateRightToolBarItemId) return { ...item, disabled };
          return item;
        });
      })
    );

    const rotateRight = {
      id: rotateRightToolBarItemId,
      type: 'custom',
      title: 'Rotate Right',
      icon: '/pspdfkit-lib/rotate-right-svgrepo-com.svg',
      onPress() {
        // disable rotate buttons on press
        disableToolBarItem(true);
        instance.applyOperations([
          {
            type: 'rotatePages',
            pageIndexes: Array.from(Array(instance.totalPageCount).keys()),
            rotateBy: 90,
          },
        ]).then(() => disableToolBarItem(false));
      },
    };

    const rotateLeft = {
      id: rotateLeftToolBarItemId,
      type: 'custom',
      title: 'Rotate Left',
      icon: '/pspdfkit-lib/rotate-left-svgrepo-com.svg',
      onPress() {
        // disable rotate buttons on press
        disableToolBarItem(true);
        instance.applyOperations([
          {
            type: 'rotatePages',
            pageIndexes: Array.from(Array(instance.totalPageCount).keys()),
            rotateBy: -90,
          },
        ]).then(() => disableToolBarItem(false));
      },
    };

    const fromSentInbox = props.fromPage === 'sent';

    if (props.canEditRecord && !fromSentInbox) {
      toolbarItems = limitAnnotationButtonsForViewer
        ? mobileToolbarConfig
        : customToolbarConfig;
      toolbarItems = props.newRecordViewer
        ? newRecordViewerToolbarConfig
        : toolbarItems;
      const lastButtonInToolbar = toolbarItems[toolbarItems.length - 1];
      // We're going to add our own custom save button but since
      // the render method is called multiple times, we want to
      // ensure the button is only added once

      // To do that, we'll just check to see if the last button
      // is the save button
      const hasSaveButton = lastButtonInToolbar.type === 'custom';
      if (!hasSaveButton) {
        toolbarItems.push(signBtn);
        if (onMobile) {
          toolbarItems.unshift(saveButton);
        } else {
          const annotateIndex = toolbarItems.findIndex(item => item.type === 'annotate');
          toolbarItems.splice(annotateIndex + 1, 0, rotateLeft);
          toolbarItems.splice(annotateIndex + 2, 0, rotateRight);
          toolbarItems.push(saveButton);
        }
      }
    }

    // See https://pspdfkit.com/api/web/PSPDFKit.Configuration.html#initialViewState
    const initialViewState = new PSPDFKitWeb.ViewState({ layoutMode: PSPDFKitWeb.LayoutMode.SINGLE });
    if (props.viewer !== 'User') {
      if (PSPDFKitWeb.Options.SIGNATURE_SAVE_MODE !== PSPDFKitWeb.SignatureSaveMode.NEVER) {
        PSPDFKitWeb.Options.SIGNATURE_SAVE_MODE = PSPDFKitWeb.SignatureSaveMode.NEVER;
      }
    }

    // Configuring the stamp button. We are picking just a few of the templates to display to the user.
    // See https://pspdfkit.com/guides/web/current/features/stamp-annotation-templates/ for a complete list of templates
    const stampAnnotationTemplates = PSPDFKitWeb.defaultStampAnnotationTemplates;
    const cancelStamp = stampAnnotationTemplates[12];
    const checkmarkStamp = stampAnnotationTemplates[13];
    const initialHereStamp = stampAnnotationTemplates[14];
    const signHereStamp = stampAnnotationTemplates[15];
    const rejectedStamp = stampAnnotationTemplates[24];
    const customStampTemplates = [initialHereStamp, signHereStamp, rejectedStamp, cancelStamp, checkmarkStamp];
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    customStampTemplates.push(
      new PSPDFKitWeb.Annotations.StampAnnotation({
        // eslint-disable-next-line quotes
        stampType: "Custom",
        title: currentDate,
        subtitle: currentTime,
        color: new PSPDFKitWeb.Color({ r: 245, g: 111, b: 21 }),
        boundingBox: new PSPDFKitWeb.Geometry.Rect({
          left: 0,
          top: 0,
          width: 150,
          height: 60,
        }),
      }),
    );

    // Config object for PSPDFKit, see here for more
    // https://pspdfkit.com/api/web/PSPDFKit.Configuration.html
    const onStaging = window.location.hostname === 'staging.medsender.com';
    const licenseKey = onStaging
      ? process.env.STAGING_PSPDFKIT_KEY
      : process.env.PRODUCTION_PSPDFKIT_KEY;

    if (!props.documentUrl) return;

    const config = {
      initialViewState,
      document: props.documentUrl,
      container: this.container,
      licenseKey,
      baseUrl: this.setBaseUrl(),
      toolbarItems,
      disableTextSelection: (!props.canEditRecord || fromSentInbox) && true,
      // setting language to english to prevent auto translation to match system language
      locale: 'en',
      styleSheets: [
        'https://static.medsender.com/pspdfkit-lib/custom-viewer.css',
      ],
      stampAnnotationTemplates: customStampTemplates,
    };

    try {
      // Documentation: https://pspdfkit.com/api/web/PSPDFKit.html#.load
      await PSPDFKitWeb.load(config)
        .then(async (_instance) => {
          instance = _instance;
          // set annotation to be default selected after its been selected once
          let newAnnotation = null;
          const interactionMode = PSPDFKitWeb.InteractionMode.TEXT;

          // Locally store the form fields
          await instance.getFormFields()
            .then((formFields) => {
              this.setState({ fields: formFields });
            })
            .catch(error => console.error(`Failed to fetch form fields: ${error}`));

          const { canGetandUpdateSignatures } = this.props;
          if (canGetandUpdateSignatures) {
            await this.props.getSignature();
            await this.props.getAttachments();

            const { signatureData, attachmentsData } = this.props;
            const blobs = await Promise.all(
              attachmentsData.map(attachment => fetch(attachment.blob).then(res => res.blob())),
            );
            blobs.forEach(instance.createAttachment);

            const list = PSPDFKitWeb.Immutable.List(
              signatureData.map(PSPDFKitWeb.Annotations.fromSerializableObject),
            );

            instance.setStoredSignatures(list);
          }

          instance.addEventListener('annotations.focus', async (annotations) => {
            const annotationList = this.state.fields.map((value) => {
              return value?.name.split('_WIDGET_')[1]?.slice(0, 8) ?? null;
            }).toArray();

            const annotationName = annotations.annotation.formFieldName;
            if (annotationName) {
              const annotationId = annotationName.split('_WIDGET_')[1].slice(0, 8);
              if (annotationName.startsWith('SIGNATURE_WIDGET') && !annotationList.includes(annotationId)) {
                await instance.setSelectedAnnotation(null);
              }
              defaultSignatureSize = 1.0;
              await instance.getFormFields()
                .then((formFields) => {
                  this.setState({ fields: formFields });
                })
                .catch(error => console.error(`Failed to fetch form fields: ${error}`));
            }
          });

          instance.addEventListener('annotations.update', async () => {
            this.setState({ annotationsUpdated: true });
          });

          instance.addEventListener('document.change', async () => {
            this.setState({ documentUpdated: true });
          });

          instance.addEventListener('formFieldValues.update', async () => {
            this.setState({ annotationsUpdated: true });
          });

          instance.addEventListener('annotations.create', async (annotations) => {
            this.setState({ annotationsUpdated: true });
            if (annotations.size === 1 && instance.viewState.interactionMode === interactionMode) {
              newAnnotation = annotations.get(0);
            }
            const annotation = annotations.get(0);
            let annotationPosition = annotation.boundingBox;
            if (annotation.formFieldName && annotation.formFieldName.startsWith('SIGNATURE_WIDGET')) {
              const newBounding = new PSPDFKitWeb.Geometry.Rect({
                left: annotation.boundingBox.left,
                top: annotation.boundingBox.top,
                width: annotation.boundingBox.width,
                height: 55,
              });
              const updatedAnnotation = annotation.set('boundingBox', newBounding);
              instance.update(updatedAnnotation);
            }
            // A bug exists for when a text annotation is saved and it cuts off part or all of the last line.
            // The code below attempts to update the height of the bounding box and the update the annotation.
            if (annotation.text) {
              const {
                top, left, height, width,
              } = annotation.boundingBox;
              
              // Increase the line height significantly for better spacing
              const lineHeight = 30; // Increased from previous values
              const lineCount = annotation.text.value.split('\n').length;
              const calculatedHeight = Math.max(height, lineCount * lineHeight);
              
              const newBounding = new PSPDFKitWeb.Geometry.Rect({
                height: calculatedHeight * 1.5, // Increased buffer from 1.3 to 1.5
                left,
                top,
                width,
              });
              const updatedAnnotation = annotation.set('boundingBox', newBounding);
              instance.update(updatedAnnotation);
            }
            // If signature, set state in record viewer.
            if (annotation.isSignature) {
              this.props.setUnsavedSignatures(true);

              // Code to halve the signature size.
              const {
                top, left, bottom, height, right, width,
              } = annotation.boundingBox;
              const newBounding = new PSPDFKitWeb.Geometry.Rect({
                bottom: bottom * defaultSignatureSize,
                height: height * defaultSignatureSize,
                left,
                right: right * defaultSignatureSize,
                top,
                width: width * defaultSignatureSize,
              });
              let updatedAnnotation;
              if (annotation.contentType !== 'image/png') {
                const newLines = annotation.lines.map((line) => {
                  return line.map((point) => {
                    return new PSPDFKitWeb.Geometry.DrawingPoint({
                      x: left + (point.x - annotation.boundingBox.left) * defaultSignatureSize,
                      y: top + (point.y - annotation.boundingBox.top) * defaultSignatureSize,
                    });
                  });
                });
                updatedAnnotation = annotation.set('boundingBox', newBounding).set('lineWidth', 2).set('lines', newLines);
                instance.update(updatedAnnotation);
                annotationPosition = updatedAnnotation.boundingBox;
              } else if (annotation.contentType === 'image/png') {
                // Code to halve signature size if it is an image/png
                updatedAnnotation = annotation.set('boundingBox', newBounding).set('pdfObjectId', annotation.id);
                instance.update(updatedAnnotation);
              }
            }

            const { addNameAndDateToSignature, inputName } = this.state;
            const { nameToSignatureRequired, recipientName } = this.props;
            const placeNameUnderSignature = addNameAndDateToSignature || nameToSignatureRequired;
            let fullName = '';
            if (placeNameUnderSignature) {
              if (localStorage.getItem('m8_name')) {
                fullName = localStorage.getItem('m8_name');
              } else {
                fullName = inputName || recipientName;
              }
            }
            if (placeNameUnderSignature && annotations.size === 1 && instance.viewState.interactionMode === 'SIGNATURE') {
              const todayDate = moment(new Date()).format('MM/DD/YYYY');
              const currentTime = moment(new Date()).format('h:mm A');
              const textAnnotation = await new TextAnnotation({
                pageIndex: annotations.get(0).pageIndex,
                text: { format: 'plain', value: `Electronically signed by ${fullName}\n${todayDate} ${currentTime}` },
                fontSize: 10,
                boundingBox: new Rect({
                  left: annotationPosition.left, 
                  top: annotationPosition.top + annotationPosition.height, 
                  width: 200, 
                  height: 50, // Increased to 50 to ensure more space
                }),
              });
              this.setState({ annotationsUpdated: true });
              await instance.create(textAnnotation);
            }
          });
          instance.addEventListener('annotationSelection.change', (annotation) => {
            if (annotation === null && newAnnotation) {
              instance.setViewState(state => state.set('interactionMode', interactionMode));
            }
            newAnnotation = null;
          });
          instance.addEventListener('page.press', () => {
            this.props.onPagePress();
          });
          // Code to store/save signatures.
          if (props.canGetandUpdateSignatures) {
            instance.addEventListener('storedSignatures.create', async (annotation) => {
              // Get the current svaed Signatures in local instance.
              const savedSignatures = await instance.getInkSignatures().then((signatures) => {
                return signatures
                  .map(signature => PSPDFKitWeb.Annotations.toSerializableObject(signature))
                  .toJS();
              });

              // Serialize the new annotation.
              const serializedAnnotation = PSPDFKitWeb.Annotations.toSerializableObject(annotation);

              savedSignatures.push(serializedAnnotation);

              if (annotation.imageAttachmentId) {
                const attachment = await instance.getAttachment(
                  annotation.imageAttachmentId,
                );

                const url = await fileToDataURL(attachment);
                await props.saveAttachment(url, annotation.imageAttachmentId);
              }

              props.updateSignatures({ signatures: savedSignatures });
              instance.setStoredSignatures(signatures => signatures.push(annotation));
            });
            // Adds the delete saved signature feature.
            instance.addEventListener('storedSignatures.delete', async (annotation) => {
              instance.setStoredSignatures(signatures => signatures.filter(signature => !signature.equals(annotation)));

              const savedSignatures = await instance.getInkSignatures().then((signatures) => {
                return signatures
                  .map(signature => PSPDFKitWeb.Annotations.toSerializableObject(signature))
                  .toJS();
              });

              props.updateSignatures({ signatures: savedSignatures });

              if (annotation.imageAttachmentId) {
                await props.deleteAttachment(annotation.imageAttachmentId);
              }
            });
          }
          // Intialization of a file blob of the document on initial load that is used in the save function
          // see onPress() for the custom save button. NewFileBlob is reassigned when annotations are changed.
          instance.exportPDF().then((buffer) => {
            newFileBlob = new Blob([buffer], { type: 'application/pdf' });
          });
          const eventsToMonitor = [
            'annotations.willChange',
            'annotations.create',
            'annotations.update',
            'annotations.delete',
            'document.change',
            'inkSignatures.change',
            'formFieldValues.update',
            'formFields.create',
            'formFields.delete',
          ];

          eventsToMonitor.forEach((annotation) => {
            instance.addEventListener(annotation, () => {
              // event.type for document.change events will specify the
              // specific button clicked
              instance.exportPDF().then((buffer) => {
                newFileBlob = new Blob([buffer], { type: 'application/pdf' });
                const bodyFormData = new FormData();
                bodyFormData.append('file', newFileBlob);
                // storing the file object in props to make it accessible on autosave before sending
                if (instance.hasUnsavedChanges() || this.state.annotationsUpdated || this.state.documentUpdated) {
                  props.updateRecordBlob(bodyFormData);
                }
              });
              props.setUnsavedEdits();
            });
          });

          instance.addEventListener('annotations.willChange', async (annotations) => {
            const annotation = annotations.annotations.get(0);
            if (annotation && annotation.text) {
              // When text is being changed, preemptively increase the height
              const { top, left, width } = annotation.boundingBox;
              const lineCount = annotation.text.value.split('\n').length;
              const lineHeight = 30;
              const calculatedHeight = Math.max(lineCount * lineHeight, 60); // Minimum height of 60
              
              const newBounding = new PSPDFKitWeb.Geometry.Rect({
                height: calculatedHeight * 1.5,
                left,
                top,
                width,
              });
              const updatedAnnotation = annotation.set('boundingBox', newBounding);
              // We use a setTimeout to let the current operation complete first
              setTimeout(() => {
                instance.update(updatedAnnotation);
              }, 0);
            }
          });
        });
    } catch (e) {
      console.log(e);
      window.raven.captureException(e);
    }
  }

  // saveAsGuest is used to determine if the user clicked the finish/save button instead of reply button.
  // If they used the finish button, saveAsGuest should be set to true so we'll call the function to submit
  // the record again.
  async flattenAnnotations(saveAsGuest) {
    // Code to flatten annotations if there's any signatures.
    const pagesAnnotations = await Promise.all(
      Array.from({ length: instance.totalPageCount }).map((_, pageIndex) => {
        return instance.getAnnotations(pageIndex);
      }),
    );

    // Get all annotations in the document.
    const allAnnotations = pagesAnnotations
      .map(pageList => pageList.toJS())
      .flat();

    // Check if any of the annotations are signatures.
    let hasSignature = false;
    allAnnotations.forEach((annotation) => {
      if (annotation.isSignature) {
        hasSignature = true;
      }
    });

    if (hasSignature && this.state.flattenUponSaving) {
      await instance.exportPDF({ flatten: true }).then((buffer) => {
        newFileBlob = new Blob([buffer], { type: 'application/pdf' });
        const bodyFormData = new FormData();
        bodyFormData.append('file', newFileBlob);
        this.props.updateRecordBlob(bodyFormData);
      });
    }

    const bodyFormData = new FormData();
    bodyFormData.append('file', newFileBlob);

    const guestViewer = window.location.href.includes('/link/')
    || window.location.href.includes('/app/access') || window.location.href.includes('/app/patient_access');
    if (guestViewer) {
      await this.props.setUnsavedSignatures(false);
      if (saveAsGuest) {
        await this.props.guestSignAndSubmitRecord(bodyFormData);
      }
      return;
    }
    await this.props.setUnsavedSignatures(false);
    await this.props.saveAnnotationsOnRecord(bodyFormData, _this.state.recordId);
    const email = localStorage.getItem('m8_uid');
    if (email !== 'mvennamaneni@ahcpllc.com') {
      this.props.sendRecord(false);
    }
  }

  unload() {
    PSPDFKitWeb.unload(this.container);
  }

  render() {
    const { saveLoading } = this.props;
    // Because the Medsender header is at the top of the page,
    // we want to customize the viewport height so we'll
    // account for the height of the header
    const { isModalOpen } = this.state;
    const onMobile = window.innerWidth < 800;
    const viewPortHeight = onMobile ? '100vh' : `${window.innerHeight - 97}px`;
    const style = {
      width: '100%',
      height: viewPortHeight,
      position: 'relative',
      overflowY: 'scroll !important',
    };
    return (
      <>
        <div
          ref={this.onRef}
          style={
            saveLoading
              ? { opacity: '.1', ...style }
              : style
          }
        />
        {
          saveLoading && (
            <div style={{ position: 'absolute', top: '50%', right: '50%' }}>
              <CircularProgress size={80} thickness={5} mode="indeterminate" />
            </div>
          )
        }
        <Modal
          title="Confirm Full Name"
          visible={isModalOpen}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" onClick={this.handleOk} disabled={this.state.inputName === '' && this.props.recipientName === ''}>
              Submit
            </Button>,
          ]}
        >
          <Form>
            <div>
              <p><i>Please confirm the information below before signing</i></p>
            </div>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[
                () => ({
                  validator(_, name) {
                    if (name && name.trim() !== '') return Promise.resolve();
                    return Promise.reject('Name cannot be blank');
                  },
                }),
              ]}
            >
              <Input
                placeholder="Enter Full Name"
                defaultValue={this.state.inputName || this.props.recipientName}
                onChange={this.handleFieldChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

PSPDFKit.defaultProps = {
  canGetandUpdateSignatures: false,
}

PSPDFKit.propTypes = {
  setUnsavedEdits: PropTypes.func.isRequired,
  updateSignatures: PropTypes.func.isRequired,
  getSignature: PropTypes.func.isRequired,
  getAttachments: PropTypes.func.isRequired,
  saveAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  canGetandUpdateSignatures: PropTypes.bool,
};

export default connect(() => {}, {
  setUnsavedEdits: setUnsavedAnnotationsOnRecord,
  updateSignatures,
  getSignature,
  getAttachments,
  saveAttachment,
  deleteAttachment,
})(PSPDFKit);
