import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Table, Button } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import ErrorDisplay from '../viewer/errorDisplay';
import '../inbox/replyMessageSidebar.scss';

class ReferralReplySidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      replies: null,
      sendToken: this.props.modalType.data.sendToken,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.modalType !== this.props.modalType) {
      if (this.props.modalType.type === 'REFERRAL_REPLY_SIDEBAR') {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          visible: true,
          sendToken: this.props.modalType.data.sendToken,
          replies: null,
        }, async () => {
          const { getReferralReplies } = this.props;
          await getReferralReplies(this.state.sendToken);
          this.setState({ replies: this.props.referralReplies });
        });
      }
    }
  }

  componentDidMount = async () => {
    const { getReferralReplies } = this.props;
    const { sendToken } = this.state;
    await getReferralReplies(sendToken);
    this.setState({ replies: this.props.referralReplies });
  }

  getUrl = async (url) => {
    const { getReferralReplyUrl } = this.props;
    await getReferralReplyUrl(url);
    const { attachmentUrl } = this.props;
    window.open(attachmentUrl);
  }

  handleClose = () => {
    this.setState({ visible: false });
  }

  render = () => {
    const { error } = this.props;
    const { replies } = this.state;

    const columns = [
      {
        title: 'To Number',
        dataIndex: 'receiver_fax',
        key: 'slug',
        width: '25%',
      },
      {
        title: 'Message',
        dataIndex: 'sender_fax',
        render: (text, record) => {
          return (
            <>
              <Button icon={<PaperClipOutlined />} type="dashed" onClick={() => this.getUrl(record.record_uri)}>
                Document
              </Button>
            </>
          );
        },
      },
      {
        title: 'Fax Status',
        dataIndex: 'fax_status',
      },
      {
        title: 'Transmission Date',
        dataIndex: 'fax_completed_at',
        width: '25%',
      },
    ];

    return (
      replies && replies.referral_replies && replies.referral_replies.length > 0 && (
        <Drawer
          title="Referral Replies"
          placement="right"
          closable={true}
          onClose={this.handleClose}
          visible={this.state.visible}
          width={730}
          style={{ marginTop: 64, padding: 0 }}
        >
          <div>
            {error ? <ErrorDisplay sidebar={true} message="Error retrieving reply. Please try again" />
              : (
                <>
                  <div style={{ borderTop: '1px solid lightgrey', padding: '25px' }}>
                    <Table dataSource={replies.referral_replies} columns={columns} rowKey="slug" />
                  </div>
                </>
              )
            }
          </div>
        </Drawer>
      )
    );
  }
}

ReferralReplySidebar.defaultProps = {
  data: {},
  error: false,
  attachmentUrl: null,
  referralReplies: {},
};

ReferralReplySidebar.propTypes = {
  error: PropTypes.bool,
  data: PropTypes.object,
  getReferralReplyUrl: PropTypes.func.isRequired,
  attachmentUrl: PropTypes.string,
  getReferralReplies: PropTypes.func.isRequired,
  referralReplies: PropTypes.object,
  modalType: PropTypes.object.isRequired,
};

export default connect(state => ({
  referralReplies: state.records.referralReplies,
  attachmentUrl: state.records.attachmentUrl,
  error: state.records.error,
}))(ReferralReplySidebar);
