// copy for the alert message on the home dashboard
const chromeExtensionNotInstalled = "It looks like you don't have our Chrome extension installed.";

const installChromeExtension = "Click here to install it.";

const refMgmtUpsellTitle = 'Ready to automate your referrals?';

const refMgmtUpsellDescription = 'Automatically create new charts, validate insurance, and invite patients to schedule via SMS with just a click.';

const refMgmtUpsellUrl = 'https://www.medsender.com/medsender-referral-management';

const mairaUpsellTitle = 'Meet MAIRA: Your AI voice agent';

const mairaUpsellDescription = 'MAIRA’s human-like voice can answer every inbound call 24/7 in over 15 languages without any voicemails or IVRs. Set rules to auto-call newly referred patients.';

const mairaUpsellUrl = 'https://www.medsender.com/maira-ai-voice-assistant';

export { chromeExtensionNotInstalled, installChromeExtension, refMgmtUpsellTitle, refMgmtUpsellDescription, refMgmtUpsellUrl, mairaUpsellTitle, mairaUpsellDescription, mairaUpsellUrl };
