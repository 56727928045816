const COLORS = {
  // Background
  BG_LIGHT_LITE: '#ffffff',
  BG_DARKEN_LITE: '#f8f8f8',

  BG_LIGHT_DARK: '#282828',
  BG_DARKENT_DARK: '#1c1c1c',

  // Greyscale
  TEXT_PRIMARY_LITE: '#181818',
  TEXT_SECONDARY_LITE: '#5c5c5c',
  TEXT_TERTIARY_LITE: '#747474',
  TEXT_DISABLED_LITE: '#8a8a8a',
  ICON_LITE: '#bebebe',
  STROKE_LITE: '#d7d7d7',
  DIVIDER_LITE: '#ededed',
  DISABLED_BG_LITE: '#efefef',

  WHITE: '#ffffff',

  TEXT_PRIMARY_DARK: '#d9d9d9',
  TEXT_SECONDARY_DARK: '#9d9d9d',
  TEXT_TERTIARY_DARK: '#5c5c5c',
  TEXT_DISABLED_DARK: '#8b8b8b',
  ICON_DARK: '#6c6c6c',
  STROKE_DARK: '#3a3a3a',
  DIVIDER_DARK: '#323232',
  DISABLED_BG_DARK: '#4a4a4a',
  COLOR_PLASH_DARK: '#181818',

  // Accent1: Purple
  PURPLE_PRIMARY_LITE: '#662c88',
  PURPLE_SECONDARY_LITE: '#9d76b4',
  PURPLE_TERTIARY_LITE: '#d4c0e1',
  PURPLE_QUARTERNARY_LITE: '#f6effa',

  HOVER_STATE: '#3d1a52',
  CLICK_STATE: '#14091b',

  PURPLE_PRIMARY_DARK: '#662c88',
  PURPLE_SECONDARY_DARK: '#9d76b4',
  PURPLE_TERTIARY_DARK: '#e0d4e7',
  PURPLE_QUARTERNARY_DARK: '#f6effa',

  // Accent2: Orange
  ORANGE_PRIMARY_LITE: '#f37045',
  ORANGE_SECONDARY_LITE: '#f9a723',
  ORANGE_TERTIARY_LITE: '#fac6b5',
  ORANGE_QUARTERNARY_LITE: '#fef1ec',

  ORANGE_PRIMARY_DARK: '#f37b38',
  ORANGE_SECONDARY_DARK: '#ea996b',
  ORANGE_TERTIARY_DARK: '#ffab7c',
  ORANGE_QUARTERNARY_DARK: '#ffc2a0',

  // System
  SUCCESS_HOVER_LITE: '#59b74a',
  SUCCESS_LITE: '#85d076',
  SUCCESS_SECONDARY_LITE: '#aaf49e',
  SUCCESS_TERTIARY_LITE: '#e6ffe2',
  ATTENTION_HOVER_LITE: '#f6d523',
  ATTENTION_LITE: '#f6d523',
  ATTENTION_SECONDARY_LITE: '#ffef98',
  ATTENTION_TERTIARY_LITE: '#fffae1',
  ERROR_HOVER_LITE: '#c94040',
  ERROR_LITE: '#f84949',
  ERROR_SECONDARY_LITE: '#ffc0c0',
  ERROR_TERTIARY_LITE: '#fff2f2',

  SUCCESS_HOVER_DARK: '#369926',
  SUCCESS_DARK: '#4da63e',
  SUCCESS_SECONDARY_DARK: '#b0f9a5',
  SUCCESS_TERTIARY_DARK: '#c2f5b9',
  ATTENTION_HOVER_DARK: '#efd02c',
  ATTENTION_DARK: '#f9de51',
  ATTENTION_SECONDARY_DARK: '#efe29b',
  ATTENTION_TERTIARY_DARK: '#f9f1c7',
  ERROR_HOVER_DARK: '#ed2f2f',
  ERROR_DARK: '#ff4545',
  ERROR_SECONDARY_DARK: '#f7b7b7',
  ERROR_TERTIARY_DARK: '#fbdfdf',

  // Rainbow
  RED: '#ffd1d1',
  PINK: '#ffd1ea',
  PURPLE: '#edd1ff',
  LAVENDER: '#ccdbff',
  BLUE: '#cceeff',
  TURQUOISE: '#ccfffc',
  TEAL: '#ccffe2',
  GREEN: '#e6ffcc',
  YELLOW: '#fff9bd',
  ORANGE: '#ffe7bd',
  BROWN: '#f1e1da',
  GREY: '#e3e3e3',
};

export default COLORS;
