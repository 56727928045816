import React from 'react';
import './constants.scss'
import { formatBirthdate } from '../../VoiceSlideOut/helpers';

export const voiceInboxColumns = [
  {
    title: 'Caller Info',
    dataIndex: 'caller_name',
    key: 'caller_name',
    render: (_, record) => (
      <>
        <span style={{ fontWeight: '500', color: '#1890ff' }}>
          {record.caller_name || 'Not Provided'}
        </span>
        {record.caller_name && record.caller_dob && (
          <>
            <br />
            <span style={{ color: 'grey' }}>
              {record.caller_dob && record.caller_dob !== 'Not mentioned' ? formatBirthdate(record.caller_dob) : '--/--/----'}
            </span>
          </>
        )}
      </>
    ),
  },
  {
    title: 'Caller Number', 
    dataIndex: 'caller_number',
    key: 'caller_number',
    render: (number) => {
      if (!number || number.length !== 10) return number;
      return `${number.slice(0,3)}-${number.slice(3,6)}-${number.slice(6)}`;
    }
  },
  {
    title: 'Date Received',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text) => {
      if (!text) return '';
      const date = new Date(text);
      return date.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }
  },
  {
    title: 'Duration (MM:SS)',
    dataIndex: 'duration_seconds',
    key: 'duration_seconds',
    render: (seconds) => {
      if (!seconds) return '00:00';
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
  },
  {
    title: 'Status',
    dataIndex: 'disconnection_reason',
    key: 'disconnection_reason'
  }
];
