import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { connect } from 'react-redux';
import { PencilSimpleSvg, CopySvg } from '../../../assets/img/redesign/svgPaths';
import Typography from '../../../components/Typography';
import Tooltip from '../../../components/Tooltip';
import Button from '../../../components/Button';
import COLORS from '../../../util/constants/colors';
import '../newRecordViewer.scss';
import { PAGES } from '../../shared/constants';

const RecordInfo = ({
  documentTitle, patientName, patientDOB, documentCategory, setEditing, currentPage, ...props
}) => {
  const { currentTransmission } = props;
  const canEdit = currentPage !== PAGES.SENT && currentTransmission.can_edit_patient_info;
  const copyValue = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({
        content:
          'Copied value',
      });
    } catch (e) {
      message.error({
        content:
          'Failed to copy value',
      });
    }
  };

  return (
    <>
      <div className="record-info-title">
        <Typography variant="text" size="medium" bold>
          Record Info
        </Typography>
        { canEdit
          && (
          <Tooltip title="Edit" placement="left">
            <Button
              className="tertiary small icon-btn"
              icon={PencilSimpleSvg}
              onClick={() => setEditing(true)}
            />
          </Tooltip>
          )
        }
      </div>
      <div className="record-value">
        <div className="value-header">
          <Typography
            variant="text"
            size="small"
            color={COLORS.TEXT_TERTIARY_LITE}
          >
            Record Title
          </Typography>
          { documentTitle && <Button type="tertiary" size="xsmall" icon={CopySvg} onClick={() => copyValue(documentTitle)} /> }
        </div>
        <Typography variant="text" size="small" bold>
          {documentTitle || 'N/A'}
        </Typography>
      </div>
      <div className="record-value">
        <div className="value-header">
          <Typography
            variant="text"
            size="small"
            color={COLORS.TEXT_TERTIARY_LITE}
          >
            Patient Full Name
          </Typography>
          { patientName && <Button type="tertiary" size="xsmall" icon={CopySvg} onClick={() => copyValue(patientName)} /> }
        </div>
        <Typography variant="text" size="small" bold>
          {patientName || 'N/A'}
        </Typography>
      </div>
      <div className="record-value">
        <div className="value-header">
          <Typography
            variant="text"
            size="small"
            color={COLORS.TEXT_TERTIARY_LITE}
          >
            Patient Date of Birth
          </Typography>
          { patientDOB && <Button type="tertiary" size="xsmall" icon={CopySvg} onClick={() => copyValue(patientDOB)} /> }
        </div>
        <Typography variant="text" size="small" bold>
          {patientDOB || 'N/A'}
        </Typography>
      </div>
      <div className="record-value">
        <div className="value-header">
          <Typography
            variant="text"
            size="small"
            color={COLORS.TEXT_TERTIARY_LITE}
          >
            Category
          </Typography>
          { documentCategory && <Button type="tertiary" size="xsmall" icon={CopySvg} onClick={() => copyValue(documentCategory)} /> }
        </div>
        <Typography variant="text" size="small" bold>
          {documentCategory || 'N/A'}
        </Typography>
      </div>
    </>
  );
};

RecordInfo.defaultProps = {
  currentTransmission: {},
};
RecordInfo.propTypes = {
  documentTitle: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  patientDOB: PropTypes.string.isRequired,
  documentCategory: PropTypes.string.isRequired,
  setEditing: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,

  currentTransmission: PropTypes.object,
};

export default connect(state => ({
  currentTransmission: state.records.currentTransmission,
}))(RecordInfo);
