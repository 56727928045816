const ERROR_MESSAGES = {
  500: 'Oh no! We\'re currently experiencing issues on our end. Please try again.',
  520: 'An unexpected error occured, please try again.',
  404: 'Whoops! The file you\'re looking for can\'t be found!',
  400: 'We were unable to process that request. Please try again.',
};

const handlingErrors = (statusCode, customMessage) => {
  return ERROR_MESSAGES[statusCode] || customMessage || 'Oops, we ran into an issue. Please try again.';
};

const initialState = {
  isLoading: false,
  data: {},
  error: false,
  errorMessage: null,
  headers: null,
  errorCode: '',
  voice_data: [],
  currentVoiceCall: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_GET_VOICE_DATA':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'voice',
        errorMessage: null,
      };
    case 'FAILED_GET_VOICE_DATA':
      return {
        ...failedState,
        mode: 'voice',
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to get voice data.'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_GET_VOICE_DATA':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        voice_data: action.payload.data,
        headers: action.payload.headers,
        mode: 'voice',
      };
    case 'SET_CURRENT_VOICE_CALL':
      return {
        ...state,
        currentVoiceCall: action.payload.call,
      };
    case 'BEGIN_GET_VOICE_CALL':
      return {
        ...state,
        isLoading: true,
        currentVoiceCall: null,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_GET_VOICE_CALL':
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to get voice call.'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_GET_VOICE_CALL':
      return {
        ...state,
        isLoading: false,
        currentVoiceCall: action.payload.data,
      };
    default:
      return state;
  }
};
