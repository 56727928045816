import axios from 'axios';
import { parseFullName } from 'parse-full-name';
import {
  USER_AUTH,
  ACCESS_CODE_AUTH,
  USER_PROFILE,
  RESEND_OTP,
  PATIENT_AUTH,
  SECURITY_QUESTION,
  RESET_PASSWORD,
  ACCESS_CODE_LOGOUT,
  VALIDATE_RESET_TOKEN,
  VERIFY_GUEST_TOKEN,
  CREATE_GUEST_ACCOUNT,
  FORGOT_PASSWORD,
  EXTEND_USER_SESSION,
} from '../actions/auth';

/**
 * Check validity of auth credentials
 * @param  {String} uid
 * @param  {String} token
 * @param  {String} client
 * @return {Boolean}
 */
async function validateAuth() {
  try {
    const uid = localStorage.getItem('m8_uid');
    const token = localStorage.getItem('m8_access');
    const client = localStorage.getItem('m8_client');
    const basePath = '/auth/validate_token';
    const urlParams = `?uid=${uid}&access-token=${token}&client=${client}`;
    const url = basePath + urlParams;
    const response = await axios.get(url);
    if (response.data.success === true) return true;
  } catch (error) {
    return false;
  }
}

/**
 * Check for presence of auth credentials
 * @return {Boolean}
 */
async function hasAuthCreds() {
  // Ensure all tokens exist
  if (localStorage.getItem('m8_access')
    && localStorage.getItem('m8_client')
    && localStorage.getItem('m8_uid')
    // Ensure token has not expired (uses UTC time)
    && Math.floor(new Date() / 1000) < parseInt(localStorage.getItem('m8_expiry'), 10)) {
    return true;
  } return false;
}

const initialState = {
  data: null,
  billing: {
    billing_on_file: false,
    trial_end_date: 'none',
    trial_days_remaining: 0,
    plan_type: 'free',
  },
  hasAuthCreds: null,
  patientAuthenticated: false,
  guestAuthenticated: false,
  isLoading: true,
  isGuestLoading: false,
  isVerifyingLoading: false,
  receiver_name: '',
  error: false,
  message: '',
  accessCodeErrorMessage: '',
  createAccountErrorMessage: '',
  resetPasswordMessage: '',
  guest: false,
  createGuest: false,
  guestAccess: '',
  sendToken: '',
  patientRecordUrl: '',
  otp_auth: {
    otp_auth_token: null, // add store prop to update the auth token
    email: null,
  }, // and keep track of the user's email
  patient: false,
  accessedPatient: false,
  accessedResetPassword: false,
  patientErrorMessage: '',
  security: {
    auth_token: null,
    recipient: null,
    question: null,
    file_token: null,
  },
  resetPasswordToken: null,
  resetPasswordEmail: null,
  trialUser: false,
  departments: [],
  documentCategories: [],
  referralManagement: false,
  referredStatuses: [],
  emaTaskTemplates: [],
  emaTaskProviders: [],
  fax: {
    fax_code: '',
    access_code: '',
    source: 'web',
  },
  hasResponse: false,
  subject: '',
  recipient: null,
  recipientName: '',
  isAdmin: false,
  hasCompletedWalkthrough: false,
  userCanSendFaxRecordsOnly: false,
  signatureRequired: false,
  shouldFlatten: false,
  disableSecureReplies: false,
  sentViaEmail: true,
  stepOnWalkthrough: 'Welcome to Medsender!',
  organizationDetails: {
    multiple_organization_login_enabled: false,
    show_multiple_organization_login_modal: false,
    organizations: [],
    current_organization: '',
  },
  sessionExtendedAt: null,
  currentOrganizationId: null,
  intercomHmac: '',
  nameToSignatureRequired: false,
};

const defaultState = {
  data: null,
  hasAuthCreds: false,
  patientAuthenticated: false,
  guestAuthenticated: false,
  isLoading: false,
  isGuestLoading: false,
  isVerifyingLoading: false,
  receiver_name: '',
  error: false,
  message: '',
  accessCodeErrorMessage: '',
  createAccountErrorMessage: '',
  resetPasswordMessage: '',
  guest: false,
  createGuest: false,
  guestAccess: '',
  patientRecordUrl: '',
  sendToken: '',
  otp_auth: {
    otp_auth_token: null, // add store prop to update the auth token
    email: null,
  },
  patient: false,
  accessedPatient: false,
  accessedResetPassword: false,
  patientErrorMessage: '',
  security: {
    auth_token: null,
    recipient: null,
    question: null,
    file_token: null,
  },
  resetPasswordToken: null,
  trialUser: false,
  departments: [],
  documentCategories: [],
  referralManagement: false,
  referredStatuses: [],
  emaTaskTemplates: [],
  emaTaskProviders: [],
  fax: {
    fax_code: '',
    access_code: '',
    source: 'web',
  },
  hasResponse: false,
  subject: '',
  recipientName: '',
  hasCompletedWalkthrough: false,
  userCanSendFaxRecordsOnly: false,
  signatureRequired: false,
  nameToSignatureRequired: false,
  shouldFlatten: false,
  disableSecureReplies: false,
  provider: 'microsoft_graph_auth',
  sentViaEmail: true,
  stepOnWalkthrough: 'Welcome to Medsender!',
  organizationDetails: {
    multiple_organization_login_enabled: false,
    show_multiple_organization_login_modal: false,
    organizations: [],
    current_organization: '',
  },
  currentOrganizationId: null,
  intercomHmac: '',
};

function constructUserInitials(data) {
  // If their name is not set, default to AU ~ Anonymous User
  if (!data.name) {
    return 'AU';
  }
  const parsedName = parseFullName(data.name);
  const firstName = parsedName.first || '';
  const lastName = parsedName.last || '';

  // If both first and last name are empty, return AU
  if (!firstName && !lastName) {
    return 'AU';
  }

  // If only first name exists, use first two letters of first name
  if (firstName && !lastName) {
    return firstName.substring(0, 2).toUpperCase();
  }

  // If only last name exists, use first two letters of last name
  if (!firstName && lastName) {
    return lastName.substring(0, 2).toUpperCase();
  }

  // If both exist, use first letter of each
  return (firstName[0] + lastName[0]).toUpperCase();
}

function userUpdate(state = initialState, action) {
  switch (action.type) {
    case USER_AUTH.LOGGING_IN:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
        hasCompletedWalkthrough: false,
        stepOnWalkthrough: 'Welcome to Medsender!',
      };
    case USER_AUTH.LOGGED_IN: {
      const userInitials = constructUserInitials(action.payload);
      const {
        trialUser,
        departments,
        documentCategories,
        referralManagement,
        referredStatuses,
        emaTaskTemplates,
        emaTaskProviders,
        billing,
        userCanSendFaxRecordsOnly,
        planType,
        provider,
        organizationDetails,
        currentOrganizationId,
        intercomHmac,
      } = action.payload;
      return {
        ...state,
        hasAuthCreds: true,
        needs2FA: true,
        trialUser,
        departments,
        documentCategories,
        referralManagement,
        referredStatuses,
        emaTaskTemplates,
        emaTaskProviders,
        billing,
        currentOrganizationId,
        intercomHmac,
        data: {
          uid: action.payload.uid,
          name: userInitials,
          fullName: action.payload.name,
          emr: action.payload.emr || '',
          referralManagement: action.payload.referralManagement,
          autoUploadEmr: action.payload.autoUploadEmr,
          documentCategories: action.payload.documentCategories,
          referredCategories: action.payload.referredCategories,
          organization: action.payload.organization_name,
          jobTitle: action.payload.jobTitle || '',
          defaultFaxNumber: action.payload.defaultFaxNumber,
          associatedFaxNumbers: action.payload.associatedFaxNumbers,
        },
        isLoading: false,
        error: false,
        message: '',
        isAdmin: action.payload.isAdmin,
        hasCompletedWalkthrough: JSON.parse(localStorage.getItem('m8_has_completed_walkthrough')) || false,
        userCanSendFaxRecordsOnly,
        planType,
        provider,
        organizationDetails,
        sessionExtendedAt: Date.now(),
      };
    }
    case USER_AUTH.LOGGED_OUT:
      return {
        data: null,
        isLoading: false,
        hasAuthCreds: false,
        error: false,
        fa,
        sessionExtendedAt: null,
      };
    case USER_AUTH.LOGOUT_FAILED:
      return defaultState;
    case USER_AUTH.LOGIN_FAILURE:
      return {
        ...defaultState,
        message: action.payload.error,
        error: true,
      };
    case USER_AUTH.INVALID: {
      // On hard refresh, if no authentication was detected and the session
      // previously timed out, the app will set an m8_error message before
      // initiating the hard refresh. After the hard refresh is complete, we
      // can display to the user that the session timed out
      if (localStorage.getItem('m8_error') !== null) {
        const errorMsg = localStorage.getItem('m8_error');
        localStorage.removeItem('m8_error');
        return {
          ...defaultState,
          message: errorMsg,
          error: true,
        };
      }
      if (action.payload && action.payload.error) {
        return {
          ...defaultState,
          message: action.payload.error,
          error: true,
        };
      }
      return defaultState;
    }
    case USER_AUTH.VALID: {
      const userInitials = constructUserInitials(action.payload);
      const {
        uid,
        trialUser,
        departments,
        documentCategories,
        referralManagement,
        referredStatuses,
        emaTaskTemplates,
        emaTaskProviders,
        billing,
        isAdmin,
        hasCompletedWalkthrough,
        userCanSendFaxRecordsOnly,
        planType,
        provider,
      } = action.payload;

      return {
        ...state,
        data: {
          uid,
          name: userInitials,
          fullName: action.payload.name,
          emr: action.payload.emr,
          referralManagement: action.payload.referralManagement,
          jobTitle: action.payload.jobTitle || '',
          organization: action.payload.organization,
          autoUploadEmr: action.payload.autoUploadEmr,
          documentCategories: action.payload.documentCategories,
          referredStatuses: action.payload.referredStatuses,
          defaultFaxNumber: action.payload.defaultFaxNumber,
          associatedFaxNumbers: action.payload.associatedFaxNumbers,
        },
        trialUser,
        billing,
        departments,
        documentCategories,
        referralManagement,
        referredStatuses,
        emaTaskTemplates,
        emaTaskProviders,
        isAdmin,
        hasAuthCreds: true,
        hasCompletedWalkthrough,
        userCanSendFaxRecordsOnly,
        isLoading: false,
        error: false,
        planType,
        provider,
        stepOnWalkthrough: state.stepOnWalkthrough,
      };
    }
    case '@@router/LOCATION_CHANGE':
      if (action.payload.pathname === '/app/access') {
        return {
          ...state,
          guest: true,
        };
      } if (action.payload.pathname === '/app/access/sign_up') {
        return { ...state, createGuest: true };
      } if (action.payload.pathname.includes('/app/patient') && state.data !== null) {
        // If a user was logged in and they tried to access patient portal, set state so dialog
        // pops up asking the user to log out in order to access patient portal.
        return {
          ...state,
          accessedPatient: true,
        };
      } if (action.payload.pathname === '/app/patient') {
        return {
          ...state,
          patient: true,
        };
      } if (action.payload.pathname === '/reset_password' && state.hasAuthCreds) {
        // If the user clicks on the reset password link and the user was logged in
        // A dialogue should pop up asking the user to log out before the password reset
        return {
          ...state,
          accessedResetPassword: true,
        };
      } return {
        ...state,
        guest: false,
        guestCreateAccount: false,
        guestAccess: '',
        guestAuthenticated: false,
        resetPasswordMessage: '',
        changePasswordMessage: '',
        changePasswordStatus: null,
      };
    case PATIENT_AUTH.PATIENT_LOGGING_IN:
      return {
        ...state,
        isLoading: true,
        patient: true,
        error: false,
        message: '',
      };
    case PATIENT_AUTH.PATIENT_LOGGED_IN: {
      return {
        ...state,
        isLoading: false,
        patientRecordUrl: action.payload.url,
        security: {
          auth_token: action.payload.auth_token,
          question: action.payload.security_question,
          security_token: action.payload.security_token,
          recipient: action.payload.recipient,
        },
        hasResponse: action.payload.hasResponse,
        sendToken: action.payload.sendToken,
        recipient: action.payload.recipient,
        recipientName: action.payload.recipientName,
        error: false,
        patientErrorMessage: '',
        patientAuthenticated: true,
        signatureRequired: action.payload.signatureRequired,
        nameToSignatureRequired: action.payload.nameToSignatureRequired,
        shouldFlatten: action.payload.should_flatten,
        disableSecureReplies: action.payload.disableSecureReplies,
      };
    }
    case PATIENT_AUTH.PATIENT_LOGGED_OUT:
      return {
        data: null,
        isLoading: false,
        patient: false,
        error: false,
        patientAuthenticated: true,
      };
    case PATIENT_AUTH.PATIENT_LOGOUT_FAILED:
      return defaultState;
    case PATIENT_AUTH.PATIENT_LOGIN_FAILURE:
      return {
        ...defaultState,
        patientErrorMessage: action.payload.error,
        error: true,
        patient: true,
      };
    case PATIENT_AUTH.RESET_PATIENT_ACCESS_ATTEMPT:
      return {
        ...state,
        accessedPatient: false,
      };
    case SECURITY_QUESTION.PATIENT_REDIRECT:
      return {
        ...defaultState,
        patient: true,
      };
    case ACCESS_CODE_AUTH.BEGIN:
      return {
        ...state,
        isGuestLoading: true,
      };
    case ACCESS_CODE_AUTH.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isGuestLoading: false,
        guestAccess: action.payload.url,
        fax: action.payload.fax,
        sendToken: action.payload.sendToken,
        hasResponse: action.payload.hasResponse,
        subject: action.payload.subject,
        recipientName: action.payload.recipientName,
        signatureRequired: action.payload.signatureRequired,
        shouldFlatten: action.payload.shouldFlatten,
        error: false,
      };
    case ACCESS_CODE_LOGOUT.LOGOUT_SUCCESS:
      return {
        ...defaultState,
        isGuestLoading: false,
      };
    case ACCESS_CODE_AUTH.FAILURE:
      return {
        ...defaultState,
        isGuestLoading: false,
        accessCodeErrorMessage: action.payload.error,
        error: true,
      };
    case VERIFY_GUEST_TOKEN.BEGIN:
      return {
        ...state,
        guest: false,
        isVerifyingLoading: true,
      };
    case VERIFY_GUEST_TOKEN.SUCCESS:
      return {
        ...state,
        isVerifyingLoading: false,
        guestAuthenticated: true,
        receiver_name: action.payload.receiver_name,
      };
    case VERIFY_GUEST_TOKEN.FAILURE:
      return {
        ...defaultState,
        isVerifyingLoading: false,
        guestAuthenticated: false,
        accessCodeErrorMessage: action.payload.error,
        error: true,
      };
    case CREATE_GUEST_ACCOUNT.BEGIN:
      return {
        ...state,
        isVerifyingLoading: true,
      };
    case CREATE_GUEST_ACCOUNT.FAILURE:
      return {
        ...state,
        isVerifyingLoading: false,
        createAccountErrorMessage: action.payload.error,
      };
    case USER_PROFILE.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordMessage: action.payload.message,
        changePasswordStatus: action.payload.success,
      };
    case USER_PROFILE.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordMessage: action.payload.message,
        changePasswordStatus: action.payload.success,
      };
    // will show the loading page during the resend otp code request
    case RESEND_OTP.RESEND_OTP_START:
      return {
        ...state,
        isLoading: true,
        sentViaEmail: action.payload.sentViaEmail,
      };
    // update the store with the new otp auth token and the user's email
    case RESEND_OTP.RESEND_OTP_FULFILLED:
      return {
        ...defaultState,
        sentViaEmail: action.payload.sentViaEmail,
      };
    // Enables redirecting to the login page and clears global errors
    case RESEND_OTP.REDIRECT:
      return defaultState;
    // update the store with the error returned by the backend
    case RESEND_OTP.RESEND_OTP_FAILED:
      return {
        ...defaultState,
        message: action.payload.error,
        error: true,
        sentViaEmail: action.payload.sentViaEmail,
      };
    case FORGOT_PASSWORD.BEGIN:
      return state;
    case FORGOT_PASSWORD.FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.data.error,
        resetPasswordMessage: '',
      };
    case FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        resetPasswordMessage: action.payload.message,
        error: false,
        errorMessage: '',
      };
    case RESET_PASSWORD.BEGIN:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      };
    case RESET_PASSWORD.SUCCESS: {
      const userInitials = constructUserInitials(action.payload);
      const {
        uid, trialUser, departments, documentCategories, referredStatuses, emaTaskTemplates, emaTaskProviders, billing, organizationDetails, currentOrganizationId, referralManagement, intercomHmac,
      } = action.payload;
      return {
        ...state,
        hasAuthCreds: true,
        isLoading: false,
        error: false,
        message: '',
        billing,
        trialUser,
        departments,
        documentCategories,
        referralManagement,
        referredStatuses,
        emaTaskTemplates,
        emaTaskProviders,
        currentOrganizationId,
        intercomHmac,
        data: {
          uid,
          name: userInitials,
          fullName: action.payload.name,
          organization: action.payload.organization,
          autoUploadEmr: action.payload.autoUploadEmr,
          documentCategories: action.payload.documentCategories,
          referredStatuses: action.payload.referredStatuses,
          emr: action.payload.emr,
          referralManagement: action.payload.referralManagement,
          jobTitle: action.payload.jobTitle,
          defaultFaxNumber: action.payload.defaultFaxNumber,
          associatedFaxNumbers: action.payload.associatedFaxNumbers,
        },
        isAdmin: action.payload.isAdmin,
        hasCompletedWalkthrough: action.payload.hasCompletedWalkthrough,
        organizationDetails,
      };
    }
    case RESET_PASSWORD.FAILED:
      return {
        ...state,
        isLoading: false,
        error: true,
        message: action.payload.message,
        resetPasswordToken: action.payload.resetPasswordToken,
      };
    case VALIDATE_RESET_TOKEN.BEGIN:
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      };
    case VALIDATE_RESET_TOKEN.FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        isLoading: false,
      };
    case VALIDATE_RESET_TOKEN.SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        resetPasswordEmail: action.payload.resetPasswordEmail,
        resetPasswordToken: action.payload.resetPasswordToken,
        passwordSet: action.payload.passwordSet,
      };
    case EXTEND_USER_SESSION.FAILED:
      return {
        ...state,
        sessionExtendedAt: null,
      };
    case EXTEND_USER_SESSION.SUCCESS:
      return {
        ...state,
        sessionExtendedAt: Date.now(),
      };
    case 'BEGIN_PRINTER_SIGN_IN':
      return {
        ...state,
        error: false,
      };
    case 'FAILED_PRINTER_SIGN_IN':
      return {
        ...state,
        error: true,
      };
    case 'FINISHED_PRINTER_SIGN_IN':
      return {
        ...state,
        gibraltarSignInToken: action.payload.data.token,
      };
    case 'BEGIN_GET_LOGIN_INFO':
      return {
        ...state,
        isLoading: true,
        error: false,
        message: '',
      };
    case 'BEGIN_ADD_SUBSCRIPTION':
      return {
        ...state,
        error: false,
        // allow them to use the app if backend is taking a while to return response.
        billing: {
          billing_on_file: false,
          trial_end_date: 'none',
          trial_days_remaining: 0,
        },
      };
    case 'FAILED_ADD_SUBSCRIPTION':
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        isLoading: false,
        // Bring back prompt since adding subscription.
        billing: {
          billing_on_file: false,
          trial_end_date: 'failed',
          trial_days_remaining: 0,
        },
      };
    case 'FINISHED_ADD_SUBSCRIPTION':
      localStorage.setItem('m8_billing_status', JSON.stringify(action.payload.data));
      return {
        ...state,
        billing: action.payload.data,
      };
    case 'SWITCH_AUTH':
      return {
        ...state,
        hasAuthCreds: true,
        data: {
          uid: action.payload.user.email,
          name: constructUserInitials(action.payload.user),
          fullName: action.payload.name,
          emr: action.payload.user.emr,
          referralManagement: action.payload.user.referralManagement,
        },
        isLoading: false,
        error: false,
        message: '',
      };
    case 'DISABLE_REPLYING':
      return {
        ...state,
        hasResponse: true,
      };
    case 'UPDATE_ASSOCIATED_FAX_NUMBERS':
      return {
        ...state,
        data: {
          ...state.data,
          associatedFaxNumbers: [...state.data.associatedFaxNumbers, [action.payload.number, action.payload.organization]],
        },
      };
    case 'UPDATE_STEP_ON_WALKTHROUGH':
      return {
        ...state,
        stepOnWalkthrough: action.payload.stepOnWalkthrough,
      };
    case 'FINISHED_UPDATE_USER_DETAILS':
      const hasCompletedWalkthrough = action.payload.data.has_completed_walkthrough || false;
      localStorage.setItem('m8_has_completed_walkthrough', hasCompletedWalkthrough);
      return {
        ...state,
        hasCompletedWalkthrough,
      };
    case 'BEGIN_CHECKING_MULTI_ORG_LOGIN':
      return {
        ...state,
        isLoading: true,
      };
    case 'FAILED_CHECKING_MULTI_ORG_LOGIN':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case 'FINISHED_CHECKING_MULTI_ORG_LOGIN':
      return {
        ...state,
        isLoading: false,
        organizationDetails: action.payload.data.user.organization_details,
        currentOrganizationId: action.payload.data.user.current_organization_id,
        intercomHmac: action.payload.data.user.intercom_hmac,
      };
    default:
      return {
        ...state,
      };
  }
}

export default userUpdate;
export {
  hasAuthCreds,
  validateAuth,
};
