import { Modal } from 'antd';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import isTouchDevice from '../../../util/helpers/isTouchDevice';
import '../styles/draggableModal';

const DraggableModal = ({ children, ...props }) => {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x - targetRect.width / 2,
      right: clientWidth - (targetRect.right - uiData.x) + targetRect.width / 2,
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y) + targetRect.height / 2,
    });
  };

  return (
    <Modal
      {...props}
      className="draggable-modal"
      mask={false}
      bodyStyle={{ padding: '24px 24px 0' }}
      modalRender={modal => (
        <Draggable
          handle={props.handleClass}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          onMouseDown={() => null}
          onTouchStart={() => null}
          onTouchEnd={() => null}
          disabled={isTouchDevice()}
          enableUserSelectHack={false}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}

    >
      {children}
    </Modal>
  );
};

DraggableModal.defaultProps = {
  children: '',
  title: '',
  handleClass: '.ant-modal-header',
};

DraggableModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  title: PropTypes.string,
  handleClass: PropTypes.string,
};


export default DraggableModal;
