import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import './link.scss';

const Link = ({ ...props }) => {
  const {
    bar,
    inline,
    size = 'large',
    label,
    leftIcon,
    rightIcon,
    className,
    internal,
    href,
    ...inputProps
  } = props;


  const linkContent = (
    <Button className={size} type="link" href={href} {...inputProps}>
      <div className="link-body">
        {leftIcon && <div className={`link-icon ${size}`}>{leftIcon}</div>}
        {label}
        {rightIcon && <div className={`link-icon ${size}`}>{rightIcon}</div>}
      </div>
      {bar && <div className="bar" />}
    </Button>
  );

  return (
    <div className={classNames('link', className, { inline })}>
      {
        internal ? (
          <RouterLink to={href} {...inputProps}>
            {linkContent}
          </RouterLink>
        ) : (
          linkContent
        )
      }
    </div>
  );
};

Link.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  bar: PropTypes.bool,
  internal: PropTypes.bool,
  href: PropTypes.string.isRequired,
};

Link.defaultProps = {
  size: 'large',
  leftIcon: null,
  rightIcon: null,
  disabled: false,
  inline: false,
  bar: false,
  internal: false,
};

export default Link;
