/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import {
  message,
  DatePicker,
  Input,
  Radio,
  Form,
  Select,
  Checkbox,
} from 'antd';

import {
  Modal
} from 'antd';

const { confirm } = Modal;

import { validPhoneNumber, validZipCode, validSendEmail } from '../../global/validation';
import ModalWrapper from './ModalWrapper';
import CircularProgress from 'material-ui/CircularProgress';

class CreateNewChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: props.modalType.data.fileName,
      sendToken: props.modalType.data.record,
      documentCategory: props.modalType.data.documentCategory || '',
      documentCategories: [],
      emaApiPatients: [],
      genderOptions: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
      ],
      patientName: this.props.modalType.data.name,
      patientDOB: this.props.modalType.data.dob,
      patientGender: this.props.modalType.data.gender?.toLowerCase(),
      patientPhoneNumber: this.props.modalType.data.phoneNumber || '',
      receiverType: this.props.modalType.data.receiverType,
      patientZipCode: this.props.modalType.data.zipCode || '',
      patientCity: this.props.modalType.data.city || '',
      practitionerName: this.props.modalType.data.practitionerName || '',
      patientAddress: this.props.modalType.data.address || '',
      patientState: this.props.modalType.data.state || '',
      patientEmail: this.props.modalType.data.patient_email || '',
      referringProvider: this.props.modalType.data.referring_provider || '',
      providerOffice: this.props.modalType.data.provider_office || '',
      primaryPlan: this.props.modalType.data.payerName || '',
      primaryMemberId: this.props.modalType.data.primaryMemberId || '',
      apiCredentials: this.props.modalType.data.apiCredentials,
      patientFirstName: this.props.modalType.data.patient_first_name || '',
      patientMiddleName: this.props.modalType.data.patient_middle_name || '',
      patientLastName: this.props.modalType.data.patient_last_name || '',
      secondaryPhone: '',
      practiceName: '',
      searchedPrimaryPayerNames: [],
      populateInsurance: localStorage.populateInsurance === 'true',
      secondaryInsurance: false,
      primaryPolicyType: '',
      policyTypeOptions: [],
      searchedPrimaryPolicyTypes: [],
      primaryGroupNumber: this.props.modalType.data.groupNumber || '',
      secondaryPlan: this.props.modalType.data.secondaryPayerName || '',
      secondaryMemberId: this.props.modalType.data.secondaryMemberId || '',
      secondaryGroupNumber: this.props.modalType.data.secondaryGroupNumber || '',
      secondaryPolicyType: '',
      searchedSecondaryPayerNames: [],
      searchedSecondaryPolicyTypes: [],
      insuranceEffectiveDate: '',
      searchedPolicyTypes: [],
      groupNumber: this.props.modalType.data.groupNumber || '',
      apiPatientFound: false,
      isInitialLoadComplete: false,
    };
    if (
      !this.state.fileName &&
      this.props.selectedRecord[0] &&
      this.props.selectedRecord[0].document_title
    ) {
      this.state.fileName = this.props.selectedRecord[0].document_title;
    } else if (this.props.modalType.data.fileName) {
      this.state.fileName = this.props.modalType.data.fileName;
    } else {
      this.state.fileName = '';
    }

    const policyTypes = [
      'EPO',
      'Group Health Plan (GHP)',
      'HMO',
      'IPA',
      'Medicare Advantage',
      'PPO',
      'POS',
      'Other',
      'Commercial - Other',
      'ACA Exchange',
      'CHAMPVA',
      'CHIP',
      'FECA (INC BLK LUNG)',
      'Medicare',
      'Medicaid',
      'Tricare',
      'Government - Other',
    ];

    // Sort the policyTypes array alphabetically
    const sortedPolicyTypes = policyTypes.sort((a, b) => a.localeCompare(b));

    this.state.policyTypeOptions = sortedPolicyTypes;
    this.state.searchedPrimaryPolicyTypes = sortedPolicyTypes;
    this.state.searchedSecondaryPolicyTypes = sortedPolicyTypes;

    this.formRef = React.createRef();
  }

  getEmaApiPatients = async () => {
    this.setState({
      emaApiPatients: [],
      apiPatientFound: false,
    });

    if (this.state.patientFirstName && this.state.patientLastName && this.state.patientDOB) {
      const query = `?name=${encodeURIComponent(this.state.patientFirstName)} ${encodeURIComponent(this.state.patientLastName)}&dob=${encodeURIComponent(this.state.patientDOB)}`;
      await this.props.getEmaPatients(query);
      if (this.props.emaError) {
        this.setState({
          apiPatientFound: false,
        });
        return;
      }
      this.setState({
        emaApiPatients: this.props.emaPatients.patients,
        apiPatientFound: this.props.emaPatients.patients.length > 0,
      });
    }
  }

  componentDidMount = async () => {
    const {
      getPayerNames,
      getDocumentCategories,
      modalType: {
        data: { callerName, receivedAt },
      },
    } = this.props;
    const { primaryPlan, secondaryPlan } = this.state;
    const autoUploadEmr = localStorage.m8_auto_upload_emr;

    await getDocumentCategories();
    await getPayerNames();

    // Filter payerNames based on the default primaryPlan
    if (primaryPlan) {
      const filteredPrimary = this.props.allPayerNames.filter(payer => 
        payer.toLowerCase().includes(primaryPlan.toLowerCase())
      );
      if (filteredPrimary.length === 1) {
        this.setState({ primaryPlan: filteredPrimary[0] });
      } else if (filteredPrimary.length > 0) {
        this.setState({ searchedPrimaryPayerNames: filteredPrimary, searchedPrimaryPayerNames: this.props.allPayerNames });
      } else {
        this.setState({ searchedPrimaryPayerNames: this.props.allPayerNames });
      }
    } else {
      this.setState({ searchedPrimaryPayerNames: this.props.allPayerNames });
    }

    if (secondaryPlan) {
      const filteredSecondary = this.props.allPayerNames.filter(payer => 
        payer.toLowerCase().includes(secondaryPlan.toLowerCase())
      );
      if (filteredSecondary.length === 1) {
        this.setState({ secondaryPlan: filteredSecondary[0], searchedSecondaryPayerNames: this.props.allPayerNames });
      } else if (filteredSecondary.length > 0) {
        this.setState({ searchedSecondaryPayerNames: filteredSecondary });
      } else {
        this.setState({ searchedSecondaryPayerNames: this.props.allPayerNames });
      }
    } else {
      this.setState({ searchedSecondaryPayerNames: this.props.allPayerNames });
    }

    if (this.state.populateInsurance && (this.state.secondaryPlan || this.state.secondaryMemberId || this.state.secondaryGroupNumber)) {
      this.setState({ secondaryInsurance: true });
    }

    // Set initial policy type to Commercial - Other if there are payer names and populateInsurance is checked
    if (this.state.populateInsurance && this.props.allPayerNames && this.props.allPayerNames.length > 0 && autoUploadEmr === 'EMA' ) {
      this.setState({ primaryPolicyType: 'Commercial - Other', secondaryPolicyType: 'Commercial - Other' });
    }

    const org = localStorage.m8_org;
    // Frontier Dermatology does not want to below filename format
    if (!this.state.fileName && org !== 'Frontier Dermatology') {
      const receivedAtVal = receivedAt || this.props.receivedAt;
      const callerNameVal = callerName || this.props.callerName;
      this.setState({ fileName: (receivedAtVal && callerNameVal) ? `${receivedAtVal.replace(/ /g, '_')}_${callerNameVal.replace(/ /g, '_')}` : '' });
    }
    this.setState({
      documentCategories: this.props.allDocumentCategories.categories,
    });

    const { patientName, patientFirstName, patientLastName } = this.state;
    if (autoUploadEmr === 'EMA' && patientName && patientFirstName === '' && patientLastName === '') {
      // Handle edge case where the patient_name has 2 surnames (this is common for Spanish names)
      if (
        patientName.includes(',') &&
        patientName.split(',')[0].trim().includes(' ') &&
        patientName.split(',')[0].trim().split(' ').length > 1
      ) {
        const parsedNames = this.parseName(patientName);
        this.setState({
          patientFirstName: parsedNames.firstName,
          patientLastName: parsedNames.lastName,
        });
      } else {
        this.setState({
          patientFirstName: parseFullName(patientName).first,
          patientMiddleName: parseFullName(patientName).middle,
          patientLastName: parseFullName(patientName).last,
        });
      }
    }
    const orgName = localStorage.m8_org;
    if (orgName === 'Sleeptopia') {
      this.setState({ practiceName: 'Sleeptopia Inc' });
    }

    setTimeout(() => {
      if (this.formRef.current) {
        const fieldsToValidate = [
          'primaryGroupNumber', 
          'secondaryGroupNumber', 
          'primaryMemberId', 
          'secondaryMemberId'
        ].filter(field => {
          const fieldValue = this.state[field];
          return fieldValue && fieldValue.trim() !== '';
        });

        if (fieldsToValidate.length > 0) {
          this.formRef.current.validateFields(fieldsToValidate);
        }
      }
    }, 0);

    // If user has EMA API credentials, get patients from EMA API
    if (this.state.apiCredentials) {
      await this.getEmaApiPatients();
    }
    this.setState({
      isInitialLoadComplete: true,
    });
  };

  triggerChartCreation = async (formattedDOB) => {
    const { hideModal, triggerAutoUpload, allPayerNames } = this.props;
    const {
      sendToken,
      fileName,
      documentCategory,
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      secondaryPhone,
      patientPhoneNumber,
      patientGender,
      patientAddress,
      patientZipCode,
      patientCity,
      practitionerName,
      receiverType,
      patientEmail,
      patientState,
      referringProvider,
      providerOffice,
      primaryMemberId,
      primaryPlan,
      practiceName,
      primaryPolicyType,
      primaryGroupNumber,
      secondaryInsurance,
      secondaryPlan,
      secondaryMemberId,
      secondaryGroupNumber,
    } = this.state;

    const updateState = () => {
      return new Promise(resolve => {
        if (!secondaryInsurance) {
          if (allPayerNames.includes(secondaryPlan) && secondaryGroupNumber && secondaryMemberId) {
            resolve();
          } else {
            this.setState({
              secondaryPlan: '',
              secondaryMemberId: '',
              secondaryGroupNumber: '',
              secondaryPolicyType: ''
            }, resolve);
          }
        } else {
          resolve();
        }
      });
    };

    await updateState();

    await triggerAutoUpload({
      sendToken,
      receiverType,
      documentCategory,
      fileName,
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      secondaryPhone,
      patientDOB: formattedDOB,
      patientPhoneNumber,
      patientGender,
      patientAddress,
      patientZipCode,
      patientCity,
      practitionerName,
      chartCreationEnabled: true,
      patientEmail,
      patientState,
      referringProvider,
      providerOffice,
      primaryMemberId,
      primaryPlan,
      practiceName,
      primaryPolicyType,
      primaryGroupNumber,
      secondaryPlan: this.state.secondaryPlan,
      secondaryMemberId: this.state.secondaryMemberId,
      secondaryPolicyType: this.state.secondaryPolicyType,
      secondaryGroupNumber: this.state.secondaryGroupNumber,
    });
    const { error, errorMessage } = this.props;
    hideModal();
    if (!error) {
      message.success({
        content: 'Patient chart will be created shortly. We will notify you of any issues via email.',
      });
    } else {
      message.error({
        content: errorMessage,
      });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // If user has EMA API credentials, get patients from EMA API
    if (this.state.apiCredentials) {
      await this.getEmaApiPatients();
    }
    const {
      patientDOB,
      apiPatientFound,
    } = this.state;
    if (apiPatientFound) {
      confirm({
        title: 'Multiple Patients Found!',
        content: 'It looks like this patient already exists in EMA? Are you sure you\'d like to proceed?',
        onOk: async () => {
          const splitDate = patientDOB.split('/');
          const formattedDOB = `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`;
          await this.triggerChartCreation(formattedDOB);
          return;
        },
        onCancel() {
          return;
        },
      });
    } else {
      const splitDate = patientDOB.split('/');
      const formattedDOB = `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`;
      await this.triggerChartCreation(formattedDOB);
    }
  };

  parseName(name) {
    let firstName = '';
    let lastName = '';
    const parts = name.split(',');
    lastName = parts[0].trim();
    firstName = parts[1].trim();

    // Handle edge cases for Spanish names with multiple surnames
    if (lastName.split(' ').length > 1 && !lastName.includes('-')) {
      const lastNames = lastName.split(' ');
      lastName = `${lastNames[0]} ${lastNames[1]}`;
    }

    return { firstName, lastName };
  }

  render() {
    const {
      modalType: {
        data: { name },
      },
      isLoading,
      hideModal,
      allPayerNames,
    } = this.props;
    const { genderOptions } = this.state;
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    const autoUploadEmr = localStorage.getItem('m8_auto_upload_emr');
    const isNymbl = autoUploadEmr === 'Nymbl';
    const isAdvancedMD = autoUploadEmr === 'AdvancedMD';
    const isEMA = autoUploadEmr === 'EMA';
    const customContentStyle = {
      width: '340px',
    };
    const {
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      secondaryPhone,
      patientDOB,
      patientPhoneNumber,
      patientGender,
      patientAddress,
      patientZipCode,
      patientCity,
      practitionerName,
      documentCategory,
      documentCategories,
      fileName,
      patientEmail,
      patientState,
      referringProvider,
      providerOffice,
      primaryMemberId,
      primaryPlan,
      practiceName,
      primaryPolicyType,
      primaryGroupNumber,
      searchedPrimaryPayerNames,
      secondaryPolicyType,
      secondaryPlan,
      secondaryMemberId,
      secondaryGroupNumber,
      searchedSecondaryPayerNames,
      populateInsurance,
      secondaryInsurance,
      insuranceEffectiveDate,
      isInitialLoadComplete,
    } = this.state;

    const validatePhoneNumber = (rule, value) => {
      if (!value) {
        return Promise.reject('Please input your phone number!');
      }
      if (!validPhoneNumber(value)) {
        return Promise.reject('Please enter a valid phone number!');
      }
      return Promise.resolve();
    };

    const validateZipCode = (rule, value) => {
      if (!value) {
        return Promise.reject('Please input a zip code!');
      }
      if (!validZipCode(value)) {
        return Promise.reject('Please enter a valid zip code!');
      }
      return Promise.resolve();
    };

    const handleFieldChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value }, () => {
        // Check if secondary insurance is populated
        if (this.state.secondaryGroupNumber || this.state.secondaryMemberId) {
          this.setState({ secondaryInsurance: true });
        }
        if (!this.state.secondaryGroupNumber && !this.state.secondaryMemberId && !this.state.secondaryPlan) {
          this.setState({ secondaryInsurance: false });
        }
      });
    };

    /**
     * @param {String} dateString format MM/DD/YYYY
     */
    const handleDateChange = (_, dateString) => {
      this.setState({ patientDOB: dateString });
    };

    /**
     * @param {String} dateString format MM/DD/YYYY
     */
    const handleEffectiveDateChange = (_, dateString) => {
      this.setState({ insuranceEffectiveDate: dateString });
    };

    const handlePayerNameChange = (type) => (e) => {
      if (type === 'primary') {
        this.setState({ primaryPlan: e });
      } else {
        this.setState({ secondaryPlan: e });
      }
      if (type === 'secondary' && e) {
        this.setState({ secondaryInsurance: true });
      } else {
        this.setState({ secondaryInsurance: false });
      }
    };

    const handlePrimaryPayerNameSearch = (e) => {
      if (e) {
        const filtered = allPayerNames.filter(payer => payer.toLowerCase().includes(e.toLowerCase()));
        this.setState({ searchedPrimaryPayerNames: filtered }); 
      } else {
        this.setState({ searchedPrimaryPayerNames: allPayerNames });
      }
    };

    const handleSecondaryPayerNameSearch = (e) => {
      if (e) {
        const filtered = allPayerNames.filter(payer => payer.toLowerCase().includes(e.toLowerCase()));
        this.setState({ searchedSecondaryPayerNames: filtered }); 
      } else {
        this.setState({ searchedSecondaryPayerNames: allPayerNames });
      }
    };

    const handlePolicyTypeChange = (type) => (e) => {
      if (type === 'primary') {
        this.setState({ primaryPolicyType: e });
      } else {
        this.setState({ secondaryPolicyType: e });
      }
    };

    const handlePopulateInsurance = async (e) => {
      if (e.target.checked) {
        localStorage.setItem('populateInsurance', 'true');
        // Set policy type to Commercial - Other if populateInsurance is checked
        this.setState({ 
          primaryPolicyType: 'Commercial - Other',
          secondaryPolicyType: 'Commercial - Other',
          populateInsurance: true
        }, () => {
          requestAnimationFrame(() => {
            const insuranceSection = document.querySelector('[name="primaryMemberId"]');
            if (insuranceSection) {
              insuranceSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
        });
      } else {
        localStorage.removeItem('populateInsurance');
        // Clear insurance fields if populateInsurance is unchecked
        this.setState({ 
          primaryPolicyType: '',
          primaryGroupNumber: '',
          primaryPlan: '',
          primaryMemberId: '',
          secondaryPolicyType: '',
          secondaryGroupNumber: '',
          secondaryPlan: '',
          secondaryMemberId: '',
          insuranceEffectiveDate: '',
          populateInsurance: false
        });
      }
    }

    const disableSubmitButton = () => {
      if (isEMA) {
        // Basic validations
        const basicValidationsFailed =
          patientFirstName === '' ||
          patientLastName === '' ||
          patientDOB === null ||
          patientDOB === '' ||
          patientGender === null ||
          patientGender === '' ||
          (patientPhoneNumber && !validPhoneNumber(patientPhoneNumber)) ||
          !documentCategory;

        // Insurance validations
        const insuranceValidationsFailed = populateInsurance &&
          allPayerNames?.length > 0 && (
            !primaryPlan ||
            !primaryMemberId ||
            !allPayerNames.includes(primaryPlan) ||
            !primaryPolicyType ||
            (primaryMemberId && !/^[a-zA-Z0-9]*$/.test(primaryMemberId)) ||
            (primaryGroupNumber && !/^[a-zA-Z0-9]*$/.test(primaryGroupNumber))
          );

        // Secondary insurance validations
        const secondaryInsuranceValidationsFailed =
          populateInsurance && secondaryInsurance &&(
            !secondaryPlan ||
            !secondaryMemberId ||
            !secondaryPolicyType ||
            !allPayerNames.includes(secondaryPlan) ||
            (secondaryMemberId && !/^[a-zA-Z0-9]*$/.test(secondaryMemberId)) ||
            (secondaryGroupNumber && !/^[a-zA-Z0-9]*$/.test(secondaryGroupNumber))
          );

        return basicValidationsFailed || 
               insuranceValidationsFailed || 
               secondaryInsuranceValidationsFailed;
      }
      if (isAdvancedMD) {
        // Basic validations
        const basicValidationsFailed =
          patientName === '' ||
          patientDOB === null ||
          patientDOB === '' ||
          patientPhoneNumber === '' ||
          !validSendEmail(patientEmail) ||
          !validPhoneNumber(patientPhoneNumber) ||
          patientGender === null ||
          patientAddress === '' ||
          patientZipCode === '' ||
          !validZipCode(patientZipCode) ||
          patientCity === '' ||
          practitionerName === '';

        // Insurance validations
        const insuranceValidationsFailed = populateInsurance &&
          allPayerNames?.length > 0 && (
            !primaryPlan ||
            !primaryMemberId ||
            !allPayerNames.includes(primaryPlan) ||
            insuranceEffectiveDate === null ||
            insuranceEffectiveDate === ''
          );
        
        return basicValidationsFailed || insuranceValidationsFailed;
      }
      if (autoUploadEmr === 'DrChrono') {
        return (
          patientName === null ||
          patientDOB === null ||
          patientDOB === '' ||
          !patientGender
        );
      }
      return (
        patientName === null ||
        patientDOB === null ||
        patientDOB === '' ||
        patientGender === null ||
        patientPhoneNumber === null ||
        !validPhoneNumber(patientPhoneNumber) ||
        (autoUploadEmr === 'EMA' && !documentCategory)
      );
    };

    const noPayerNameMessage = 'Contact Admin to add payers';

    const validateAlphanumeric = (rule, value) => {
      if (!value) {
        return Promise.resolve();
      }
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (!alphanumericRegex.test(value)) {
        return Promise.reject('Only alphanumeric characters are allowed!');
      }
      return Promise.resolve();
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.handleSubmit}
        actionName='Submit'
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={disableSubmitButton()}
        modalTitle='Create New Chart'
        form='createNewChart'
        isDraggable
      >
        {
          isInitialLoadComplete ? (
            <Form
              ref={this.formRef}
              id='createNewChart'
              onFinish={hideModal}
              layout='vertical'
              initialValues={{
                patientDOB: patientDOB && moment(patientDOB, dateFormat),
                fileName,
                populateInsurance,
              }}
            >
              <div>
                {autoUploadEmr !== 'EMA' ? (
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item
                      label='Patient Name'
                      name='form_patient_name'
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: 'Must provide a name',
                        },
                      ]}
                    >
                      <Input
                        placeholder={name ? undefined : 'Enter patient name'}
                        name='patientName'
                        defaultValue={patientName}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label='Patient Date of Birth'
                      name='form_dob'
                      rules={[
                        {
                          required: true,
                          message: 'Must provide a date of birth',
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={handleDateChange}
                        name='patientDOB'
                        format={dateFormat}
                        defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                        value={patientDOB && moment(patientDOB, dateFormat)}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item label='Patient First Name' style={{ flex: 1 }}>
                      <Input
                        placeholder='First name'
                        name='patientFirstName'
                        value={patientFirstName}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item label='Patient Middle Name' style={{ flex: 1 }}>
                      <Input
                        placeholder='Middle name'
                        name='patientMiddleName'
                        value={patientMiddleName}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item label='Patient Last Name' style={{ flex: 1 }}>
                      <Input
                        placeholder='Last name'
                        name='patientLastName'
                        value={patientLastName}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                {autoUploadEmr !== 'DrChrono' && (
                  <Form.Item
                    label={isNymbl ? 'Phone Number (optional)' : 'Phone Number'}
                    name='patientPhoneNumber'
                    rules={[
                      {
                        validator: validatePhoneNumber,
                      },
                    ]}
                    onChange={handleFieldChange}
                    style={{ flex: 0.5 }}
                  >
                    <Input
                      placeholder='Enter phone number'
                      name='patientPhoneNumber'
                      defaultValue={patientPhoneNumber}
                      style={{ flex: 0.5 }}
                    />
                  </Form.Item>
                )}
                {(autoUploadEmr === 'EMA' || autoUploadEmr === 'Kareo') && (
                  <Form.Item label='Document category' style={{ flex: 0.5 }}>
                    <Select
                      showSearch
                      label='Document Category'
                      name='documentCategory'
                      allowClear
                      onChange={(event) =>
                        this.setState({ documentCategory: event })
                      }
                      value={documentCategory}
                      style={{ flex: 0.5 }}
                    >
                      {documentCategories.map((emrCategory) => (
                        <Select.Option
                          key={emrCategory.id}
                          value={emrCategory.emr_category}
                        >
                          {emrCategory.emr_category}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                <div style={{ display: 'flex', gap: '10px' }}>
                  {isEMA && (
                    <Form.Item
                      label='Patient Date of Birth'
                      name='form_dob'
                      rules={[
                        {
                          required: true,
                          message: 'Must provide a date of birth',
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={handleDateChange}
                        name='patientDOB'
                        format={dateFormat}
                        defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                        value={patientDOB && moment(patientDOB, dateFormat)}
                      />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div style={{ flex: 0.5 }}>
                {isEMA && (
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item label='Secondary Phone Number' style={{ flex: 1 }}>
                      <Input
                        placeholder='Secondary Phone Number'
                        name='secondaryPhone'
                        defaultValue={secondaryPhone}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item label='Email Address' style={{ flex: 1 }}>
                      <Input
                        placeholder='Email Address'
                        name='patientEmail'
                        defaultValue={patientEmail}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
              <div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Form.Item
                    label={isNymbl ? 'Gender (optional)' : 'Gender'}
                    style={{ flex: 0.5 }}
                    name='form_gender'
                    rules={[
                      {
                        required: true,
                        message: 'Please select your gender!',
                      },
                    ]}
                  >
                    <Radio.Group
                      options={genderOptions}
                      name='patientGender'
                      onChange={handleFieldChange}
                      defaultValue={patientGender}
                    />
                  </Form.Item>
                </div>
              </div>
              {isAdvancedMD && (
                <>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item
                      label={<span><span style={{ color: 'red', fontSize: '10px' }}>*</span> Email Address</span>}
                      style={{ flex: 0.5 }}
                    >
                      <Input
                        placeholder='Email Address'
                        name='patientEmail'
                        onChange={handleFieldChange}
                        defaultValue={patientEmail}
                      />
                    </Form.Item>
                    <Form.Item label={<span><span style={{ color: 'red', fontSize: '10px' }}>*</span> Patient Address</span>} style={{ flex: 0.5 }}>
                      <Input
                        placeholder='Enter address'
                        name='patientAddress'
                        onChange={handleFieldChange}
                        defaultValue={patientAddress}
                      />
                    </Form.Item>
                    <Form.Item
                      name='patientZipCode'
                      label={<span><span style={{ color: 'red', fontSize: '10px'  }}>*</span> Zip Code</span>}
                      style={{ flex: 0.5 }}
                      rules={[
                        {
                          validator: validateZipCode,
                        },
                      ]}
                    >
                      <Input
                        placeholder='Enter zip code'
                        name='patientZipCode'
                        onChange={handleFieldChange}
                        defaultValue={patientZipCode}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item label={<span><span style={{ color: 'red', fontSize: '10px'  }}>*</span> Patient City </span>} style={{ flex: 0.5 }}>
                      <Input
                        placeholder='Enter city'
                        name='patientCity'
                        onChange={handleFieldChange}
                        defaultValue={patientCity}
                      />
                    </Form.Item>

                    <Form.Item label={<span><span style={{ color: 'red', fontSize: '10px'  }}>*</span> Provider Name</span>} style={{ flex: 0.5 }}>
                      <Input
                        placeholder='Last name, First name'
                        name='practitionerName'
                        onChange={handleFieldChange}
                        defaultValue={practitionerName}
                      />
                    </Form.Item>
                  </div>
                  {allPayerNames && allPayerNames.length > 0 &&
                    <Form.Item
                      name="populateInsurance"
                      valuePropName="checked"
                      onChange={handlePopulateInsurance}
                    >
                      <Checkbox>Populate insurance information</Checkbox>
                    </Form.Item>
                  }
                  {populateInsurance && allPayerNames && allPayerNames.length > 0 &&
                  <>
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Primary Insurance</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item
                        label={<span><span style={{ color: 'red', fontSize: '10px'  }}>*</span> Payer {primaryPlan && !allPayerNames.includes(primaryPlan) && 'Select carrier from the dropdown'}</span>}
                        style={{ flex: '0.7', width: '70%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select the carrier name!',
                          },
                        ]}
                      >
                        {allPayerNames.length > 0 ? (
                          <Select
                            showSearch
                            placeholder="Payer"
                            value={primaryPlan}
                            optionFilterProp="children"
                            allowClear
                            onClear={() => this.setState({
                              primaryPlan: '',
                              primaryMemberId: '',
                              primaryGroupNumber: '',
                              primaryPolicyType: '',
                            })}
                            onChange={handlePayerNameChange('primary')}
                            onSearch={handlePrimaryPayerNameSearch}
                            filterOption={false}
                            style={{ width: '100%' }}
                          >
                            {searchedPrimaryPayerNames.map(e => (
                              <Select.Option key={e} value={e}>
                                {e}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            disabled
                            placeholder={noPayerNameMessage}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={<span><span style={{ color: 'red', fontSize: '10px'  }}>*</span> Subscriber ID</span>}
                        style={{ flex: '0.3', width: '30%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the Subscriber ID!',
                          },
                        ]}
                      >
                        <Input
                          placeholder='Subscriber ID'
                          name='primaryMemberId'
                          value={primaryMemberId}
                          onChange={handleFieldChange}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span><span style={{ color: 'red', fontSize: '10px'  }}>*</span> Effective Date</span>}
                        name='effective_date'
                      >
                        <DatePicker
                          onChange={handleEffectiveDateChange}
                          name='insuranceEffectiveDate'
                          format={dateFormat}
                          value={insuranceEffectiveDate && moment(insuranceEffectiveDate, dateFormat)}
                        />
                    </Form.Item>
                    </div>
                  </>              
                  }
                </>
              )}
              {isEMA && (
                <div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item label='Address' style={{ flex: 1 }}>
                      <Input
                        placeholder='Address'
                        name='patientAddress'
                        defaultValue={patientAddress}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item label='City' style={{ flex: 0.4 }}>
                      <Input
                        placeholder='City'
                        name='patientCity'
                        defaultValue={patientCity}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item label='State' style={{ flex: 0.2 }}>
                      <Input
                        placeholder=''
                        name='patientState'
                        defaultValue={patientState}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item label='Zip' style={{ flex: 0.3 }}>
                      <Input
                        placeholder=''
                        name='patientZipCode'
                        defaultValue={patientZipCode}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Form.Item
                      label="Referring Practice"
                      style={{ flex: 1 }}
                    >
                      <Input
                        placeholder="Referring Practice"
                        name="providerOffice"
                        defaultValue={providerOffice}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Referring Provider"
                      style={{ flex: 1 }}
                    >
                      <Input
                        placeholder='Referring Provider'
                        name='referringProvider'
                        defaultValue={referringProvider}
                        onChange={handleFieldChange}
                      />
                    </Form.Item>
                  </div>
                  {allPayerNames && allPayerNames.length > 0 &&
                    <Form.Item
                      name="populateInsurance"
                      valuePropName="checked"
                      onChange={handlePopulateInsurance}
                    >
                      <Checkbox>Populate insurance information</Checkbox>
                    </Form.Item>
                  }
                  {populateInsurance && allPayerNames && allPayerNames.length > 0 &&
                  <>
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Primary Insurance</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item
                        label={<span>Payer<span style={{ color: 'red', fontSize: '12px' }}> * {primaryPlan && !allPayerNames.includes(primaryPlan) && 'Select payer from the dropdown'}</span></span>}
                        style={{ flex: '0.7', width: '70%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select the payer name!',
                          },
                        ]}
                      >
                        {allPayerNames.length > 0 ? (
                          <Select
                            showSearch
                            placeholder="Payer"
                            value={primaryPlan}
                            optionFilterProp="children"
                            allowClear
                            onClear={() => this.setState({
                              primaryPlan: '',
                              primaryMemberId: '',
                              primaryGroupNumber: '',
                              primaryPolicyType: '',
                            })}
                            onChange={handlePayerNameChange('primary')}
                            onSearch={handlePrimaryPayerNameSearch}
                            filterOption={false}
                            style={{ width: '100%' }}
                          >
                            {searchedPrimaryPayerNames.map(e => (
                              <Select.Option key={e} value={e}>
                                {e}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            disabled
                            placeholder={noPayerNameMessage}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={<span>Policy Number <span style={{ color: 'red' }}>*</span></span>}
                        style={{ flex: '0.3', width: '30%' }}
                        name="primaryMemberId"
                        initialValue={primaryMemberId}
                        rules={[
                          {
                            validator: validateAlphanumeric,
                          },
                        ]}
                      >
                        <Input
                          placeholder='Policy Number'
                          name='primaryMemberId'
                          onChange={handleFieldChange}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item
                        name="primaryGroupNumber"
                        label="Group Number"
                        style={{ flex: 0.5 }}
                        initialValue={primaryGroupNumber}
                        rules={[
                          {
                            validator: validateAlphanumeric,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Group Number"
                          name="primaryGroupNumber"
                          onChange={handleFieldChange}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span>Policy Type <span style={{ color: 'red' }}>*</span></span>}
                        style={{ flex: 0.5 }}
                      >
                        <Select
                          placeholder="Policy Type"
                          value={primaryPolicyType}
                          onSelect={handlePolicyTypeChange('primary')}
                          showSearch
                          optionFilterProp="children"
                        >
                        {this.state.policyTypeOptions.map(e => (
                              <Select.Option key={e} value={e}>
                                {e}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Secondary Insurance (Optional)</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item
                        label={<span>Payer <span style={{ color: 'red', fontSize: '12px' }}>{secondaryPlan && !allPayerNames.includes(secondaryPlan) && 'Select payer from the dropdown'}</span></span>}
                        style={{ flex: '0.7', width: '70%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select the payer name!',
                          },
                        ]}
                      >
                        {allPayerNames.length > 0 ? (
                          <Select
                            showSearch
                            placeholder="Payer"
                            value={secondaryPlan}
                            allowClear
                            onClear={() => this.setState({
                              secondaryPlan: '',
                              secondaryMemberId: '',
                              secondaryGroupNumber: '',
                              secondaryPolicyType: '',
                              secondaryInsurance: false,
                            })}
                            optionFilterProp="children"
                            onChange={handlePayerNameChange('secondary')}
                            onSearch={handleSecondaryPayerNameSearch}
                            filterOption={false}
                            style={{ width: '100%' }}
                          >
                            {searchedSecondaryPayerNames.map(e => (
                              <Select.Option key={e} value={e}>
                                {e}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            disabled
                            placeholder={noPayerNameMessage}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        name="secondaryMemberId"
                        initialValue={secondaryMemberId}
                        label={<span>Policy Number</span>}
                        style={{ flex: '0.3', width: '30%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the policy number!',
                          },
                          {
                            validator: validateAlphanumeric,
                          },
                        ]}
                      >
                        <Input
                          placeholder='Policy Number'
                          name='secondaryMemberId'
                          onChange={handleFieldChange}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item
                        name="secondaryGroupNumber"
                        initialValue={secondaryGroupNumber}
                        label="Group Number"
                        style={{ flex: 0.5 }}
                        rules={[
                          {
                            validator: validateAlphanumeric,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Group Number"
                          name="secondaryGroupNumber"
                          onChange={handleFieldChange}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span>Policy Type</span>}
                        style={{ flex: 0.5 }}
                      >
                        <Select
                          placeholder="Policy Type"
                          value={secondaryPolicyType}
                          onSelect={handlePolicyTypeChange('secondary')}
                          showSearch
                          optionFilterProp="children"
                        >
                        {this.state.policyTypeOptions.map(e => (
                              <Select.Option key={e} value={e}>
                                {e}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </>              
                  }
                </div>
              )}
              {!isEMA && autoUploadEmr !== 'DrChrono' && (
                <Form.Item label='File Name (optional)'>
                  <Input
                    placeholder='File name'
                    name='fileName'
                    onChange={handleFieldChange}
                    defaultValue={fileName}
                  />
                </Form.Item>
              )}
              {(localStorage.m8_org === 'Sleeptopia' && practiceName)
                && (
                  <Form
                    initialValues={{
                      locationSelection: practiceName,
                    }}
                  >
                  <Form.Item
                    style={{ display: 'flex', marginTop: 20 }}
                    name="locationSelection"
                    label="Location"
                  >
                    <Select
                      onChange={value => this.setState({ practiceName: value })}
                      value={practiceName}
                      options={[
                        { value: 'Sleeptopia Inc', label: 'Sleeptopia Inc' },
                        { value: 'CPAP and Supplies by Sleeptopia', label: 'CPAP and Supplies by Sleeptopia' },
                      ]}
                    >
                    </Select>
                  </Form.Item>
                  </Form>
                )
              }
            </Form>
          ) : (
          <CircularProgress
            size={30}
            thickness={3}
            style={{ marginTop: '5px', marginLeft: '50%' }} // Adjust positioning as needed
          />
          )
        }
      </ModalWrapper>
    );
  }
}

CreateNewChart.defaultProps = {
  error: false,
  errorMessage: null,
  receivedAt: '',
  callerName: '',
};

CreateNewChart.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  receivedAt: PropTypes.string,
  callerName: PropTypes.string,
  allDocumentCategories: PropTypes.object.isRequired,
  allPayerNames: PropTypes.array.isRequired,
  getDocumentCategories: PropTypes.func.isRequired,
  getPayerNames: PropTypes.func.isRequired,
  getEmaPatients: PropTypes.func.isRequired,
  emaPatients: PropTypes.object.isRequired,
};

export default connect((state) => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedLabel: state.records.updatedLabel,
  data: state.labels.data,
  selectedRecord: state.inboxTable.recordData,
  error: state.records.error,
  errorMessage: state.records.errorMessage,
  receivedAt: state.records.receivedAt,
  callerName: state.records.callerName,
  allDocumentCategories: state.userProfile.allDocumentCategories,
  allPayerNames: state.userProfile.allPayerNames,
  emaPatients: state.userProfile.emaPatients,
  emaError: state.userProfile.emaError,
}))(CreateNewChart);
