/* eslint-disable no-undef */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  CloudDownloadOutlined,
  FormOutlined,
} from '@ant-design/icons';

import { Header, Viewer } from '../components';
import { openModal } from '../actions/modal';
import { printPatientRecord } from '../actions/records';
import { sendReplyToRecord } from '../actions/replies';
import '../components/main.scss';

// Opening in a new window with different origins is typically not
// allowed due to pop up blocker policies. In order to allow new windows to open,
// we are keeping a global reference to the new window that will be created.
//
// In order to by-pass pop up blockers, we will open the window after an onClick
// event
//
// Fixes: https://sentry.io/medsender-inc/react/issues/701115083/
// See also: https://stackoverflow.com/questions/40362093/safari-window-open-doesnt-work
let downloadedRecordWindow;

class RecordViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordBlobObject: null,
      recordHasReply: false,
      unsavedSignatures: false,
    };
  }

  // If the user tries to close the page or refresh, it will prompt a
  // confirmation dialog, asking them if they want to leave. This is done so
  // that while viewing the records (especially on mobile) and attempting to
  // scroll, they don't accidentally trigger a refresh.
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleLeavePage);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage);
  }

  handleLeavePage(e) {
    // This custom message will not show in the following browser versions:
    // Chrome < 51, Opera < 38, Firefox < 44, Safari < 9.1
    const confirmationMessage = 'Are you sure you want to leave?';
    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage; // Gecko, WebKit, Chrome <34
  }

  downloadRecord = async () => {
    await this.props.printPatientRecord({
      patient: {
        file_token: this.props.auth.security_token,
        auth_token: this.props.auth.auth_token,
        recipient: this.props.recipient,
      },
    });
    const { downloadUrl } = this.props;
    if (downloadUrl !== '') {
      downloadedRecordWindow.location.href = downloadUrl;
      downloadedRecordWindow.opener = null;
    }
  }

  setUnsavedSignatures = (unsavedSignatures) => {
    this.setState({ unsavedSignatures });
  };

  openReplyToModal = async () => {
    const {
      recipient,
      recipientName,
      sendToken,
      hasResponse,
      signatureRequired,
      recordHasUnsavedEdits,
      openModal,
    } = this.props;

    if (this.state.unsavedSignatures) {
      this.setState({ unsavedSignatures: false });
      await this.flattenAnnotations(false);
    }

    const { recordBlobObject } = this.state;

    // If signature is required and record edits is not present.
    if (signatureRequired && !recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Signature is required before making a reply');
    }

    const data = {
      recipientEmail: recipient,
      recipientName,
      sendToken,
      hasResponse,
      recordBlobObject,
    };
    const modalType = {
      type: 'REPLY_TO_EMAIL',
      data,
    };

    // Make request to BE to securely reply to the record.
    this.setState({
      recordHasReply: true,
    });
    openModal(modalType);
  }

  // Save file to state when `Save Changes` button is hit.
  beginSaveAnnotationsOnRecord = async (file) => {
    const { error, openModal: openMessageModal } = this.props;

    // If saving annonations/signature on record has no error the save the file blob in local state.
    if (!error) {
      const { recordHasReply } = this.state;

      if (recordHasReply) {
        const modalType = {
          type: 'MESSAGE_MODAL',
          message: 'You have already sent a secure reply for this record therefore you cannot make any further changes.',
        };
        return openMessageModal(modalType);
      }
      toastr.success('Changes saved');
      return this.setState({
        recordBlobObject: file,
      });
    }
    toastr.error('Unable to save changes. Please try again');
  }

  // This function allows guest users to quickly submit their signature/edits without requiring them
  // to input any additional information like name and email/fax number.
  patientSignAndSubmitRecord = async (file) => {
    const {
      recipient,
      hasResponse,
      signatureRequired,
      recordHasUnsavedEdits,
      sendToken,
      sendReplyToRecord,
    } = this.props;

    if (this.state.unsavedSignatures) {
      this.setState({ unsavedSignatures: false });
      await this.flattenAnnotations(true);
      return;
    }

    // If signature is required and record edits is not present.
    if (signatureRequired && !recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Signature is required before making a reply');
    }

    // If signature is not required and record edits is not present.
    if (!recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Please make changes to the document before hitting finish');
    }

    if (hasResponse) {
      return toastr.error('You\'ve already replied to this record.');
    }

    const bodyFormData = new FormData();
    bodyFormData.append('subject', 'Signed Reply');
    bodyFormData.append('from', recipient);
    bodyFormData.append('edited_record', file.get('file'), 'signed_record.pdf');

    await sendReplyToRecord(sendToken, bodyFormData);

    const { replyError, replyErrorMessage } = this.props;
    if (replyError && replyErrorMessage === 'Record already has an associated reply') {
      toastr.error('You have already replied to this record.');
      return;
    }

    if (replyError) {
      toastr.error('There was an issue saving your edits, please try again.');
      return;
    }

    // Make request to BE to securely reply to the record.
    this.setState({
      recordHasReply: true,
    });

    toastr.success('Your edits have been submitted.');
  }

  updateRecordBlob = (file) => {
    // Updates the file blob saved in state,
    this.setState({
      recordBlobObject: file,
    });
  }

  render() {
    if (this.props.patientRecordUrl === '') {
      return (<Redirect to="/app/patient" />);
    }

    const { recordHasUnsavedEdits, loading } = this.props;

    return (
      <div>
        <Helmet>
          <title>Record Viewer - Medsender</title>
        </Helmet>
        <div
          style={{ marginLeft: '0' }}
          className="record-viewer"
        >
          <Header
            main="Document Viewer"
            subtext="Search and navigate your document"
            locationPath={[
              { icon: 'home', text: 'Patient Access', link: 'patient' },
              { icon: 'list', text: 'View record', link: '' },
            ]}
            buttons={[
              {
                text: 'Download Document',
                icon: <CloudDownloadOutlined />,
                disabled: false,
                func: () => {
                  downloadedRecordWindow = window.open();
                  this.downloadRecord();
                },
              },
              this.props.disableSecureReplies === false
                ? {
                  text: 'Secure Reply',
                  icon: <FormOutlined />,
                  disabled: false,
                  func: () => this.openReplyToModal(),
                }
                : {},
            ]}
          />
          <Viewer
            documentUrl={this.props.patientRecordUrl}
            recordId={this.props.match.params.id}
            canEditRecord={!this.props.disableSecureReplies}
            recordNeedsToBeSaved={recordHasUnsavedEdits}
            updateRecordBlob={this.updateRecordBlob}
            saveAnnotationsOnRecord={this.beginSaveAnnotationsOnRecord}
            guestSignAndSubmitRecord={this.patientSignAndSubmitRecord}
            saveLoading={loading}
            saveChangesButtonName="FINISH"
            setClick={click => this.flattenAnnotations = click}
            unsavedSignatures={this.state.unsavedSignatures}
            setUnsavedSignatures={this.setUnsavedSignatures}
            shouldFlatten={this.props.shouldFlatten}
            recipientEmail={this.props.recipient}
            recipientName={this.props.recipientName}
            nameToSignatureRequired={this.props.nameToSignatureRequired}
          />
        </div>
      </div>
    );
  }
}

RecordViewer.propTypes = {
  patientRecordUrl: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string,
  auth: PropTypes.shape({
    auth_token: PropTypes.string,
    question: PropTypes.string,
    recipient: PropTypes.string,
    security_token: PropTypes.string,
  }),
  printPatientRecord: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  sendReplyToRecord: PropTypes.func.isRequired,
  sendToken: PropTypes.string.isRequired,
  recipientName: PropTypes.string,
  recipient: PropTypes.string.isRequired,
  replyErrorMessage: PropTypes.string.isRequired,
  hasResponse: PropTypes.bool.isRequired,
  recordHasUnsavedEdits: PropTypes.bool.isRequired,
  replyError: PropTypes.bool.isRequired,
  signatureRequired: PropTypes.bool.isRequired,
  nameToSignatureRequired: PropTypes.bool.isRequired,
  disableSecureReplies: PropTypes.bool.isRequired,
  shouldFlatten: PropTypes.bool.isRequired,
};

RecordViewer.defaultProps = {
  auth: {
    auth_token: '',
    question: '',
    security_token: '',
    recipient: '',
  },
  downloadUrl: '',
  recipientName: '',
};

export default connect(state => ({
  recipient: state.auth.recipient,
  recipientName: state.auth.recipientName,
  loading: state.replies.isLoading,
  sendToken: state.auth.sendToken,
  hasResponse: state.auth.hasResponse,
  patientRecordUrl: state.auth.patientRecordUrl,
  downloadUrl: state.records.guestAccessUrl,
  auth: state.auth.security,
  recordHasUnsavedEdits: state.records.recordHasUnsavedEdits,
  signatureRequired: state.auth.signatureRequired,
  nameToSignatureRequired: state.auth.nameToSignatureRequired,
  replyError: state.replies.error,
  replyErrorMessage: state.replies.errorMessage,
  disableSecureReplies: state.auth.disableSecureReplies,
  shouldFlatten: state.auth.shouldFlatten,
}), {
  printPatientRecord,
  openModal,
  sendReplyToRecord,
})(RecordViewer);
export {
  RecordViewer,
};
