import React from 'react';
import { connect } from "react-redux";
import COLORS from "../../util/constants/colors";
import Typography from "../../components/Typography";
import Button from '../../components/Button';
import { Copy } from '@phosphor-icons/react';
import { message } from 'antd';

const Summary = ({ ...props  }) => {
  const { voiceCall } = props;
  const call_summary = voiceCall ? voiceCall.call_summary : null;

  const copyValue = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({
        content:
          'Copied Summary to clipboard!',
      });
    } catch (e) {
      message.error({
        content:
          'Failed to copy summary',
      });
    }
  }

  return (
    <div className="call-summary">
      <div className="summary-header">
        <Typography variant="text" size="large" bold>
          Summary
        </Typography>
        { call_summary && <Button type="tertiary" size="small" icon={<Copy size={16} weight="fill" />} onClick={() => copyValue(call_summary)} /> }
      </div>
      <div className="summary-container">
      <div className="summary-text">
        <Typography variant="text" size="small" color={COLORS.TEXT_SECONDARY_LITE}>
          {call_summary || 'No summary available'}
        </Typography>
      </div>
    </div>
    <div className="divider" />
    </div>
  )
}

export default connect((state) => ({
  voiceCall: state.calls.currentVoiceCall,
}), {})(Summary);
