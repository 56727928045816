/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { parse } from 'qs';
import ToolbarButton from './ToolbarButton';
import { ArchiveSvg } from '../../../assets/img/redesign/svgPaths';
import {
  archiveReceivedRecords, archiveRecords, unarchiveReceivedRecords, unarchiveRecords,
} from '../../../actions/records';

const ArchiveButton = ({
  inboxLink,
  ...props
}) => {
  const viewingArchivedRecords = window.location.search.includes('archived');
  const { fileToken, sendToken } = props;

  const beginArchiveRecord = async () => {
    const { archiveRecords, archiveReceivedRecords, archiveErrorMessage } = props;
    // for the edge case of archiving records sent to the user by the user, we need to get the sendToken from the url
    const searchParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    // get sendToken from the searchparams for received records sent to self
    // The file token changes to edited record when in record viewer if there are edits; if that's the case, send in both tokens so it disappears from the inbox too
    if (inboxLink === '/app/uploads') {
      await archiveRecords([fileToken]);
    } else {
      await archiveReceivedRecords([sendToken || searchParams.sendToken]);
    }
    if (!archiveErrorMessage) {
      message.success({
        content: 'Record successfully archived',
      });
      window.location.reload();
    } else {
      message.error({
        content:
          'We were unable to archive the selected records. Please try again',
      });
    }
  };

  const beginUnarchiveRecord = async () => {
    const { unarchiveRecords, unarchiveReceivedRecords, error } = props;
    // for the edge case of unarchiving records sent to the user by the user, we need to get the sendToken from the url
    // eslint-disable-next-line no-restricted-globals
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    // get sendToken from the searchparams for received records sent to self
    // The file token changes to edited record when in record viewer if there are edits; if that's the case, send in both tokens so it disappears from the inbox too
    if (inboxLink === '/app/uploads') {
      await unarchiveRecords([fileToken]);
    } else {
      await unarchiveReceivedRecords([sendToken || searchParams.sendToken]);
    }
    if (!error) {
      message.success({
        content: 'Record successfully unarchived',
      });
      window.location.reload();
    } else {
      message.error({
        content: 'We were unable to unarchive the record. Please try again',
      });
    }
  };

  return (
    <ToolbarButton
      title={viewingArchivedRecords ? 'Unarchive' : 'Archive'}
      icon={ArchiveSvg}
      onClick={viewingArchivedRecords
        ? beginUnarchiveRecord
        : beginArchiveRecord}
    />
  );
};

ArchiveButton.defaultProps = {
  archiveErrorMessage: null,
  sendToken: '',
  error: false,
};

ArchiveButton.propTypes = {
  inboxLink: PropTypes.string.isRequired,

  archiveErrorMessage: PropTypes.string,
  fileToken: PropTypes.string.isRequired,
  sendToken: PropTypes.string,
  error: PropTypes.bool,

  archiveRecords: PropTypes.func.isRequired,
  archiveReceivedRecords: PropTypes.func.isRequired,
  unarchiveRecords: PropTypes.func.isRequired,
  unarchiveReceivedRecords: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    archiveErrorMessage: state.records.archiveErrorMessage,
    fileToken: state.records.fileToken,
    sendToken: state.records.sendToken,
    error: state.records.error,
  }),
  {
    archiveRecords,
    archiveReceivedRecords,
    unarchiveRecords,
    unarchiveReceivedRecords,
  },
)(ArchiveButton);
