/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { parse } from 'qs';

import { Sidebar } from '../../../components';
import { voiceInboxColumns } from '../shared/voice_constants';

import { openModal } from '../../../actions/modal';
import {
  getVoiceDataByQuery,
  setVoiceCall,
} from '../../../actions/calls';
import { Table } from 'antd';
import '../styles/voiceInbox.scss';
import VoiceSlideOut from '../../VoiceSlideOut';
/* global toastr */

class VoiceInbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      searchQuery: '',
      loading: false,
    };
  }

  async componentDidMount() {
    const { getVoiceDataByQuery } = this.props;
    const { pageSize } = this.state;
    this.setState({ loading: true });
    await getVoiceDataByQuery(`?page=1&per_page=${pageSize}`);
    this.setState({ loading: false });
  }

  handleTableChange = async (pagination) => {
    const { getVoiceDataByQuery } = this.props;
    const { pageSize, searchQuery } = this.state;
    
    this.setState({ 
      currentPage: pagination.current,
      loading: true 
    });
    
    // Construct query with all current search parameters
    const params = {
      ...searchQuery,
      page: pagination.current,
      per_page: pageSize
    };
    
    const query = new URLSearchParams(params).toString();
    await getVoiceDataByQuery(`?${query}`);
    this.setState({ loading: false });
  };

  openSlideOut = (call) => {
    this.props.setVoiceCall(call);
    this.setState({ slideOutOpen: true });
  }

  closeSlideOut = () => {
    this.setState({ slideOutOpen: false });
    this.props.setVoiceCall(null);
  }

  render() {
    const { docked, sidebar, sidebarActions, inboxActions } = this.props;
    const filterData = {
      departments: this.props.departments,
    };
    const onMobile = window.innerWidth < 768;
    const totalResults = this.props.headers ? parseInt(this.props.headers.total, 10) : 0;
    const parseOptions = {
      ignoreQueryPrefix: true,
    };
    return (
      <div>
        <Helmet>
          <title>Voice Inbox - Medsender</title>
        </Helmet>

        <div 
          className="voice-inbox"
          style={{ 
          display: 'flex',
          width: '100%',
          position: 'relative'
        }}>
          <div style={{
            width: !onMobile && '256px',
            flexShrink: 0,
            borderRight: '1px solid #f0f0f0'
          }}>
            <Sidebar
              className="voice-inbox-sidebar"
              page="voice_inbox"
              docked={docked}
              sidebar={sidebar}
              actions={sidebarActions}
              inboxActions={{
                ...inboxActions,
                getRecords: async (query, field) => {
                  this.setState({ currentPage: 1 });
                  const { getVoiceDataByQuery } = this.props;
                  // Parse the query string
                  const params = new URLSearchParams(query);
                  const searchParams = Object.fromEntries(params);

                  this.setState(prevState => {
                    // Start with a fresh state when clearing searches
                    let updatedSearchQuery = { ...prevState.searchQuery };

                    if (field === 'caller_name') {
                      if (!searchParams.search) {
                        delete updatedSearchQuery.search;
                      } else {
                        updatedSearchQuery.search = searchParams.search;
                      }
                    }

                    if (field === 'voice_ai_numbers') {
                      if (!searchParams.voice_ai_numbers) {
                        delete updatedSearchQuery.voice_ai_numbers;
                      } else {
                        updatedSearchQuery.voice_ai_numbers = searchParams.voice_ai_numbers;
                      }
                    }

                    return { searchQuery: updatedSearchQuery };
                  });

                  // Clean up empty fields
                  Object.keys(searchParams).forEach(key => {
                    if (searchParams[key] === '' || searchParams[key] === undefined || searchParams[key] === null) {
                      delete searchParams[key];
                    }
                  });
                  
                  const finalParams = {
                    ...searchParams,
                    page: 1,
                    per_page: this.state.pageSize
                  };

                  const finalQuery = new URLSearchParams(finalParams).toString();
                  await getVoiceDataByQuery(`?${finalQuery}`);
                }
              }}
              paramTools={{
                params: {
                  ...parse(location.search, parseOptions)
                } || {},
                stringify: (params, options) => {
                  const combinedParams = {
                    ...this.state.searchQuery,
                    ...params
                  };

                  Object.keys(params).forEach(key => {
                    if (params[key] === '' || params[key] === undefined || params[key] === null) {
                      delete combinedParams[key];

                      if (key === 'startDate' || key === 'endDate') {
                        delete combinedParams.startDate;
                        delete combinedParams.endDate;
                        delete combinedParams.date_start;
                        delete combinedParams.date_end;
                        // Update state and trigger search
                        this.setState({ 
                          searchQuery: combinedParams,
                          currentPage: 1
                        }, () => {
                          const query = new URLSearchParams({
                            ...combinedParams,
                            page: 1,
                            per_page: this.state.pageSize
                          }).toString();
                          this.props.getVoiceDataByQuery(`?${query}`);
                        });
                      }
                    }
                  });

                  combinedParams.page = 1;
                  combinedParams.per_page = this.state.pageSize;

                  const query = new URLSearchParams(combinedParams).toString();
                  return `?${query}`;
                },
                stringifyOptions: {
                  addQueryPrefix: true,
                  arrayFormat: 'comma',
                  encode: true
                }
              }}
              mode={this.props.mode}
              getPageDataWithQuery={this.props.getPageDataWithQuery}
              filterData={filterData}
              location={this.props.location}
              getLabelsByQuery={this.props.getLabelsByQuery}
              data={this.props.data}
              labels={this.props.labels}
            />
          </div>

          <div style={{ 
            flex: 1,
            minWidth: 0
          }}>
            <Table
              loading={this.state.loading}
              columns={voiceInboxColumns}
              dataSource={this.props.data}
              pagination={{
                total: totalResults,
                pageSize: this.state.pageSize,
                current: this.state.currentPage,
                showSizeChanger: false,
                showTotal: (total, range) => `Viewing ${range[0]}-${range[1]} of ${total} calls`,
                position: ['bottomCenter'],
              }}
              onChange={this.handleTableChange}
              onRow={(call) => ({
                onClick: () => {
                  const selectedText = window.getSelection().toString();
                  if (selectedText) return;
                  this.openSlideOut(call);
                },
              })}
            />
          </div>
        </div>
        <VoiceSlideOut visible={this.state.slideOutOpen} onClose={this.closeSlideOut} />
      </div>
    );
  }
}

VoiceInbox.defaultProps = {
  selectedData: [],
  downloadUrl: '',
  errorMessage: null,
  autoUploadEmr: '',
  errorCode: null,
};

VoiceInbox.propTypes = {
  selectedData: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
  currentOrganizationId: PropTypes.number.isRequired,
};

export default connect(state => ({
    selectedData: state.inboxTable.recordData,
    downloadUrl: state.records.downloadUrl,
    data: state.calls.voice_data.call_logs,
    totalCount: state.calls.voice_data.total_count,
    docked: state.appState.sidebarVisible,
    sidebar: state.sidebar,
    departments: state.auth.departments,
    archiveErrorMessage: state.records.archiveErrorMessage,
    errorMessage: state.records.errorMessage,
    currentOrganizationId: state.auth.currentOrganizationId,
    headers: state.calls.headers,
  }), {
    openModal,
    getVoiceDataByQuery,
    setVoiceCall,
})(VoiceInbox);
