import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { ArrowsOutSimpleSvg } from '../../../assets/img/redesign/svgPaths';

const ExpandButton = ({
  inboxLink,
  ...props
}) => {
  const goToRecordPage = () => {
    const { fileToken, sendToken, assignedDocument: { slug: assignedDocumentId } } = props;
    let mode = window.location.pathname.split('/')[2];
    if (mode === 'uploads') {
      mode = 'uploaded';
    }
    const recordViewerPath = `/app/view/${fileToken}`;
    const sendTokenParam = sendToken ? `&sendToken=${sendToken}` : '';
    const assignedDocumentParam = assignedDocumentId
      ? `&assignedDocumentId=${assignedDocumentId}`
      : '';
    window.location.assign(
      `${recordViewerPath}?from=${mode.toLowerCase()}${sendTokenParam}${assignedDocumentParam}`,
    );
  };

  return (
    <ToolbarButton
      title="Expand"
      icon={ArrowsOutSimpleSvg}
      onClick={goToRecordPage}
    />
  );
};

ExpandButton.defaultProps = {
  sendToken: '',
  assignedDocument: {},
};

ExpandButton.propTypes = {
  inboxLink: PropTypes.string.isRequired,

  fileToken: PropTypes.string.isRequired,
  sendToken: PropTypes.string,
  assignedDocument: PropTypes.object,
};

export default connect(
  state => ({
    fileToken: state.records.fileToken,
    sendToken: state.records.sendToken,
    assignedDocument: state.records.assignedRecord,
  }),
  {},
)(ExpandButton);
