import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RevokeRecordDialog from './modals/RevokeRecordDialog';
import ArchiveRecordDialog from './modals/ArchiveRecordDialog';
import GetEmailDialog from './modals/GetEmailDialog';
import AddRecordDialog from './modals/AddRecordDialog';
import EditPatientDialog from './modals/EditPatientDialog';
import UpdateAssignedDocument from './modals/UpdateAssignedDocumentDialog';
import UpdateReferralDialog from './modals/UpdateReferralDialog';
import SecureLinkDialog from './modals/SecureLinkDialog';
import ResendRecordDialog from './modals/ResendRecordDialog';
import LogoutUserDialog from './modals/LogoutUserDialog';
import GuestLogoutDialog from './modals/GuestLogoutDialog';
import UploadRecordDialog from './modals/UploadRecordDialog';
import ChangeEMRDialog from './modals/ChangeEMRDialog';
import RaiseFlagDialog from './modals/RaiseFlagDialog';
import DownloadZipDialog from './modals/DownloadZipDialog';
import NotifyDialog from './modals/NotifyDialog';
import AddLabelToRecordDialog from './modals/AddLabelToRecordDialog';
import AddLabelToMultipleRecordsDialog from './modals/AddLabelToMultipleRecordsDialog';
import CreateNewChartDialog from './modals/CreateNewChartDialog';
import AddLabelDialog from './modals/AddLabelDialog';
import AddCategoryDialog from './modals/AddCategoryDialog';
import AddTaskTemplateDialog from './modals/AddTaskTemplateDialog';
import AddTaskProviderDialog from './modals/AddTaskProviderDialog';
import DirectMessagingDialog from './modals/DirectMessagingDialog';
import ReferralDialog from './modals/ReferralDialog';
import ReplyToEmailDialog from './modals/ReplyToEmailDialog';
import AddNewUserDilaog from './modals/AddNewUserDialog';
import UpgradeBrowserDialog from './modals/UpgradeBrowserDialog';
import FailedSSODialog from './modals/FailedSSODialog';
import ProviderSwitchedDialog from './modals/ProviderSwitchedDialog';
import AddFaxNumberDialog from './modals/AddFaxNumberDialog';
import FaxNumberInstructionsDialog from './modals/FaxNumberInstructionsDialog';
import SubmitFormDialog from './modals/SubmitFormDialog';
import MessageModal from '../components/modal/MessageModal';
import SplitSelectedRecordDialog from './modals/SplitSelectedRecordDialog';
import AutoUploadDialog from './modals/AutoUploadDialog';
import SelectOrganization from './modals/SelectOrganizationDialog';
import UpdatePhoneNumberDialog from './modals/UpdatePhoneNumberDialog';
import CreateTaskDialog from './modals/CreateTaskDialog';
import AssignRecordDialog from './modals/AssignRecordDialog';
import SmsMessageDialog from './modals/SmsMessageDialog';
import TrialExpiryDialog from './modals/TrialExpiryDialog';
import ReferralReplyDialog from './modals/ReferralReplyDialog';
import ReferralReplySidebarDialog from './modals/ReferralReplySidebarDialog';

import { hideModal } from '../actions/modal';


const MODAL_COMPONENTS = {
  ARCHIVE_RECORD: ArchiveRecordDialog,
  REVOKE_RECORDS: RevokeRecordDialog,
  ADD_RECORD: AddRecordDialog,
  UPLOAD_RECORD: UploadRecordDialog,
  GET_EMAIL: GetEmailDialog,
  EDIT_PATIENT: EditPatientDialog,
  UPDATE_ASSIGNED_DOCUMENT: UpdateAssignedDocument,
  UPDATE_REFERRAL: UpdateReferralDialog,
  SPLIT_SELECTED_RECORD: SplitSelectedRecordDialog,
  SECURE_LINK: SecureLinkDialog,
  RESEND_RECORD: ResendRecordDialog,
  GUEST_LOGOUT: GuestLogoutDialog,
  LOGOUT_USER: LogoutUserDialog,
  RAISE_FLAG: RaiseFlagDialog,
  DOWNLOAD_ZIP: DownloadZipDialog,
  NOTIFY: NotifyDialog,
  ADD_LABEL_TO_RECORD: AddLabelToRecordDialog,
  ADD_LABEL_TO_MULTIPLE_RECORDS: AddLabelToMultipleRecordsDialog,
  CREATE_NEW_CHART: CreateNewChartDialog,
  CREATE_TASK: CreateTaskDialog,
  ASSIGN_RECORD: AssignRecordDialog,
  ADD_LABEL: AddLabelDialog,
  ADD_CATEGORY: AddCategoryDialog,
  ADD_TASK_TEMPLATE: AddTaskTemplateDialog,
  ADD_TASK_PROVIDER: AddTaskProviderDialog,
  FORWARD_RECORD: DirectMessagingDialog,
  REFERRAL: ReferralDialog,
  REPLY_TO_EMAIL: ReplyToEmailDialog,
  ADD_NEW_USER: AddNewUserDilaog,
  UPGRADE_BROWSER: UpgradeBrowserDialog,
  ADD_FAX_NUMBER: AddFaxNumberDialog,
  MESSAGE_MODAL: MessageModal,
  EXISTING_ACCOUNT: FailedSSODialog,
  PROVIDER_SWITCHED: ProviderSwitchedDialog,
  FAX_NUMBER_INSTRUCTIONS: FaxNumberInstructionsDialog,
  SUBMIT_FORM: SubmitFormDialog,
  CHANGE_EMR: ChangeEMRDialog,
  AUTO_UPLOAD: AutoUploadDialog,
  SELECT_ORGANIZATION: SelectOrganization,
  UPDATE_PHONE_NUMBER: UpdatePhoneNumberDialog,
  SMS_MESSAGE: SmsMessageDialog,
  TRIAL_EXPIRY: TrialExpiryDialog,
  REFERRAL_REPLY: ReferralReplyDialog,
  REFERRAL_REPLY_SIDEBAR: ReferralReplySidebarDialog,
};

class ModalRoot extends Component {
  modalType = () => {
    if (!this.props.modalType.type) return null;

    const SpecificModal = MODAL_COMPONENTS[this.props.modalType.type];
    return (
      <SpecificModal
        {...this.props}
      />
    );
  }

  render() {
    return (
      <>
        {this.modalType()}
      </>
    );
  }
}

ModalRoot.propTypes = {
  modalType: PropTypes.object.isRequired,
};

export default connect(state => ({
  modalType: state.modal.modalType,
}),
{ hideModal })(ModalRoot);
