import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../components/Typography';

const MenuItems = ({ buttons }) => (
  <div className="menu-items">
    {buttons.map(button => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        key={button.key}
        onClick={button.onClick}
        className={`nav-btn ${button.link && window.location.pathname === button.link && !button.noHighlightOnSelect && 'add-menu-item-selected-highlight'}`}
      >
        <div className="nav-icon">
          {button.badgeCount && <div className="badge">{button.badgeCount}</div>}
          {button.dot && <div className="dot" />}
          {button.icon}
        </div>
        <Typography variant="text" size="xsmall" color="inherit">
          {button.label}
        </Typography>
      </div>
    ))}
  </div>
);

MenuItems.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    link: PropTypes.string,
    noHighlightOnSelect: PropTypes.bool,
    badgeCount: PropTypes.number,
    dot: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default MenuItems;
