import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Select,
  message,
  Form,
  Input,
  DatePicker,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { validPhoneNumber } from '../../global/validation';
import ModalWrapper from './ModalWrapper';

class ReferralReply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: null,
      sendToken: this.props.modalType.data.sendToken,
      patientName: this.props.modalType.data.patientName,
      referringProvider: this.props.modalType.data.referringProvider,
      patientDOB: this.props.modalType.data.patientDOB,
      fileToken: this.props.modalType.data.fileToken,
      callerName: this.getTenDigitPhoneNumber(this.props.modalType.data.caller_name),
      templates: JSON.parse(localStorage.getItem('referral_reply_templates')) || [],
      isPreviewLoading: false,
    };
  }

  componentDidMount = async () => {
    const { getReferralDocumenStatus } = this.props;
    const { sendToken, fileToken } = this.state;
    await getReferralDocumenStatus(fileToken, sendToken, 'received_fax');
    this.setState({ selectedTemplate: this.props.currentReferralStatusTemplate });
  }

  getTenDigitPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '').slice(-11);
  };

  handleTemplateChange = (value) => {
    this.setState({ selectedTemplate: value });
  }

  handleFaxNumberChange = (e) => {
    this.setState({ callerName: e.target.value });
  }

  handleDateChange = (_, dateString) => {
    this.setState({ patientDOB: dateString });
  }

  handleNameChange = (e) => {
    this.setState({ patientName: e.target.value });
  }

  handleProviderChange = (e) => {
    this.setState({ referringProvider: e.target.value });
  }

  handleDownload = async () => {
    const { getReferralReplyPreviewUrl } = this.props;
    const {
      selectedTemplate,
      sendToken,
      patientDOB,
      patientName,
      referringProvider,
    } = this.state;

    this.setState({ isPreviewLoading: true });

    try {
      await getReferralReplyPreviewUrl(
        sendToken,
        patientName,
        patientDOB,
        referringProvider,
        selectedTemplate,
      );
      window.open(this.props.previewUrl.signed_url);
    } catch (error) {
      message.error('Failed to generate preview. Please try again.');
    } finally {
      this.setState({ isPreviewLoading: false });
    }
  }

  handleSend = async () => {
    const {
      hideModal, modalType: { data: { sendToken } },
    } = this.props;
    const { sendReferralReply } = this.props;
    const {
      selectedTemplate,
      callerName,
      patientDOB,
      patientName,
      referringProvider,
    } = this.state;
    const splitDate = patientDOB.split('/');
    const formattedDOB = `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`;
    await sendReferralReply({
      sendToken,
      selectedTemplate,
      callerName,
      patientDOB: formattedDOB,
      patientName,
      referringProvider,
    });

    const { error, errorCode } = this.props;
    const errorMessageContent = (errorCode === 422) ? 'You must create an address book contact for the received fax phone number before you can reply to the fax.' : 'Error sending reply. Please try again.';
    hideModal();
    if (!error) {
      message.success({
        content: 'Referral Reply will be sent shortly.',
      });
    } else {
      message.error({
        content: errorMessageContent,
      });
    }
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const {
      selectedTemplate,
      templates,
      callerName,
      patientDOB,
      patientName,
      referringProvider,
    } = this.state;

    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    const validatePhoneNumber = (rule, value) => {
      if (!value) {
        return Promise.reject('Please enter the fax number!');
      } if (!validPhoneNumber(value)) {
        return Promise.reject('Please enter a valid fax number!');
      }
      return Promise.resolve();
    };

    const disableSubmitButton = () => {
      return (
        !selectedTemplate
        || callerName === null || callerName === ''
        || patientDOB === null || patientDOB === ''
        || patientName === null || patientName === ''
        || referringProvider === null || referringProvider === ''
        || !validPhoneNumber(callerName)
      );
    };

    const customContentStyle = {
      width: '270px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.handleSend}
        actionName="Send"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={disableSubmitButton()}
        modalTitle="Send Referral Reply"
        form="referralReply"
        width="550px"
        isDraggable
      >
        <div>
          <div style={{ marginBottom: '20px', padding: '10px' }}>
            {templates.length === 0 && (
              <div style={{ color: 'red', marginBottom: '10px' }}>
                * Your Organization does not have any referral reply templates. Please contact your Admin.
              </div>
            )}
            <div style={{ marginBottom: '5px', padding: '10px' }}>
              {/* Template and Fax Number Section */}
              <div style={{ display: 'flex', gap: '20px', marginBottom: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginBottom: '5px', color: 'black' }}>Referral Template</label>
                  <Select
                    style={{ width: 300 }}
                    onChange={this.handleTemplateChange}
                    value={selectedTemplate}
                  >
                    {templates.map(template => (
                      <Select.Option key={template.slug} value={template.slug}>
                        {template.template_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginBottom: '5px', color: 'black' }}>Fax Number</label>
                  <Form.Item
                    rules={[
                      {
                        validator: validatePhoneNumber,
                      },
                    ]}
                    onChange={this.handleFaxNumberChange}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      placeholder="Enter Fax number"
                      name="faxnumber"
                      value={this.getTenDigitPhoneNumber(callerName)}
                      style={{ width: 130 }}
                    />
                    {callerName && !validPhoneNumber(callerName) && <div style={{ color: 'red' }}>Invalid fax number</div>}
                  </Form.Item>
                </div>
              </div>

              {/* Patient Name and DOB Section */}
              <div style={{ display: 'flex', gap: '20px', marginBottom: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginBottom: '5px', color: 'black' }}>Patient Name</label>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Input
                      value={patientName}
                      placeholder="Name"
                      onChange={this.handleNameChange}
                      style={{ width: 300 }}
                    />
                  </Form.Item>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginBottom: '5px', color: 'black' }}>Patient Date of Birth</label>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <DatePicker
                      onChange={this.handleDateChange}
                      name="patientDOB"
                      format={dateFormat}
                      defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                      value={patientDOB && moment(patientDOB, dateFormat)}
                    />
                  </Form.Item>
                </div>
              </div>

              {/* Referring Provider Section */}
              <div style={{ display: 'flex', gap: '20px', marginBottom: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ marginBottom: '5px', color: 'black' }}>Referring Provider</label>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Input
                      value={referringProvider}
                      placeholder="Referring Provider Name"
                      onChange={this.handleProviderChange}
                      style={{ width: 300 }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: '10px', position: 'relative' }}>
              <Button
                type="primary"
                disabled={disableSubmitButton() || this.state.isPreviewLoading}
                onClick={this.handleDownload}
                style={{
                  backgroundColor: '#691b9a',
                  borderColor: '#691b9a',
                  color: 'white',
                  opacity: (disableSubmitButton() || this.state.isPreviewLoading) ? 0.6 : 1,
                }}
              >
                {this.state.isPreviewLoading ? 'Generating Preview...' : 'Preview Referral Reply'}
              </Button>
              {this.state.isPreviewLoading && (
                <Spin style={{ marginLeft: '10px' }} />
              )}
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

ReferralReply.defaultProps = {
  data: {},
  error: false,
  errorCode: '',
  currentReferralStatusTemplate: '',
};

ReferralReply.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  errorCode: PropTypes.string,
  data: PropTypes.shape({
    referral_reply: PropTypes.object.isRequired,
  }),
  getReferralDocumenStatus: PropTypes.func.isRequired,
  sendReferralReply: PropTypes.func.isRequired,
  currentReferralStatusTemplate: PropTypes.string,
  getReferralReplyPreviewUrl: PropTypes.func.isRequired,
  previewUrl: PropTypes.shape({
    signed_url: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(state => ({
  currentReferralStatusTemplate: state.records.currentReferralStatusTemplate,
  previewUrl: state.records.previewUrl,
  error: state.records.error,
  errorCode: state.records.errorCode,
}))(ReferralReply);
