import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { NotificationSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { PAGES } from '../../shared/constants';

const NotifyButton = ({
  isFormDocument, currentPage, ...props
}) => {
  // eslint-disable-next-line no-shadow
  const { sendToken, openModal } = props;
  const hideButton = !sendToken || isFormDocument || currentPage === PAGES.ASSIGNED;

  const openNotifyModal = () => {
    const modalType = {
      type: 'NOTIFY',
      data: {
        record: sendToken,
      },
    };
    return openModal(modalType);
  };

  return (
    <ToolbarButton
      title="Notify"
      icon={NotificationSvg}
      hide={hideButton}
      onClick={openNotifyModal}
    />
  );
};

NotifyButton.defaultProps = {
  sendToken: null,
};

NotifyButton.propTypes = {
  sendToken: PropTypes.string,
  isFormDocument: PropTypes.bool.isRequired,
  currentPage: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default connect(state => ({
  sendToken: state.records.sendToken,
}), { openModal })(NotifyButton);
