import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';
import Box from '../../components/Box';
import StatsCard from '../../components/StatsCard';
import { EnvelopeSimple, ListChecks, Users } from '@phosphor-icons/react';
import COLORS from '../../util/constants/colors';
import { verifyFeatureAccess } from '../../global/featureFlags';

const Intro = ({ ...props }) => {
  const { user } = props;
  const onMobile = window.innerWidth < 800;
  const avatarContent = user?.name
    ?.split(' ')
    .map(n => n[0])
    .join('');

  const [referralManagementAccess, setReferralManagementAccess] = useState(false);
  useEffect(() => {
    const setPermissions = async () => {
      const canViewReferralMgmt = await verifyFeatureAccess('referral_management');
      setReferralManagementAccess(canViewReferralMgmt);
    };
    setPermissions();
  }, []);

  const statCards = [
    {
      title: 'Inbox',
      icon: <EnvelopeSimple size={32} color={COLORS.STROKE_LITE} weight="fill" />,
      url: '/app/received',
    },
    {
      title: 'Assigned',
      icon: <ListChecks size={32} color={COLORS.STROKE_LITE} weight="fill" />,
      url: '/app/assigned',
    },
    {
      title: 'Referrals',
      icon: <Users size={32} color={COLORS.STROKE_LITE} weight="fill" />,
      // stat: props.newReferralsCount,
      url: '/app/referred',
      hide: !referralManagementAccess,
    },
  ];

  return (
    <Box variant="secondary" style={{
      padding: onMobile ? '16px' : '32px',
      display: 'flex',
      flexDirection: onMobile ? 'column' : 'row',
      gap: onMobile && '16px',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
    >
      <Avatar
        full
        size="large"
        name={user?.fullName}
        org={user?.organization}
        email={user?.uid}
        content={avatarContent}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: onMobile ? 'wrap' : 'nowrap',
        alignItems: 'flex-end',
        gap: '50px',
      }}
      >
        {statCards.map((card, index) => {
          if (card.hide) return null;
          return (
            <StatsCard
              key={index}
              title={card.title}
              url={card.url}
              stat={card.stat}
              icon={card.icon}
            />
          );
        })}
      </div>
    </Box>
  );
};

Intro.defaultProps = {
  user: {},
};

Intro.propTypes = {
  user: PropTypes.object,
};

export default connect(state => ({
  user: state.auth.data,
  newReferralsCount: state.socket.newReferralsCount,
}), {}
)(Intro);
