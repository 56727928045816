import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Cascader, Form } from 'antd';
// Advanced search allows you to "Sort by" 4 different options, in the
// <SelectField> component below. On page load, this function checks
// params in the URL to see which option should be selected by mapping
// query values to the select values in the render() function
const mapQueryToValue = (query) => {
  if (query.sort === 'dob' && query.sort_dir === 'asc') return 4;
  if (query.sort === 'dob') return 3;
  if (query.sort_dir === 'asc') return 2;
  return 1;
};

class SortSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: mapQueryToValue(props.paramTools.params),
      query: '',
    };
    // For the voice inbox page, we want to sort by date received
    if (window.location.pathname.includes('voice_inbox')) {
      this.options = [
        {
          name: 'Date Received (newest)',
          key: 1,
        },
        {
          name: 'Date Received (oldest)',
          key: 2,
        },
      ]
    } else {
      // For the other pages (uploads, sent, received, assigned, referred), we want to sort by date uploaded
      this.options = [
        {
          name: `${props.mode} (newest)`,
          key: 1,
        },
        {
          name: `${props.mode} (oldest)`,
          key: 2,
        },
        {
          name: 'Date of Birth (youngest)',
          key: 3,
        },
        {
          name: 'Date of Birth (oldest)',
          key: 4,
        },
      ];
    }
  }

  /**
   * Called when the user selects an option in the menu.
   * It stores the value in state and then calls updateQuery to sort the data.
   * @param {Array} selectedOption = [{
        name: `option name`,
        key: 1,
      }]
   */
  handleMenuChange = (_, selectedOption) => {
    const selectedKey = selectedOption[0].key;
    this.setState({ selectValue: selectedKey });
    this.updateQuery(selectedKey);
  }

  /**
   * Updates the params according to user selection
   * @param {Number} selectedOption = 1
  */
  updateQuery = (selectedOptionKey) => {
    // refer to <Sidebar> for prop docs. (See ./index.jsx)
    const { paramTools, inboxActions } = this.props;
    const { params, stringify, stringifyOptions } = paramTools;
    const { getRecords } = inboxActions;
    switch (selectedOptionKey) {
      case 1: {
        // Uploaded/Sent/Received (newest)
        delete params.sort;
        params.sort_dir = 'desc';
        break;
      }
      case 2: {
        // Uploaded/Sent/Received (oldest)
        delete params.sort;
        params.sort_dir = 'asc';
        break;
      }
      case 3: {
        // Date of Birth (newest)
        delete params.sort_dir;
        params.sort = 'dob';
        break;
      }
      case 4: {
        // Date of Birth (oldest)
        params.sort_dir = 'asc';
        params.sort = 'dob';
        break;
      }
    }

    const query = stringify(params, stringifyOptions);
    
    // eslint-disable-next-line react/destructuring-assignment
    if (query === this.state.query) return;
    this.setState({ query });
    getRecords(query);
  }

  render() {
    const { selectValue } = this.state;
    const { floatingLabelText } = this.props;
    const selectedValueIndex = selectValue - 1;
    return (
      <Form.Item
        label={floatingLabelText || 'Sort by'}
        style={{ marginTop: 10 }}
      >
        <Cascader
          allowClear={false}
          fieldNames={{ label: 'name', value: 'name' }}
          options={this.options}
          onChange={this.handleMenuChange}
          defaultValue={[this.options[selectedValueIndex].name]}
        />
      </Form.Item>
    );
  }
}

SortSettings.defaultProps = {
  floatingLabelText: '',
}

// refer to <Sidebar> for prop docs. (See ./index.jsx)
SortSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  floatingLabelText: PropTypes.string,
};

export default SortSettings;
