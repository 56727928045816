import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { parseFullName } from 'parse-full-name';
import ToolbarButton from './ToolbarButton';
import { DeviceMobileSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { getDefaultSmsTemplate } from '../../../actions/userProfile';
import { PAGES } from '../../shared/constants';

const SMSButton = ({ currentPage, ...props }) => {
  // eslint-disable-next-line no-shadow
  const [smsMessageFeature, setSmsMessageFeature] = useState(false);
  const hideButton = currentPage !== PAGES.REFERRED;

  useEffect(() => {
    const setPermission = async () => setSmsMessageFeature(await verifyFeatureAccess('referral_sms'));

    setPermission();
  }, []);

    // Call endpoint to get the default SMS template
    useEffect(() => {
    const loadTemplate = async () => {
      if (smsMessageFeature && props.sendToken && props.receiverType) {
        await props.getDefaultSmsTemplate(props.sendToken, props.receiverType);
      }
    };

    loadTemplate();
  }, [smsMessageFeature, props.sendToken, props.receiverType]);

  const smsMessage = async () => {
    const {
      sendToken,
      receiverType,
      patientName,
      patientDOB,
      patientPhoneNumber,
      openModal,
      defaultSmsTemplate,
    } = props;
    if (!patientName || !patientDOB) {
      message.error({
        content:
          'Cannot send a message without a valid patient name and date of birth. Edit record details and try again.',
      });
    } else {
      const parsedName = parseFullName(patientName)?.first;
      const firstName = parsedName || patientName;
      const messageTemplates = JSON.parse(localStorage.getItem('referral_sms_template'));
      let defaultTemplateIndex = 0;
      for (let idx = 0; idx < messageTemplates.length; idx++) {
        const template = messageTemplates[idx];
        if (template.default_template) {
          defaultTemplateIndex = idx;
        }
      }

      const allMessageTemplates = JSON.parse(localStorage.getItem('referral_sms_template'));
      // Only display templates for departments current user is in
      const userDepartmentIds = JSON.parse(localStorage.getItem('departments')).map(dept => dept.id);
      const userTemplates = allMessageTemplates.filter(template => 
        template.owner_type === "Organization" ||
        (template.owner_type === "Department" && userDepartmentIds.includes(template.owner_id))
      );        

      const modalType = {
        type: 'SMS_MESSAGE',
        data: {
          sendToken,
          receiverType,
          patientName,
          messageTemplates: userTemplates,
          messageText: `Hello ${firstName}, ${defaultSmsTemplate.message}`,
          url: defaultSmsTemplate.short_url,
          originalUrl: defaultSmsTemplate.url,
          patientPhoneNumber,
          smsMessageFeature,
        },
      };
      openModal(modalType);
    }
  };

  return (
    <ToolbarButton
      title="Send SMS"
      icon={DeviceMobileSvg}
      hide={hideButton}
      onClick={smsMessage}
    />
  );
};

SMSButton.defaultProps = {
  sendToken: null,
  patientName: '',
  patientDOB: '',
  patientPhoneNumber: '',
  receiverType: '',
};

SMSButton.propTypes = {
  sendToken: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  patientPhoneNumber: PropTypes.string,
  receiverType: PropTypes.string,
};

export default connect(
  state => ({
    sendToken: state.records.sendToken,
    patientName: state.records.patientName,
    patientDOB: state.records.patientDOB,
    patientPhoneNumber: state.records.patientPhoneNumber,
    receiverType: state.records.receiverType,
    defaultSmsTemplate: state.userProfile.defaultSmsTemplate,
  }),
  { openModal, getDefaultSmsTemplate },
)(SMSButton);
