import axios from 'axios';
import crud from './helpers/crud';
import download, { downloadToBrowser } from './helpers/download';
import { updateCanEditStatus } from './faxes';

const getUploadedRecordsByPage = (page, pageSize = 10, query = '') => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_UPLOADED_RECORDS',
      fail: 'FAILED_GET_UPLOADED_RECORDS',
      end: 'FINISH_GET_UPLOADED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/records?page=${page}&page_size=${pageSize}${query}`,
  })
);

const getUploadedByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_UPLOADED_RECORDS',
      fail: 'FAILED_GET_UPLOADED_RECORDS',
      end: 'FINISH_GET_UPLOADED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/records${query}`,
    query,
  })
);

const duplicateRecord = fileToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_DUPLICATE_RECORD',
      fail: 'FAILED_DUPLICATE_RECORD',
      end: 'FINISH_DUPLICATE_RECORD',
    },
    method: 'POST',
    url: `/api/v1/records/${fileToken}/clone`,
  })
);

/** Gets a record by ID
 * Takes in an additional param because the BE now saves an original copy
 * of a record in addition to an edited copy. When the user is accessing a record
 * from the sent inbox, we want to return the unedited copy of the record back
 * to them.
 *
 * @param  {Boolean} sent   whether the record is accessed from sent inbox
 * @param  {Boolean} ext    whether the record is being requested from the
 *                          Chrome Extension
 */
const getRecordById = (id, sent = false, ext = false, archive = false) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SINGLE_UPLOADED_RECORD',
      fail: 'FAILED_GET_SINGLE_UPLOADED_RECORD',
      end: 'FINISHED_GET_SINGLE_UPLOADED_RECORD',
    },
    method: 'GET',
    url: `/api/v1/records/${id}?sent=${sent}&ext=${ext}&archive=${archive}`,
  })
);

const getSentRecordsByPage = page => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SENT_RECORDS',
      fail: 'FAILED_GET_SENT_RECORDS',
      end: 'FINISHED_GET_SENT_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/records/sent?page=${page}`,
  })
);

const getSentByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SENT_RECORDS',
      fail: 'FAILED_GET_SENT_RECORDS',
      end: 'FINISHED_GET_SENT_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/records/sent${query}`,
    query,
  })
);


const getReceivedRecordsByPage = (page, pageSize = 10, includeSignedReplies = '') => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_RECEIVED_RECORDS',
      fail: 'FAILED_GET_RECEIVED_RECORDS',
      end: 'FINISHED_GET_RECEIVED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/receivers?page=${page}&page_size=${pageSize}${includeSignedReplies}`,
    data: includeSignedReplies,
  })
);

const getReferralsByPage = (page, pageSize = 10) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_RECEIVED_RECORDS',
      fail: 'FAILED_GET_RECEIVED_RECORDS',
      end: 'FINISHED_GET_RECEIVED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/receivers?page=${page}&page_size=${pageSize}&referrals=true&category=Referral`,
  })
);

const getReferralDocumenStatus = (fileToken, sendToken, parentType) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_REFERRAL_STATUS',
      fail: 'FAILED_GET_REFERRAL_STATUS',
      end: 'FINISHED_GET_REFERRAL_STATUS',
    },
    method: 'GET',
    url: `/api/v1/records/${fileToken}/referred_document_statuses/${sendToken}?parent_type=${parentType}`,
  })
);

const getAssignedRecordsByPage = (page, pageSize = 10, includeSignedReplies = '') => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ASSIGNED_RECORDS',
      fail: 'FAILED_GET_ASSIGNED_RECORDS',
      end: 'FINISHED_GET_ASSIGNED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/assigned_documents?page=${page}&page_size=${pageSize}${includeSignedReplies}`,
    data: includeSignedReplies,
  })
);

const updateAssignedRecord = (assignedDocId, statusId, assignee) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_ASSIGNED_RECORD_STATUS',
      fail: 'FAILED_UPDATE_ASSIGNED_RECORD_STATUS',
      end: 'FINISHED_UPDATE_ASSIGNED_RECORD_STATUS',
    },
    method: 'POST',
    url: `/api/v1/assigned_documents/${assignedDocId}/assigned_document_statuses`,
    data: {
      assigned_document_status: {
        document_status_id: statusId,
        assignee,
      },
    },
  })
);

const updateReferral = (recordId, sendToken, status, parentType) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_REFERRAL_STATUS',
      fail: 'FAILED_UPDATE_REFERRAL_STATUS',
      end: 'FINISHED_UPDATE_REFERRAL_STATUS',
    },
    method: 'POST',
    url: `/api/v1/records/${recordId}/referred_document_statuses`,
    data: {
      referred_document_status: status,
      parent_type: parentType,
      send_token: sendToken,
      referral_status_id: status.referral_status_id,
    },
  })
);

const assignDocument = assignDoc => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_ASSIGNED_RECORD',
      fail: 'FAILED_CREATE_ASSIGNED_RECORD',
      end: 'FINISHED_CREATE_ASSIGNED_RECORD',
    },
    method: 'POST',
    url: '/api/v1/assigned_documents/',
    data: {
      assigned_document: assignDoc,
    },
  })
);

const markDocumentAsCompleted = assignedDocumentId => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_ASSIGNED_RECORD',
      fail: 'FAILED_UPDATE_ASSIGNED_RECORD',
      end: 'FINISHED_UPDATE_ASSIGNED_RECORD',
    },
    method: 'PATCH',
    url: `/api/v1/assigned_documents/${assignedDocumentId}`,
    data: {
      assigned_document: { completed: true },
    },
  })
);

const getNextRecord = fileToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_NEXT_RECORD',
      fail: 'FAILED_GET_NEXT_RECORD',
      end: 'FINISHED_GET_NEXT_RECORD',
    },
    method: 'GET',
    url: `/api/v1/receivers/${fileToken}/next_record`,
  })
);

const getReceivedByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_RECEIVED_RECORDS',
      fail: 'FAILED_GET_RECEIVED_RECORDS',
      end: 'FINISHED_GET_RECEIVED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/receivers${query}`,
    query,
  })
);

const getReferralsQuery = (query) => {
  let queryAttribute = query;
  if (queryAttribute.includes('?')) {
    queryAttribute = queryAttribute.replace('?', '');
  }

  return crud({
    dispatch: {
      begin: 'BEGIN_GET_RECEIVED_RECORDS',
      fail: 'FAILED_GET_RECEIVED_RECORDS',
      end: 'FINISHED_GET_RECEIVED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/receivers?referrals=true&category=Referral&${queryAttribute}`,
    query,
  });
};

const getAssignedByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ASSIGNED_RECORDS',
      fail: 'FAILED_GET_ASSIGNED_RECORDS',
      end: 'FINISHED_GET_ASSIGNED_RECORDS',
    },
    method: 'GET',
    url: `/api/v1/assigned_documents${query}`,
    query,
  })
);

const getAssignedRecord = assignedDocumentId => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ASSIGNED_RECORD',
      failed: 'FAILED_GET_ASSIGNED_RECORD',
      end: 'FINISHED_GET_ASSIGNED_RECORD',
    },
    method: 'GET',
    url: `/api/v1/assigned_documents/${assignedDocumentId}`,
  })
);

const removeReceivedRecords = (tokens) => {
  return ({
    type: 'REMOVE_RECEIVED_RECORDS',
    payload: {
      tokens,
    },
  });
};

const archiveRecords = tokens => (
  crud({
    dispatch: {
      begin: 'BEGIN_ARCHIVE_RECORDS',
      fail: 'FAILED_ARCHIVE_RECORDS',
      end: 'FINISHED_ARCHIVE_RECORDS',
    },
    method: 'PATCH',
    url: '/api/v1/records',
    data: { tokens },
    callback: () => removeReceivedRecords(tokens),
  })
);

const unarchiveRecords = tokens => (
  crud({
    dispatch: {
      begin: 'BEGIN_UNARCHIVE_RECORD',
      fail: 'FAILED_UNARCHIVE_RECORD',
      end: 'FINISHED_UNARCHIVE_RECORD',
    },
    method: 'PATCH',
    url: '/api/v1/records/unarchive',
    data: { tokens },
  })
);

const archiveReceivedRecords = tokens => (
  crud({
    dispatch: {
      begin: 'BEGIN_ARCHIVE_RECORDS',
      fail: 'FAILED_ARCHIVE_RECORDS',
      end: 'FINISHED_ARCHIVE_RECORDS',
    },
    method: 'PATCH',
    url: '/api/v1/receivers',
    data: { tokens },
    callback: () => removeReceivedRecords(tokens),
  })
);

const unarchiveReceivedRecords = tokens => (
  crud({
    dispatch: {
      begin: 'BEGIN_UNARCHIVE_RECORD',
      fail: 'FAILED_UNARCHIVE_RECORD',
      end: 'FINISHED_UNARCHIVE_RECORD',
    },
    method: 'PATCH',
    url: '/api/v1/receivers/unarchive',
    data: { tokens },
  })
);

const getStatusOfSentRecordById = (recordId, sendToken) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SINGLE_RECORD_STATUS',
      fail: 'FAILED_GET_SINGLE_RECORD_STATUS',
      end: 'FINISHED_GET_SINGLE_RECORD_STATUS',
    },
    method: 'GET',
    url: `/api/v1/records/${recordId}/send/${sendToken}`,
  })
);

const getStatusOfSentPatientRecordById = (recordId, sendToken) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SINGLE_RECORD_STATUS',
      fail: 'FAILED_GET_SINGLE_RECORD_STATUS',
      end: 'FINISHED_GET_SINGLE_RECORD_STATUS',
    },
    method: 'GET',
    url: `/api/v1/records/${recordId}/send/${sendToken}?patient=true`,
  })
);

/** Removes sent data from the store after backend call
 * @param {String} recordId the id of the record to revoke
 * @param {String} receiver to revoke access
 */
const removeSentData = (recordId, receiver) => {
  return ({
    type: 'REMOVE_SENT_RECORD',
    payload: {
      recordId,
      receiver,
    },
  });
};

/** Updates the store's uploaded data after request
 * @param {String} recordId - the id of the record to update
 * @param {String} patientDOB - the  updated DOB
 * @param {String} patientName - the updated patient's name
 */
const updateUploadedData = (recordId, patientDOB, patientName, mode, privacyLevel, incorrectPatientInfo, documentTitle, documentCategory, referralNote, referringProvider, referringProviderOffice) => {
  toastr.success('You have successfully updated the record');
  if (documentCategory === 'None') {
    // eslint-disable-next-line no-param-reassign
    documentCategory = null;
  }
  return ({
    type: 'UPDATE_UPLOADED_RECORDS',
    payload: {
      recordId,
      patientDOB,
      patientName,
      mode,
      privacyLevel,
      incorrectPatientInfo,
      documentTitle,
      documentCategory,
      referralNote,
      referringProvider,
      referringProviderOffice,
    },
  });
};

/** Call action to update the record info
 * @param {String} recordId - the id of the record to update
 * @param {String} patientDOB - the  updated DOB
 * @param {String} patientName - the updated patient's name
 */
const updatePatientInfo = (recordId, patientDOB, patientName, mode, privacyLevel, fromPage, isDOBIncorrect, isNameIncorrect, documentTitle, documentCategory, referralNote, referringProvider, referringProviderOffice) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_RECORD',
      fail: 'FAILED_UPDATE_RECORD',
      end: 'FINISHED_UPDATE_RECORD',
    },
    method: 'PUT',
    data: {
      record: {
        patient_dob: patientDOB,
        patient_name: patientName?.trim(),
        privacy_level: privacyLevel,
        is_patient_dob_incorrect: isDOBIncorrect,
        is_patient_name_incorrect: isNameIncorrect,
        document_title: documentTitle,
        document_category_id: documentCategory,
        referral_note: referralNote,
        referring_provider: referringProvider?.trim(),
        provider_office: referringProviderOffice?.trim(),
      },
    },
    url: `/api/v1/records/${recordId}?from=${fromPage}`,
    callback: () => updateUploadedData(recordId, patientDOB, patientName, mode, privacyLevel, isDOBIncorrect || isNameIncorrect, documentTitle, documentCategory, referralNote, referringProvider, referringProviderOffice),
  })
);
const updateRecordReferralNote = (recordId, referralNote, referringProvider, referringProviderOffice) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_RECORD_NOTE',
      fail: 'FAILED_UPDATE_RECORD_NOTE',
      end: 'FINISHED_UPDATE_RECORD_NOTE',
    },
    method: 'PUT',
    data: {
      referral_note: referralNote,
      referring_provider: referringProvider,
      provider_office: referringProviderOffice,
    },
    url: `/api/v1/records/${recordId}`,
  })
);

const updateReceivedData = (sendToken, tag, response) => {
  if (response.status !== 204) {
    return ({
      type: 'UPDATE_RECEIVED_RECORDS',
      payload: {
        sendToken,
        tag,
      },
    });
  }
  return ({
    type: 'IGNORE_RECEIVER_UPDATE',
  });
};

const updateReceivedLabels = (sendToken, labels, response, isLabelIncorrect = false) => {
  if (response.status !== 500) {
    return ({
      type: 'UPDATE_RECEIVED_RECORDS',
      payload: {
        sendToken,
        labels,
        isLabelIncorrect,
      },
    });
  }
  return ({
    type: 'IGNORE_RECEIVER_UPDATE',
  });
};

const markRecordAsViewed = (sendToken) => {
  return ({
    type: 'MARK_RECORD_AS_VIEWED',
    payload: {
      sendToken,
    },
  });
};

/** Adds/updates the tag for a received record.
 * @param {String} recordId The file token of the record to update
 * @param {String} tag The tag to assign to the received record
 */
const editReceivedRecordTag = (sendToken, tag) => (
  crud({
    dispatch: {
      begin: 'BEGIN_EDIT_NOTIFY',
      fail: 'FAILED_EDIT_NOTIFY',
      end: 'FINISHED_EDIT_NOTIFY',
    },
    method: 'PUT',
    data: {
      receiver: {
        tag,
        send_token: sendToken,
      },
    },
    url: '/api/v1/receivers/update_tags',
    callback: response => updateReceivedData(sendToken, tag, response),
  })
);

const editReceivedRecordLabels = (sendToken, labelIds, labels, receiverType, isLabelIncorrect = false, correctLabel = null, multipleReceiverUpdate = false) => (
  crud({
    dispatch: {
      begin: 'BEGIN_EDIT_RECEIVED_RECORD_LABELS',
      fail: 'FAILED_EDIT_RECEIVED_RECORD_LABELS',
      end: 'FINISHED_EDIT_RECEIVED_RECORD_LABELS',
    },
    method: 'POST',
    data: {
      ids: labelIds,
      is_label_incorrect: isLabelIncorrect,
      correct_label: correctLabel,
      multiple_receiver_update: multipleReceiverUpdate,
    },
    url: `/api/v1/receivers/${sendToken}/labels?receiver_type=${receiverType}`,
    callback: response => updateReceivedLabels(sendToken, labels, response, isLabelIncorrect),
  })
);

const getRecordLink = recordId => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_RECORD_LINK',
      fail: 'FAILED_GET_RECORD_LINK',
      end: 'FINISHED_GET_RECORD_LINK',
    },
    method: 'POST',
    url: `/api/v1/records/${recordId}/link`,
  })
);

const getSecureSignedURL = (recordId, token, source) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SECURE_URL',
      fail: 'FAILED_GET_SECURE_URL',
      end: 'FINISHED_GET_SECURE_URL',
    },
    method: 'GET',
    url: `/api/v1/records/${recordId}/link/${token}?source=${source}`,
  })
);

const submitForm = (recordId, token, data) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SUBMIT_FORM',
      fail: 'FAILED_SUBMIT_FORM',
      end: 'FINISHED_SUBMIT_FORM',
    },
    method: 'POST',
    data,
    url: `/api/v1/records/${recordId}/submit_form`,
    callback: () => getSecureSignedURL(recordId, token, 'web'),
  })
);

const printOrDownloadSecureLink = (recordId, token) => (
  crud({
    dispatch: {
      begin: 'BEGIN_PRINT_SECURE_URL',
      fail: 'FAILED_PRINT_SECURE_URL',
      end: 'FINISHED_PRINT_SECURE_URL',
    },
    method: 'GET',
    url: `/api/v1/records/${recordId}/link/${token}?print=1`,
  })
);

const clearDownloadUrl = () => {
  return {
    type: 'CLEAR_DOWNLOAD_URL',
  };
};

/** Sends call to backend to resend the record to receiver.
 * @param {Object} recipient { file_token, send_token }
 */
const resendRecord = recipient => (
  crud({
    dispatch: {
      begin: 'BEGIN_RESEND_RECORD',
      fail: 'FAILED_RESEND_RECORD',
      end: 'FINISHED_RESEND_ONE_RECORD',
    },
    method: 'PUT',
    data: {
      recipient: {
        send_token: recipient.send_token,
      },
    },
    url: `/api/v1/records/${recipient.file_token}/resend/`,
  })
);

/** Sends call to backend to batch resend records.
 * @param {String} days -The no. of days the records have not been viewed.
 */
const bulkResend = days => (
  crud({
    dispatch: {
      begin: 'BEGIN_RESEND_RECORD',
      fail: 'FAILED_RESEND_RECORD',
      end: 'FINISHED_RESEND_RECORD',
    },
    method: 'PUT',
    url: `/api/v1/records/resend/${days}`,
  })
);

/** Sends call to the backend to delete receiver
 * @param {String} recordId the id of the record to revoke
 * @param {String} sendToken identifies the receiver
 */
const revokeRecordAcessById = (recordId, sendToken) => (
  crud({
    dispatch: {
      begin: 'BEGIN_REVOKE_RECORD',
      fail: 'FAILED_REVOKE_RECORD',
      end: 'FINISHED_REVOKE_RECORD',
    },
    method: 'PUT',
    data: {
      receiver: {
        send_token: sendToken,
      },
    },
    url: `/api/v1/records/${recordId}/send/`,
    callback: () => removeSentData(recordId, receiver),
  })
);

/** Uploads record through endpoint in rails.
 * @param {FormData} body
 */
const uploadRecord = body => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPLOAD_RECORD',
      fail: 'FAILED_UPLOAD_RECORD',
      end: 'FINISHED_UPLOAD_RECORD',
    },
    method: 'POST',
    data: body,
    url: '/api/v1/records/upload',
  })
);

/**
 * Let backend know that user printed record
 * @param  {Object} data { access_code, fax_code }
 */
const printGuestRecord = data => (
  crud({
    dispatch: {
      // None of these reducers exist because no state on the frontend
      // needs to be changed
      //
      // The function simply sends data which goes into the Pub/Sub pipeline
      begin: 'BEGIN_PRINT_GUEST_RECORD',
      fail: 'FAILED_PRINT_GUEST_RECORD',
      end: 'FINISHED_PRINT_GUEST_RECORD',
    },
    method: 'POST',
    url: '/api/v1/print',
    data,
  })
);

const printPatientRecord = data => (
  crud({
    dispatch: {
      begin: 'BEGIN_PRINT_GUEST_RECORD',
      fail: 'FAILED_PRINT_GUEST_RECORD',
      end: 'FINISHED_PRINT_GUEST_RECORD',
    },
    method: 'POST',
    url: '/api/v1/patients/print',
    data,
  })
);

/**
 * Concatenate multiple records together into a new record.
 * The original records aren't affected.
 * @param  {string[]} tokens[] - The file tokens to be merged.
 */
const mergeRecords = tokens => (
  crud({
    dispatch: {
      begin: 'BEGIN_MERGE_RECORDS',
      fail: 'FAILED_MERGE_RECORDS',
      end: 'FINISHED_MERGE_RECORDS',
    },
    method: 'POST',
    url: '/api/v1/records/merge',
    data: { tokens },
  })
);

/**
 * Concatenate multiple referrals together into a new record.
 * The original records aren't affected.
 * @param  {string[]} tokens[] - The send tokens to be merged.
 */
const mergeReferrals = tokens => (
  crud({
    dispatch: {
      begin: 'BEGIN_MERGE_REFERRALS',
      fail: 'FAILED_MERGE_REFERRALS',
      end: 'FINISHED_MERGE_REFERRALS',
    },
    method: 'POST',
    url: '/api/v1/receivers/merge',
    data: { tokens },
  })
);

const resendAllFailedFaxes = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_RESEND_ALL_FAILED_FAXES',
      fail: 'FAILED_RESEND_ALL_FAILED_FAXES',
      end: 'FINISHED_RESEND_ALL_FAILED_FAXES',
    },
    method: 'POST',
    url: '/api/v1/records/sent/resend_all_failed',
  })
);

const printUserRecord = record => async (dispatch) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/api/v1/print/${record}`,
      headers: {
        'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content,
      },
    });
    dispatch({
      type: 'FINISHED_PRINT_USER_RECORD',
      payload: response,
    });
    if (response.status === 201) {
      return 0;
    }
  } catch (error) {
    dispatch({
      type: 'FAILED_PRINT_USER_RECORD',
    });
    return 1;
  }
};

const downloadZip = tokens => async (dispatch) => {
  try {
    const response = await axios({
      method: 'POST',
      url: '/api/v1/records/download',
      responseType: 'arraybuffer',
      data: { tokens },
      headers: {
        'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content,
        'Content-Type': 'application/json',
        Accept: 'application/zip',
      },
      onDownloadProgress: (progress) => {
        dispatch({
          type: 'PROGRESS_DOWNLOAD_RECORDS_ZIP',
          payload: progress,
        });
      },
    });
    const currentDate = new Date().toISOString().slice(0, 10); // YYYY-MM-DD
    downloadToBrowser(response, `medsender_records_download_${currentDate}`, 'zip');
  } catch (error) {
    dispatch({
      type: 'FAILED_DOWNLOAD_RECORDS_ZIP',
      payload: {
        error:
          `We\'re sorry, but something went wrong and the download failed. Please try again
            or contact support if the issue persists.
          `,
      },
    });
  }
};

const saveAnnotationsOnRecord = (record, id) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SAVE_ANNOTATIONS_ON_RECORD',
      fail: 'FAILED_SAVE_ANNOTATIONS_ON_RECORD',
      end: 'FINISHED_SAVE_ANNOTATIONS_ON_RECORD',
    },
    method: 'POST',
    url: `/api/v1/records/${id}/create_edited_record`,
    data: record,
  })
);

const setUnsavedAnnotationsOnRecord = () => (dispatch) => {
  dispatch({
    type: 'SET_UNSAVED_EDITS_ON_RECORD',
  });
};

const updateSignatures = data => (
  crud({
    dispatch: {
      begin: 'BEGIN_SAVE_SIGNATURE',
      fail: 'FAILED_SAVE_SIGNATURE',
      end: 'FINISHED_SAVE_SIGNATURE',
    },
    method: 'PATCH',
    url: '/api/v1/signatures/',
    data,
  })
);

const getSignature = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SIGNATURE',
      fail: 'FAILED_GET_SIGNATURE',
      end: 'FINISHED_GET_SIGNATURE',
    },
    method: 'GET',
    url: '/api/v1/signatures/',
  })
);

const getAttachments = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ATTACHMENTS',
      fail: 'FAILED_GET_ATTACHMENTS',
      end: 'FINISHED_GET_ATTACHMENTS',
    },
    method: 'GET',
    url: '/api/v1/signature_attachments/',
  })
);

const getReferralReplyUrl = (url) => {
  return crud({
    dispatch: {
      begin: 'BEGIN_GET_REFERRAL_REPLY_FAX_URL',
      fail: 'FAILED_GET_REFERRAL_REPLY_FAX_URL',
      end: 'FINISHED_GET_REFERRAL_REPLY_FAX_URL',
    },
    method: 'GET',
    url,
  });
};

const getReferralReplyPreviewUrl = (sendToken, patientName, patientDOB, referringProvider, referralTemplate) => {
  return crud({
    dispatch: {
      begin: 'BEGIN_GET_REFERRAL_REPLY_PREVIEW_URL',
      fail: 'FAILED_GET_REFERRAL_REPLY_PREVIEW_URL',
      end: 'FINISHED_GET_REFERRAL_REPLY_PREVIEW_URL',
    },
    method: 'GET',
    url: `/api/v1/receivers/${sendToken}/referral_replies/preview?patient_name=${patientName}&patient_dob=${patientDOB}&referring_provider=${referringProvider}&referral_template=${referralTemplate}`
  });
};

const getReferralReplies = sendToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_REFERRAL_REPLIES',
      fail: 'FAILED_GET_REFERRAL_REPLIES',
      end: 'FINISHED_GET_REFERRAL_REPLIES',
    },
    method: 'GET',
    url: `/api/v1/receivers/${sendToken}/referral_replies`,
  })
);

const sendReferralReply = ({
  sendToken,
  selectedTemplate,
  callerName,
  patientDOB,
  patientName,
  referringProvider,
}) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SEND_REFERRAL_REPLY',
      fail: 'FAILED_SEND_REFERRAL_REPLY',
      end: 'FINISH_SEND_REFERRAL_REPLY',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/referral_replies`,
    data: {
      referral_template: selectedTemplate,
      fax_number: callerName,
      patient_dob: patientDOB,
      patient_name: patientName,
      referring_provider: referringProvider,
    },
  })
);

const saveAttachment = (blob, attachmentId) => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_ATTACHMENTS',
      fail: 'FAILED_CREATE_ATTACHMENTS',
      end: 'FINISHED_CREATE_ATTACHMENTS',
    },
    method: 'POST',
    url: '/api/v1/signature_attachments',
    data: {
      blob,
      attachment_id: attachmentId,
    },
  })
);

const deleteAttachment = attachmentId => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_ATTACHMENTS',
      fail: 'FAILED_CREATE_ATTACHMENTS',
      end: 'FINISHED_CREATE_ATTACHMENTS',
    },
    method: 'DELETE',
    url: `/api/v1/signature_attachments/${attachmentId}`,
  })
);

const splitUploadedRecord = (fileToken, pageToSplit, patientName, dob) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SPLIT_RECORD',
      fail: 'FAILED_SPLIT_RECORD',
      end: 'FINISH_SPLIT_RECORD',
    },
    method: 'POST',
    url: `/api/v1/records/${fileToken}/split`,
    data: {
      num_pages: pageToSplit,
      patient_name: patientName,
      patient_dob: dob,
    },
  })
);

const splitReceivedRecord = (token, pageToSplit, patientName, dob) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SPLIT_RECORD',
      fail: 'FAILED_SPLIT_RECORD',
      end: 'FINISH_SPLIT_RECORD',
    },
    method: 'POST',
    url: `/api/v1/receivers/${token}/split`,
    data: {
      num_pages: pageToSplit,
      patient_name: patientName,
      patient_dob: dob,
    },
  })
);

const triggerGoogleDriveUpload = sendToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPLOAD_TO_GOOGLE_DRIVE',
      fail: 'FAILED_UPLOAD_TO_GOOGLE_DRIVE',
      end: 'FINISH_UPLOAD_TO_GOOGLE_DRIVE',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/google_drive_uploads`,
  })
);

const triggerSFTPUpload = sendToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPLOAD_TO_SFTP_FOLDER',
      fail: 'FAILED_UPLOAD_TO_SFTP_FOLDER',
      end: 'FINISH_UPLOAD_TO_SFTP_FOLDER',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/sftp_uploads`,
  })
);

const triggerSharepointUpload = sendToken => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPLOAD_TO_SHAREPOINT_FOLDER',
      fail: 'FAILED_UPLOAD_TO_SHAREPOINT_FOLDER',
      end: 'FINISH_UPLOAD_TO_SHAREPOINT_FOLDER',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/sharepoint_uploads`,
  })
);

const triggerAutoUpload = ({
  sendToken,
  receiverType,
  documentCategory = '',
  fileName = '',
  patientName = '',
  patientFirstName = '',
  patientLastName = '',
  patientMiddleName = '',
  secondaryPhone = '',
  patientDOB = '',
  patientMrn = '',
  patientPhoneNumber = '',
  patientGender = '',
  patientAddress = '',
  patientZipCode = '',
  patientCity = '',
  practitionerName = '',
  chartCreationEnabled = false,
  patientEmail = '',
  patientState = '',
  referringProvider = '',
  providerOffice = '',
  primaryMemberId = '',
  primaryPlan = '',
  practiceName = '',
  primaryPolicyType = '',
  primaryGroupNumber = '',
  secondaryPlan = '',
  secondaryMemberId = '',
  secondaryPolicyType = '',
  secondaryGroupNumber = '',
  insuranceEffectiveDate = '',
  documentType = '',
  dateOfService = '',
}) => (
  crud({
    dispatch: {
      begin: 'BEGIN_AUTOUPLOAD',
      fail: 'FAILED_AUTOUPLOAD',
      end: 'FINISH_AUTOUPLOAD',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/emr_uploads`,
    data: {
      receiver_type: receiverType,
      document_category: documentCategory,
      file_name: fileName,
      patient_name: patientName,
      patient_first_name: patientFirstName,
      patient_middle_name: patientMiddleName,
      patient_last_name: patientLastName,
      secondary_phone: secondaryPhone,
      patient_dob: patientDOB,
      patient_mrn: patientMrn,
      patient_gender: patientGender,
      patient_phone_number: patientPhoneNumber,
      patient_address: patientAddress,
      patient_zip_code: patientZipCode,
      patient_city: patientCity,
      practitioner_name: practitionerName,
      create_chart_enabled: chartCreationEnabled,
      patient_email: patientEmail,
      patient_state: patientState,
      referring_provider: referringProvider,
      provider_office: providerOffice,
      primary_member_id: primaryMemberId,
      primary_plan: primaryPlan,
      practice_name: practiceName,
      policy_type: primaryPolicyType,
      group_number: primaryGroupNumber,
      secondary_plan: secondaryPlan,
      secondary_member_id: secondaryMemberId,
      secondary_policy_type: secondaryPolicyType,
      secondary_group_number: secondaryGroupNumber,
      insurance_effective_date: insuranceEffectiveDate,
      document_type: documentType,
      date_of_service: dateOfService,
    },
  })
);

const triggerSmsMessage = ({
  sendToken,
  receiverType,
  patientName,
  messageText,
  patientPhoneNumber,
}) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SMSMESSAGE',
      fail: 'FAILED_SMSMESSAGE',
      end: 'FINISH_SMSMESSAGE',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/sms_send_messages`,
    data: {
      receiver_type: receiverType,
      message_text: messageText,
      patient_name: patientName,
      patient_phone_number: patientPhoneNumber,
    },
  })
);

const triggerTaskCreation = ({
  sendToken, receiverType, patientName = '', patientFirstName = '', patientLastName = '', patientMiddleName = '', patientDOB = '', patientMrn = '', assignTo = '', task = '', taskDetails = '', dueDate = '', priority = '', notes = '', associateDoc = false,
}) => (
  crud({
    dispatch: {
      begin: 'BEGIN_TASK_CREATION',
      fail: 'FAILED_TASK_CREATION',
      end: 'FINISH_TASK_CREATION',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/emr_uploads`,
    data: {
      receiver_type: receiverType,
      patient_name: patientName,
      patient_first_name: patientFirstName,
      patient_middle_name: patientMiddleName,
      patient_last_name: patientLastName,
      patient_dob: patientDOB,
      patient_mrn: patientMrn,
      practitioner_name: assignTo,
      task,
      task_details: taskDetails,
      due_date: dueDate,
      priority,
      notes,
      associate_doc: associateDoc,
    },
  })
);

const triggerAutoUploadAndTaskCreation = ({
  sendToken, receiverType, documentCategory = '', fileName = '', patientName = '', patientFirstName = '', patientLastName = '', patientMiddleName = '', patientDOB = '', patientMrn = '', assignTo = '', task = '', taskDetails = '', dueDate = '', priority = '', notes = '', uploadAndCreateTask = true,
}) => (
  crud({
    dispatch: {
      begin: 'BEGIN_TASK_CREATION',
      fail: 'FAILED_TASK_CREATION',
      end: 'FINISH_TASK_CREATION',
    },
    method: 'POST',
    url: `/api/v1/receivers/${sendToken}/emr_uploads`,
    data: {
      receiver_type: receiverType,
      document_category: documentCategory,
      file_name: fileName,
      patient_name: patientName,
      patient_first_name: patientFirstName,
      patient_middle_name: patientMiddleName,
      patient_last_name: patientLastName,
      patient_dob: patientDOB,
      patient_mrn: patientMrn,
      practitioner_name: assignTo,
      task,
      task_details: taskDetails,
      due_date: dueDate,
      priority,
      notes,
      upload_and_create_task: uploadAndCreateTask,
    },
  })
);

const setCurrentTransmission = (record) => {
  return {
    type: 'SET_CURRENT_TRANSMISSION',
    payload: {
      transmission: record,
      currentRecordId: record.file_token,
    },
  };
};

const setCurrentRecordId = (recordId) => {
  return {
    type: 'SET_CURRENT_RECORD_ID',
    payload: {
      recordId,
    },
  };
};

const clearCurrentRecordId = () => {
  return {
    type: 'CLEAR_CURRENT_RECORD_ID',
  };
};

const clearCurrentTransmission = () => {
  return {
    type: 'CLEAR_CURRENT_TRANSMISSION',
  };
};

export {
  getUploadedRecordsByPage,
  getUploadedByQuery,
  getRecordById,
  getSentRecordsByPage,
  getSentByQuery,
  getStatusOfSentRecordById,
  getStatusOfSentPatientRecordById,
  printGuestRecord,
  printPatientRecord,
  printUserRecord,
  getReceivedRecordsByPage,
  getReferralsByPage,
  getAssignedRecordsByPage,
  getAssignedRecord,
  getReceivedByQuery,
  getAssignedByQuery,
  getReferralsQuery,
  archiveRecords,
  unarchiveRecords,
  archiveReceivedRecords,
  unarchiveReceivedRecords,
  uploadRecord,
  resendRecord,
  bulkResend,
  markDocumentAsCompleted,
  updatePatientInfo,
  updateRecordReferralNote,
  editReceivedRecordTag,
  getRecordLink,
  getSecureSignedURL,
  revokeRecordAcessById,
  mergeRecords,
  resendAllFailedFaxes,
  downloadZip,
  printOrDownloadSecureLink,
  markRecordAsViewed,
  saveAnnotationsOnRecord,
  setUnsavedAnnotationsOnRecord,
  updateSignatures,
  submitForm,
  getSignature,
  getAttachments,
  saveAttachment,
  deleteAttachment,
  duplicateRecord,
  editReceivedRecordLabels,
  splitUploadedRecord,
  splitReceivedRecord,
  triggerAutoUpload,
  triggerSmsMessage,
  triggerGoogleDriveUpload,
  triggerSFTPUpload,
  triggerSharepointUpload,
  triggerTaskCreation,
  triggerAutoUploadAndTaskCreation,
  getNextRecord,
  updateAssignedRecord,
  updateReferral,
  assignDocument,
  getReferralDocumenStatus,
  mergeReferrals,
  getReferralReplyUrl,
  getReferralReplyPreviewUrl,
  getReferralReplies,
  sendReferralReply,
  clearDownloadUrl,
  setCurrentTransmission,
  setCurrentRecordId,
  clearCurrentRecordId,
  clearCurrentTransmission,
};
