import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { DesktopSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { PAGES } from '../../shared/constants';

const ForwardToEMRButton = ({
  currentPage, ...props
}) => {
  // eslint-disable-next-line no-shadow
  const { sendToken, openModal } = props;
  const [canForward, setCanForward] = useState(false);
  const [autoUploadToEMR, setAutoUploadToEMR] = useState(false);
  const hideButton = currentPage !== PAGES.RECEIVED || !canForward || autoUploadToEMR;

  useEffect(() => {
    const setPermissions = async () => {
      setCanForward(await verifyFeatureAccess('fwd_to_emr'));
      setAutoUploadToEMR(await verifyFeatureAccess('manual_upload_emr'));
    };

    setPermissions();
  }, []);


  const forwardToEMR = async () => {
    const type = canForward ? 'FORWARD_RECORD' : 'RAISE_FLAG';
    const modalType = {
      type,
      data: sendToken,
      inboxLink: '/app/received',
      featureFlag: 'fwd_to_emr',
    };
    openModal(modalType);
  };


  return (
    <ToolbarButton
      title="Forward to EMR"
      icon={DesktopSvg}
      hide={hideButton}
      onClick={forwardToEMR}
    />
  );
};

ForwardToEMRButton.defaultProps = {
  sendToken: null,
};

ForwardToEMRButton.propTypes = {
  sendToken: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
};

export default connect(state => ({
  sendToken: state.records.sendToken,
}), { openModal })(ForwardToEMRButton);
