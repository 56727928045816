import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { parse, stringify } from 'qs';
import PropTypes from 'prop-types';

import {
  Tabs,
  Button,
  Checkbox,
  message,
  Spin,
} from 'antd';
import { Header, ChangePassword, UserInformation } from '../../index';
import AddUsers from '../modals/AddUsers';
import { changePassword } from '../../../actions/auth';
import {
  getUserInfo,
  getDocumentCategories,
  changeEmailPreference,
  changeEmailPreferenceForEmailedRecords,
  changeEmailPreferenceForAssignedRecords,
  changeEmailPreferenceForFailedFaxes,
  loginAdmin,
  changeModalState,
  getTaskTemplates,
  getTaskTemplatesByQuery,
  deleteTaskTemplate,
  getTaskProviders,
  getTaskProvidersByQuery,
  deleteTaskProvider,
  getActiveFeatures,
  enableFeature,
  disableFeature,
} from '../../../actions/userProfile';
import { BILLING_ENUMS } from '../../billing/constants';
import { addNewUser, clearAddUserModal, openModal } from '../../../actions/modal';
import { getLabelsByPage, deleteLabel, getLabelsByQuery } from '../../../actions/labels';
import { getCategoriesByPage, deleteCategory, getCategoriesByQuery } from '../../../actions/categories';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import ManageLabels from './ManageLabels';
import ManageCategories from './ManageCategories';
import ManageTaskTemplates from './ManageTaskTemplates';
import ManageProviders from './ManageProviders';
import ToggleFeatures from './ToggleFeatures';

import '../../main.scss';

const { TabPane } = Tabs;

/* global toastr */

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

const tabKeys = {
  user_info: '1',
  notifications: '2',
  manage_labels: '3',
  manage_categories: '4',
  manage_task_templates: '5',
  manage_providers: '6',
  toggle_features: '7',
  change_password: '8',
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displayModal: false,
      autoLabel: false,
    };
  }

  componentDidMount = async () => {
    const autoLabel = await verifyFeatureAccess('auto_labeling');
    this.props.getActiveFeatures();
    this.setState({ autoLabel });
  }

  redirectToAdminDashboard = () => {
    const win = window.open(`${window.location.origin}/admin`, '_blank');
    win.opener = null;
  }

  loginAsAdmin = async () => {
    if (!this.props.isAdmin) {
      this.redirectToAdminDashboard();
    }
    const { loginAdmin } = this.props;
    await loginAdmin();

    // TODO (Ammar Khan) use loginErrorMessage
    const { adminLoginSuccess, loginErrorMessage } = this.props;
    if (adminLoginSuccess) {
      const adminUrl = `${window.location.origin}/admin`;
      const win = window.open(adminUrl, '_blank');
      // if popup is blocked, we will just navigate to the admin url in the same tab
      if (!win || win?.closed) {
        window.location = adminUrl;
      } else {
        win.opener = null;
      }
    }
  }

  renderButtons = () => {
    const { isAdmin, changeModalState: changeModal, billingTrialDetails } = this.props;
    const { billing_on_file: billingOnFile } = billingTrialDetails;
    const { UPDATE_PAYMENT_METHOD } = BILLING_ENUMS;
    return (
      <div>
        {
          isAdmin
            && (
              <>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => this.setState({ displayModal: true })}
                >
                  Add Users
                </Button>
                { billingOnFile && (
                  <Button
                    type="primary"
                    onClick={() => changeModal(UPDATE_PAYMENT_METHOD, -1, true)}
                    style={{ marginRight: '10px' }}
                  >
                    Update Payment Details
                  </Button>
                )}
              </>
            )
        }
        <Button type="primary" onClick={() => this.loginAsAdmin()}>
          Admin Login
        </Button>
      </div>
    );
  }

  onCheck = async (e) => {
    this.setState({ loading: true });
    const { checked } = e.target;
    const { changeEmailPreference } = this.props;
    await changeEmailPreference(checked);
    this.setState({ loading: false });
    const { notificationErrorMessage } = this.props;
    if (notificationErrorMessage !== '') {
      toastr.error(notificationErrorMessage, 3);
    } else {
      const toastrMessage = checked
        ? 'You will now receive email notifications when receiving records'
        : 'You will no longer receive email notifications when receiving records';
      message.success({
        content: toastrMessage,
      });
    }
  }

  onCheckForEmail = async (e) => {
    this.setState({ loading: true });
    const { checked } = e.target;
    const { changeEmailPreferenceForEmailedRecords } = this.props;
    await changeEmailPreferenceForEmailedRecords(checked);
    this.setState({ loading: false });
    const { notificationErrorMessage } = this.props;
    if (notificationErrorMessage !== '') {
      toastr.error(notificationErrorMessage, 3);
    } else {
      const toastrMessage = checked
        ? 'You will now receive email notifications when receiving records via email'
        : 'You will no longer receive email notifications when receiving records via email';
      message.success({
        content: toastrMessage,
      });
    }
  }

  onCheckForAssignedEmails = async (e) => {
    this.setState({ loading: true });
    const { checked } = e.target;
    const { changeEmailPreferenceForAssignedRecords } = this.props;
    await changeEmailPreferenceForAssignedRecords(checked);
    this.setState({ loading: false });
    const { notificationErrorMessage } = this.props;
    if (notificationErrorMessage !== '') {
      toastr.error(notificationErrorMessage, 3);
    } else {
      const toastrMessage = checked
        ? 'You will now receive email notifications when being assigned records'
        : 'You will no longer receive email notifications when being assigned records';
      message.success({
        content: toastrMessage,
      });
    }
  }

  onCheckForFailedFaxes = async (e) => {
    this.setState({ loading: true });
    const { checked } = e.target;
    const { changeEmailPreferenceForFailedFaxes } = this.props;
    await changeEmailPreferenceForFailedFaxes(checked);
    this.setState({ loading: false });
    const { notificationErrorMessage } = this.props;
    if (notificationErrorMessage !== '') {
      toastr.error(notificationErrorMessage, 3);
    } else {
      const toastrMessage = checked
        ? 'You will now receive email notifications on total failed faxes sent in a day'
        : 'You will no longer receive email notifications on total failed faxes sent in a day';
      message.success({
        content: toastrMessage,
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  onTabChange = (selectedTab, _) => {
    const { history: { push } } = this.props;
    const tabKey = Object.keys(tabKeys).find(key => tabKeys[key] === selectedTab);
    push(`/app/user?tab=${tabKey}`);
  }

  render() {
    const {
      handleGetUserInfo: getUserInfo,
      passwordChangeStatus,
      passwordChangeMessage,
      addNewUser,
      modalErrorStatus,
      newUser,
      changePassword,
      modalLoading,
      notification_fax_email,
      email_notification_emailed_records,
      email_notification_assigned_records,
      failedSentFaxesNotification,
      allowedUserDomains,
      provider,
      clearAddUserModal,
      isAdmin,
    } = this.props;

    const {
      loading,
      displayModal,
      autoLabel,
    } = this.state;

    let params = {};
    if (location.search) {
      params = parse(location.search, { ignoreQueryPrefix: true });
    }

    const paramTools = {
      params,
      stringify,
      stringifyOptions,
      push: history.push,
    };

    return (
      <div>
        <Helmet>
          <title>Settings - Medsender</title>
        </Helmet>
        <div className="med-container false-dock">
          <Header
            main="Settings"
            subtext="Change and modify your user settings"
            locationPath={[
              { icon: 'home', text: 'Home', link: '/app' },
              { icon: 'account_circle', text: 'User', link: '/app/user' },
            ]}
          />
          <div style={{ textAlign: 'center' }}>
            {
              displayModal
              && (
                <AddUsers
                  submit={addNewUser}
                  show={() => this.setState({ displayModal: false })}
                  modalLoading={modalLoading}
                  modalErrorStatus={modalErrorStatus}
                  newUser={newUser}
                  clearAddUserModal={clearAddUserModal}
                />
              )
            }
            <Tabs
              style={{ padding: '0px 10px', marginLeft: '20px', marginTop: '10px' }}
              tabPosition="top"
              type="card"
              defaultActiveKey={!params.tab ? '1' : tabKeys[params.tab]}
              tabBarExtraContent={this.renderButtons()}
              onTabClick={this.onTabChange}
            >
              <TabPane tab="User Information" key="1">
                <UserInformation
                  submit={this.props.changePassword}
                  changeStatus={this.props.passwordChangeStatus === true}
                  getUserInfo={this.props.getUserInfo}
                  {...this.props}
                />
              </TabPane>
              <TabPane tab="Notifications" key="2">
                <Spin spinning={loading} size="large">
                  <div className="reset-password-container">
                    <h3 className="header" style={{ marginTop: 50 }}>Notifications</h3>
                    <Checkbox
                      onChange={this.onCheck}
                      checked={notification_fax_email}
                      style={{ marginTop: 30 }}
                    >
                      Get email notifications when records are received
                    </Checkbox>
                    <Checkbox
                      onChange={this.onCheckForEmail}
                      checked={email_notification_emailed_records}
                      style={{ marginTop: 30 }}
                    >
                      Get email notifications when records are received via email
                    </Checkbox>
                    <Checkbox
                      onChange={this.onCheckForAssignedEmails}
                      checked={email_notification_assigned_records}
                      style={{ marginTop: 30 }}
                    >
                      Get email notifications when records are assigned to you
                    </Checkbox>
                    <Checkbox
                      onChange={this.onCheckForFailedFaxes}
                      checked={failedSentFaxesNotification}
                      style={{ marginTop: 30 }}
                    >
                      Get email notifications on total failed faxes sent in a day
                    </Checkbox>
                  </div>
                </Spin>
              </TabPane>
              <TabPane tab="Manage Labels" key="3">
                <Spin spinning={loading} size="large">
                  <div className="reset-password-container">
                    <ManageLabels paramTools={paramTools} {...this.props} isAutoLabelingEnabled={autoLabel} />
                  </div>
                </Spin>
              </TabPane>
              <TabPane tab="Manage Categories" key="4">
                <Spin spinning={loading} size="large">
                  <div className="reset-password-container">
                    <ManageCategories paramTools={paramTools} {...this.props} />
                  </div>
                </Spin>
              </TabPane>
              {localStorage.m8_auto_upload_emr === 'EMA'
              && (
              <TabPane tab="Manage Task Templates" key="5">
                <Spin spinning={loading} size="large">
                  <div className="reset-password-container">
                    <ManageTaskTemplates paramTools={paramTools} {...this.props} />
                  </div>
                </Spin>
              </TabPane>
              )}
              <TabPane tab="Manage Providers" key="6">
                <Spin spinning={loading} size="large">
                  <div className="reset-password-container">
                    <ManageProviders paramTools={paramTools} {...this.props} />
                  </div>
                </Spin>

              </TabPane>
              {isAdmin
              && (
              <TabPane tab="Toggle Features" key="7">
                <Spin spinning={loading} size="large">
                  <div className="toggle-features">
                    <ToggleFeatures paramTools={paramTools} {...this.props} />
                  </div>
                </Spin>

              </TabPane>
              )}
              {
                provider !== 'microsoft_graph_auth'
                && (
                  <TabPane tab="Change Password" key="8">
                    <div style={{ marginLeft: '20px' }}>
                      <ChangePassword
                        submit={changePassword}
                        changeStatus={passwordChangeStatus === true}
                        passwordChangeMessage={passwordChangeMessage}
                      />
                    </div>
                  </TabPane>
                )
              }
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

UserProfile.defaultProps = {
  passwordChangeStatus: false,
  passwordChangeMessage: '',
  jobTitle: '',
  provider: '',
};

UserProfile.propTypes = {
  changePassword: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  handleGetUserInfo: PropTypes.func.isRequired,
  passwordChangeStatus: PropTypes.bool,
  loginAdmin: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  departments: PropTypes.array.isRequired,
  emr: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  associatedFaxNumbers: PropTypes.array.isRequired,
  adminLoginSuccess: PropTypes.bool.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
  modalLoading: PropTypes.bool.isRequired,
  passwordChangeMessage: PropTypes.string,
  notificationErrorMessage: PropTypes.string.isRequired,
  provider: PropTypes.string,
  allowedUserDomains: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  billingTrialDetails: PropTypes.object.isRequired,
  getActiveFeatures: PropTypes.func.isRequired,
  changeEmailPreference: PropTypes.func.isRequired,
  changeEmailPreferenceForEmailedRecords: PropTypes.func.isRequired,
  changeEmailPreferenceForAssignedRecords: PropTypes.func.isRequired,
  changeEmailPreferenceForFailedFaxes: PropTypes.func.isRequired,
};

export default connect(state => ({
  passwordChangeStatus: state.auth.changePasswordStatus,
  passwordChangeMessage: state.auth.changePasswordMessage,
  email: state.userProfile.email,
  phoneNumber: state.userProfile.phone_number,
  jobTitle: state.userProfile.job_title,
  emr: state.userProfile.emr,
  name: state.userProfile.name,
  adminLoginSuccess: state.userProfile.loginSuccess,
  loginErrorMessage: state.userProfile.loginErrorMessage,
  notificationErrorMessage: state.userProfile.notificationErrorMessage,
  departments: state.userProfile.departments,
  associated_fax_numbers: state.userProfile.associated_fax_numbers,
  notification_fax_email: state.userProfile.notification_fax_email,
  email_notification_emailed_records: state.userProfile.email_notification_emailed_records,
  email_notification_assigned_records: state.userProfile.email_notification_assigned_records,
  failedSentFaxesNotification: state.userProfile.failedSentFaxesNotification,
  isAdmin: state.userProfile.isAdmin,
  associatedFaxNumbers: state.userProfile.associated_fax_numbers,
  defaultFaxNumber: state.userProfile.user_fax_number,
  allowedUserDomains: state.userProfile.allowed_user_domains,
  organizationDetails: state.userProfile.organization_details,
  availableEmrs: state.userProfile.available_emrs,
  provider: state.auth.provider,
  modalLoading: state.modal.isLoading,
  modalErrorStatus: state.modal.errorStatus,
  newUser: state.modal.newUser,
  billingTrialDetails: state.auth.billing,
  allOrganizationSubscriptions: state.userProfile.allOrganizationSubscriptions,
}), {
  changePassword,
  getUserInfo,
  getDocumentCategories,
  changeEmailPreference,
  changeEmailPreferenceForEmailedRecords,
  changeEmailPreferenceForAssignedRecords,
  changeEmailPreferenceForFailedFaxes,
  loginAdmin,
  handleGetUserInfo: getUserInfo,
  addNewUser,
  clearAddUserModal,
  openModal,
  changeModalState,
  getLabelsByPage,
  deleteLabel,
  deleteCategory,
  deleteTaskTemplate,
  getLabelsByQuery,
  verifyFeatureAccess,
  getCategoriesByPage,
  getCategoriesByQuery,
  getTaskTemplates,
  getTaskTemplatesByQuery,
  getTaskProviders,
  getTaskProvidersByQuery,
  deleteTaskProvider,
  getActiveFeatures,
  enableFeature,
  disableFeature,
})(UserProfile);

export {
  UserProfile,
};
