/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { snakeCase } from 'snake-case';
import { Helmet } from 'react-helmet';
import { message, Menu } from 'antd';
import {
  CloudDownloadOutlined,
  DesktopOutlined,
  EditOutlined,
  TagOutlined,
  FolderOpenOutlined,
  CloudUploadOutlined,
  BellOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons';

import RecordsHOC from '../RecordsHOC';
import { Header } from '../../../components';
import { receivedColumns } from '../shared/constants';

import { openModal } from '../../../actions/modal';
import {
  getReceivedRecordsByPage,
  getReceivedByQuery,
  printUserRecord,
  downloadZip,
  unarchiveReceivedRecords,
  triggerAutoUpload,
  triggerGoogleDriveUpload,
  triggerSFTPUpload,
  triggerSharepointUpload,
  assignDocument,
  mergeReferrals,
} from '../../../actions/records';
import download from '../../../actions/helpers/download';
import { verifyFeatureAccess } from '../../../global/featureFlags';

/* global toastr */

class ReceivedRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canForward: false,
      autoUploadToEMR: false,
      createPatientChart: false,
      uploadToGoogleDrive: false,
      uploadToSFTPFolder: false,
      uploadToSharepointFolder: false,
      hideTaskButton: false,
      taskCreation: false,
    };
  }

  componentDidMount = async () => {
    const canForward = await verifyFeatureAccess('fwd_to_emr');
    const autoUploadToEMR = await verifyFeatureAccess('manual_upload_emr');
    const uploadToGoogleDrive = await verifyFeatureAccess('upload_google_drive');
    const createPatientChart = await verifyFeatureAccess('create_patient_chart');
    const hideTaskButton = await verifyFeatureAccess('hide_task_creation_button');
    const taskCreation = await verifyFeatureAccess('task_creation');
    const uploadToSFTPFolder = await verifyFeatureAccess('sftp_upload');
    const uploadToSharepointFolder = await verifyFeatureAccess('sharepoint_upload');
    this.setState({
      canForward,
      autoUploadToEMR,
      createPatientChart,
      uploadToGoogleDrive,
      uploadToSFTPFolder,
      uploadToSharepointFolder,
      hideTaskButton,
      taskCreation,
    });
  };

  downloadRecord = async () => {
    const {
      selectedData,
      data: allDataOnPage,
      openModal,
      downloadZip,
      printUserRecord,
    } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Download one individual record
      const file = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const {
        file_token: recordId,
        patient_name: patientName,
        record_status: recordStatus,
      } = file;

      // Users shouldn't be able to download revoked records
      if (recordStatus === 'revoked') {
        return toastr.error(
          'You can not download a record that has been revoked',
        );
      }

      // Get the signed url for the record we want to download
      await printUserRecord(recordId);
      // De-structure once signed url has been obtained so we download
      // correctly
      const { downloadUrl } = this.props;
      // snakeCase package doesn't allow passing undefined, so we need to pass an empty string if so.
      const patientNameSnakeCase = snakeCase(patientName || '');
      const customFileName = `${patientNameSnakeCase}-${recordId
        .slice(-7)
        .toLowerCase()}`;
      await download(downloadUrl, customFileName);
      return;
    }

    // Open modal to show download
    openModal({ type: 'DOWNLOAD_ZIP' });

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of file tokens to be downloaded in a single zip file
    const recordsToDownload = (selectedAll ? allDataOnPage : selectedData)
      // Don't allow users to download records that have been revoked
      // TODO (Ammar Khan) We need to enforce this on the backend
      .filter(record => record.record_status !== 'revoked')
      .map(x => x.file_token);
    await downloadZip(recordsToDownload);
  };

  handleMergeRecords = async (objects) => {
    const receiverType = objects[0].receiver_type;
    let incompatibleMerge = false;
    objects.forEach((element) => {
      if (element.receiver_type !== receiverType) {
        incompatibleMerge = true;
      }
    });
    if (!incompatibleMerge) {
      const tokens = objects.map(x => x.send_token);
      await this.props.mergeReferrals(tokens);
      location.reload();
      return;
    }
    // raise error
    return message.error({
      content: 'You can not merge incompatible record types',
    });
  };

  handleMergeAndArchiveRecords = async (objects) => {
    const receiverType = objects[0].receiver_type;
    let incompatibleMerge = false;
    objects.forEach((element) => {
      if (element.receiver_type !== receiverType) {
        incompatibleMerge = true;
      }
    });
    if (!incompatibleMerge) {
      const tokens = objects.map(x => x.send_token);
      const mergeResponse = await this.handleMergeRecords(objects);
      if (!mergeResponse?.error) {
        this.archiveRecordOpen(tokens, '/app/referred', true);
      }
    }
    // raise error
    return message.error({
      content: 'You can not merge incompatible record types',
    });
  };

  editTag = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const {
        send_token: sendToken,
        tag,
        receiver_type: receiverType,
      } = receiver;

      const modalType = {
        type: 'ADD_LABEL_TO_RECORD',
        data: {
          reportAiDetails: {
            isLabelEdited: selectedData[0].is_label_edited,
            hasWrongLabelingBeenReported: selectedData[0].has_wrong_labeling_been_reported,
            aiStatus: selectedData[0].ai_status,
          },
          record: sendToken,
          tag,
          receiverType,
        },
      };
      openModal(modalType);
      return;
    }

    if (selectedData.length > 1) {
      const sendTokens = [];
      const receiverTypes = [];
      for (let i = 0; i < selectedData.length; i++) {
        sendTokens.push(selectedData[i].send_token);
        receiverTypes.push(selectedData[i].receiver_type);
      }

      const modalType = {
        type: 'ADD_LABEL_TO_MULTIPLE_RECORDS',
        data: {
          record: sendTokens,
          receiverTypes,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData)
      .map(x => x.send_token);

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  };

  newChart = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';
    //  left this logic, though I don't think it's needed

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const {
        patient_dob: PatientDOB,
        patient_name: PatientName,
        send_token: sendToken,
        receiver_type: receiverType,
        api_credentials: apiCredentials,
      } = receiver;

      const modalType = {
        type: 'CREATE_NEW_CHART',
        data: {
          name: PatientName,
          patient_first_name: selectedData[0].patient_first_name,
          patient_middle_name: selectedData[0].patient_middle_name,
          patient_last_name: selectedData[0].patient_last_name,
          dob: PatientDOB,
          record: sendToken,
          gender: selectedData[0].patient_gender,
          phoneNumber: selectedData[0].patient_phone_number,
          address: selectedData[0].patient_address,
          state: selectedData[0].patient_state,
          zipCode: selectedData[0].patient_zip_code,
          city: selectedData[0].patient_city,
          patient_email: selectedData[0].patient_email,
          practitionerName: selectedData[0].practitioner_name,
          referring_provider: selectedData[0].referring_provider,
          provider_office: selectedData[0].provider_office,
          receiverType,
          receivedAt: selectedData[0].received_at,
          callerName: selectedData[0].caller_name,
          documentCategory: this.props.selectedData[0].document_category,
          groupNumber: this.props.selectedData[0].group_number,
          primaryMemberId: this.props.selectedData[0].primary_member_id,
          payerName: this.props.selectedData[0].payer_name,
          secondaryPayerName: this.props.selectedData[0].secondary_payer_name,
          secondaryMemberId: this.props.selectedData[0].secondary_member_id,
          secondaryGroupNumber: this.props.selectedData[0].secondary_group_number,
          apiCredentials,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData)
      .map(x => x.send_token);

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  };

  createTask = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';
    //  left this logic, though I don't think it's needed

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const {
        patient_dob: PatientDOB,
        patient_name: PatientName,
        send_token: sendToken,
        receiver_type: receiverType,
      } = receiver;

      const modalType = {
        type: 'CREATE_TASK',
        data: {
          name: PatientName,
          dob: PatientDOB,
          record: sendToken,
          practitionerName: selectedData[0].practitioner_name,
          receiverType,
          receivedAt: selectedData[0].received_at,
          callerName: selectedData[0].caller_name,
        },
      };
      openModal(modalType);
    }
  };

  notifyModal = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const { send_token: sendToken, tag } = receiver;

      const modalType = {
        type: 'NOTIFY',
        data: {
          record: sendToken,
          tag,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData)
      .map(x => x.send_token);

    const modalType = {
      type: 'NOTIFY',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  };

  editPatientOpen = () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
    const {
      send_token: sendToken,
      tag,
      receiver_type: receiverType,
    } = receiver;
    const modalType = {
      type: 'EDIT_PATIENT',
      data: {
        patient: selectedData[0],
        fromPage: 'received',
        record: sendToken,
        tag,
        receiverType,
      },
    };
    openModal(modalType);
  };

  performAction = async (recordId, action) => {
    const canFwdToEMR = await verifyFeatureAccess('fwd_to_emr');
    const type = canFwdToEMR ? action : 'RAISE_FLAG';
    const { openModal } = this.props;
    const modalType = {
      type,
      data: recordId,
      inboxLink: '/app/received',
      featureFlag: 'fwd_to_emr',
    };
    openModal(modalType);
  };

  archiveRecordOpen = (tokens, inboxLink, autoArchive) => {
    const { openModal } = this.props;
    const recordArrayData = { tokens };
    const modalType = {
      type: 'ARCHIVE_RECORD',
      data: recordArrayData,
      inboxLink,
      autoArchive,
    };
    openModal(modalType);
  };

  unarchiveRecordOpen = async (tokens) => {
    const { unarchiveReceivedRecords } = this.props;
    await unarchiveReceivedRecords(tokens);

    const { archiveErrorMessage } = this.props;
    this.setState({ completedArchiveRecord: true });
    if (archiveErrorMessage) {
      message.error({
        content: 'We were unable to unarchive the selected records. Please try again',
      });
    } else {
      message.success({
        content: 'Successfully unarchived selected record(s)',
      });
    }
    window.location.reload();
  };

  // Checks if a row containing a reply is selected. It will loop through all the selected rows and
  // check if one of the rows is a reply to a sent record. If it is, it returns true that the
  // selectedData contains a reply. Otherwise return false.
  isAReplySelected = (selectedData) => {
    for (let i = 0; i < selectedData.length; i++) {
      if (selectedData[i].uri) {
        return true;
      }
    }
    return false;
  };

  uploadToGoogleDrive = async (sendToken) => {
    const { triggerGoogleDriveUpload: triggerDriveUpload } = this.props;
    await triggerDriveUpload(sendToken);
    const { error, errorCode } = this.props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to Google Drive',
      });
    } else if (errorCode === 400) {
      message.error({
        content: 'A Google Drive Folder does not exist for the selected Fax Number. Please contact a Medsender admin to create a Fax Folder.',
      });
    } else {
      message.error({
        content: 'We were unable to upload to Google Drive. Please try again.',
      });
    }
  };

  uploadToSFTPFolder = async (sendToken) => {
    const { triggerSFTPUpload: triggerUpload } = this.props;
    await triggerUpload(sendToken);
    const { error, errorCode, errorMessage } = this.props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to SFTP Server.',
      });
    } else if (errorCode === 400) {
      message.error({
        content: 'A SFTP Folder does not exist for the selected Fax Number. Please contact a Medsender admin to create a Fax Folder.',
      });
    } else if (errorCode === 403) {
      if (errorMessage === 'No SFTP Credential found on your account.') {
        message.error({
          content: 'Your SFTP service is missing credentials. Please contact Medsender support to add credentials.',
        });
      } else {
        message.error({
          content: 'You do not have access to this feature.',
        });
      }
    } else {
      message.error({
        content: 'We were unable to upload to the SFTP Server. Please try again.',
      });
    }
  };

  uploadToSharepointFolder = async (sendToken) => {
    const { triggerSharepointUpload: triggerUpload } = this.props;
    await triggerUpload(sendToken);
    const { error, errorCode } = this.props;
    if (!error) {
      message.success({
        content: 'Successfully uploaded to Microsoft Sharepoint',
      });
    } else if (errorCode === 422) {
      message.error({
        content: 'There is an existing file with the same name as the document you are trying to upload.',
      });
    } else {
      message.error({
        content: 'We were unable to upload to Microsoft Sharepoint. Please try again.',
      });
    }
  };

  createAssignedDocument = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const { send_token: sendToken, receiver_type: receiverType } = receiver;

      const modalType = {
        type: 'ASSIGN_RECORD',
        data: {
          record: sendToken,
          receiverType,
        },
      };
      openModal(modalType);
    }
  };

  // Checks if a row of type form is selected.
  isFormRecord = selectedData => selectedData.some(data => data?.form_id);

  isEmailRecord = selectedData => selectedData.some(data => data?.attachment_id);

  autoUploadRecord = async (selectedRecord) => {
    const {
      patient_name: patientName,
      patient_dob: patientDOB,
      send_token: sendToken,
      receiver_type: receiverType,
      api_credentials: apiCredentials,
    } = selectedRecord;
    const { autoUploadEmr, currentOrganizationId } = this.props;
    // If EMR is EMA, we will allow Send Chart Modal to open as Patient MRN is an option to send with
    // chart instead of Patient Name and DOB.
    if (autoUploadEmr !== 'EMA' && (!patientName || !patientDOB)) {
      message.error({
        content: 'Cannot upload a document without a valid name and date of birth. Edit record details and try again.',
      });
    } else {
      const { triggerAutoUpload, openModal } = this.props;

      const fileNameSupported = [
        'Kareo',
        'OncoEMR',
        'Nymbl',
        'EMA',
        'AllegianceMD',
        'Elation',
      ];

      // We want to enable modal support for new ECW practices without affect the old practices' workflow
      if (
        fileNameSupported.includes(autoUploadEmr)
        || (autoUploadEmr === 'ECW' && currentOrganizationId > 4206) || (autoUploadEmr === 'CareTracker' && currentOrganizationId === 4477)
      ) {
        let recordFileName = selectedRecord.document_title;
        if (!recordFileName && selectedRecord.is_email_submission !== true) {
          recordFileName = `${selectedRecord.received_at.replace(/ /g, '_')}_${selectedRecord.caller_name.replace(/ /g, '_')}`;
        } else if (!recordFileName) {
          recordFileName = `${selectedRecord.created_at.replace(/ /g, '_')}_${selectedRecord.sender_name.replace(/ /g, '_')}`;
        }
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            fileName: recordFileName,
            providerName: this.props.selectedData[0].practitioner_name,
            patientName: this.props.selectedData[0].patient_name,
            patientDOB: this.props.selectedData[0].patient_dob,
            documentCategory: this.props.selectedData[0].document_category,
            sendToken,
            receiverType,
            autoUploadEmr,
            apiCredentials,
          },
        };
        openModal(modalType);
      } else if (autoUploadEmr === 'AdvancedMD') {
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            providerName: this.props.selectedData[0].practitioner_name, // Once we have AI extraction, this will be replaced with the extracted value
            documentCategory: this.props.selectedData[0].document_category,
            sendToken,
            receiverType,
            autoUploadEmr,
          },
        };
        openModal(modalType);
      } else {
        await triggerAutoUpload({ sendToken, receiverType });
        const { error } = this.props;
        if (!error) {
          message.success({
            content: 'Record will be uploaded shortly. We will notify you of any issues via email.',
          });
        } else {
          message.error({
            content: 'Unable to autoupload document.',
          });
        }
      }
    }
  };

  isUploadDisabled(receiverType) {
    if (receiverType === 'received_fax') {
      return false;
    }
    if (receiverType === 'email_attachment') {
      return false;
    }
    return true;
  }

  render() {
    const { selectedData, data: allDataOnPage } = this.props;
    const canEdit = !(selectedData.length === 1 && (selectedData[0].can_edit_patient_info || selectedData[0].can_edit_privacy_level));
    const selectedAll = selectedData[0] === 'all';
    const {
      canForward,
      autoUploadToEMR,
      createPatientChart,
      uploadToGoogleDrive,
      uploadToSFTPFolder,
      uploadToSharepointFolder,
      hideTaskButton,
      taskCreation,
    } = this.state;
    const viewingArchivedRecords = window.location.search.includes('archived');

    const mergeDropdownMenu = (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            this.handleMergeRecords(this.props.selectedData);
          }}
        >
          Merge Records
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            this.handleMergeAndArchiveRecords(this.props.selectedData);
          }}
        >
          Merge and Archive Records
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Helmet>
          <title>Received Records - Medsender</title>
        </Helmet>
        <Header
          main="Received records"
          subtext="Choose a record below"
          locationPath={[
            { icon: 'home', text: 'Home', link: '/app' },
            { icon: 'inbox', text: 'Received', link: '/app/received' },
          ]}
          buttons={[
            {
              text: 'Download',
              icon: <CloudDownloadOutlined />,
              disabled: !selectedData.length >= 1 || this.isAReplySelected(selectedData),
              func: () => this.downloadRecord(),
            },
            {
              text: 'Forward to EMR',
              icon: <DesktopOutlined />,
              disabled: selectedData.length !== 1 || selectedData[0] === 'all' || this.isAReplySelected(selectedData),
              hide: !canForward || autoUploadToEMR,
              func: () => this.performAction(selectedData[0].send_token, 'FORWARD_RECORD'),
            },
            {
              text: 'Send to Chart',
              icon: <CloudUploadOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !autoUploadToEMR,
              func: () => this.autoUploadRecord(selectedData[0]),
            },
            {
              text: 'Upload to Google Drive',
              icon: <CloudUploadOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || selectedData[0].receiver_type !== 'received_fax',
              hide: !uploadToGoogleDrive,
              func: () => this.uploadToGoogleDrive(selectedData[0].send_token),
            },
            {
              text: 'Create New Chart',
              icon: <CloudUploadOutlined id="createChart" />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !createPatientChart,
              func: () => this.newChart(),
              //  not sure what isAReplySelected means exactly but added as it's used in others
            },
            {
              text: 'Create Task',
              icon: <CloudUploadOutlined id="createTask" />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              hide: (hideTaskButton || !taskCreation),
              func: () => this.createTask(),
            },
            {
              text: 'Upload to SFTP Folder',
              icon: <CloudUploadOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || selectedData[0].receiver_type !== 'received_fax',
              hide: !uploadToSFTPFolder,
              func: () => this.uploadToSFTPFolder(selectedData[0].send_token),
            },
            {
              text: 'Upload to Sharepoint Folder',
              icon: <CloudUploadOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || selectedData[0].receiver_type !== 'received_fax',
              hide: !uploadToSharepointFolder,
              func: () => this.uploadToSharepointFolder(selectedData[0].send_token),
            },
            {
              text: 'Edit',
              icon: <EditOutlined />,
              disabled: canEdit || selectedData.length !== 1,
              func: this.editPatientOpen,
            },
            {
              text: selectedData.length > 1 ? 'Add Labels' : 'Add/Edit Label',
              icon: <TagOutlined />,
              disabled: selectedData.length === 0 || this.isAReplySelected(selectedData) || this.isEmailRecord(selectedData),
              func: this.editTag,
            },
            {
              text: 'Notify',
              icon: <BellOutlined />,
              disabled: selectedData.length < 1 || this.isAReplySelected(selectedData) || this.isEmailRecord(selectedData),
              func: this.notifyModal,
            },
            {
              text: 'Assign Document',
              icon: <BellOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || selectedData[0].receiver_type === 'jotform_submission',
              func: this.createAssignedDocument,
            },
            {
              text: viewingArchivedRecords ? 'Unarchive' : 'Archive',
              icon: <FolderOpenOutlined />,
              disabled: selectedData.length < 1,
              func: () => {
                const tokens = (selectedAll ? allDataOnPage : selectedData).map(x => x.send_token);
                viewingArchivedRecords ? this.unarchiveRecordOpen(tokens) : this.archiveRecordOpen(tokens, '/app/received');
              },
            },
            {
              type: 'dropdown',
              text: 'Merge Records',
              icon: <MergeCellsOutlined />,
              disabled: selectedData.length <= 1,
              menu: mergeDropdownMenu,
            },
          ]}
        />
      </div>
    );
  }
}

const passedProps = {
  getSelectedPageData: getReceivedRecordsByPage,
  getPageDataWithQuery: getReceivedByQuery,
  data: 'received_data',
  mode: 'Received',
  enableSelectAll: true,
  columnTitles: receivedColumns,
  markRecordAsReceived: true,
};

ReceivedRecords.defaultProps = {
  selectedData: [],
  downloadUrl: '',
  errorMessage: null,
  autoUploadEmr: '',
  errorCode: null,
};

ReceivedRecords.propTypes = {
  selectedData: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  downloadUrl: PropTypes.string,
  downloadZip: PropTypes.func.isRequired,
  unarchiveReceivedRecords: PropTypes.func.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  triggerGoogleDriveUpload: PropTypes.func.isRequired,
  triggerSFTPUpload: PropTypes.func.isRequired,
  triggerSharepointUpload: PropTypes.func.isRequired,
  mergeReferrals: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
  autoUploadEmr: PropTypes.string,
  currentOrganizationId: PropTypes.string.isRequired,
};

export default RecordsHOC(passedProps)(
  connect(
    state => ({
      selectedData: state.inboxTable.recordData,
      downloadUrl: state.records.downloadUrl,
      data: state.records.received_data,
      archiveErrorMessage: state.records.archiveErrorMessage,
      errorMessage: state.records.errorMessage,
      autoUploadEmr: state.auth.data.autoUploadEmr,
      documentCategories: state.auth.data.documentCategories,
      currentOrganizationId: state.auth.currentOrganizationId,
    }),
    {
      printUserRecord,
      downloadZip,
      openModal,
      unarchiveReceivedRecords,
      triggerAutoUpload,
      triggerGoogleDriveUpload,
      triggerSFTPUpload,
      triggerSharepointUpload,
      assignDocument,
      mergeReferrals,
    },
  )(ReceivedRecords),
);
