/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  DatePicker, Form, Tag, message,
} from 'antd';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Label from '../../components/Label';
import { getDocumentCategories } from '../../actions/userProfile';
import {
  setCurrentTransmission,
  updatePatientInfo,
} from '../../actions/records';
import { formatDate } from '../../global/validation';

const EditRecordForm = ({ setEditing, currentPage, ...props }) => {
  const dateFormat = ['MM/DD/YYYY']; // , 'MMDDYYYY'];
  const categoryMenu = [{ id: 0, value: null, label: '' }];

  const { currentTransmission, allDocumentCategories } = props;

  const {
    file_token: fileToken,
    document_title: documentTitle,
    patient_name: patientName,
    patient_dob: patientDOB,
    document_category: documentCategory,
    privacy_level: privacyLevel,
  } = currentTransmission;

  const [titleField, setTitleField] = useState(documentTitle || '');
  const [nameField, setNameField] = useState(patientName || '');
  const [birthField, setBirthField] = useState(patientDOB || '');
  const [categoryField, setCategoryField] = useState(documentCategory || '');
  const [formDirty, setFormDirty] = useState(false);

  useEffect(() => {
    setTitleField(documentTitle || '');
    setNameField(patientName || '');
    setBirthField(patientDOB || '');
    setCategoryField(documentCategory || '');
  }, [documentTitle, patientName, patientDOB, documentCategory]);

  useEffect(() => {
    const setDocumentCategories = async () => await props.getDocumentCategories();

    setDocumentCategories();
  }, []);

  useEffect(() => {
    const {
      allDocumentCategories: { categories },
    } = props;
    if (categories) {
      categoryMenu.push(
        ...categories.map((category) => {
          return {
            id: category.id,
            value: category.emr_category,
            label: category.emr_category,
          };
        }),
      );
    }
  }, [allDocumentCategories]);

  useEffect(() => {
    const titleChanged = (documentTitle || '') !== titleField;
    const nameChanged = patientName !== nameField;
    const birthChanged = patientDOB !== birthField;
    const categoryChanged = documentCategory !== categoryField;
    setFormDirty(
      titleChanged || nameChanged || birthChanged || categoryChanged,
    );
  }, [titleField, nameField, birthField, categoryField]);

  const handleSave = async (fields) => {
    const {
      title, name, birth, category,
    } = fields;
    const { updatePatientInfo, setCurrentTransmission } = props;

    let fromPage = currentPage === 'referred' ? 'received' : currentPage;

    if (fromPage === 'uploads') {
      fromPage = 'uploaded';
    }

    try {
      await updatePatientInfo(
        fileToken,
        formatDate(birth),
        name,
        fromPage,
        privacyLevel,
        fromPage,
        props.isDOBIncorrect || false,
        props.isNameIncorrect || false,
        title,
        category,
        props.referralNote,
        props.referringProvider,
        props.referringProviderOffice,
      );
      await setCurrentTransmission({
        ...currentTransmission,
        patient_dob: birth,
        patient_name: name,
        document_title: title,
        document_category: category,
      });
      setEditing(false);
    } catch (e) {
      message.error({
        content: 'Error saving record. Please try again',
      });
    }
  };

  return (
    <>
      <div className="record-info-title">
        <Typography variant="text" size="medium" bold>
          Edit Record Info
        </Typography>
      </div>
      <Form
        name="record-form"
        fields={[
          { name: ['title'], value: titleField },
          { name: ['name'], value: nameField },
          { name: ['birth'], value: birthField },
          { name: ['category'], value: categoryField },
        ]}
        onFinish={handleSave}
        autoComplete="on"
      >
        <div className="record-value">
          <Form.Item name="title">
            <Input
              className="title"
              label="Title"
              value={titleField}
              onChange={(e) => {
                setTitleField(String(e.target.value));
              }}
            />
          </Form.Item>
        </div>
        <div className="record-value">
          <Form.Item name="name">
            <Input
              label="Patient Full Name"
              value={nameField}
              onChange={(e) => {
                setNameField(String(e.target.value));
              }}
            />
          </Form.Item>
        </div>
        <div className="record-value">
          <Form.Item name="birth">
            <Label label="Patient Date of Birth" />
            <DatePicker
              value={birthField && moment(birthField, dateFormat)}
              onChange={(_, ds) => setBirthField(ds)}
              format={dateFormat}
            />
          </Form.Item>
        </div>
        <div className="record-value">
          <Form.Item name="category">
            <Label label="Category" />
            <Select
              options={[
                { id: 0, value: null, label: null },
                ...(allDocumentCategories?.categories || [])
                  // Filter to keep only first occurrence of each emr_category
                  .filter((category, index, self) => 
                    index === self.findIndex(c => c.emr_category === category.emr_category)
                  )
                  .map(category => ({
                    id: category.id,
                    value: category.emr_category,
                    label: category.emr_category,
                  }))
                ]}
              value={categoryField}
              onChange={(val) => {
                setCategoryField(val || '');
              }}
              allowClear
            />
          </Form.Item>
        </div>
        <div className="edit-actions">
          <Button
            type="tertiary"
            label="Cancel"
            size="small"
            onClick={() => setEditing(false)}
          />
          <Button
            type="primary"
            label="Save"
            size="small"
            htmlType="submit"
            disabled={!formDirty}
          />
        </div>
      </Form>
    </>
  );
};

EditRecordForm.defaultProps = {
  currentTransmission: {},
  isInboxPage: false,
  title: '',
};

EditRecordForm.propTypes = {
  setEditing: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  isInboxPage: PropTypes.bool,

  title: PropTypes.string,
  currentTransmission: PropTypes.object,
  allDocumentCategories: PropTypes.object.isRequired,

  getDocumentCategories: PropTypes.func.isRequired,
  updatePatientInfo: PropTypes.func.isRequired,
  setCurrentTransmission: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    allDocumentCategories: state.userProfile.allDocumentCategories,
    currentTransmission: state.records.currentTransmission,
  }),
  {
    getDocumentCategories,
    updatePatientInfo,
    setCurrentTransmission,
  },
)(EditRecordForm);
