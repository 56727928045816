import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '../../../components/Typography';
import { ClipboardSvg } from '../../../assets/img/redesign/svgPaths';
import Button from '../../../components/Button';
import COLORS from '../../../util/constants/colors';
import '../newRecordViewer.scss';

const AssignRequest = ({ createAssignedDocument, ...props }) => {
  const { currentTransmission } = props;
  const {
    assignee,
    assignee_email: assigneeEmail,
    assignee_task: assigneeTask,
  } = currentTransmission;

  return (
    <div className="assign-request">
      <Button
        type="primary"
        leftIcon={ClipboardSvg}
        size="medium"
        label="Assign Document"
        fullWidth
        onClick={createAssignedDocument}
      />
      { assignee
        ? (
          <div className="assignee">
            <div>
              <Typography variant="text" size="medium" bold>
                {assignee}
              </Typography>
              <Typography
                className="email"
                variant="text"
                size="xsmall"
                color={COLORS.TEXT_SECONDARY_LITE}
                ellipsis
              >
                {assigneeEmail}
              </Typography>
            </div>
            <div>
              <Typography variant="text" size="xsmall" bold>
                Status:
              </Typography>
              <Typography
                variant="text"
                size="xsmall"
                color={COLORS.TEXT_SECONDARY_LITE}
              >
                {assigneeTask}
              </Typography>
            </div>
          </div>
        )
        : (
          <Typography
            variant="text"
            size="medium"
            bold
            color={COLORS.TEXT_TERTIARY_LITE}
          >
            Unassigned
          </Typography>
        )
      }
      <div className="divider" />
    </div>
  );
};

AssignRequest.defaultProps = {
  currentTransmission: {},
};

AssignRequest.propTypes = {
  createAssignedDocument: PropTypes.func.isRequired,
  currentTransmission: PropTypes.object,
};

export default connect(state => ({
  currentTransmission: state.records.currentTransmission,
}), {})(AssignRequest);
