
const formatPhoneNumber = (number) => {
  if (!number || number.length !== 10) return number;
  return `${number.slice(0,3)}-${number.slice(3,6)}-${number.slice(6)}`;
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatBirthdate = (date) => {
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
}

const formatTime = (time) => {
  return new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
}

const formatRole = (role) => (
  role === 'user' ? 'User' : 'Maira™'
)

const copyValue = async (value) => {
  try {
    await navigator.clipboard.writeText(value);
    message.success({
      content:
        'Copied value',
    });
  } catch (e) {
    message.error({
      content:
        'Failed to copy value',
    });
  }
} 

const formatDuration = (seconds) => {
  if (!seconds) return '0s';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
}

export {
  formatPhoneNumber,
  capitalizeFirstLetter,
  formatBirthdate,
  formatDate,
  formatTime,
  formatRole,
  copyValue,
  formatDuration,
};
