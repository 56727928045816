import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Link from '../../components/Link';
import Typography from '../../components/Typography';
import { forgotPassword } from '../../actions/auth';
import { validSendEmail } from '../../global/validation';
import './forgotPassword.scss';
import COLORS from '../../util/constants/colors';
import NewHeader from '../NewHeader';

const ForgotPassword = ({ ...props }) => {
  const { message, error, errorMessage } = props;
  const [email, setEmail] = useState(localStorage.getItem('m8_email') || '');
  const [disabled, setDisabled] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleSubmit = () => {
    if (validSendEmail(email)) {
      setDisabled(true);
      setErrorText('');
      props.forgotPassword(email);
    } else {
      setErrorText('Not a valid email address');
    }
  };

  return (
    <>
      <NewHeader />
      <div className="forgot-password">
        <div className="email-form">
          <Typography className="forgot-title" variant="h3">Reset Your Password</Typography>
          <Form
            name="forgot-password"
            onFinish={handleSubmit}
          >
            <Form.Item>
              <Input
                label="Email"
                placeholder="user@email.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
                status={errorText && 'error'}
                description={errorText}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                label="Submit"
                htmlType="submit"
                disabled={disabled}
              />
            </Form.Item>
          </Form>
          <Link href="/login" bar label="Return to Log in" />
          <div className="message">
            {
              !error
              && (
              <Typography variant="subtitleTwo" color={COLORS.SUCCESS_LITE}>
                {message}
              </Typography>
              )
            }
            {
              error && errorMessage !== ''
              && (
              <Typography variant="subtitleTwo" color={COLORS.ERROR_LITE}>
                {errorMessage}
              </Typography>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

ForgotPassword.propTypes = {
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  forgotPassword: PropTypes.func,
};

ForgotPassword.defaultProps = {
  errorMessage: '',
  message: '',
  forgotPassword: {},
};

export default connect(state => ({
  message: state.auth.resetPasswordMessage,
  error: state.auth.error,
  errorMessage: state.auth.errorMessage,
}), { forgotPassword })(ForgotPassword);
