import React from 'react';
import FullLogo from '../../assets/img/redesign/fullLogo.png';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const LoggedOutHeader = () => {
  const breakpoint = useBreakpoint();
  return (
    <>
      <div className="left">
        <a href="https://www.medsender.com/">
          <img src={FullLogo} width="185px" alt="fullLogo.png" />
        </a>
      </div>
      {
          breakpoint !== 'xsmall'
          && (
            <div className="right">
              <Typography variant="text" size="medium" bold>
                Don't have an account?
              </Typography>
              <Button label="Sign up" href="https://www.medsender.com/sign-up?ref=loginpage" size="medium" />
            </div>
          )
        }
    </>
  );
};

export default LoggedOutHeader;
