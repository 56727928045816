/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Tag,
  message,
  Input,
  Tooltip,
  Checkbox,
  Select,
  Form,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { stringify } from 'qs';

import ModalWrapper from './ModalWrapper';

const { Search } = Input;
const { Option } = Select;

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

// The typing timer is used in the search bar. It waits until the user's
// keystrokes are idle for 1 second before sending the request
// to Rails
let typingTimer = null;

class AddLabelToRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendTokens: props.modalType.data.record,
      receiverType: props.modalType.data.receiverType,
      labels: [],
      selectedLabels: [],
      searchLoading: false,
      isDocumentWronglyLabeled: false,
      correctLabel: null,
      correctLabelValidateStatus: '',
      help: '',
    };
  }

  componentDidMount = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const page = 1;
    const pageSize = 20;
    const { getLabelsByPage } = this.props;
    await getLabelsByPage(page, pageSize);
    const { data, selectedRecord, modalType } = this.props;
    this.setState({
      labels: data,
      selectedLabels: modalType.data.selectedLabelsRecordViewer ? modalType.data.selectedLabelsRecordViewer : selectedRecord[0].labels,
    });
  }

  selectLabel = (label) => {
    const { selectedLabels } = this.state;
    const currentlySelected = [...selectedLabels];
    const indexOfSelectedLabel = currentlySelected.findIndex(selectedLabel => selectedLabel.name === label.name);
    const labelAlreadySelected = currentlySelected.find(selectedLabel => selectedLabel.name === label.name);
    if (!labelAlreadySelected) {
      this.setState({
        selectedLabels: [...currentlySelected, label],
      });
    } else {
      currentlySelected.splice(indexOfSelectedLabel, 1);
      this.setState({
        selectedLabels: currentlySelected,
      });
    }
  }

  deselectLabel = (index) => {
    const { selectedLabels } = this.state;
    const currentlySelected = selectedLabels;
    currentlySelected.splice(index, 1);
    this.setState({
      selectedLabels: [...currentlySelected],
    });
  }

  update = async (event) => {
    event.preventDefault();
    const {
      sendTokens,
      selectedLabels,
      receiverType,
      isDocumentWronglyLabeled,
      correctLabel,
    } = this.state;
    if (isDocumentWronglyLabeled && !correctLabel) {
      this.setState({
        correctLabelValidateStatus: 'error',
        help: 'Please select a label',
      });
      return;
    }
    const { editReceivedRecordLabels } = this.props;
    const selectedLabelIds = [];
    selectedLabels.map(label => selectedLabelIds.push(label.id));
    await editReceivedRecordLabels(sendTokens, selectedLabelIds, selectedLabels, receiverType, isDocumentWronglyLabeled, correctLabel);

    const { updatedLabel, hideModal } = this.props;
    if (updatedLabel) {
      hideModal();
      message.success({
        content: 'Labels changed',
      });
      this.updateRowSelected();
    }
  }

  onSearch = async (value) => {
    const { getLabelsByQuery } = this.props;
    const params = {
      page: 1,
      page_size: 20,
      contains: value,
    };
    const query = stringify(params, stringifyOptions);
    await getLabelsByQuery(query);
    const { data } = this.props;
    this.setState({
      labels: data,
      searchLoading: false,
    });
  }

  searchKeyUp = (event) => {
    this.setState({ searchLoading: true });
    // Maintain a 1 second timer before sending request
    // If any new key is pressed during the course of that
    // timer, reset the timer
    clearTimeout(typingTimer);
    const query = event.target.value;
    typingTimer = setTimeout(() => {
      this.searchQuery(query);
    }, 1000);
  }

  updateRowSelected() {
    const { selectedLabels, fileToken } = this.state;
    const { modalType, rowSelected } = this.props;
    const selectedRow = {
      labels: selectedLabels,
      file_token: fileToken,
      index: modalType.data.record.index,
    };
    rowSelected([selectedRow]);
  }

  renderLabelIncorrectCheckBox = () => {
    const { isDocumentWronglyLabeled } = this.state;
    return (
      <>
        <Checkbox
          checked={isDocumentWronglyLabeled}
          onChange={() => this.setState(prevState => ({
            isDocumentWronglyLabeled: !prevState.isDocumentWronglyLabeled,
          }))}
          disabled={false}
          style={{ marginBottom: '10px', display: 'inline' }}
        >
          Has document been wrongly labeled?
        </Checkbox>
        <Tooltip
          overlayStyle={{ marginLeft: '20px' }}
          title="Reporting errors with our AI Labeling will help us train our model and become more accurate in the future"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </>
    );
  }

  renderModal = () => {
    const { isLoading, hideModal: handleHideModal } = this.props;
    const {
      labels, selectedLabels, searchLoading, isDocumentWronglyLabeled,
    } = this.state;
    const { modalType: { data: { reportAiDetails } } } = this.props;
    const { isLabelEdited, hasWrongLabelingBeenReported, aiStatus } = reportAiDetails;
    const hasDocumentBeenClassified = !!aiStatus;
    const { onSearch } = this;
    if (isLoading) {
      return (<span> Updating labels, Please wait... </span>);
    }
    const maxAdditionalLabels = 5 - selectedLabels.length;
    const allowedAdditionalLabels = maxAdditionalLabels > 0;
    const noLabelsSelected = selectedLabels.length === 0;
    return (
      <React.Fragment>
        <div style={{ fontWeight: '800', display: 'flex', alignItems: 'flex-end' }}>
          Current Labels
          <span style={{
            color: allowedAdditionalLabels ? 'grey' : 'red',
            fontSize: '12px',
            marginBottom: '3px',
            marginLeft: '10px',
          }}
          >
            {allowedAdditionalLabels
              ? `(You can add up to ${maxAdditionalLabels} more label(s))`
              : 'Remove some current labels to add additional labels.'
            }
          </span>
        </div>
        {noLabelsSelected
          ? <div>No labels selected</div>
          : (
            selectedLabels.map((label, index) => (
              <Tag
                color={label.color}
                closable={true}
                key={`${label.name}${index}`} 
                onClose={() => this.deselectLabel(index)}
                style={{
                  color: label.is_dark_text ? 'black' : 'white',
                  marginBottom: '5px',
                }}
              >
                {label.name}
              </Tag>
            ))
          )
        }
        <p style={{ fontWeight: 800, marginTop: '40px' }}>Additional Labels</p>
        <p style={{ color: 'grey', fontSize: 14 }}>
          Navigate to
          <Link to="/app/user?tab=manage_labels" onClick={handleHideModal}> Manage Labels </Link>
          in settings to add, edit, and delete labels.
        </p>
        <Search
          placeholder="search labels"
          allowClear
          onSearch={onSearch}
          onChange={(e) => this.setState({ searchValue: e.target.value })}
          style={{ width: 200 }}
          disabled={!allowedAdditionalLabels}
          loading={searchLoading}
          onKeyUp={(e) => {
            this.setState({ searchLoading: true });
            // Maintain a 1 second timer before sending request
            // If any new key is pressed during the course of that
            // timer, reset the timer
            clearTimeout(typingTimer);
            const query = e.target.value;
            typingTimer = setTimeout(() => {
              this.onSearch(query);
            }, 1000);
          }}
        />

        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>

          {labels.map((label) => {
            const labelIsSelected = selectedLabels.find(selectedLabel => selectedLabel.name === label.name);
            const recordAlreadyHasLabel = selectedLabels.find(selectedRecordLabel => selectedRecordLabel.name === label.name);
            const labelTextColor = label.is_dark_text ? 'black' : 'white';
            return (
              <Tag
                key={label.id}
                onClick={() => !recordAlreadyHasLabel && allowedAdditionalLabels && this.selectLabel(label)}
                color={recordAlreadyHasLabel ? 'white' : label.color}
                style={{
                  color: recordAlreadyHasLabel ? label.color : labelTextColor,
                  marginBottom: '15px',
                  cursor: allowedAdditionalLabels ? 'pointer' : 'not-allowed',
                  border: recordAlreadyHasLabel ? `1px dotted ${label.color}` : (labelIsSelected && '2px solid black'),
                }}
              >
                {label.name}
              </Tag>
            );
          })}
        </div>
        {hasDocumentBeenClassified && !isLabelEdited && !hasWrongLabelingBeenReported && this.renderLabelIncorrectCheckBox()}
        <br />
        <br />
        {isDocumentWronglyLabeled && this.renderCorrectLabelInput()}
      </React.Fragment>
    );
  }

  handleChangeSelect = (value) => {
    return this.setState({
      correctLabel: value,
      correctLabelValidateStatus: '',
      help: '',
    });
  }

  renderCorrectLabelInput = () => {
    const { onSearch } = this;
    const { labels, correctLabelValidateStatus, help } = this.state;
    return (
      <Form.Item
        name="label"
        label="Correct Label"
        rules={[{ required: true }]}
        validateStatus={correctLabelValidateStatus}
        help={help}
      >
        <Select
          showSearch
          placeholder="Select a label"
          optionFilterProp="children"
          onChange={value => this.handleChangeSelect(value)}
          onSearch={onSearch}
          style={{ width: 200 }}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {labels.map(label => <Option value={label.name}>{label.name}</Option>)}
        </Select>
      </Form.Item>
    );
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.update}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={isLoading}
        modalTitle="Add/Edit Labels"
        form="addLabelToRecord"
      >
        <Form
          layout="vertical"
          onFinish={this.update}
          id="addLabelToRecord"
        >
          {this.renderModal()}
        </Form>
      </ModalWrapper>
    );
  }
}

AddLabelToRecord.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  rowSelected: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updatedLabel: PropTypes.bool.isRequired,
  editReceivedRecordLabels: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  selectedRecord: PropTypes.array.isRequired,
  getLabelsByPage: PropTypes.func.isRequired,
  getLabelsByQuery: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedLabel: state.records.updatedLabel,
  data: state.labels.data,
  selectedRecord: state.inboxTable.recordData,
}))(AddLabelToRecord);
