import React from 'react';
import PropTypes from 'prop-types';
import {
  DateSettings,
  SortSettings,
  FilterSettings,
  SearchSettings,
  CategorySearchSettings,
  StatusSearchSettings,
  AssignerSearchSettings,
  AssigneeSearchSettings,
  ReferredSearchSettings,
  VoiceSearchSettings,
} from './SettingsComponents';

import './sidebar.scss';
import '../inbox/inbox.scss';

class SidebarSettings extends React.Component {
  state = {
    dateSettingsOpen: false
  };

  handleDateSettingsToggle = (isOpen) => {
    this.setState({ dateSettingsOpen: isOpen });
  };

  render() {
    // refer to <Sidebar> for prop docs. (See ./index.jsx)
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.page === 'viewer') {
      return null;
    }
    // refer to <Sidebar> for prop docs. (See ./index.jsx)
    const {
      actions,
      paramTools,
      inboxActions,
      mode,
      location,
      filterData,
      getPageDataWithQuery,
      getLabelsByQuery,
      labels,
    } = this.props;

    if (actions) {
      return (
        <div>
          <div style={{
            width: '240px',
            paddingLeft: 18,
            marginTop: 20,
          }}
          >
            {
              !location.pathname.includes('referred') && !location.pathname.includes('voice_inbox')
              && (
                <CategorySearchSettings
                  actions={actions}
                  inboxActions={inboxActions}
                  hint="Search by Category"
                  queryType="category"
                  paramTools={paramTools}
                />
              )
            }
            {
              location.pathname.includes('referred')
              && (
                <ReferredSearchSettings
                  actions={actions}
                  inboxActions={inboxActions}
                  hint="Filter by Status"
                  queryType="referral_statuses"
                  paramTools={paramTools}
                />
              )
            }
            {
              location.pathname.includes('assigned')
              && (
                <>
                  <StatusSearchSettings
                    actions={actions}
                    inboxActions={inboxActions}
                    hint="Search by Status"
                    queryType="statuses"
                    paramTools={paramTools}
                  />
                  <AssignerSearchSettings
                    actions={actions}
                    inboxActions={inboxActions}
                    hint="Search by Assigner"
                    queryType="assigner"
                    paramTools={paramTools}
                  />
                  <AssigneeSearchSettings
                    actions={actions}
                    inboxActions={inboxActions}
                    hint="Search by Assigner"
                    queryType="assignee"
                    paramTools={paramTools}
                  />
                </>
              )
            }
            {
              (location.pathname.includes('received')
              || location.pathname.includes('assigned')
              || location.pathname.includes('referred'))
              && (
              <br />
              )}
            {
              (location.pathname.includes('received')
              || location.pathname.includes('referred'))
                && (
                  <SearchSettings
                    actions={actions}
                    inboxActions={inboxActions}
                    hint="Search by Label"
                    queryType="tag"
                    paramTools={paramTools}
                    getLabelsByQuery={getLabelsByQuery}
                    labels={labels}
                  />
                )}
            {
              !location.pathname.includes('voice_inbox')
              && (
                <>
                  <DateSettings
                    name="dob"
                    actions={actions}
                    inboxActions={inboxActions}
                    hint="Search by DOB..."
                    queryType="dob"
                    paramTools={paramTools}
                  />
                  <SortSettings
                    inboxActions={inboxActions}
                    paramTools={paramTools}
                    mode={mode}
                  />
                  <DateSettings
                    name="startDate"
                    actions={actions}
                    inboxActions={inboxActions}
                    floatingLabelText={`${mode} Date Range:`}
                    hint="Start Date"
                    paramTools={paramTools}
                    ranged={true}
                  />
                </>
              )
            }
            {
              location.pathname.includes('referred')
              && (
                  <DateSettings
                    name="dueDate"
                    actions={actions}
                    inboxActions={inboxActions}
                    floatingLabelText="Due Date Range:"
                    startHint="Start Due Date"
                    endHint="End Due Date"
                    queryType="due_date"
                    paramTools={paramTools}
                    ranged={true}
                  />
              )
            }
            {
              location.pathname.includes('voice_inbox')
              && (
                <>
                  <VoiceSearchSettings
                    actions={actions}
                    inboxActions={inboxActions}
                    hint="Search by Caller Number"
                    queryType="caller_number"
                    paramTools={paramTools}
                  />
                  <DateSettings
                    name="startDate"
                    actions={actions}
                    inboxActions={inboxActions}
                    floatingLabelText={'Call Date Range:'}
                    hint="Start Date"
                    paramTools={paramTools}
                    ranged={true}
                    onDropdownToggle={this.handleDateSettingsToggle}
                    isVoiceInbox={true}
                  />
                  <div style={{ 
                    marginTop: this.state.dateSettingsOpen ? '275px' : '0',
                    transition: 'margin-top 0.2s ease-in-out'
                  }}>
                    <SortSettings
                      inboxActions={inboxActions}
                      paramTools={paramTools}
                      mode={mode}
                    />
                    <DateSettings
                      name="dob"
                      actions={actions}
                      inboxActions={inboxActions}
                      hint="Search by DOB..."
                      queryType="dob"
                      paramTools={paramTools}
                    />
                  </div>
                </>
              )
            }
            <div style={{ 
              marginBottom: '45px',
            }}>
              <FilterSettings
                inboxActions={inboxActions}
                paramTools={paramTools}
                location={location}
                departments={filterData.departments}
                getPageDataWithQuery={getPageDataWithQuery}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

SidebarSettings.defaultProps = {
  labels: [],
};

SidebarSettings.propTypes = {
  page: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  inboxActions: PropTypes.object.isRequired,
  getLabelsByQuery: PropTypes.func.isRequired,
  paramTools: PropTypes.object,
  mode: PropTypes.string,
  getPageDataWithQuery: PropTypes.func.isRequired,
  filterData: PropTypes.shape({
    departments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  location: PropTypes.object.isRequired,
  labels: PropTypes.array,
};

SidebarSettings.defaultProps = {
  paramTools: {},
  mode: 'Uploaded',
};

export default SidebarSettings;
