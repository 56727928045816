import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ToolbarButton from './ToolbarButton';
import { LinkSimpleSvg } from '../../../assets/img/redesign/svgPaths';
import { verifyFeatureAccess } from '../../../global/featureFlags';

const SecureLinkButton = ({
  isForm, linkDialogVisible,
}) => {
  const [secureLink, setSecureLink] = useState(false);
  const hideButton = isForm || !secureLink;

  useEffect(() => {
    const setPermission = async () => {
      setSecureLink(await verifyFeatureAccess('secure_link'));
    };

    setPermission();
  }, []);

  return (
    <ToolbarButton
      title="Secure Link"
      icon={LinkSimpleSvg}
      hide={hideButton}
      onClick={linkDialogVisible}
    />
  );
};

SecureLinkButton.propTypes = {
  isForm: PropTypes.bool.isRequired,
  linkDialogVisible: PropTypes.func.isRequired,
};

export default SecureLinkButton;
