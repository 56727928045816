import React, { Component } from 'react';
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { Provider } from 'react-redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import actionCable from 'actioncable';

/* eslint import/no-extraneous-dependencies: 0 */
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';

import App from './containers/App';
import * as allReducers from './reducers';

const reducers = combineReducers({
  ...allReducers,
  router: routerReducer,
});

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

const CableApp = {};

const onStaging = window.location.hostname === 'staging.medsender.com';

let appUrl = 'app.medsender.com';
if (onStaging) {
  appUrl = 'staging.medsender.com:4443';
}

const actionCableUrl =
  process.env.NODE_ENV !== 'development'
  ? `wss://${appUrl}/cable`
  : 'ws://localhost:5000/cable';
CableApp.cable = actionCable.createConsumer(actionCableUrl);

const DevTools = createDevTools(
  <DockMonitor
    toggleVisibilityKey="ctrl-h"
    changePositionKey="ctrl-q"
    defaultIsVisible={false}
  >
    <LogMonitor theme="tomorrow" />
  </DockMonitor>,
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV === 'development') {
  console.log('Running in development!');
}
const enhancer = composeEnhancers(applyMiddleware(middleware, thunkMiddleware));

export const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  enhancer,
);

export default class Medsender extends Component {
  render() {
    if (process.env.NODE_ENV === 'development') {
      return (
        <Provider store={store}>
          <>
            <App history={history} cable={CableApp.cable} />
            <DevTools />
          </>
        </Provider>
      );
    }

    return (
      <Provider store={store}>
        <>
          <App history={history} cable={CableApp.cable} />
        </>
      </Provider>
    );
  }
}
