import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './typography.scss';

const Typography = ({
  className,
  variant,
  size,
  color,
  bold,
  button,
  ellipsis,
  children,
}) => {
  // size and button styles only apply if the 'text' variant is used

  return (
    <div
      className={classNames(
        className,
        'typography',
        variant,
        size,
        { bold },
        { button }
      )}
      style={color && { color }}
    >
      <span className={ellipsis && 'ellipsis'}>{children}</span>
    </div>
  );
};

Typography.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'subtitleOne',
    'subtitleTwo',
    'text',
  ]).isRequired,
  size: PropTypes.oneOf(['', 'xsmall', 'small', 'medium', 'large']),
  bold: PropTypes.bool,
  button: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.any.isRequired,
};

Typography.defaultProps = {
  className: '',
  size: '',
  bold: false,
  button: false,
  color: null,
};

export default Typography;
