import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, AutoComplete } from 'antd';

import './sidebar.scss';

const { Search } = Input;

// a map of placeholder messages by page type
const PLACEHOLDER_MESSAGES = {
  'voice_inbox': 'Caller Name',
  'default': 'Find a record'
};

class SidebarInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: props.paramTools.params.contains || '',
      autocompleteNameOptions: [],
    };
  }

  runAutocompleteOnSearch = (searchText) => {
    // The <AutoComplete> component of antd can autocomplete for any data on the page
    // without touching the server. It just needs an array of objects to suggest,
    // structured like so: [{ value: 'STRING_VALUE' }, ...]
    //
    // We will suggest patient names in the autocomplete for data present
    // on the page by filtering out rows of data that (1) don't have a patient name and (2) don't
    // contain the exact search text
    //
    // We will then build the array of objects the <AutoComplete> component requires
    const { data } = this.props;
    if (searchText.length > 0) {
      const isVoiceInbox = this.props.page === 'voice_inbox';
      const nameField = isVoiceInbox ? 'caller_name' : 'patient_name';

      const seenNames = new Set();
      const autocompleteNameOptions = data.filter(patient => { 
        // skip "Not mentioned" 
        if (!patient[nameField] || patient[nameField].includes('Not mentioned')) { 
          return false; 
        } 
          
        const patientNameLower = patient[nameField].toLowerCase(); 
        const searchTextLower = searchText.toLowerCase(); 

        // Only include if matches and hasn't been seen before 
        if (patientNameLower.includes(searchTextLower) && !seenNames.has(patient[nameField])) { 
          seenNames.add(patient[nameField]); 
          return true; 
        } 
        
        return false; 
      }).map((patient, index) => ({ key: index, value: patient[nameField] }));

      this.setState({
        autocompleteNameOptions,
        search: searchText,
      });
      return;
    }
    this.setState({
      autocompleteNameOptions: [],
      search: searchText,
    });
  }

  input = () => {
    // refer to <Sidebar> for prop docs. (See ./index.jsx)
    const { inboxActions, paramTools } = this.props;
    const { search, autocompleteNameOptions } = this.state;
    const { getRecords } = inboxActions;
    const placeholderMessage = PLACEHOLDER_MESSAGES[this.props.page] || PLACEHOLDER_MESSAGES.default;
    if (paramTools) {
      const { params, stringify, stringifyOptions } = paramTools;
      const searchTrigger = () => {
        const { search: newSearchQuery } = this.state;
        const { contains: existingSearchQuery } = params;
        const containsSearch = (newSearchQuery !== undefined && newSearchQuery !== null && newSearchQuery !== '');

        // Each new search should start on the first page
        let searchParams = { ...params, page: 1 };
        // We are deleting the existing query so that if the newSearchQuery
        // variable is empty, it will not send a param of ?contains= (a blank value)
        delete searchParams.contains;

        // Update with new search query
        if (containsSearch) {
          searchParams = {
            contains: newSearchQuery.trim(),
            ...searchParams,
          };
        }

        const sameQuery = (!existingSearchQuery && existingSearchQuery === newSearchQuery);
        const noQuery = (!existingSearchQuery && !containsSearch);

        // Don't send API request in these two cases
        if (sameQuery || noQuery) return;
        const query = stringify(searchParams, stringifyOptions);
        getRecords(query);
      };

        return (
          <div style={{ padding: '0px 16px' }}>
            <AutoComplete
              options={autocompleteNameOptions}
              onSelect={(value) => {
                this.setState({
                  autocompleteNameOptions: [],
                  search: value,
                }, () => {
                  searchTrigger();
                });
              }}
              onSearch={this.runAutocompleteOnSearch}
              value={search}
              notFoundContent = {"No results found"}
            >
              <Search
                size="large"
                placeholder={placeholderMessage}
                onSearch={searchTrigger}
              />
            </AutoComplete>
          </div>
          );
      }
  };

  render() {
    // refer to <Sidebar> for prop docs. (See ./index.jsx)
    const { guest, page } = this.props;
    const isViewer = page === 'viewer';
    return (
      <div style={{ width: '256px' }}>
        { !guest && !isViewer && this.input() }
      </div>
    );
  }
}

// refer to <Sidebar> for prop docs. (See ./index.jsx)
SidebarInput.propTypes = {
  guest: PropTypes.bool,
  inboxActions: PropTypes.object,
  page: PropTypes.string.isRequired,
  paramTools: PropTypes.object,
  data: PropTypes.array,
};

SidebarInput.defaultProps = {
  inboxActions: {},
  guest: false,
  paramTools: {},
  data: {},
};

export default SidebarInput;
