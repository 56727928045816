/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Badge, Dropdown, Menu } from 'antd';
import { verifyFeatureAccess } from '../../global/featureFlags';
import { openModal } from '../../actions/modal';
import { changeDefaultOrganization } from '../../actions/appActions';
import { getLoginInfo } from '../../actions/auth';
// Using Dropdown from antd means the redesign
// Button's props of 'type' and 'size' must be
// Applied to the its className since the
// Dropdown doesn't apply childrens' styles properly
import MedsenderIcon from '../../assets/img/redesign/medsender_icon.png';
import Avatar from '../../components/Avatar';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import MobileOptions from './MobileOptions';
import {
  PlusSvg,
  ContactSvg,
  UploadSvg,
  HouseSvg,
  NotificationSvg,
} from '../../assets/img/redesign/svgPaths';
import './newHeader.scss';
import { changeModalState } from '../../actions/userProfile';
import { getNotifications } from '../../actions/notifications';
import { BILLING_ENUMS } from '../../components/billing/constants';

const LoggedInHeader = ({
  ...props
}) => {
  const [canAddContact, setCanAddContact] = useState(false);
  const [referralMgmtTrial, setReferralMgmtTrial] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState('');
  const [organizationMenu, setOrganizationMenu] = useState(null);
  const onMobile = window.innerWidth < 800;

  const {
    user,
    isAdmin,
    isTrialUser,
    billingTrialDetails,
    organizationDetails: { organizations, current_organization },
    currentPath,
  } = props;

  const reloadPage = async () => {
    const { getLoginInfo } = props;
    await getLoginInfo();
    window.location.reload();
  };

  const handleChangeDefaultOrganization = async (org) => {
    const { changeDefaultOrganization } = props;
    setCurrentOrganization(org);
    await changeDefaultOrganization(org, reloadPage);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const canModify = !(
        (await verifyFeatureAccess('admin_lock_address_book')) && !isAdmin
      );
      setReferralMgmtTrial(await verifyFeatureAccess('referral_mgmt_trial'));
      if (isMounted) {
        setCanAddContact(canModify);
        props.getNotifications();
      }

      if (isMounted && current_organization) {
        setCurrentOrganization(current_organization);
      }

      if (isMounted && organizations) {
        setOrganizationMenu(
          <Menu>
            {organizations.map(org => (
              <Menu.Item key={org.name} onClick={() => handleChangeDefaultOrganization(org.name)}>
                {org.name}
              </Menu.Item>
            ))}
          </Menu>,
        );
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [current_organization, organizations, verifyFeatureAccess, isAdmin]);

  const avatarContent = user?.name
    ?.split(' ')
    .filter(Boolean)
    .map(n => n[0])
    .join('') || '';

  const hideUpgradePlan = !isAdmin
    || !(
      billingTrialDetails.plan_type?.includes('edsender_')
      || billingTrialDetails.plan_type === 'free'
      || !billingTrialDetails.plan_type
    );
  const { trial_days_remaining: trialDaysRemaining } = billingTrialDetails;
  const remainingDaysOnTrial = trialDaysRemaining;

  let buttonMessage = '';
  if (isTrialUser) {
    if (remainingDaysOnTrial > 0) {
      buttonMessage = `Upgrade: ${remainingDaysOnTrial} day(s) left in trial`;
    } else {
      buttonMessage = 'Trial expired';
    }
  } else {
    buttonMessage = 'Upgrade Plan';
  }

  const upgradePlan = () => {
    if (isAdmin && referralMgmtTrial) {
      const modalType = {
        type: 'TRIAL_EXPIRY',
      };
      props.openModal(modalType);
    } else {
      props.changeModalState(BILLING_ENUMS.PLAN_SELECTOR, -1, true);
    }
  };

  const pageNames = {
    '/app': 'Home',
    '/app/contacts': 'Contacts',
    '/app/uploads': 'Uploads',
    '/app/sent': 'Sent',
    '/app/received': 'Inbox',
    '/app/assigned': 'Assigned',
    '/app/referred': 'Referral Management',
    '/app/user': 'Settings',
    '/app/guide': 'Guide',
    '/app/unread_messages': 'Messages',
    '/app/voice_inbox': 'Voice Calls',
  };

  // Handler for the Upload Record button. Shows the modal to upload new record.
  const openUploadModal = async () => {
    const canUpload = await verifyFeatureAccess('upload_record');
    const modal = canUpload ? 'UPLOAD_RECORD' : 'RAISE_FLAG';
    const modalType = {
      type: modal,
      ...(!canUpload && { featureFlag: 'upload_record' }),
    };

    props.openModal(modalType);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={openUploadModal}>
        <span className="dropdown-icon">{UploadSvg}</span>
        Upload
      </Menu.Item>
      {canAddContact && (
        <Menu.Item onClick={() => window.location.assign('/app/contacts?add=true')}>
          <span className="dropdown-icon">{ContactSvg}</span>
          Contact
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <div className="left">
        <a href="/app">
          <img src={MedsenderIcon} width="30px" alt="fullLogo.png" />
        </a>
        <Typography variant="text" size="large" bold>
          {pageNames[currentPath]}
        </Typography>
        {organizations.length > 1 && (
          <Dropdown overlay={organizationMenu} trigger={['click']}>
            <Button className="tertiary medium" leftIcon={HouseSvg} label={currentOrganization} />
          </Dropdown>
        )}
      </div>
      <div className="right">
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="primary medium" label="New" leftIcon={PlusSvg} />
        </Dropdown>
        {!hideUpgradePlan && (
          <Button type="secondary" size="medium" label={buttonMessage} onClick={upgradePlan} />
        )}
        <Badge size="small" count={props.notifications.length} offset={[-10, 10]}>
          <Button type="tertiary" size="medium" icon={NotificationSvg} onClick={() => window.location.assign('/app/unread_messages')} />
        </Badge>
        <Avatar
          size="small"
          content={avatarContent}
          name={user?.fullName}
          org={user?.organization}
        />
        {onMobile && (
          <MobileOptions />
        )}
      </div>
    </>
  );
};

LoggedInHeader.defaultProps = {
  billingTrialDetails: {},
  user: {},
};

LoggedInHeader.propTypes = {
  user: PropTypes.object,
  isTrialUser: PropTypes.bool.isRequired,
  billingTrialDetails: PropTypes.object,
  organizationDetails: PropTypes.shape({
    organizations: PropTypes.array.isRequired,
    current_organization: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,

  openModal: PropTypes.func.isRequired,
  changeDefaultOrganization: PropTypes.func.isRequired,
  getLoginInfo: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
};

export default connect(state => ({
  user: state.auth.data,
  isTrialUser: state.auth.trialUser,
  billingTrialDetails: state.auth.billing,
  organizationDetails: state.auth.organizationDetails,
  isAdmin: state.auth.isAdmin,
  currentPath: state.appState.currentPath,
  notifications: state.notifications.notifications,
}),
{
  openModal,
  changeDefaultOrganization,
  getLoginInfo,
  getNotifications,
  changeModalState,
})(withRouter(LoggedInHeader));
