import axios from 'axios';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
import { handleError, handleUnexpectedAuthError } from '../global/error_handler';
import { fetchFeatureFlags, saveFeatureFlags, loadFeatureFlags, saveUserFlags } from '../global/featureFlags';
import crud from './helpers/crud';

export const USER_AUTH = {
  LOGGING_IN: 'LOGGING_IN',
  LOGGED_IN: 'LOGGED_IN',
  LOGGED_OUT: 'LOGGED_OUT',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_FAILED: 'USER_LOGOUT_FAILED',
  VALID: 'VALID',
  INVALID: 'INVALID',
};
// Add constants for the Resend action types
export const RESEND_OTP = {
  RESEND_OTP_START: 'RESEND_OTP_START',
  RESEND_OTP_FULFILLED: 'RESEND_OTP_FULFILLED',
  REDIRECT: 'REDIRECT',
  RESEND_OTP_FAILED: 'RESEND_OTP_FAILED',
};

export const ACCESS_CODE_AUTH = {
  BEGIN: 'BEGIN',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const ACCESS_CODE_LOGOUT = {
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

export const VERIFY_GUEST_TOKEN = {
  BEGIN: 'VERIFY_GUEST_TOKEN_BEGIN',
  SUCCESS: 'VERIFY_GUEST_TOKEN_SUCCESS',
  FAILURE: 'VERIFY_GUEST_TOKEN_FAILURE',
};

export const CREATE_GUEST_ACCOUNT = {
  BEGIN: 'CREATE_GUEST_ACCOUNT_BEGIN',
  FAILURE: 'CREATE_GUEST_ACCOUNT_FAILURE',
};

export const USER_PROFILE = {
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
};

export const PATIENT_AUTH = {
  PATIENT_LOGGING_IN: 'PATIENT_LOGGING_IN',
  PATIENT_LOGGED_IN: 'PATIENT_LOGGED_IN',
  PATIENT_LOGGED_OUT: 'PATIENT_LOGGED_OUT',
  PATIENT_LOGIN_FAILURE: 'PATIENT_LOGIN_FAILURE',
  PATIENT_LOGOUT_FAILED: 'PATIENT_LOGOUT_FAILED',
  PATIENT_VALID: 'PATIENT_VALID',
  PATIENT_INVALID: 'PATIENT_INVALID',
  RESET_PATIENT_ACCESS_ATTEMPT: 'RESET_PATIENT_ACCESS_ATTEMPT',
};

export const SECURITY_QUESTION = {
  RESEND_SECURITY_START: 'RESEND_OTP_START',
  RESEND_SECURITY_FULFILLED: 'RESEND_OTP_FULFILLED',
  PATIENT_REDIRECT: 'PATIENT_REDIRECT',
  RESEND_SECURITY_FAILED: 'RESEND_OTP_FAILED',
};

export const RESET_PASSWORD = {
  BEGIN: 'RESET_PASSWORD_BEGIN',
  FAILED: 'RESET_PASSWORD_FAILED',
  SUCCESS: 'RESET_PASSWORD_SUCCESS',
};

export const FORGOT_PASSWORD = {
  BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FAILED: 'FORGOT_PASSWORD_FAILED',
  SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
};

export const VALIDATE_RESET_TOKEN = {
  BEGIN: 'VALIDATE_TOKEN_BEGIN',
  FAILED: 'VALIDATE_TOKEN_FAILED',
  SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
};

export const LIST_ORGANIZATIONS = {
  BEGIN: 'BEGIN_CHECKING_MULTI_ORG_LOGIN',
  FAILED: 'FAILED_CHECKING_MULTI_ORG_LOGIN',
  SUCCESS: 'FINISHED_CHECKING_MULTI_ORG_LOGIN',
};

export const EXTEND_USER_SESSION = {
  BEGIN: 'BEGIN_EXTEND_USER_SESSION',
  FAILED: 'FAILED_EXTEND_USER_SESSION',
  SUCCESS: 'FINISHED_EXTEND_USER_SESSION',
};

/**
 * Remove auth credentials from local storage
 */
const resetAuth = () => {
  localStorage.removeItem('flags');
  localStorage.removeItem('user_flags');
  localStorage.removeItem('trial_user');
  localStorage.removeItem('m8_uid');
  localStorage.removeItem('m8_name');
  localStorage.removeItem('m8_org');
  localStorage.removeItem('m8_auto_upload_emr');
  localStorage.removeItem('m8_document_categories');
  localStorage.removeItem('m8_emr');
  localStorage.removeItem('m8_referral_management');
  localStorage.removeItem('m8_job_title');
  localStorage.removeItem('is_admin');
  localStorage.removeItem('default_fax_number');
  localStorage.removeItem('associated_fax_numbers');
  localStorage.removeItem('m8_has_completed_walkthrough');
  localStorage.removeItem('user_can_send_fax_records_only');
  localStorage.removeItem('phone_number');
};

const resetSentryContext = () => {
  window.raven.setUserContext();
};


/**
 * This fxn runs after authentication and if the current org is not set,
 * it shows the modal for setting the currently signed in organization
*/
const showMultiOrganizationLoginModal = async (dispatch) => {
  dispatch({ type: LIST_ORGANIZATIONS.BEGIN });
  try {
    const response = await axios({
      method: 'GET',
      url: '/api/v1/users/multiple_organization_login_enabled',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        timezone: moment.tz.guess() || 'Eastern Time (US & Canada)',
      },
    });
    dispatch({
      type: LIST_ORGANIZATIONS.SUCCESS,
      payload: response,
    });
    const modalType = {
      type: 'SELECT_ORGANIZATION',
      data: {
        ...response.data.user,
        updateDefaultOrgCookie: true,
      },
    };
    const { data: { show_multiple_organization_login_modal: showOrgModal } } = modalType;

    // Show multi-org login modal
    if (showOrgModal) {
      dispatch({
        type: 'SHOW_MODAL',
        modalType,
      });
    }
  } catch (error) {
    dispatch({
      type: LIST_ORGANIZATIONS.FAILED,
      payload: error.response,
    });

    dispatch({
      type: 'SHOW_ERROR_DISPLAY',
      payload: error.response,
    });
  }
};


/**
 * On page load, we want to make a quick determination on whether to render
 * the authenticated or unauthenticated pages to the user. Without doing an
 * additional AJAX request, we see if the server has sent back a string with
 * "true" indicating a valid user auth session, or "false"
 *
 * If success, let user through, otherwise redirect to login/
 * @return {ActionDispatch}
 */
const validateAuth = () => async (dispatch) => {
  try {
    // Server sends back a small string if user is authenticated according
    // to their session cookie
    //
    // This string is rendered in app/views/pages/index.html.erb
    const authValid = document.getElementById('_m8.a').getAttribute('m-req');
    if (authValid === 'false') {
      return dispatch({
        type: USER_AUTH.INVALID,
      });
    }
    window.raven.setUserContext({
      email: localStorage.getItem('m8_uid'),
    });

    // Fetches feature flags and saves in the localStorage.
    const featureFlags = await loadFeatureFlags();
    const trialUser = JSON.parse(localStorage.getItem('trial_user'));
    const departments = JSON.parse(localStorage.getItem('departments'));
    const defaultFaxNumber = localStorage.getItem('default_fax_number');
    const associatedFaxNumbers = JSON.parse(localStorage.getItem('associated_fax_numbers'));
    const billing = JSON.parse(localStorage.getItem('m8_billing_status'));
    const hasCompletedWalkthrough = JSON.parse(localStorage.getItem('m8_has_completed_walkthrough'));
    const userCanSendFaxRecordsOnly = JSON.parse(localStorage.getItem('user_can_send_fax_records_only'));
    const autoUploadEmr = localStorage.getItem('m8_auto_upload_emr');
    const documentCategories = localStorage.getItem('m8_document_categories');
    const planType = localStorage.getItem('m8_plan_type');
    const provider = localStorage.getItem('login_provider');

    saveFeatureFlags(featureFlags);
    await showMultiOrganizationLoginModal(dispatch);

    // TODO: Check to see if trial has expired and display banner

    return dispatch({
      type: USER_AUTH.VALID,
      payload: {
        uid: localStorage.getItem('m8_uid'),
        name: localStorage.getItem('m8_name'),
        isAdmin: JSON.parse(localStorage.getItem('is_admin')) || false,
        organization: localStorage.getItem('m8_org'),
        autoUploadEmr,
        documentCategories,
        trialUser,
        departments,
        featureFlags,
        billing,
        emr: localStorage.getItem('m8_emr'),
        referralManagement: localStorage.getItem('m8_referral_management'),
        jobTitle: localStorage.getItem('m8_job_title'),
        defaultFaxNumber,
        associatedFaxNumbers,
        hasCompletedWalkthrough,
        userCanSendFaxRecordsOnly,
        planType,
        provider,
      },
    });
  } catch (error) {
    resetSentryContext();
    return dispatch({
      type: USER_AUTH.INVALID,
    });
  }
};

/**
 * Reset the user session
 */
const extendUserSession = () => (
  crud({
    dispatch: {
      begin: EXTEND_USER_SESSION.BEGIN,
      fail: EXTEND_USER_SESSION.FAILED,
      end: EXTEND_USER_SESSION.SUCCESS,
    },
    method: 'POST',
    url: '/api/v1/session_timeout',
  })
);

/**
 * Handle user logout
 * @return {ActionDispatch}
 */
const logout = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'DELETE',
      url: '/auth/sign_out',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    });
    const newCSRFToken = response.headers['x-csrf-token'];
    document.getElementsByName('csrf-token')[0].setAttribute('content', newCSRFToken);
    dispatch({
      type: USER_AUTH.LOGGED_OUT,
    });
    // Redirect user to login
    dispatch(push('/login'));
    resetAuth();
    resetSentryContext();
  } catch (error) {
    dispatch({
      type: USER_AUTH.LOGOUT_FAILED,
    });
    resetAuth();
    resetSentryContext();
  }
};

const getLoginInfo = () => async (dispatch) => {
  dispatch({
    type: 'BEGIN_GET_LOGIN_INFO',
  });

  try {
    await axios({
      method: 'GET',
      url: '/api/v1/users',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    }).then(async (response) => {
      const {
        data: {
          name,
          email,
          trial_user: trialUser,
          organization_departments: departments,
          access_token: accessToken,
          client,
          chrome_client: chromeClient,
          chrome_access_token: chromeAccessToken,
          has_completed_walkthrough: hasCompletedWalkthrough,
          user_can_send_fax_records_only: userCanSendFaxRecordsOnly,
          provider,
          plan_type: planType,
          allowed_user_domains: allowedUserDomains,
          organization_name: organization,
          auto_upload_emr: autoUploadEmr,
          document_categories: documentCategories,
          referral_management: referralManagement,
          organization_details: organizationDetails,
          available_emrs: availableEmrs,
          customer_payment_id: customerPaymentId,
          intercom_hmac: intercomHmac,
          current_signed_in_organization_id: currentOrganizationId,
          tracking,
          referral_sms_template: referralSmsTemplate,
          referral_reply_templates: referralReplyTemplates,
        },
      } = response;

      localStorage.setItem('m8_has_completed_walkthrough', hasCompletedWalkthrough);
      localStorage.setItem('user_can_send_fax_records_only', JSON.stringify(userCanSendFaxRecordsOnly));
      window.raven.setUserContext({
        email,
      });

      const featureResponse = await fetchFeatureFlags().catch((error) => {
        return handleUnexpectedAuthError(error, dispatch, USER_AUTH.LOGIN_FAILURE);
      });

      const { data: { flags, user_flags: userFlags, departments: allDepartments } } = featureResponse;

      dispatch({
        type: USER_AUTH.LOGGED_IN,
        payload: {
          name,
          uid: email,
          trialUser,
          departments,
          flags,
          allDepartments,
          organization_name: organization,
          autoUploadEmr,
          documentCategories,
          billing: response.data.billing_status,
          emr: response.data.emr || '',
          referralManagement: response.data.referral_management,
          jobTitle: response.data.job_title || '',
          defaultFaxNumber: response.data.user_fax_number,
          associatedFaxNumbers: response.data.associated_fax_numbers,
          isAdmin: response.data['is_user_admin?'],
          hasCompletedWalkthrough,
          provider,
          planType,
          organization,
          organizationDetails,
          intercomHmac,
          currentOrganizationId,
        },
      });

      saveFeatureFlags(flags, allDepartments);
      saveUserFlags(userFlags);
      const newCSRFToken = response.headers['x-csrf-token'];
      document.getElementsByName('csrf-token')[0].setAttribute('content', newCSRFToken);

      localStorage.setItem('m8_billing_status', JSON.stringify(response.data.billing_status));

      // Sets the new access token, expiry and client token onto the localStorage.
      // Sets value to single space since there is no expiry in the response.
      localStorage.setItem('m8_access', accessToken);
      localStorage.setItem('m8_client', client);
      localStorage.setItem('m8_expiry', ' ');
      localStorage.setItem('m8_chrome_ext_access', chromeAccessToken);
      localStorage.setItem('m8_chrome_ext_client', chromeClient);
      localStorage.setItem('m8_chrome_ext_expiry', ' ');
      if (response.data.job_title) localStorage.setItem('m8_job_title', response.data.job_title);
      localStorage.setItem('m8_emr', response.data.emr || '');
      localStorage.setItem('m8_referral_management', response.data.referral_management || false);
      localStorage.setItem('m8_uid', email);
      localStorage.setItem('m8_name', name);
      localStorage.setItem('m8_org', organization);
      localStorage.setItem('m8_auto_upload_emr', autoUploadEmr);
      localStorage.setItem('m8_document_categories', documentCategories);
      localStorage.setItem('is_admin', response.data['is_user_admin?']);
      localStorage.setItem('trial_user', trialUser);
      localStorage.setItem('departments', JSON.stringify(departments) || []);
      localStorage.setItem('allDepartments', JSON.stringify(allDepartments) || []);
      localStorage.setItem('allowed_user_domains', allowedUserDomains);
      localStorage.setItem('m8_plan_type', planType);
      // Save fax numbers data
      const { user_fax_number: userFaxNumber, associated_fax_numbers: associatedFaxNumbers } = response.data;
      const defaultFaxNumber = response.data.user_fax_number !== null ? userFaxNumber : '';
      localStorage.setItem('default_fax_number', defaultFaxNumber);
      localStorage.setItem('associated_fax_numbers', JSON.stringify(associatedFaxNumbers));
      localStorage.setItem('login_provider', provider);
      localStorage.setItem('customer_payment_id', customerPaymentId);
      localStorage.setItem('tracking', tracking);
      localStorage.setItem('referral_sms_template', referralSmsTemplate);
      localStorage.setItem('referral_reply_templates', referralReplyTemplates);
    }).catch((error) => {
      const forceRefresh = error.response.data.force || false;
      const alreadySignedIn = error.response && error.response.status === 409;
      const errorMessage = handleError(error);

      dispatch({
        type: USER_AUTH.LOGIN_FAILURE,
        payload: {
          error: errorMessage,
        },
      });
      // If the API asks us to force refresh the page in the event of a CSRF
      // or cookie mismatch, wait 2 seconds before initiating
      if (forceRefresh || alreadySignedIn) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
    });
  } catch (error) {
    handleUnexpectedAuthError(error, dispatch, USER_AUTH.LOGIN_FAILURE);
  }
};

/**
 * Authenticate user with backend. This function is used for both regular auth
 * and 2FA. The rememberEmail param is not used for 2FA.
 * @param  {Object} data
 * @param  {Boolean} rememberEmail whether to remember the email or not
 * @return {Void}
 */
const login = (data, rememberEmail) => async (dispatch) => {
  dispatch({
    type: USER_AUTH.LOGGING_IN,
  });

  // This param is only used for email/password authentication and will
  // be undefined during 2FA. That's why we're checking the actual truthy
  // values before handling a response.
  if (rememberEmail === false) {
    localStorage.removeItem('m8_email');
  } else if (rememberEmail === true) {
    localStorage.setItem('m8_email', data.email);
  }

  try {
    axios({
      method: 'POST',
      url: '/auth/sign_in',
      data: {
        user: data,
        timezone: moment.tz.guess() || 'Eastern Time (US & Canada)',
        os: window.navigator.platform,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    }).then(async (response) => {
      // Response status 201 means login is successful
      // 200 means 2FA is needed, so redirect to login page to show the OTP form

      const {
        data: {
          name,
          email,
          trial_user,
          organization_departments: departments,
          access_token,
          client,
          chrome_access_token,
          chrome_client,
          organization_name: organizationName,
          auto_upload_emr: autoUploadEmr,
          document_categories: documentCategories,
          referral_management: referralManagement,
          allowedUserDomains,
          has_completed_walkthrough: hasCompletedWalkthrough,
          user_can_send_fax_records_only: userCanSendFaxRecordsOnly,
          billing_status: billingStatus,
          provider,
          organization_details: organizationDetails,
          available_emrs: availableEmrs,
          customer_payment_id: customerPaymentId,
          intercom_hmac: intercomHmac,
          tracking,
          current_signed_in_organization_id: currentOrganizationId,
          referral_sms_template: referralSmsTemplate,
          referral_reply_templates: referralReplyTemplates,
        },
      } = response;

      if (response.status === 201) {
        // remove the auth token from localStorage since the user will not need
        // to click resend code.
        localStorage.removeItem('otp_auth_token');
        localStorage.setItem('m8_has_completed_walkthrough', hasCompletedWalkthrough);
        window.raven.setUserContext({
          email: data.email,
        });

        // Fetches feature flag data
        const featureResponse = await fetchFeatureFlags().catch((error) => {
          return handleUnexpectedAuthError(error, dispatch, USER_AUTH.LOGIN_FAILURE);
        });

        const { data: { flags, user_flags: userFlags, departments: allDepartments } } = featureResponse;

        dispatch({
          type: USER_AUTH.LOGGED_IN,
          payload: {
            name,
            uid: email,
            trialUser: trial_user,
            departments,
            flags,
            allDepartments,
            billing: billingStatus,
            emr: response.data.emr || '',
            jobTitle: response.data.job_title || '',
            defaultFaxNumber: response.data.user_fax_number,
            associatedFaxNumbers: response.data.associated_fax_numbers,
            isAdmin: response.data['is_user_admin?'],
            organization_name: response.data.organization_name,
            autoUploadEmr: response.data.auto_upload_emr,
            documentCategories: response.data.document_categories,
            referralManagement: response.data.referral_management,
            hasCompletedWalkthrough,
            userCanSendFaxRecordsOnly,
            organizationDetails,
            intercomHmac,
            currentOrganizationId,
          },
        });

        saveFeatureFlags(flags);
        saveUserFlags(userFlags);
        await showMultiOrganizationLoginModal(dispatch);
        // Update the CSRF token to match the authenticated session cookie
        const newCSRFToken = response.headers['x-csrf-token'];
        document.getElementsByName('csrf-token')[0].setAttribute('content', newCSRFToken);
        localStorage.setItem('m8_billing_status', JSON.stringify(response.data.billing_status));

        // Sets the new access token, expiry and client token onto the localStorage.
        // Sets value to single space since ther is no expiry in the response.
        localStorage.setItem('m8_access', access_token);
        localStorage.setItem('m8_client', client);
        localStorage.setItem('m8_expiry', ' ');
        localStorage.setItem('m8_chrome_ext_access', chrome_access_token);
        localStorage.setItem('m8_chrome_ext_client', chrome_client);
        localStorage.setItem('m8_chrome_ext_expiry', ' ');
        if (response.data.job_title) localStorage.setItem('m8_job_title', response.data.job_title)
        localStorage.setItem('m8_emr', response.data.emr);
        localStorage.setItem('m8_referral_management', response.data.referral_management);

        // Save fax numbers data
        const { user_fax_number: userFaxNumber, associated_fax_numbers: associatedFaxNumbers } = response.data;
        const defaultFaxNumber = response.data.user_fax_number !== null ? userFaxNumber : '';
        localStorage.setItem('default_fax_number', defaultFaxNumber);
        localStorage.setItem('associated_fax_numbers', JSON.stringify(associatedFaxNumbers));
        localStorage.setItem('user_can_send_fax_records_only', JSON.stringify(userCanSendFaxRecordsOnly));
        localStorage.setItem('m8_plan_type', billingStatus.plan_type);
        localStorage.setItem('allDepartments', JSON.stringify(allDepartments) || []);
        localStorage.setItem('phone_number', response.data.phone_number);
      } else if (response.status === 200) {
        // store variables in localStorage to be accesed by the Login component
        localStorage.setItem('needs2FA', true);
        localStorage.setItem('email', data.email);
        localStorage.setItem('otp_auth_token', response.data.otp_auth_token);
        localStorage.setItem('phone_number', response.data.phone_number);
        // call the RESEND_OTP.REDIRECT action which will redirect to login page.
        dispatch({
          type: RESEND_OTP.REDIRECT,
        });
      }
      // Store the user email and name in localStorage
      localStorage.setItem('m8_uid', email);
      localStorage.setItem('m8_name', name);
      localStorage.setItem('trial_user', trial_user);
      localStorage.setItem('m8_org', organizationName);
      localStorage.setItem('m8_auto_upload_emr', autoUploadEmr);
      localStorage.setItem('m8_document_categories', documentCategories);
      localStorage.setItem('is_admin', response.data['is_user_admin?']);
      localStorage.setItem('departments', JSON.stringify(departments));
      localStorage.setItem('login_provider', provider);
      localStorage.setItem('customer_payment_id', customerPaymentId);
      localStorage.setItem('tracking', tracking);
      localStorage.setItem('referral_sms_template', referralSmsTemplate);
      localStorage.setItem('referral_reply_templates', referralReplyTemplates);
    }).catch((error) => {
      const forceRefresh = error.response.data.force || false;
      const alreadySignedIn = error.response && error.response.status === 409;
      const errorMessage = handleError(error);
      // Remove from localStorage if an OTP error is encountered, keep the
      // auth token in case the user clicks resend code
      if (errorMessage === 'The verification code was incorrect or expired, please try again.') {
        localStorage.setItem('needs2FA', true);
        localStorage.setItem('email', data.email);
      }
      dispatch({
        type: USER_AUTH.LOGIN_FAILURE,
        payload: {
          error: errorMessage,
        },
      });
      // If the API asks us to force refresh the page in the event of a CSRF
      // or cookie mismatch, wait 2 seconds before initiating
      if (forceRefresh || alreadySignedIn) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
    });
  } catch (error) {
    handleUnexpectedAuthError(error, dispatch, USER_AUTH.LOGIN_FAILURE);
  }
};

// Action dispatched when the Resend Security code link is clicked
const resendOtp = data => async (dispatch) => {
  localStorage.setItem('needs2FA', true);
  // To show the loader after making the resendOtp request
  dispatch({
    type: RESEND_OTP.RESEND_OTP_START,
    payload: {
      sentViaEmail: !data.send_otp_via_sms,
    },
  });
  // Make a call to the resend code endpoint
  try {
    axios({
      method: 'POST',
      url: '/auth/resend_token',
      data: {
        user: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    }).then((response) => {
      // If a response OK is returned, show a toastr message, store variables to localStorage
      // and dispatch the action
      /* global toastr */
      toastr.success(response.data.info);
      localStorage.setItem('needs2FA', true);
      localStorage.setItem('email', data.email);
      localStorage.setItem('otp_auth_token', response.data.otp_auth_token);

      dispatch({
        type: RESEND_OTP.RESEND_OTP_FULFILLED,
        payload: {
          otp_auth_token: response.data.otp_auth_token,
          email: data.email,
          sentViaEmail: !data.send_otp_via_sms,
        },
      });
      // Update the CSRF token to match the authenticated session cookie
      const newCSRFToken = response.headers['x-csrf-token'];
      document.getElementsByName('csrf-token')[0].setAttribute('content', newCSRFToken);
      // Dispatch this action if theres an error returned from the backend
    }).catch((error) => {
      dispatch({
        type: RESEND_OTP.RESEND_OTP_FAILED,
        payload: {
          error: error.response.data.error,
          sentViaEmail: !data.send_otp_via_sms,
        },
      });
    });
  } catch (error) {
    handleUnexpectedAuthError(error, dispatch, USER_AUTH.LOGIN_FAILURE);
  }
};

// Action dispatched when the Start Over link is clicked
const redirectToLogin = () => async (dispatch) => {
  dispatch({
    type: RESEND_OTP.REDIRECT,
  });
};

const accessCodeAuth = (payload, gcaptcha, checked) => async (dispatch) => {
  const { 
    receiver_fax: faxCode,
    access_code: accessCode,
  } = payload;
  dispatch({
    type: ACCESS_CODE_AUTH.BEGIN,
  });
  try {
    const response = await axios({
      method: 'POST',
      url: '/api/v1/fax',
      headers: {
        'X-CAPTCHA': gcaptcha,
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      data: { access_fax: payload },
    });
    window.raven.setUserContext({
      fax: faxCode,
    });
    const { 
      send_token: sendToken,
      has_reply: hasResponse,
      subject,
      source,
      recipient_name: recipientName,
      signature_required: signatureRequired,
      should_flatten: shouldFlatten,
    } = response.data;
    dispatch({
      type: ACCESS_CODE_AUTH.SUCCESS,
      payload: {
        url: response.data.signed_url,
        fax: {
          fax_code: faxCode,
          access_code: accessCode,
          source,
        },
        shouldFlatten,
        sendToken,
        hasResponse,
        subject,
        recipientName,
        signatureRequired,
      },
    });
    // Remember the fax number code for future sign ins
    if (checked === true) {
      localStorage.setItem('m8_fax', faxCode);
    } else {
      localStorage.removeItem('m8_fax');
    }

    // Store guest token in localStorage
    localStorage.setItem('m8_guest_token', response.data.guest_token);

    // Remove invalid login flag to allow user to login without captcha.
    localStorage.removeItem(faxCode);

    dispatch(push('/app/access'));
  } catch (error) {
    // Add fax number to localStorage to force user to login with captcha.
    localStorage.setItem(faxCode, 1);

    // Customize error message when record is revoked
    let errorMessage;
    if (error.response.status === 403) {
      errorMessage = error.response.data.message || error.response.data.error;
    } else {
      errorMessage = 'Invalid access or fax code entered. The code is case sensitive.';
    }

    dispatch({
      type: ACCESS_CODE_AUTH.FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  }
};

const logoutGuest = () => async (dispatch) => {
  dispatch({
    type: ACCESS_CODE_LOGOUT.LOGOUT_SUCCESS,
  });
  dispatch(push('/app/access_code'));
};

const verifyGuestToken = () => async (dispatch) => {
  dispatch({
    type: VERIFY_GUEST_TOKEN.BEGIN,
  });
  axios({
    method: 'POST',
    url: '/api/v1/fax/verify_guest',
    headers: {
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
    },
    data: {
      guest_token: localStorage.getItem('m8_guest_token'),
    },
  }).then((response) => {
    dispatch({
      type: VERIFY_GUEST_TOKEN.SUCCESS,
      payload: {
        receiver_name: response.status === 200 ? response.data.receiver_name : '',
      },
    });
  }).catch((error) => {
    localStorage.removeItem('m8_guest_token');
    if (error.response && error.response.status === 400) {
      dispatch({
        type: VERIFY_GUEST_TOKEN.FAILURE,
        payload: {
          error: error.response.data.error,
        },
      });
    } else {
      dispatch({
        type: VERIFY_GUEST_TOKEN.FAILURE,
        payload: {
          error: 'There was an issue processing your request on our end. Please try again later.',
        },
      });
    }
  });
};

const redirectToCreateGuestAccount = () => async (dispatch) => {
  dispatch(push('/app/access/sign_up'));
};

const createGuestAccount = (name, email, password, passwordConfirmation) => async (dispatch) => {
  dispatch({
    type: CREATE_GUEST_ACCOUNT.BEGIN,
  });
  axios({
    method: 'POST',
    url: '/auth/sign_up/guest',
    data: {
      user: {
        name,
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
      guest_token: localStorage.getItem('m8_guest_token'),
    },
    headers: {
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
    },
  }).then((response) => {
    toastr.success('Successfully created your account!');
    localStorage.removeItem('m8_guest_token');
    localStorage.setItem('otp_auth_token', response.data.otp_auth_token);
    localStorage.setItem('email', email);
    localStorage.setItem('needs2FA', true);

    // call the RESEND_OTP.REDIRECT action which will reset the global state.
    dispatch({
      type: RESEND_OTP.REDIRECT,
    });

    // Redirect to login page.
    dispatch(push('/login'));
  }).catch((error) => {
    if (error.response && error.response.status === 400) {
      // If we got back a 400 response, that means there was an issue with the body. Stay on page,
      // and display the error.
      dispatch({
        type: CREATE_GUEST_ACCOUNT.FAILURE,
        payload: {
          error: error.response.data.error,
        },
      });
    } else if (error.response && error.response.status === 401) {
      localStorage.removeItem('m8_guest_token');
      dispatch({
        type: VERIFY_GUEST_TOKEN.FAILURE,
        payload: {
          error: 'Your session has expired, please login again to create your account.',
        },
      });
    } else {
      // Send error to sentry.
      window.raven.captureException(error);
      localStorage.removeItem('m8_guest_token');
      dispatch({
        type: VERIFY_GUEST_TOKEN.FAILURE,
        payload: {
          error: 'There was an issue processing your request on our end. Please try again later.',
        },
      });
    }
  });
};

const patientLogin = data => async (dispatch) => {
  // The patient portal must be accessed using a uniquely generated link for
  // each record. If the right parameters are not in the URL, we abort the
  // request
  if (!data.token || !data.file_token) {
    return dispatch({
      type: PATIENT_AUTH.PATIENT_LOGIN_FAILURE,
      payload: {
        error: 'Your credentials were not accepted. Please access the original link sent to you and try again.',
      },
    });
  }
  dispatch({
    type: PATIENT_AUTH.PATIENT_LOGGING_IN,
  });
  try {
    axios({
      method: 'POST',
      url: '/api/v1/patients/sign_in',
      data: {
        patient: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    }).then((response) => {
      // store variables in localStorage to be accessed by the Login component
      localStorage.setItem('m8_needs_security', true);
      if (response.data.security_question) {
        // Store question in localStorage.
        localStorage.setItem('m8_security_question', response.data.security_question);
      }
      localStorage.setItem('m8_patient_recipient', data.recipient);
      localStorage.setItem('m8_file_token', data.file_token);
      localStorage.setItem('m8_auth_token', response.data.auth_token);
      dispatch({
        type: SECURITY_QUESTION.PATIENT_REDIRECT,
      });
    }).catch((error) => {
      const errorMessage = handleError(error);
      dispatch({
        type: PATIENT_AUTH.PATIENT_LOGIN_FAILURE,
        payload: {
          error: errorMessage,
        },
      });
    });
  } catch (error) {
    handleUnexpectedAuthError(error, dispatch, PATIENT_AUTH.PATIENT_LOGIN_FAILURE);
  }
};

const patientSecurityLogin = data => async (dispatch) => {
  dispatch({
    type: PATIENT_AUTH.PATIENT_LOGGING_IN,
  });
  try {
    axios({
      method: 'POST',
      url: '/api/v1/patients/validate',
      data: {
        patient: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    }).then((response) => {
      if (response.status === 200) {
        // store variables in localStorage to be accesed by the Login component
        localStorage.setItem('m8_needs_security', true);
        if (response.data.security_question) {
          // Store question in localStorage.
          localStorage.setItem('m8_security_question', response.data.security_question);
        }
        localStorage.setItem('m8_patient_recipient', data.recipient);
        localStorage.setItem('m8_auth_token', response.data.auth_token);
        dispatch({
          type: PATIENT_AUTH.PATIENT_LOGGED_IN,
          payload: {
            url: response.data.signed_url,
            auth_token: data.auth_token,
            security_token: data.file_token,
            recipient: data.recipient,
            sendToken: response.data.send_token,
            recipientName: response.data.recipient_name,
            recipientEmail: response.data.recipient,
            hasResponse: response.data.has_reply,
            signatureRequired: response.data.signature_required,
            disableSecureReplies: response.data.disable_replies,
            should_flatten: response.data.should_flatten,
            nameToSignatureRequired: response.data.name_to_signature_required,
          },
        });
        window.raven.setUserContext({
          patient: data.email,
        });

        dispatch(push('/app/patient_access'));
      }
    }).catch((error) => {
      const errorMessage = handleError(error);
      // Remove from localStorage if Security Question error is encountered, keep the
      // auth token in case the user clicks resend code
      if (errorMessage === 'Your answer was incorrect. Please try again or contact Medsender support.') {
        localStorage.setItem('m8_needs_security', true);
        localStorage.setItem('m8_security_question', data.question);
        localStorage.setItem('m8_patient_recipient', data.recipient);
        localStorage.setItem('m8_auth_token', data.auth_token);
        localStorage.setItem('m8_file_token', data.file_token);
      }
      dispatch({
        type: PATIENT_AUTH.PATIENT_LOGIN_FAILURE,
        payload: {
          error: errorMessage,
        },
      });
    });
  } catch (error) {
    handleUnexpectedAuthError(error, dispatch, PATIENT_AUTH.PATIENT_LOGIN_FAILURE);
  }
};

/**
 * Makes request to set the patient's security question and answer. Will log the patient in and
 * redirect to record viewer on success.
 */
const patientSetQuestionAndLogin = data => async (dispatch) => {
  dispatch({
    type: PATIENT_AUTH.PATIENT_LOGGING_IN,
  });
  try {
    axios({
      method: 'PUT',
      url: '/api/v1/patients',
      data: {
        patient: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    }).then((response) => {
      if (response.status === 200) {
        // store variables in localStorage to be accessed by the Login component
        localStorage.setItem('m8_needs_security', true);
        localStorage.setItem('m8_patient_recipient', data.recipient);
        localStorage.setItem('m8_auth_token', response.data.auth_token);

        dispatch({
          type: PATIENT_AUTH.PATIENT_LOGGED_IN,
          payload: {
            url: response.data.signed_url,
            auth_token: data.auth_token,
            security_token: data.file_token,
            email: data.email,
            sendToken: response.data.send_token,
            recipientName: response.data.recipient_name,
            recipient: data.recipient,
            recipientEmail: response.data.recipient,
            hasResponse: response.data.has_reply,
            signatureRequired: response.data.signature_required,
            disableSecureReplies: response.data.disable_replies,
            should_flatten: response.data.should_flatten,
            nameToSignatureRequired: response.data.name_to_signature_required,
          },
        });

        dispatch(push('/app/patient_access'));
      }
    }).catch((error) => {
      const errorMessage = handleError(error);
      dispatch({
        type: PATIENT_AUTH.PATIENT_LOGIN_FAILURE,
        payload: {
          error: errorMessage,
        },
      });
    });
  } catch (error) {
    handleUnexpectedAuthError(error, dispatch, PATIENT_AUTH.PATIENT_LOGIN_FAILURE);
  }
};

// TODO (Ammar Khan)
// Align these routes with backend properly
const changePassword = data => async (dispatch) => {
  try {
    await axios({
      method: 'PATCH',
      url: '/auth/password',
      data: {
        user: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    });
    dispatch({
      type: USER_PROFILE.CHANGE_PASSWORD_SUCCESS,
      payload: {
        message: 'Password changed successfully',
        success: true,
      },
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE.CHANGE_PASSWORD_FAILED,
      payload: {
        message: error.response.data.error,
        success: false,
      },
    });
  }
};

/** Checks if the reset password token is valid
 * @param {Object} - data - data object sent as params
 * @param {string} - data.reset_password_token
 */
const validateResetToken = (data, passwordSet) => async (dispatch) => {
  dispatch({
    type: VALIDATE_RESET_TOKEN.BEGIN,
  });

  try {
    const response = await axios({
      method: 'POST',
      url: '/auth/password/validate',
      data: {
        password: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    });
    dispatch({
      type: VALIDATE_RESET_TOKEN.SUCCESS,
      payload: {
        message: response.message,
        resetPasswordEmail: response.data.email,
        resetPasswordToken: data.reset_password_token,
        passwordSet,
      },
    });
  } catch (error) {
    let errorMessage = 'Reset password request is invalid or has expired.\n' +
     'Please contact your administrator.';
    if (error.response.status === 400) {
      errorMessage = error.response.data.error;
    }

    dispatch({
      type: VALIDATE_RESET_TOKEN.FAILED,
      payload: {
        message: errorMessage,
      },
    });
    // Displays error message
    toastr.error(errorMessage);
  }
};

const forgotPassword = email => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD.BEGIN });
  try {
    const response = await axios({
      method: 'POST',
      url: '/auth/password/reset_password',
      data: {
        email,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    });
    dispatch({
      type: FORGOT_PASSWORD.SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: FORGOT_PASSWORD.FAILED,
      payload: e.response,
    });
  }
};

/** Reset password and updates the store
 * @param {Object} - data - data object sent as params
 * @param {string} - data.password
 * @param {string} - data.confirm_password
 * @param {string} - data.reset_password_token
 */
const resetPassword = data => async (dispatch) => {
  dispatch({
    type: RESET_PASSWORD.BEGIN,
  });
  try {
    if (data.password === '' || data.password_confirmation === '') {
      throw new Error('Password or the confirmation field cannot be blank.');
    }

    const response = await axios({
      method: 'PUT',
      url: '/auth/password/reset_password',
      data: {
        password: data,
      },
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
    });

    const successMessage = 'Password has been changed successfully.';

    dispatch({
      type: RESET_PASSWORD.SUCCESS,
      payload: {
        message: successMessage,
        name: response.data.name,
        uid: response.data.email,
        trialUser: response.data.trial_user,
        organization: response.data.organization_name,
        autoUploadEmr: response.data.auto_upload_emr,
        documentCategories: response.data.document_categories,
        emr: response.data.emr,
        referralManagement: response.data.referral_management,
        billing: response.data.billing_status,
        jobTitle: response.data.job_title || '',
        defaultFaxNumber: response.data.user_fax_number || '',
        associatedFaxNumbers: response.data.associated_fax_numbers,
        departments: response.data.organization_departments,
        hasCompletedWalkthrough: response.data.has_completed_walkthrough,
        isAdmin: response.data['is_user_admin?'],
        organizationDetails: response.data.organization_details,
        availableEmrs: response.data.available_emrs,
        currentOrganizationId: response.data.current_signed_in_organization_id,
      },
    });
    await showMultiOrganizationLoginModal(dispatch);

    // Display toastr if password reset is successful
    toastr.success(successMessage);
    const { user_fax_number: userFaxNumber, associated_fax_numbers: associatedFaxNumbers } = response.data;
    const defaultFaxNumber = response.data.user_fax_number !== null ? userFaxNumber : '';

    // Store auth tokens on localStorage
    localStorage.setItem('m8_billing_status', JSON.stringify(response.data.billing_status));
    localStorage.setItem('m8_access', response.data.access_token);
    localStorage.setItem('m8_client', response.data.client);
    localStorage.setItem('m8_chrome_ext_access', response.data.chrome_access_token);
    localStorage.setItem('m8_chrome_ext_client', response.data.chrome_client);
    localStorage.setItem('m8_name', response.data.name);
    localStorage.setItem('m8_org', response.data.organization_name);
    localStorage.setItem('m8_auto_upload_emr', response.data.auto_upload_emr);
    localStorage.setItem('m8_document_categories', response.data.document_categories);
    localStorage.setItem('trial_user', response.data.trial_user);
    localStorage.setItem('m8_job_title', response.data.job_title);
    localStorage.setItem('m8_emr', response.data.emr);
    localStorage.setItem('m8_referral_management', response.data.referral_management);
    localStorage.setItem('default_fax_number', defaultFaxNumber);
    localStorage.setItem('associated_fax_numbers', JSON.stringify(associatedFaxNumbers));
    localStorage.setItem('m8_uid', response.data.email);
    localStorage.setItem('is_admin', response.data['is_user_admin?']);
    localStorage.setItem('departments', JSON.stringify(response.data.organization_departments));
    localStorage.setItem('m8_has_completed_walkthrough', JSON.stringify(response.data.has_completed_walkthrough));
    localStorage.setItem('m8_plan_type', response.data.billing_status.plan_type);
  } catch (error) {
    const errorMessage = error.response ? error.response.data.error : error.message;
    dispatch({
      type: RESET_PASSWORD.FAILED,
      payload: {
        message: errorMessage,
        resetPasswordToken: data.reset_password_token,
      },
    });
  }
};

/**
 * Prompts user with dialog to sign out if they're trying to access patient portal while signed in
 * to a Medsender account.
 */
const resetPatientAccessAttempt = () => async (dispatch) => {
  dispatch({ type: PATIENT_AUTH.RESET_PATIENT_ACCESS_ATTEMPT });
};

/**
 * Logs in the user to Medsender after login via the modal.
 * @param {Object} user - The user who is authenticated.
 * @param {string} user.name - The name of the user.
 * @param {string} user.uid - The initials of the user's name.
 */
const switchAuth = (user, dispatch) => {
  dispatch({
    type: 'SWITCH_AUTH',
    payload: {
      user,
    },
  });
};

const printerSignIn = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_PRINTER_SIGN_IN',
      fail: 'FAILED_PRINTER_SIGN_IN',
      end: 'FINISHED_PRINTER_SIGN_IN',
    },
    method: 'POST',
    url: '/api/v1/printer_tokens',
  })
);

const addSubscription = data => (
  crud({
    dispatch: {
      begin: 'BEGIN_ADD_SUBSCRIPTION',
      fail: 'FAILED_ADD_SUBSCRIPTION',
      end: 'FINISHED_ADD_SUBSCRIPTION',
    },
    method: 'POST',
    data: {
      payment: data,
    },
    url: '/api/v1/payments',
  })
);

/**
 * Disable replying on the modal after a reply has been made on the viewer.
 */
const disableReplying = () => {
  return ({
    type: 'DISABLE_REPLYING',
    payload: {},
  });
};

/**
 * Updates the global store with the newly created fax number.
 */
const updateAssociatedFaxNumber = faxNumber => async (dispatch) => {
  dispatch({
    type: 'UPDATE_ASSOCIATED_FAX_NUMBERS',
    payload: faxNumber,
  });
};

/**
 * Update walkthorugh to the final step after getting fax number.
 */
const updateWalkthroughStep = stepOnWalkthrough => (dispatch) => {
  dispatch({
    type: 'UPDATE_STEP_ON_WALKTHROUGH',
    payload: {
      stepOnWalkthrough,
    },
  });
};

export {
  login,
  logout,
  resetAuth,
  validateAuth,
  extendUserSession,
  accessCodeAuth,
  logoutGuest,
  verifyGuestToken,
  redirectToCreateGuestAccount,
  createGuestAccount,
  patientLogin,
  patientSecurityLogin,
  patientSetQuestionAndLogin,
  changePassword,
  validateResetToken,
  resetPassword,
  forgotPassword,
  resendOtp,
  redirectToLogin,
  resetPatientAccessAttempt,
  switchAuth,
  addSubscription,
  disableReplying,
  updateAssociatedFaxNumber,
  printerSignIn,
  getLoginInfo,
  updateWalkthroughStep,
  showMultiOrganizationLoginModal,
};
