import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tag } from 'antd';
import Typography from '../../../components/Typography';
import Tooltip from '../../../components/Tooltip';
import Button from '../../../components/Button';
import COLORS from '../../../util/constants/colors';
import { PlusSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import '../newRecordViewer.scss';

const LabelsInfo = ({ ...props }) => {
  const {
    currentTransmission: { labels },
    isFormDocument,
  } = props;

  const editLabel = () => {
    const {
      sendToken,
      receiverType,
      hasWrongLabelingBeenReported,
      isLabelEdited,
      aiStatus,
      // eslint-disable-next-line no-shadow
      openModal,
    } = props;

    const reportAiDetails = {
      hasWrongLabelingBeenReported,
      aiStatus,
      isLabelEdited,
    };
    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendToken,
        selectedLabelsRecordViewer: labels,
        receiverType,
        reportAiDetails,
      },
    };
    return openModal(modalType);
  };

  return (
    <>
      <div className="labels-info">
        <div className="labels-info-title">
          <Typography variant="text" size="medium" bold>
            Labels
          </Typography>
          {!isFormDocument && (
          <Tooltip title="Add/Edit" placement="left">
            <Button
              className="tertiary small icon-btn"
              icon={PlusSvg}
              onClick={editLabel}
            />
          </Tooltip>
          )}
        </div>
        <div className="labels">
          {labels?.length > 0 ? (
            labels.map(label => (
              <Tag
                color={label.color}
                style={{
                  color: label.is_dark_text ? 'black' : 'white',
                  marginBottom: '5px',
                }}
              >
                {label.name}
              </Tag>
            ))
          ) : (
            <Typography
              variant="text"
              size="small"
              bold
              color={COLORS.TEXT_TERTIARY_LITE}
            >
              None
            </Typography>
          )}
        </div>
      </div>
      <div className="divider" />
    </>
  );
};

LabelsInfo.defaultProps = {
  currentTransmission: {},
  isFormDocument: false,
  sendToken: '',
  receiverType: '',
  isLabelEdited: false,
  hasWrongLabelingBeenReported: false,
  aiStatus: '',
};

LabelsInfo.propTypes = {
  currentTransmission: PropTypes.object,
  isFormDocument: PropTypes.bool,
  sendToken: PropTypes.string,
  receiverType: PropTypes.string,
  hasWrongLabelingBeenReported: PropTypes.bool,
  isLabelEdited: PropTypes.bool,
  aiStatus: PropTypes.string,

  openModal: PropTypes.func.isRequired,
};

export default connect(state => ({
  sendToken: state.records.sendToken,
  currentTransmission: state.records.currentTransmission,
  isFormDocument: state.records.isFormDocument,
  receiverType: state.records.receiverType,
  hasWrongLabelingBeenReported: state.records.hasWrongLabelingBeenReported,
  isLabelEdited: state.records.isLabelEdited,
  aiStatus: state.records.aiStatus,
}), { openModal })(LabelsInfo);
