export const caretrackerEmr = 'CareTracker';
export const caretrackerAccessOrg = 'Test Org.';
export const categoryRequiredEmrs = ['EMA', 'Kareo', 'OncoEMR', 'AllegianceMD', 'Elation', 'CareTracker'];
export const caretrackerProviders = [
  'Amin, Dr',
  'Bower, Dr',
  'Brown, Amy',
  'Cabral, Dr',
  'Cohen, Dr',
  'Dunn, Kyle M',
  'Frank, Dr',
  'Hermes, Dr',
  'PROLASTNAME1, PROFIRSTNAME1',
  'Patel, Rahim A',
  'White, Damien'
];
export const resultsTable = [
  {
    title: 'Patient Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Patient DOB',
    dataIndex: 'dob',
    key: 'dob',
  },
  {
    title: 'Patient MRN',
    dataIndex: 'mrn',
    key: 'mrn',
  }
];

