/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { NotePencilSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { PAGES } from '../../shared/constants';
import { getAssignedRecord } from '../../../actions/records';

const UpdateAssignedDocumentButton = ({
  currentPage, recordBlobObject, beginSaveAnnotationsOnRecord, ...props
}) => {
  // eslint-disable-next-line no-shadow
  const hideButton = currentPage !== PAGES.ASSIGNED;

  const updateDocumentOpen = async () => {
    const {
      currentRecordId, openModal, getAssignedRecord, currentTransmission: { slug: assignedDocumentId },
    } = props;
    if (recordBlobObject) {
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    await getAssignedRecord(assignedDocumentId);

    const { assignedDocument } = props;

    const modalType = {
      type: 'UPDATE_ASSIGNED_DOCUMENT',
      data: {
        selectedDocumentStatus: assignedDocument.document_status_id,
        assignedDocumentId,
        assignee: { label: assignedDocument.assignee, value: assignedDocument.assignee_email, key: assignedDocument.assignee_email },
      },
    };
    openModal(modalType);
  };

  return (
    <ToolbarButton
      title="Update Assigned Document"
      icon={NotePencilSvg}
      hide={hideButton}
      onClick={updateDocumentOpen}
    />
  );
};

UpdateAssignedDocumentButton.defaultProps = {
  recordBlobObject: {},
  assignedDocument: {},
};

UpdateAssignedDocumentButton.propTypes = {
  recordBlobObject: PropTypes.object,
  currentPage: PropTypes.string.isRequired,
  beginSaveAnnotationsOnRecord: PropTypes.func.isRequired,

  currentRecordId: PropTypes.string.isRequired,
  currentTransmission: PropTypes.object.isRequired,
  assignedDocument: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  getAssignedRecord: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    currentRecordId: state.records.currentRecordId,
    currentTransmission: state.records.currentTransmission,
    assignedDocument: state.records.assignedRecord,
  }),
  { openModal, getAssignedRecord },
)(UpdateAssignedDocumentButton);
