import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input as AntInput } from 'antd';
import './input.scss';
import Typography from '../Typography';
import COLORS from '../../util/constants/colors';

const Input = ({
  password,
  label,
  required,
  description,
  ...props
}) => {
  const { className, ...inputProps } = props;

  return (
    <div className={classNames(className, 'input')}>
      {
        label
        && (
        <Typography
          className="label"
          variant="text"
          color={COLORS.TEXT_SECONDARY_LITE}
          size="xsmall"
          bold
        >
          {label}
          {required && '*'}
        </Typography>
        )
      }
      {
        password
          ? (
            <AntInput.Password
              className={props.disabled && 'disabled'}
              {...inputProps}
            />
          )
          : <AntInput {...inputProps} />
      }
      {
        description
        && (
        <Typography
          className="description"
          variant="text"
          size="xsmall"
        >
          {description}
        </Typography>
        )
      }
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.oneOf(['', 'error', 'warning']),
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  password: PropTypes.bool,
  required: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  placeholder: '',
  label: '',
  description: '',
  disabled: false,
  status: '',
  prefix: null,
  suffix: null,
  password: false,
  required: false,
};

export default Input;
