import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Button,
  Table,
} from 'antd';
import moment from 'moment';

import {
  getNotifications,
} from '../../../actions/notifications';
import '../../../assets/stylesheets/components/_header.scss';
// eslint-disable-next-line import/no-useless-path-segments
import { Header } from '../../../components';

class MessageNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    this.props.getNotifications();
  }

  render() {
    const columns = [
      {
        title: 'Message',
        dataIndex: 'message',
        align: 'left',
        key: 'message',
        sorter: false,
      },
      {
        title: 'Patient Name',
        dataIndex: 'patient_name',
      },
      {
        title: 'Time Sent',
        dataIndex: 'timestamp',
        render: text => moment(text).format('MM/DD/YYYY hh:mm A'),
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => {
          return (
            <>
              <Button onClick={() => window.open(record.link,"_blank")} style={{ marginRight: 10 }}>
                View
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <div>
        <Helmet>
          <title>Notifications</title>
        </Helmet>
        <div className="page-style">
          <div className="content-container">
            <Header
              main="Message notifications"
              subtext="You have unread messages in the following documents"
            />
            <Table
              dataSource={this.props.notifications}
              columns={columns}
              onChange={this.handleTableChange}
              pagination={{
                position: ['bottomCenter'],
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

MessageNotifications.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default connect(state => ({
  notifications: state.notifications.notifications,
}), {
  getNotifications,
})(MessageNotifications);
export { MessageNotifications };
