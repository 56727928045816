import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '../Box';
import './banner.scss';
import COLORS from '../../util/constants/colors';
import Typography from '../Typography';
import Link from '../Link';
import { RightArrowSvg } from '../../assets/img/redesign/svgPaths';

const SIZE_STYLES = {
  small: { padding: '10px 15px', width: '286px' },
};

const Banner = ({ title, icon: Icon, size, description, url }) => {
  return (
    <Box
      className={classNames('banner', size)}
      variant="secondary"
      style={{
        backgroundColor: COLORS.PURPLE_QUARTERNARY_LITE,
        ...SIZE_STYLES[size],
      }}
    >
      <div className="banner-title">
        {Icon && <Icon size={48} color={COLORS.PURPLE_SECONDARY_LITE} weight="fill" />}
        <Typography variant="text" size="medium" bold>{title}</Typography>
      </div>
      <Typography variant="text" size="xsmall" color={COLORS.TEXT_SECONDARY_LITE}>{description}</Typography>
      <Link className="banner-link" href={url} rightIcon={RightArrowSvg} label="Learn more" size="small" />
    </Box>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export default Banner;
