import React from 'react';
import Typography from '../Typography';
import COLORS from '../../util/constants/colors';
import Link from '../Link';
import { RightArrowSvg } from '../../assets/img/redesign/svgPaths';
import './statsCard.scss';

const StatsCard = ({ icon, stat, title, url, ...props }) => {
  return (
    <div className="stats-card">
      <div className="stats-card-icon">
        {icon}
        <Typography variant="text" size="large" >
          {stat}
        </Typography>
      </div>
      <Typography variant="text" size="small" bold color={COLORS.TEXT_TERTIARY_LITE}>
        {title}
      </Typography>
      <Link href={url} rightIcon={RightArrowSvg} label="View" size="small" internal />
    </div>
  );
};

export default StatsCard;
