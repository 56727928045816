/* eslint-disable no-shadow */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu, message } from 'antd';
import {
  ClipboardSvg,
  FolderSimplePlusSvg,
  PlusSquareSvg,
} from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';
import { PAGES, RECEIVER_TYPES } from '../../shared/constants';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { triggerAutoUpload } from '../../../actions/records';
import Button from '../../../components/Button';
import '../newRecordViewer.scss';

const EMRActionsDropdown = ({
  currentPage,
  recordBlobObject,
  beginSaveAnnotationsOnRecord,
  ...props
}) => {
  const [isUploadHidden, setIsUploadHidden] = useState(false);
  const [canCreatePatientChart, setCanCreatePatientChart] = useState(false);
  const [canCreateTask, setCanCreateTask] = useState(false);
  const [hideTaskButton, setHideTaskButton] = useState(false);
  const { openModal, receiverType } = props;

  const hide = currentPage === PAGES.UPLOADS || (currentPage !== PAGES.SENT && isUploadHidden);

  useEffect(() => {
    const setPermission = async () => {
      setCanCreatePatientChart(
        await verifyFeatureAccess('create_patient_chart'),
      );
      setCanCreateTask(await verifyFeatureAccess('task_creation'));
      setHideTaskButton(await verifyFeatureAccess('hide_task_creation_button'));
    };

    setPermission();
  }, []);

  useEffect(() => {
    const uploadHidden = () => {
      if (receiverType === RECEIVER_TYPES.RECEIVED_FAX) {
        return false;
      }
      if (receiverType === RECEIVER_TYPES.EMAIL_ATTACHMENT) {
        return false;
      }
      if (
        receiverType === RECEIVER_TYPES.RECEIVER
        && currentPage !== PAGES.RECEIVED
      ) {
        return false;
      }
      return true;
    };

    setIsUploadHidden(uploadHidden());
  }, [receiverType]);

  const {
    patientName,
    patientDOB,
    triggerAutoUpload,
    receivedAt,
    callerName,
    autoUploadEmr,
    documentTitle,
    documentCategory,
    payerName,
    groupNumber,
    secondaryPayerName,
    secondaryMemberId,
    secondaryGroupNumber,
    currentOrganizationId,
    practitionerName,
    sendToken,
    apiCredentials,
  } = props;

  const autoUploadRecord = async () => {
    // If EMR is EMA, we will allow Send Chart Modal to open as Patient MRN is an option to send with
    // chart instead of Patient Name and DOB.
    if (autoUploadEmr !== 'EMA' && (!patientName || !patientDOB)) {
      message.error({
        content:
          'Cannot upload a document without a valid name and date of birth. Edit record details and try again.',
      });
    } else {
      const fileNameSupported = [
        'Kareo',
        'OncoEMR',
        'Nymbl',
        'EMA',
        'AllegianceMD',
        'Elation',
      ];

      // We want to enable modal support for new ECW practices without affect the old practices' workflow
      if (
        fileNameSupported.includes(autoUploadEmr)
        || (autoUploadEmr === 'ECW' && currentOrganizationId > 4206) || (autoUploadEmr === 'CareTracker' && currentOrganizationId === 4477)
      ) {
        let recordFileName = documentTitle;
        if (
          !recordFileName
          && receiverType !== RECEIVER_TYPES.EMAIL_ATTACHMENT && currentPage !== PAGES.SENT
        ) {
          recordFileName = `${receivedAt?.replace(/ /g, '_')}_${callerName?.replace(/ /g, '_')}`;
        } else if (!recordFileName) {
          const { created_at: createdAt, sender_name: senderName } = props.currentTransmission;
          recordFileName = `${createdAt?.replace(
            / /g,
            '_',
          )}_${senderName?.replace(/ /g, '_')}`;
        }
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            fileName: recordFileName,
            providerName: practitionerName,
            patientName,
            patientDOB,
            documentCategory,
            apiCredentials,
            sendToken,
            receiverType: !(currentPage === PAGES.SENT)
              ? receiverType
              : RECEIVER_TYPES.RECEIVER,
            noTaskCreation: currentPage === PAGES.SENT,
          },
        };
        await openModal(modalType);
      } else if (autoUploadEmr === 'AdvancedMD') {
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            providerName: practitionerName, // Once we have AI extraction, this will be replaced with the extracted value
            sendToken,
            autoUploadEmr,
            receiverType,
          },
        };
        openModal(modalType);
      } else {
        await triggerAutoUpload({ sendToken, receiverType });
        const { error } = props;
        if (!error) {
          message.success({
            content:
              'Record will be uploaded shortly. We will notify you of any issues via email.',
          });
        } else {
          message.error({
            content: 'Unable to autoupload document.',
          });
        }
      }
    }
  };

  const newChart = async () => {
    const {
      patientGender,
      patientPhoneNumber,
      patientAddress,
      patientZipCode,
      patientCity,
      patientState,
      patientEmail,
      patientFirstName,
      patientLastName,
      referringProvider,
      referringProviderOffice,
      primaryPlan,
      primaryMemberId,
      apiCredentials,
    } = props;

    const modalType = {
      type: 'CREATE_NEW_CHART',
      data: {
        name: patientName,
        dob: patientDOB,
        gender: patientGender,
        phoneNumber: patientPhoneNumber,
        address: patientAddress,
        zipCode: patientZipCode,
        city: patientCity,
        record: sendToken,
        practitionerName,
        fileName: documentTitle,
        documentCategory,
        receiverType,
        state: patientState,
        patient_email: patientEmail,
        patient_first_name: patientFirstName,
        patient_last_name: patientLastName,
        referring_provider: referringProvider,
        provider_office: referringProviderOffice,
        primary_plan: primaryPlan,
        primaryMemberId: primaryMemberId,
        payerName,
        groupNumber,
        secondaryPayerName,
        secondaryMemberId,
        secondaryGroupNumber,
        apiCredentials,
      },
    };

    openModal(modalType);
  };

  const createTask = async () => {
    const modalType = {
      type: 'CREATE_TASK',
      data: {
        name: patientName,
        dob: patientDOB,
        record: sendToken,
        practitionerName,
        fileName: documentTitle,
        receiverType,
      },
    };

    openModal(modalType);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={autoUploadRecord}>
        <span className="dropdown-icon">{ClipboardSvg}</span>
        Send to Chart
      </Menu.Item>
      {canCreatePatientChart && (currentPage !== PAGES.SENT)
        && (
        <Menu.Item onClick={newChart}>
          <span className="dropdown-icon">{FolderSimplePlusSvg}</span>
          Create New Chart
        </Menu.Item>
        )
      }
      {(!hideTaskButton && canCreateTask) && (currentPage !== PAGES.SENT)
        && (
        <Menu.Item onClick={createTask}>
          <span className="dropdown-icon">{PlusSquareSvg}</span>
          Create Task
        </Menu.Item>
        )
      }
    </Menu>
  );

  return (
    !hide && (
      <Dropdown overlay={menu}>
        <Button className="tertiary medium icon-btn" icon={ClipboardSvg} />
      </Dropdown>
    )
  );
};

EMRActionsDropdown.defaultProps = {
  recordBlobObject: {},

  receiverType: '',
  autoUploadEmr: '',
  patientName: '',
  patientDOB: '',
  senderName: '',
  callerName: '',
  documentTitle: '',
  documentCategory: '',
  payerName: '',
  groupNumber: '',
  secondaryPayerName: '',
  secondaryMemberId: '',
  secondaryGroupNumber: '',
  practitionerName: '',
  sendToken: '',
  error: false,
  currentTransmission: {},

  patientGender: '',
  patientPhoneNumber: '',
  patientAddress: '',
  patientZipCode: '',
  patientCity: '',
  patientState: '',
  patientEmail: '',
  patientFirstName: '',
  patientLastName: '',
  referringProvider: '',
  referringProviderOffice: '',
  primaryPlan: '',
  primaryMemberId: '',
};

EMRActionsDropdown.propTypes = {
  currentPage: PropTypes.string.isRequired,
  recordBlobObject: PropTypes.object,
  beginSaveAnnotationsOnRecord: PropTypes.func.isRequired,

  fileToken: PropTypes.string.isRequired,
  receiverType: PropTypes.string,

  autoUploadEmr: PropTypes.string,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  receivedAt: PropTypes.string.isRequired,
  callerName: PropTypes.string,
  senderName: PropTypes.string,
  documentTitle: PropTypes.string,
  documentCategory: PropTypes.string,
  payerName: PropTypes.string,
  groupNumber: PropTypes.string,
  secondaryPayerName: PropTypes.string,
  secondaryMemberId: PropTypes.string,
  secondaryGroupNumber: PropTypes.string,
  currentOrganizationId: PropTypes.number.isRequired,
  practitionerName: PropTypes.string,
  sendToken: PropTypes.string,
  error: PropTypes.bool,
  currentTransmission: PropTypes.object,

  patientGender: PropTypes.string,
  patientPhoneNumber: PropTypes.string,
  patientAddress: PropTypes.string,
  patientZipCode: PropTypes.string,
  patientCity: PropTypes.string,
  patientState: PropTypes.string,
  patientEmail: PropTypes.string,
  patientFirstName: PropTypes.string,
  patientLastName: PropTypes.string,
  referringProvider: PropTypes.string,
  referringProviderOffice: PropTypes.string,
  primaryPlan: PropTypes.string,
  primaryMemberId: PropTypes.string,

  triggerAutoUpload: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    fileToken: state.records.fileToken,
    receiverType: state.records.receiverType,

    autoUploadEmr: state.auth.data.autoUploadEmr,
    patientName: state.records.patientName,
    patientDOB: state.records.patientDOB,
    receivedAt: state.records.receivedAt,
    callerName: state.records.callerName,
    senderName: state.records.senderName,
    documentTitle: state.records.documentTitle,
    documentCategory: state.records.documentCategory,
    currentOrganizationId: state.auth.currentOrganizationId,
    practitionerName: state.records.practitionerName,
    sendToken: state.records.currentTransmission.send_token,
    error: state.records.error,
    currentTransmission: state.records.currentTransmission,

    patientGender: state.records.patientGender,
    patientPhoneNumber: state.records.patientPhoneNumber,
    patientAddress: state.records.patientAddress,
    patientZipCode: state.records.patientZipCode,
    patientCity: state.records.patientCity,
    patientState: state.records.patientState,
    patientEmail: state.records.patientEmail,
    patientFirstName: state.records.patientFirstName,
    patientLastName: state.records.patientLastName,
    referringProvider: state.records.referringProvider,
    referringProviderOffice: state.records.referringProviderOffice,
    primaryPlan: state.records.primaryPlan,
    primaryMemberId: state.records.primaryMemberId,
    payerName: state.records.payerName,
    groupNumber: state.records.groupNumber,
    secondaryPayerName: state.records.secondaryPayerName,
    secondaryMemberId: state.records.secondaryMemberId,
    secondaryGroupNumber: state.records.secondaryGroupNumber,
    apiCredentials: state.records.apiCredentials,
  }),
  {
    triggerAutoUpload,
    openModal,
  },
)(EMRActionsDropdown);
