/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoggedInHeader from './LoggedInHeader';
import LoggedOutHeader from './LoggedOutHeader';

const NewHeader = ({ ...props }) => {
  const { hasAuthCreds, hasCompletedWalkthrough } = props;

  if (hasAuthCreds === null || (hasAuthCreds && !hasCompletedWalkthrough)) return null;

  return (
    <div className="new-header">
      {hasAuthCreds ? <LoggedInHeader /> : <LoggedOutHeader />}
    </div>
  );
};

NewHeader.propTypes = {
  hasAuthCreds: PropTypes.bool.isRequired,
  hasCompletedWalkthrough: PropTypes.bool.isRequired,
};

export default connect(state => ({
  hasAuthCreds: state.auth.hasAuthCreds,
  hasCompletedWalkthrough: state.auth.hasCompletedWalkthrough,
}))(withRouter(NewHeader));
