/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { snakeCase } from 'snake-case';
import { Helmet } from 'react-helmet';
import { Menu, message } from 'antd';
import {
  CloudDownloadOutlined,
  DesktopOutlined,
  EditOutlined,
  TagOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import RecordsHOC from '../RecordsHOC';
import { Header } from '../../../components';
import { assignedColumns } from '../shared/constants';

import { openModal } from '../../../actions/modal';
import {
  getAssignedRecordsByPage,
  getAssignedByQuery,
  printUserRecord,
  downloadZip,
  triggerAutoUpload,
  markDocumentAsCompleted,
  assignDocument,
} from '../../../actions/records';

import download from '../../../actions/helpers/download';
import { verifyFeatureAccess } from '../../../global/featureFlags';

/* global toastr */

function parseAssignee(queryString) {
  const regex = /(?:\?|&)assignee=([^&]+)/;
  const match = regex.exec(queryString);
  return match ? decodeURIComponent(match[1]) : null;
}

class AssignedRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canForward: false,
      autoUploadToEMR: false,
      createPatientChart: false,
      hideTaskButton: false,
      taskCreation: false,
      markCompleteButtonOption: localStorage.mark_complete_reassign_option || 'Mark Complete',
    };
  }

  componentDidMount = async () => {
    const canForward = await verifyFeatureAccess('fwd_to_emr');
    const autoUploadToEMR = await verifyFeatureAccess('manual_upload_emr');
    const createPatientChart = await verifyFeatureAccess('create_patient_chart');
    const hideTaskButton = await verifyFeatureAccess('hide_task_creation_button');
    const taskCreation = await verifyFeatureAccess('task_creation');
    this.setState({
      canForward,
      autoUploadToEMR,
      createPatientChart,
      hideTaskButton,
      taskCreation,
    });
  };

  downloadRecord = async () => {
    const {
      selectedData,
      data: allDataOnPage,
      openModal,
      downloadZip,
      printUserRecord,
    } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Download one individual record
      const file = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const {
        file_token: recordId,
        patient_name: patientName,
        record_status: recordStatus,
      } = file;

      // Users shouldn't be able to download revoked records
      if (recordStatus === 'revoked')
        return toastr.error(
          'You can not download a record that has been revoked'
        );

      // Get the signed url for the record we want to download
      await printUserRecord(recordId);
      // De-structure once signed url has been obtained so we download
      // correctly
      const { downloadUrl } = this.props;
      // snakeCase package doesn't allow passing undefined, so we need to pass an empty string if so.
      const patientNameSnakeCase = snakeCase(patientName || '');
      const customFileName = `${patientNameSnakeCase}-${recordId
        .slice(-7)
        .toLowerCase()}`;
      await download(downloadUrl, customFileName);
      return;
    }

    // Open modal to show download
    openModal({ type: 'DOWNLOAD_ZIP' });

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of file tokens to be downloaded in a single zip file
    const recordsToDownload = (selectedAll ? allDataOnPage : selectedData)
      // Don't allow users to download records that have been revoked
      // TODO (Ammar Khan) We need to enforce this on the backend
      .filter((record) => record.record_status !== 'revoked')
      .map((x) => x.file_token);
    await downloadZip(recordsToDownload);
  };

  editTag = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const {
        send_token: sendToken,
        tag,
        receiver_type: receiverType,
      } = receiver;

      const modalType = {
        type: 'ADD_LABEL_TO_RECORD',
        data: {
          reportAiDetails: {
            isLabelEdited: selectedData[0].is_label_edited,
            hasWrongLabelingBeenReported:
              selectedData[0].has_wrong_labeling_been_reported,
            aiStatus: selectedData[0].ai_status,
          },
          record: sendToken,
          tag,
          receiverType,
        },
      };
      openModal(modalType);
      return;
    }

    if (selectedData.length > 1) {
      const sendTokens = [];
      const receiverTypes = [];
      for (let i = 0; i < selectedData.length; i += 1) {
        sendTokens.push(selectedData[i].send_token);
        receiverTypes.push(selectedData[i].receiver_type);
      }

      const modalType = {
        type: 'ADD_LABEL_TO_MULTIPLE_RECORDS',
        data: {
          record: sendTokens,
          receiverTypes,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData).map(
      (x) => x.send_token
    );

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  };

  newChart = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';
    //  left this logic, though I don't think it's needed

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const {
        patient_dob: PatientDOB,
        patient_name: PatientName,
        send_token: sendToken,
        receiver_type: receiverType,
        api_credentials: apiCredentials,
      } = receiver;

      const modalType = {
        type: 'CREATE_NEW_CHART',
        data: {
          name: PatientName,
          patient_first_name: selectedData[0].patient_first_name,
          patient_middle_name: selectedData[0].patient_middle_name,
          patient_last_name: selectedData[0].patient_last_name,
          dob: PatientDOB,
          record: sendToken,
          gender: selectedData[0].patient_gender,
          phoneNumber: selectedData[0].patient_phone_number,
          patient_email: selectedData[0].patient_email,
          address: selectedData[0].patient_address,
          zipCode: selectedData[0].patient_zip_code,
          city: selectedData[0].patient_city,
          state: selectedData[0].patient_state,
          practitionerName: selectedData[0].practitioner_name,
          referring_provider: selectedData[0].referring_provider,
          provider_office: selectedData[0].provider_office,
          primary_plan: selectedData[0].primary_plan,
          primaryMemberId: selectedData[0].primary_member_id,
          payerName: selectedData[0].payer_name,
          groupNumber: selectedData[0].group_number,
          receiverType,
          apiCredentials,
          receivedAt: selectedData[0].received_at,
          callerName: selectedData[0].caller_name,
          documentCategory: this.props.selectedData[0].document_category,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData).map(
      (x) => x.send_token
    );

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  };

  createTask = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';
    //  left this logic, though I don't think it's needed

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord
        ? allDataOnPage[0]
        : selectedData[0];
      const {
        patient_dob: PatientDOB,
        patient_name: PatientName,
        send_token: sendToken,
        receiver_type: receiverType,
      } = receiver;

      const modalType = {
        type: 'CREATE_TASK',
        data: {
          name: PatientName,
          dob: PatientDOB,
          record: sendToken,
          practitionerName: selectedData[0].practitioner_name,
          receiverType,
          receivedAt: selectedData[0].received_at,
          callerName: selectedData[0].caller_name,
        },
      };
      openModal(modalType);
    }
  };

  editPatientOpen = () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
    const {
      send_token: sendToken,
      tag,
      receiver_type: receiverType,
    } = receiver;
    const modalType = {
      type: 'EDIT_PATIENT',
      data: {
        patient: selectedData[0],
        fromPage: 'assigned',
        record: sendToken,
        tag,
        receiverType,
      },
    };
    openModal(modalType);
  };

  updateDocumentOpen = () => {
    const { selectedData, openModal } = this.props;

    const modalType = {
      type: 'UPDATE_ASSIGNED_DOCUMENT',
      data: {
        selectedDocumentStatus: selectedData[0].document_status_id,
        assignedDocumentId: selectedData[0].slug,
        assignee: {
          value: selectedData[0].assignee_email,
          label: selectedData[0].assignee,
          key: selectedData[0].assignee_email,
        },
      },
    };
    openModal(modalType);
  };

  performAction = async (recordId, action) => {
    const canFwdToEMR = await verifyFeatureAccess('fwd_to_emr');
    const type = canFwdToEMR ? action : 'RAISE_FLAG';
    const { openModal } = this.props;
    const modalType = {
      type,
      data: recordId,
      inboxLink: '/app/assigned',
      featureFlag: 'fwd_to_emr',
    };
    openModal(modalType);
  };

  markComplete = async () => {
    localStorage.setItem('mark_complete_reassign_option', 'Mark Complete');
    this.setState({ markCompleteButtonOption: 'Mark Complete' });

    const { markDocumentAsCompleted, selectedData } = this.props;
    await markDocumentAsCompleted(selectedData[0].slug);

    const { error } = this.props;
    if (!error) {
      message.success({
        content: 'Your record has been marked as completed.',
      });

      window.location.reload();
    } else {
      message.error({
        content: 'Unable to mark as completed. Please try again.',
      });
    }
  };

  markCompleteAndReassign = async () => {
    const { openModal, selectedData } = this.props;

    localStorage.setItem(
      'mark_complete_reassign_option',
      'Mark Complete and Reassign'
    );
    this.setState({ markCompleteButtonOption: 'Mark Complete and Reassign' });

    const modalType = {
      type: 'ASSIGN_RECORD',
      data: {
        record: selectedData[0].send_token,
        receiverType: selectedData[0].receiver_type,
        markComplete: true,
        assignedDocumentId: selectedData[0].slug,
      },
    };
    openModal(modalType);
  };

  markCompleteAndAssignBack = async () => {
    const { selectedData } = this.props;

    localStorage.setItem(
      'mark_complete_reassign_option',
      'Mark Complete and Assign Back'
    );
    this.setState({
      markCompleteButtonOption: 'Mark Complete and Assign Back',
    });

    const { assignDocument } = this.props;

    // Let's reassign this document back to the assignee.
    const receiverType = selectedData[0].receiver_type;
    const assignedDocumentId = selectedData[0].slug;
    const sendToken = selectedData[0].send_token;

    // Figure out the parent type
    let parent = '';
    if (receiverType === 'received_fax') {
      parent = 'ReceivedFax';
    } else if (receiverType === 'receiver') {
      parent = 'Receiver';
    } else {
      parent = 'EmailAttachment';
    }

    const assignedDocument = {
      send_token: sendToken,
      assignee_id: selectedData[0].assigned_from_email,
      parent_type: parent,
      document_status_id: selectedData[0].document_status_id,
      force_assign: true,
    };
    await assignDocument(assignedDocument);

    const { markDocumentAsCompleted } = this.props;
    await markDocumentAsCompleted(assignedDocumentId);

    const { error } = this.props;
    if (!error) {
      message.success({
        content: 'The document has been assigned back to the assigner.',
      });

      // After 3 seconds, go to assigned records page.
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      message.error({
        content:
          'Unable to assign the document back to the assigner. Please try again.',
      });
    }
  };

  // Checks if a row containing a reply is selected. It will loop through all the selected rows and
  // check if one of the rows is a reply to a sent record. If it is, it returns true that the
  // selectedData contains a reply. Otherwise return false.
  isAReplySelected = (selectedData) => {
    for (let i = 0; i < selectedData.length; i += 1) {
      if (selectedData[i].uri) {
        return true;
      }
    }
    return false;
  };

  // Checks if a row of type form is selected.
  isFormRecord = (selectedData) => selectedData.some((data) => data?.form_id);

  isEmailRecord = (selectedData) =>
    selectedData.some((data) => data?.attachment_id);

  autoUploadRecord = async (selectedRecord) => {
    const {
      patient_name: patientName,
      patient_dob: patientDOB,
      send_token: sendToken,
      receiver_type: receiverType,
      api_credentials: apiCredentials,
    } = selectedRecord;
    const { autoUploadEmr, currentOrganizationId } = this.props;
    // If EMR is EMA, we will allow Send Chart Modal to open as Patient MRN is an option to send with
    // chart instead of Patient Name and DOB.
    if (autoUploadEmr !== 'EMA' && (!patientName || !patientDOB)) {
      message.error({
        content: 'Cannot upload a document without a valid name and date of birth. Edit record details and try again.',
      });
    } else {
      const { triggerAutoUpload, openModal } = this.props;

      const fileNameSupported = [
        'Kareo',
        'OncoEMR',
        'Nymbl',
        'EMA',
        'AllegianceMD',
        'Elation',
      ];

      // We want to enable modal support for new ECW practices without affect the old practices' workflow
      if (
        fileNameSupported.includes(autoUploadEmr) ||
        (autoUploadEmr === 'ECW' && currentOrganizationId > 4206) || (autoUploadEmr === 'CareTracker' && currentOrganizationId === 4477)
      ) {
        let recordFileName = selectedRecord.document_title;
        if (!recordFileName && selectedRecord.is_email_submission !== true) {
          recordFileName = `${selectedRecord.received_at.replace(/ /g, '_')}_${selectedRecord.caller_name.replace(/ /g, '_')}`;
        } else if (!recordFileName) {
          recordFileName = `${selectedRecord.created_at.replace(/ /g, '_')}_${selectedRecord.sender_name.replace(/ /g, '_')}`;
        }
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            fileName: recordFileName,
            providerName: this.props.selectedData[0].practitioner_name,
            patientName: this.props.selectedData[0].patient_name,
            patientDOB: this.props.selectedData[0].patient_dob,
            documentCategory: this.props.selectedData[0].document_category,
            sendToken,
            receiverType,
            autoUploadEmr,
            apiCredentials,
          },
        };
        openModal(modalType);
      } else if (autoUploadEmr === 'AdvancedMD') {
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            providerName: this.props.selectedData[0].practitioner_name, // Once we have AI extraction, this will be replaced with the extracted value
            documentCategory: this.props.selectedData[0].document_category,
            sendToken,
            receiverType,
            autoUploadEmr,
          },
        };
        openModal(modalType);
      } else {
        await triggerAutoUpload({ sendToken, receiverType });
        const { error } = this.props;
        if (!error) {
          message.success({
            content: 'Record will be uploaded shortly. We will notify you of any issues via email.',
          });
        } else {
          message.error({
            content: 'Unable to autoupload document.',
          });
        }
      }
    }
  };

  isUploadDisabled(receiverType) {
    if (receiverType === 'received_fax') {
      return false;
    }
    if (receiverType === 'email_attachment') {
      return false;
    }
    return true;
  }

  render() {
    const {
      selectedData,
      location: { search },
    } = this.props;
    const canEdit = !(
      selectedData.length === 1 &&
      (selectedData[0].can_edit_patient_info ||
        selectedData[0].can_edit_privacy_level)
    );
    const { canForward, autoUploadToEMR, hideTaskButton, createPatientChart, taskCreation } =
      this.state;
    const { departmentUsers } = this.props;
    let assignee = '';
    let assigneeName = 'me';
    if (search && search.toString().includes('assignee=')) {
      assignee = parseAssignee(search);
      departmentUsers.forEach((userObj) => {
        if (userObj[1] === assignee) {
          assigneeName = userObj[0].toString();
        }
      });
    }

    const pageTitle = `Assigned to ${assigneeName}`;
    const markCompletedMenu = (
      <Menu>
        <Menu.Item key='0' onClick={() => this.markComplete()}>
          Mark Complete
        </Menu.Item>
        <Menu.Item key='1' onClick={() => this.markCompleteAndReassign()}>
          Mark Complete and Reassign
        </Menu.Item>
        <Menu.Item key='2' onClick={() => this.markCompleteAndAssignBack()}>
          Mark Complete and Assign Back
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Helmet>
          <title>Assigned Records - Medsender</title>
        </Helmet>
        <Header
          main={pageTitle}
          subtext='Choose a record below'
          locationPath={[
            { icon: 'home', text: 'Home', link: '/app' },
            { icon: 'inbox', text: 'Received', link: '/app/received' },
          ]}
          buttons={[
            {
              text: 'Download',
              icon: <CloudDownloadOutlined />,
              disabled:
                !selectedData.length >= 1 ||
                this.isAReplySelected(selectedData),
              func: () => this.downloadRecord(),
            },
            {
              text: 'Forward to EMR',
              icon: <DesktopOutlined />,
              disabled:
                selectedData.length !== 1 ||
                selectedData[0] === 'all' ||
                this.isAReplySelected(selectedData),
              hide: !canForward || autoUploadToEMR,
              func: () =>
                this.performAction(
                  selectedData[0].send_token,
                  'FORWARD_RECORD'
                ),
            },
            {
              text: 'Send to Chart',
              icon: <CloudUploadOutlined />,
              disabled:
                selectedData.length !== 1 ||
                this.isAReplySelected(selectedData) ||
                this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !autoUploadToEMR,
              func: () => this.autoUploadRecord(selectedData[0]),
            },
            {
              text: 'Create New Chart',
              icon: <CloudUploadOutlined id='createChart' />,
              disabled:
                selectedData.length !== 1 ||
                this.isAReplySelected(selectedData) ||
                this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !createPatientChart,
              func: () => this.newChart(),
              //  not sure what isAReplySelected means exactly but added as it's used in others
            },
            {
              text: 'Create Task',
              icon: <CloudUploadOutlined id='createTask' />,
              disabled:
                selectedData.length !== 1 ||
                this.isAReplySelected(selectedData) ||
                this.isUploadDisabled(selectedData[0].receiver_type),
              hide: (hideTaskButton || !taskCreation),
              func: () => this.createTask(),
            },

            {
              text: 'Edit',
              icon: <EditOutlined />,
              disabled: canEdit || selectedData.length !== 1,
              func: this.editPatientOpen,
            },
            {
              text: 'Update Assigned Document',
              icon: <EditOutlined />,
              disabled: canEdit || selectedData.length !== 1,
              func: this.updateDocumentOpen,
            },
            {
              text: selectedData.length > 1 ? 'Add Labels' : 'Add/Edit Label',
              icon: <TagOutlined />,
              disabled:
                selectedData.length === 0 ||
                this.isAReplySelected(selectedData) ||
                this.isEmailRecord(selectedData),
              func: this.editTag,
            },
            {
              type: 'dropdown',
              text: this.state.markCompleteButtonOption,
              icon: <CheckCircleOutlined />,
              disabled:
                selectedData.length !== 1 ||
                this.isAReplySelected(selectedData),
              menu: markCompletedMenu,
            },
          ]}
        />
      </div>
    );
  }
}

const passedProps = {
  getSelectedPageData: getAssignedRecordsByPage,
  getPageDataWithQuery: getAssignedByQuery,
  data: 'assigned_data',
  mode: 'Assigned',
  enableSelectAll: true,
  columnTitles: assignedColumns,
  markRecordAsReceived: true,
};
AssignedRecords.defaultProps = {
  selectedData: [],
  downloadUrl: '',
  autoUploadEmr: '',
};

AssignedRecords.propTypes = {
  selectedData: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  printUserRecord: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  downloadUrl: PropTypes.string,
  downloadZip: PropTypes.func.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  autoUploadEmr: PropTypes.string,
  currentOrganizationId: PropTypes.string.isRequired,
  markDocumentAsCompleted: PropTypes.func.isRequired,
  departmentUsers: PropTypes.array.isRequired,
  assignDocument: PropTypes.func.isRequired,
  referringProvider: PropTypes.string,
  referringProviderOffice: PropTypes.string,
};

export default RecordsHOC(passedProps)(
  connect(
    (state) => ({
      selectedData: state.inboxTable.recordData,
      downloadUrl: state.records.downloadUrl,
      data: state.records.assigned_data,
      errorMessage: state.records.errorMessage,
      autoUploadEmr: state.auth.data.autoUploadEmr,
      documentCategories: state.auth.data.documentCategories,
      currentOrganizationId: state.auth.currentOrganizationId,
      allDocumentStatuses: state.userProfile.allDocumentStatuses,
      departmentUsers: state.records.department_users,
      referringProvider: state.records.referringProvider,
      referringProviderOffice: state.records.referringProviderOffice,
    }),
    {
      printUserRecord,
      downloadZip,
      openModal,
      triggerAutoUpload,
      markDocumentAsCompleted,
      assignDocument,
    }
  )(AssignedRecords)
);
