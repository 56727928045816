import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as AntAvatar } from 'antd';
import classNames from 'classnames';
import { parseFullName } from 'parse-full-name';
import Typography from '../Typography/index';
import './avatar.scss';
import COLORS from '../../util/constants/colors';

const Avatar = ({
  size, online, content, name, org, email, full, ...props
}) => {
  const AvatarInfo = () => {
    const parsedName = parseFullName(name);
    const firstName = parsedName.first || parsedName.last;

    return (
      <div className="avatar-info">
        {
          size === 'small' ? (
            <>
              { name && <Typography variant="text" size="small" bold color={COLORS.TEXT_SECONDARY_LITE}>{firstName}</Typography> }
              { org && <Typography variant="text" size="xsmall" color={COLORS.TEXT_TERTIARY_LITE}>{org}</Typography> }
            </>
          ) : (
            <>
              { name && (
              <Typography variant="text" size="large" bold>
                Hello, {firstName}
              </Typography>
              ) }
              { org && <Typography variant="text" size="medium" bold color={COLORS.TEXT_TERTIARY_LITE}>{org}</Typography> }
              { email && <Typography variant="text" size="small" color={COLORS.TEXT_TERTIARY_LITE}>{email}</Typography> }
            </>
          )
        }
      </div>
    );
  };

  const avatarSize = size === 'small' ? 32 : 48;

  return (
    <div className={classNames('avatar', { full })}>
      <div className="avatar-icon">
        <AntAvatar size={avatarSize} style={{ backgroundColor: COLORS.ORANGE_PRIMARY_LITE }} {...props}>
          {content}
        </AntAvatar>
        { online && (<div className={classNames('online-dot', { [size]: size })} />) }
      </div>
      <AvatarInfo />
    </div>
  );
};

Avatar.defaultProps = {
  size: 'small',
  online: false,
  org: '',
  email: '',
  full: false,
};

Avatar.propTypes = {
  size: PropTypes.string,
  online: PropTypes.bool,
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  org: PropTypes.string,
  email: PropTypes.string,
  full: PropTypes.bool,
};

export default Avatar;
