import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { SquareSplitHorizontalSvg } from '../../../assets/img/redesign/svgPaths';
import { openModal } from '../../../actions/modal';

const SplitRecordButton = ({ currentPage, isForm, ...props }) => {
  // eslint-disable-next-line no-shadow
  const { sendToken, openModal } = props;
  const hideButton = currentPage === 'sent' || isForm || currentPage === 'assigned';

  const splitSelectedRecord = () => {
    const {
      fileToken,
      patientName,
      patientDOB,
      privacyLevel,
      canEditPrivacyLevel,
      canEditPatientInfo,
      numPages,
      documentTitle,
      documentCategory,
    } = props;
    const patient = {
      file_token: fileToken,
      patient_name: patientName,
      patient_dob: patientDOB,
      privacy_level: privacyLevel,
      can_edit_privacy_level: canEditPrivacyLevel,
      can_edit_patient_info: canEditPatientInfo,
      document_title: documentTitle,
      document_category: documentCategory,
    };
    let fromPage = currentPage;
    fromPage = fromPage === 'uploads' ? 'uploaded' : fromPage;

    const modalType = {
      type: 'SPLIT_SELECTED_RECORD',
      data: {
        patient,
        fromPage,
        token: sendToken,
        numPages,
      },
    };
    openModal(modalType);
  };

  return (
    <ToolbarButton
      title="Split Record"
      icon={SquareSplitHorizontalSvg}
      hide={hideButton}
      onClick={splitSelectedRecord}
    />
  );
};

SplitRecordButton.defaultProps = {
  sendToken: null,
  patientName: '',
  patientDOB: '',
  privacyLevel: '',
  numPages: null,
  documentTitle: '',
  documentCategory: '',
};

SplitRecordButton.propTypes = {
  currentPage: PropTypes.string.isRequired,
  isForm: PropTypes.bool.isRequired,

  sendToken: PropTypes.string,
  fileToken: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  privacyLevel: PropTypes.string,
  canEditPrivacyLevel: PropTypes.bool.isRequired,
  canEditPatientInfo: PropTypes.bool.isRequired,
  numPages: PropTypes.number,
  documentTitle: PropTypes.string,
  documentCategory: PropTypes.string,

  openModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    sendToken: state.records.sendToken,
    fileToken: state.records.fileToken,
    patientName: state.records.patientName,
    patientDOB: state.records.patientDOB,
    privacyLevel: state.records.privacyLevel,
    canEditPrivacyLevel: state.records.canEditPrivacyLevel,
    canEditPatientInfo: state.records.canEditPatientInfo,
    numPages: state.records.numPages,
    documentTitle: state.records.documentTitle,
    documentCategory: state.records.documentCategory,
  }),
  { openModal },
)(SplitRecordButton);
