import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import { Link } from 'react-router-dom';

import { printerSignIn } from '../actions/auth';

const parent = {
  lineHeight: '300px',
  height: '300px',
  textAlign: 'center',
};

const child = {
  lineHeight: '1.5',
  display: 'inline-block',
  verticalAlign: 'middle',
};

class PrinterSignIn extends Component {
  componentDidMount() {
    this.getSignInToken();
  }

  getSignInToken = async () => {
    const { printerSignIn: getToken } = this.props;
    await getToken();
    const { gibraltarSignInToken } = this.props;
    window.open(`medsender://signInToken=${gibraltarSignInToken}`, '_self');
  }

  render() {
    return (
      <div style={parent}>
        <div style={child}>
          <p>The Printer Driver should open automatically. If not, click below to open and sign in.</p>
          <RaisedButton
            label="Sign In to Printer Driver"
            backgroundColor="rgb(163,237,255)"
            onClick={this.getSignInToken}
            style={{ marginTop: '20px', marginBottom: '30px' }}
          />
          <div>
            <Link to="/app" style={{ fontSize: '15px' }}>Back to Main Dashboard</Link>
          </div>
        </div>
      </div>
    );
  }
}

PrinterSignIn.propTypes = {
  gibraltarSignInToken: PropTypes.string.isRequired,
  printerSignIn: PropTypes.func.isRequired,
};

export default connect(state => ({
  gibraltarSignInToken: state.auth.gibraltarSignInToken,
}), { printerSignIn })(PrinterSignIn);
