/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu, message } from 'antd';
import { CheckCircleSvg } from '../../../assets/img/redesign/svgPaths';
import Button from '../../../components/Button';
import { PAGES } from '../../shared/constants';
import {
  assignDocument,
  getAssignedRecord,
  markDocumentAsCompleted,
} from '../../../actions/records';
import { openModal } from '../../../actions/modal';
import '../newRecordViewer.scss';

const MarkCompleteDropdown = ({
  currentPage,
  recordBlobObject,
  beginSaveAnnotationsOnRecord,
  ...props
}) => {
  const {
    // eslint-disable-next-line react/prop-types
    receiverType,
    currentRecordId,
    sendToken,
    assignedDocument: { slug: assignedDocumentId },
  } = props;

  const hide = currentPage !== PAGES.ASSIGNED;

  const markComplete = async () => {
    if (recordBlobObject) {
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    localStorage.setItem('mark_complete_reassign_option', 'Mark Complete');

    const { markDocumentAsCompleted } = props;
    await markDocumentAsCompleted(assignedDocumentId);

    const { error } = props;
    if (!error) {
      message.success({
        content:
          'Your record has been marked as completed. You\'ll be redirected back to the assigned records page.',
      });

      // After 3 seconds, go to assigned records page.
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      message.error({
        content: 'Unable to mark as completed. Please try again.',
      });
    }
  };

  const markCompleteAndReassign = async () => {
    if (recordBlobObject) {
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    const { openModal } = props;

    localStorage.setItem(
      'mark_complete_reassign_option',
      'Mark Complete and Reassign',
    );

    const modalType = {
      type: 'ASSIGN_RECORD',
      data: {
        record: sendToken,
        receiverType,
        markComplete: true,
        assignedDocumentId,
      },
    };
    openModal(modalType);
  };

  const markCompleteAndAssignBack = async () => {
    if (recordBlobObject) {
      await beginSaveAnnotationsOnRecord(recordBlobObject, currentRecordId);
    }

    localStorage.setItem(
      'mark_complete_reassign_option',
      'Mark Complete and Assign Back',
    );

    const { getAssignedRecord, assignDocument } = props;

    await getAssignedRecord(assignedDocumentId);

    const { assignedDocument } = props;

    // Let's reassign this document back to the assignee.
    // Figure out the parent type
    let parent = '';
    if (receiverType === 'received_fax') {
      parent = 'ReceivedFax';
    } else if (receiverType === 'receiver') {
      parent = 'Receiver';
    } else {
      parent = 'EmailAttachment';
    }

    const newAssignedDocument = {
      send_token: sendToken,
      assignee_id: assignedDocument.assigned_from_email,
      parent_type: parent,
      document_status_id: assignedDocument.document_status_id,
      force_assign: true,
    };
    await assignDocument(newAssignedDocument);

    const { markDocumentAsCompleted } = props;
    await markDocumentAsCompleted(assignedDocumentId);

    const { error } = props;
    if (!error) {
      message.success({
        content:
          'The document has been assigned back to the assigner. You will be redirected back to the assigned records page.',
      });

      // After 3 seconds, go to assigned records page.
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      message.error({
        content:
          'Unable to assign the document back to the assigner. Please try again.',
      });
    }
  };

  const menu = (
    <Menu>
      {<Menu.Item onClick={markComplete}>Mark Complete</Menu.Item>}
      {
        <Menu.Item onClick={markCompleteAndReassign}>
          Mark Complete and Reassign
        </Menu.Item>
      }
      {
        <Menu.Item onClick={markCompleteAndAssignBack}>
          Mark Complete and Assign Back
        </Menu.Item>
      }
    </Menu>
  );

  return (
    !hide && (
      <Dropdown overlay={menu}>
        <Button className="tertiary medium icon-btn" icon={CheckCircleSvg} />
      </Dropdown>
    )
  );
};

MarkCompleteDropdown.defaultProps = {
  recordBlobObject: {},
  sendToken: '',
  error: false,
  errorCode: null,
  errorMessage: null,
};

MarkCompleteDropdown.propTypes = {
  currentPage: PropTypes.string.isRequired,
  recordBlobObject: PropTypes.object,
  beginSaveAnnotationsOnRecord: PropTypes.func.isRequired,

  sendToken: PropTypes.string,
  error: PropTypes.bool,
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
  assignedDocument: PropTypes.object.isRequired,
  currentRecordId: PropTypes.string.isRequired,

  markDocumentAsCompleted: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  getAssignedRecord: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    sendToken: state.records.currentTransmission.send_token,
    error: state.records.error,
    errorCode: state.records.errorCode,
    errorMessage: state.records.errorMessage,
    assignedDocument: state.records.assignedRecord,
    currentRecordId: state.records.currentRecordId,
    receiverType: state.records.currentTransmission.receiver_type,
  }),
  {
    markDocumentAsCompleted,
    openModal,
    getAssignedRecord,
    assignDocument,
  },
)(MarkCompleteDropdown);
