import React from 'react';
import Typography from '../../components/Typography';
import COLORS from '../../util/constants/colors';
import { message } from 'antd';

const InfoSection = ({ title, fields }) => {
  return (
    <div className="info-section">
      <Typography variant="text" size="large" bold>
        {title}
      </Typography>
      <div className="info-section-fields">
        {
          fields.map((field) => (
            <div key={field.label} className="value-header">
              <div className="value-header-label">
                {field.icon}
                <Typography variant="text" size="small" color={COLORS.TEXT_TERTIARY_LITE}>
                  {field.label}
                </Typography>
              </div>
              <div className="value-header-value">
                <Typography bold variant="text" size="small">
                  {field.value}
                </Typography>
              </div>
            </div>
          ))
        }
      </div>
      <div className="divider" />
    </div>
  );
}

export default InfoSection;
