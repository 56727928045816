const defaultIcon = {
    name: 'check_circle',
    color: '#037d03',
    text: 'The record was sent successfully.',
};

const viewCountIcon = {
    ...defaultIcon,
    name: 'remove_red_eye',
    text: 'The record has been viewed by the recipient.'
};

const inProgressIcon = {
    name: 'check_circle_outline',
    color: '#FFBE00',
    text: 'The record is still being sent.',
};

const directFaxSuccessIcon = {
    ...defaultIcon,
    name: 'print',
    text: 'The record was sent as a fax',
};

const failureIcon = (message) => ({
    name: 'error',
    color: '#b31c0e',
    text: message
});

export const getIcon = (row) => {
    const {
        view_count: viewCount,
        fax_status: faxStatus,
        record_status: recordStatus
    } = row;

    let icon = defaultIcon;
    const successfulDirectFax = (faxStatus === 'success' && recordStatus === 'direct_fax');
    const transmissionFailure = (faxStatus === 'failure' || faxStatus === 'partialsuccess');
    const scheduledFax = (faxStatus === 'scheduled');

    if (viewCount >= 1) icon = viewCountIcon;
    if (faxStatus == 'inprogress') icon = inProgressIcon;
    else if (successfulDirectFax && viewCount < 1) icon = directFaxSuccessIcon;
    else if (transmissionFailure) icon = failureIcon(row.fax_error_message);
    else if (scheduledFax) icon = scheduledIcon;
    return icon;
}
