import axios from 'axios';

/**
 * Fetches feature flags from GET - api/v1/features
 */
export const fetchFeatureFlags = () =>
  axios.get('/api/v1/features');


/**
 * Saves feature flags in local storage
 */
export const saveFeatureFlags = (flags) => {
  try {
    const serializedData = JSON.stringify(flags);
    localStorage.setItem('flags', serializedData);
  } catch (error) {
    return [];
  }
};

/**
 * Saves user flags in local storage
 */
export const saveUserFlags = (user_flags) => {
  try {
    const serializedData = JSON.stringify(user_flags);
    localStorage.setItem('user_flags', serializedData);
  } catch (error) {
    return [];
  }
};

/**
 * Checks if local storage has the feature flag in question.
 */
export const hasFeatureInLocalStorage = (featureName) => {
  try {
    const serializedData = localStorage.getItem('flags');
    const featureFlags = JSON.parse(serializedData);
    const flagsIsArray = Array.isArray(featureFlags) || Array.isArray(featureFlags.data.flags);
    if (featureFlags === null || !flagsIsArray || featureFlags.length < 0) {
      return false;
    }
    if (Array.isArray(featureFlags)) {
      const [feature] = featureFlags.filter(flag => flag.name === featureName);
      if (feature && feature.active) return feature.active;
    }
    return false;
  } catch {
    return false;
  }
};

/**
 * Loads feature flags from feature flags
 * Fetches from backend if local storage is empty
 */
export const loadFeatureFlags = async () => {
  try {
    const serializedData = localStorage.getItem('flags');
    const featureFlags = JSON.parse(serializedData);
    const flagsIsArray = Array.isArray(featureFlags) || Array.isArray(featureFlags.data.flags);
    if (featureFlags === null || !flagsIsArray || featureFlags.length < 0) {
      throw new Error('Flags is not an array or array is empty');
    }
    if (Array.isArray(featureFlags)) {
      return featureFlags;
    }
    return featureFlags.data.flags;
  } catch (error) {
    const featureFlags = await fetchFeatureFlags();
    saveFeatureFlags(featureFlags);
    return featureFlags.data.flags;
  }
};

/**
 * Takes in the feature name and verifies,
 * if user has access to this feature.
 * @param {String} feature_name
 */
export const verifyFeatureAccess = async (featureName) => {
  const featureFlags = await loadFeatureFlags();
  const [feature] = featureFlags.filter(flag => flag.name === featureName);
  if (feature && feature.active) return feature.active;
  return false;
};

/**
 * Verifies if user has access to a feature.
 * @param {String} featureName
 */
export const verifyReferralMgmtAccess = async () => {
  const referralManagementStatus = localStorage.getItem('m8_referral_management');
  return referralManagementStatus === 'true';
};
