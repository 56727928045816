import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import Tooltip from '../../../components/Tooltip';
import '../newRecordViewer.scss';

const ToolbarButton = ({
  title, icon, onClick, hide,
}) => {
  return (
    !hide
    && (
    <Tooltip title={title}>
      <Button
        className="tertiary medium icon-btn"
        icon={icon}
        onClick={onClick}
      />
    </Tooltip>
    )
  );
};

ToolbarButton.defaultProps = {
  title: '',
  hide: false,
};

ToolbarButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  hide: PropTypes.bool,
};

export default ToolbarButton;
