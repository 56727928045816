import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
} from 'antd';
import {
  ExceptionOutlined,
} from '@ant-design/icons';
import AnnounceKit from 'announcekit-react';
import {
  AddressBookSvg, CloudArrowUpSvg, GearSvg, HouseSvg, KeySvg, MailboxSvg, MegaphoneSvg, PaperPlaneTiltSvg, QuestionSvg, SignOutSvg,
  UsersSvg,
} from '../../assets/img/redesign/svgPaths';
import { Phone } from '@phosphor-icons/react';
import { verifyFeatureAccess, verifyReferralMgmtAccess } from '../../global/featureFlags';
import './newNav.scss';
import MenuItems from './MenuItems';

const { Sider } = Layout;

let cableSubscription;

const NewNav = ({
  auth,
  guest,
  createGuest,
  user,
  logout,
  toggle,
  openModal,
  faxSource,
  redirectToCreateGuestAccount,
  loginErrorMessage,
  adminLoginSuccess,
  loginAdmin,
  cable,
  setNumberOfNewReferrals,
  incrementReferralCount,
  decrementReferralCount,
  newReferralsCount,
  history,
}) => {
  const [referralManagementAccess, setReferralManagementAccess] = useState(false);
  const [canViewVoiceAi, setCanViewVoiceAi] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const announceKitRef = useRef(null);

  useEffect(() => {
    const getReferralManagementAccess = async () => {
      const canViewReferralMgmt = await verifyReferralMgmtAccess();
      setReferralManagementAccess(canViewReferralMgmt);
    };

    const getVoiceAiAccess = async () => {
      setCanViewVoiceAi(await verifyFeatureAccess('voice_ai'));
    };
    if (user) {
      getReferralManagementAccess();
      getVoiceAiAccess();
    }

    return () => {
      if (cableSubscription) {
        cableSubscription.unsubscribe();
      }
    };
  }, [user]);

  useEffect(() => {
    if (auth && !cableSubscription) {
      let canViewReferrals = referralManagementAccess;
      if (user) {
        if (user?.referralManagement === 'true' || user?.referralManagement === true) {
          canViewReferrals = true;
        } else {
          canViewReferrals = false;
        }
      }

      if (canViewReferrals) {
        cableSubscription = cable.subscriptions.create(
          {
            channel: 'ReferralsChannel',
          },
          {
            received: (message) => {
              if (message === 'increment') {
                incrementReferralCount();
              } else if (message === 'decrement') {
                decrementReferralCount();
              } else {
                setNumberOfNewReferrals(message);
              }
            },
          },
        );
      }
    }

    return () => {
      if (cableSubscription) {
        cableSubscription.unsubscribe();
      }
    };
  }, [auth, user]);

  useEffect(() => {
    if (loginErrorMessage) {
      /* global toastr */
      toastr.error(loginErrorMessage);
    }
  }, [loginErrorMessage]);

  useEffect(() => {
    if (adminLoginSuccess) {
      const win = window.open(`${window.location.origin}/admin`, '_blank');
      win.opener = null;
    }
  }, [adminLoginSuccess]);

  useEffect(() => {
    if (announceKitRef.current && !unreadNotifications) {
      announceKitRef.current.unread().then((unread) => {
        if (unread !== 0) {
          setUnreadNotifications(true);
        }
      });
    }
  }, [announceKitRef.current]);

  const isOnChromeExt = window.location.pathname.includes('/app/chrome/');

  let canViewReferrals = referralManagementAccess;
  if (user) {
    if (user?.referralManagement === 'true' || user?.referralManagement === true) {
      canViewReferrals = true;
    } else {
      canViewReferrals = false;
    }
  }

  const onMobile = window.innerWidth < 800;

  const openNewUpdates = () => {
    announceKitRef.current.open();
  };

  const navButtons = [
    {
      label: 'Home',
      link: '/app',
      icon: HouseSvg,
      key: 'home',
      onClick: () => history.push('/app'),
    },
    {
      label: 'Uploads',
      link: '/app/uploads',
      icon: CloudArrowUpSvg,
      key: 'uploads',
      onClick: () => history.push('/app/uploads'),
    },
    {
      label: 'Sent',
      link: '/app/sent',
      icon: PaperPlaneTiltSvg,
      key: 'sent',
      onClick: () => history.push('/app/sent'),
    },
    {
      label: 'Inbox',
      link: '/app/received',
      icon: MailboxSvg,
      key: 'inbox',
      onClick: () => history.push('/app/received'),
    },
    {
      label: 'Assigned',
      link: '/app/assigned',
      icon: <ExceptionOutlined />,
      key: 'assigned',
      onClick: () => history.push('/app/assigned'),
    },
    canViewReferrals
    && {
      label: 'Referrals',
      link: '/app/referred',
      icon: UsersSvg,
      key: 'referrals',
      badgeCount: 0 || null,
      onClick: () => history.push('/app/referred'),
    },
    canViewVoiceAi
    && {
      label: 'Voice',
      link: '/app/voice_inbox',
      icon: <Phone size={24} weight="fill" />,
      key: 'voice_inbox',
      onClick: () => history.push('/app/voice_inbox'),
    },
    {
      label: 'Contacts',
      link: '/app/contacts',
      icon: AddressBookSvg,
      key: 'contacts',
      onClick: () => history.push('/app/contacts'),
    },
    {
      label: 'Updates',
      icon: MegaphoneSvg,
      key: 'updates',
      dot: unreadNotifications,
      onClick: () => {
        openNewUpdates();
        setUnreadNotifications(false);
      },
    },
    {
      label: 'Settings',
      link: '/app/user',
      icon: GearSvg,
      key: 'settings',
      onClick: () => history.push('/app/user'),
    },
    {
      label: 'Guide',
      link: '/app/guide',
      icon: QuestionSvg,
      key: 'guide',
      style: { position: 'absolute', bottom: '170px', width: '100%' },
      onClick: () => history.push('/app/guide'),
    },
    {
      label: 'Admin',
      icon: KeySvg,
      noHighlightOnSelect: true,
      key: 'admin-login',
      style: { position: 'absolute', bottom: '110px', width: '100%' },
      onClick: loginAdmin,
    },
    {
      label: 'Logout',
      icon: SignOutSvg,
      noHighlightOnSelect: true,
      key: 'sign-out',
      onClick: logout,
    },
  ].filter(Boolean);

  return (
  // hide the new nav bar before logging in and completing the walkthrough or if the user is on mobile
    (auth && window.location.pathname !== '/app/walkthrough' && !onMobile && !isOnChromeExt)
      && (
    // z-index set so nav will always be on top of the inbox sidebars that have a z-index of 1000
        <div className="new-nav" style={{ height: '100%', position: 'absolute', zIndex: 900 }}>
          <Sider id="sidebar-nav" collapsible collapsed={true} trigger={null} style={{ height: '100%', position: 'fixed' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
            >
              <MenuItems buttons={navButtons} />
            </div>
            <AnnounceKit ref={announceKitRef} widget="https://releasenotes.medsender.com/widgets/v2/3FvLNK" />
          </Sider>
        </div>
      )
  );
};

NewNav.defaultProps = {
  user: '',
  faxSource: '',
};

NewNav.propTypes = {
  auth: PropTypes.bool.isRequired,
  guest: PropTypes.bool.isRequired,
  createGuest: PropTypes.bool.isRequired,
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  faxSource: PropTypes.string,
  redirectToCreateGuestAccount: PropTypes.func.isRequired,
  adminLoginSuccess: PropTypes.bool.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
  loginAdmin: PropTypes.func.isRequired,
  cable: PropTypes.any.isRequired,
  setNumberOfNewReferrals: PropTypes.func.isRequired,
  incrementReferralCount: PropTypes.func.isRequired,
  decrementReferralCount: PropTypes.func.isRequired,
  newReferralsCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

export default NewNav;
