/* eslint-disable no-shadow */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { snakeCase } from 'snake-case';
import {
  DownloadSvg,
  TrayArrowDownSvg,
} from '../../../assets/img/redesign/svgPaths';
import Button from '../../../components/Button';
import { PAGES } from '../../shared/constants';
import download from '../../../actions/helpers/download';
import { printUserRecord } from '../../../actions/records';
import '../newRecordViewer.scss';

const DownloadDropdown = ({
  currentPage, isPrinting, beginArchiveRecord, ...props
}) => {
  const [downloadAndArchive, setDownloadAndArchive] = useState(false);
  const viewingArchivedRecords = window.location.search.includes('archived');
  const {
    // eslint-disable-next-line react/prop-types
    printUserRecord, fileToken, patientName, downloadUrl,
  } = props;

  // useEffect to handle downloading and printing
  useEffect(() => {
    const startDownload = async (url, fileName) => {
      await download(url, fileName);
      if (downloadAndArchive) {
        await beginArchiveRecord();
        setDownloadAndArchive(false);
      }
    };

    if (downloadUrl && !isPrinting) {
      // snakeCase package doesn't allow passing undefined, so we need to pass an empty string if so.
      const patientNameSnakeCase = snakeCase(patientName || '');
      const customFileName = `${patientNameSnakeCase}-${fileToken
        .slice(-7)
        .toLowerCase()}`;
      startDownload(downloadUrl, customFileName);
    }
  }, [downloadUrl]);

  const downloadRecord = async () => {
    try {
      printUserRecord(fileToken);
    } catch (e) {
      // eslint-disable-next-line no-undef
      toastr.error(
        'Unable to download this record. Try refreshing and downloading again.',
      );
    }
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={downloadRecord}>
        <span className="dropdown-icon">{DownloadSvg}</span>
        Download
      </Menu.Item>
      {
        currentPage !== PAGES.ASSIGNED && !viewingArchivedRecords
        && (
        <Menu.Item onClick={() => {
          setDownloadAndArchive(true);
          downloadRecord();
        }}
        >
          <span className="dropdown-icon">{TrayArrowDownSvg}</span>
          Download and Archive
        </Menu.Item>
        )
      }
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button className="tertiary medium icon-btn" icon={DownloadSvg} />
    </Dropdown>
  );
};

DownloadDropdown.defaultProps = {
  patientName: '',
  downloadUrl: '',
};

DownloadDropdown.propTypes = {
  currentPage: PropTypes.string.isRequired,
  isPrinting: PropTypes.bool.isRequired,
  beginArchiveRecord: PropTypes.func.isRequired,

  fileToken: PropTypes.string.isRequired,
  patientName: PropTypes.string,
  downloadUrl: PropTypes.string,
};

export default connect(state => ({
  fileToken: state.records.fileToken,
  patientName: state.records.patientName,
  downloadUrl: state.records.downloadUrl,
}), {
  printUserRecord,
})(DownloadDropdown);
