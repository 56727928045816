import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { Statistic } from 'antd';
import Typography from '../Typography';
import ModalWrapper from './ModalWrapper';
import COLORS from '../../util/constants/colors';
import { addSubscription } from '../../actions/auth';
import Button from '../Button';
import { checkSvg } from '../../assets/img/redesign/svgPaths';
import './styles/trialExpiry.scss';

const TrialExpiry = ({ ...props }) => {
  const { hideModal, addSubscription } = props;

  const benefits = [
    'Automatically track who\'s sending the most referrals to you',
    'Track status of each referral and how long it takes to schedule',
    'Invite patients to schedule via SMS',
  ];

  const dismissReminder = () => {
    localStorage.setItem('referral_upgrade_reminder_time', Date.now());
    hideModal();
  };

  const triggerChargebeeBackend = () => {
    const cb = document.createElement('script');
    cb.innerHTML = `Chargebee.init({
          site: "${process.env.CB_SITE}",
          publishableKey: "${process.env.CB_KEY}"
        })`;
    document.body.appendChild(cb);

    /* global Chargebee */
    const cbInstance = Chargebee.getInstance();
    const _this = this;
    const chargebeeCallbacks = () => {};
    const callbacks = chargebeeCallbacks();
    cbInstance.openCheckout({
      ...callbacks,
      hostedPage: async () => {
        return axios.post(`${window.location.origin}/api/v1/payments/update_trial`).then(response => response.data);
      },
      success: (hostedPageId) => {
        // Successfully checked out, close upgrade modal.
        dismissReminder();
        const data = {
          page_id: hostedPageId,
        };
        return addSubscription(data);
      },
    });
  };

  return (
    <ModalWrapper
      hideModal={dismissReminder}
      primary={true}
      dismiss
    >
      <div className="trial-expiry">
        <Typography variant="subtitleOne" color={COLORS.PURPLE_PRIMARY_LITE}>CONTINUE WITH REFERRAL MANAGEMENT</Typography>
        <div className="trial-copy">
          <Typography variant="text" size="large" bold color={COLORS.TEXT_PRIMARY_LITE}>Don't lose access to these benefits:</Typography>
          <div className="benefits">
            {
            benefits.map(benefit => (
              <div className="benefit">
                <div style={{ height: '24px', width: '24px', color: COLORS.SUCCESS_LITE }}>
                  {checkSvg}
                </div>
                <Typography variant="text" size="medium" color={COLORS.TEXT_PRIMARY_LITE}>{benefit}</Typography>
              </div>
            ))
          }
          </div>
          <div className="sub-copy">
            <Typography variant="text" size="medium" color={COLORS.TEXT_SECONDARY_LITE}>
              Now that your trial is over, subscribe to regain access to Referral Management for life.
            </Typography>
          </div>
        </div>
        <div className="actions">
          <Button type="secondary" label="No Thanks" onClick={() => dismissReminder()} />
          <Button type="primary" label="View My Offer" onClick={() => triggerChargebeeBackend()} />
        </div>
      </div>
    </ModalWrapper>
  );
};

TrialExpiry.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  addSubscription: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  isAdmin: state.auth.isAdmin,
  loginErrorMessage: state.userProfile.loginErrorMessage,
  adminLoginSuccess: state.userProfile.loginSuccess,
}), {
  addSubscription,
})(TrialExpiry);
