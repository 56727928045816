/* eslint-disable react/no-array-index-key */
import React, {
  Component,
  useState,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stringify } from 'qs';
import {
  message,
  Select,
  Form,
  Spin,
  Modal,
} from 'antd';

const { confirm } = Modal;
import debounce from 'lodash/debounce';

import { updateAssignedRecord } from '../../actions/records';

import ModalWrapper from './ModalWrapper';

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

// eslint-disable-next-line react/prop-types
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

class UpdateAssignedDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocumentStatus: this.props.modalType.data.selectedDocumentStatus,
      documentStatuses: [],
      assignedDocId: this.props.modalType.data.assignedDocumentId,
      assignee: this.props.modalType.data.assignee,
      assignees: null,
    };
  }

  componentDidMount = async () => {
    const { getDocumentStatuses } = this.props;
    await getDocumentStatuses();
    this.setState({ documentStatuses: this.props.allDocumentStatuses.document_statuses });
  }

  update = async (event) => {
    event.preventDefault();
    const {
      assignedDocId,
      selectedDocumentStatus,
      assignee,
    } = this.state;
    await this.props.updateAssignedRecord(assignedDocId, selectedDocumentStatus, assignee.value);
    const { updatedStatus, hideModal } = this.props;
    if (updatedStatus) {
      hideModal();
      window.location.reload();
      message.success({
        content: 'Assigned document successfully updated',
      });
    }
  }

  handleChange = (value) => {
    this.setState({ selectedDocumentStatus: value });
  }

  handleAssignToChange = (e) => {
    const assignee = this.state.assignees.find(user => user.value === e.value);
    this.setState({ assignee: assignee });
  };

  fetchUserList = async (value) => {
    const { getUserDepartmentsByQuery, paramTools } = this.props;
    const { params } = paramTools;
    if (params.contains === '' || value === '') {
      delete params.contains;
      params.page = 1;
    } else {
      params.contains = value;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getUserDepartmentsByQuery(query);
    const searchedAssignees = this.props.departmentUsers;
    const assignees = searchedAssignees.map(user => ({
      label: user[1],
      value: user[0],
    }));

    this.setState({ assignees });
    return assignees;
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const {
      documentStatuses, selectedDocumentStatus, assignee, assignees,
    } = this.state;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.update}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={isLoading}
        modalTitle="Update Assigned Document"
        form="updateAssignedDocument"
      >
        <Form
          layout="vertical"
          onFinish={this.update}
          id="updateAssignedDocument"
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Document Status"
              style={{ flex: 0.5 }}
            >
              <Select
                style={{ width: '220px' }}
                placeholder="Select Document Status"
                onChange={this.handleChange}
                value={selectedDocumentStatus}
              >
                {documentStatuses.map(docStatus => <Select.Option key={docStatus.id} value={docStatus.id}>{docStatus.name}</Select.Option>)}
              </Select>
            </Form.Item>

            <Form.Item
              name="Assigned_to"
              label="Assigned Medsender User:"
              style={{ flex: 0.5 }}
            >
              <></>
              <DebounceSelect
                showSearch
                value={assignee}
                placeholder="Search for Medsender users"
                fetchOptions={this.fetchUserList}
                onChange={this.handleAssignToChange}
                options={(assignees || []).map(user => ({
                  value: user[0],
                  label: user[1],
                }))}
              />
            </Form.Item>
          </div>
        </Form>
      </ModalWrapper>
    );
  }
}

UpdateAssignedDocument.defaultProps = {
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  departmentUsers: null,
};

UpdateAssignedDocument.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  allDocumentStatuses: PropTypes.object.isRequired,
  getDocumentStatuses: PropTypes.func.isRequired,
  updatedStatus: PropTypes.bool.isRequired,
  updateAssignedRecord: PropTypes.func.isRequired,
  getUserDepartmentsByQuery: PropTypes.func.isRequired,
  paramTools: PropTypes.object,
  departmentUsers: PropTypes.array,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedStatus: state.records.updatedStatus,
  data: state.labels.data,
  allDocumentStatuses: state.userProfile.allDocumentStatuses,
  departmentUsers: state.userProfile.departmentUsers,
}), {
  updateAssignedRecord,
})(UpdateAssignedDocument);
