import React, {
  Component,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stringify } from 'qs';
import {
  Input,
  Select,
  Form,
  message,
  Spin,
  Modal,
} from 'antd';

const { confirm } = Modal;
import debounce from 'lodash/debounce';
import ModalWrapper from './ModalWrapper';

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

// eslint-disable-next-line react/prop-types
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

const { TextArea } = Input;
class AssignRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocumentStatus: null,
      sendToken: props.modalType.data.record,
      parentType: props.modalType.data.receiverType,
      markComplete: props.modalType.data.markComplete || false,
      assignedDocumentId: props.modalType.data.assignedDocumentId || null,
      assignees: null,
      documentStatuses: [],
      note: '',
    };
  }

  componentDidMount = async () => {
    const { getDocumentStatuses } = this.props;
    await getDocumentStatuses();
    this.setState({ documentStatuses: this.props.allDocumentStatuses.document_statuses });
  }

  fetchUserList = async (value) => {
    const { getUserDepartmentsByQuery, paramTools } = this.props;
    const { params } = paramTools;
    if (params.contains === '' || value === '') {
      delete params.contains;
      params.page = 1;
    } else {
      params.contains = value;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getUserDepartmentsByQuery(query);
    const searchedAssignees = this.props.departmentUsers;
    if (searchedAssignees.length === 0) {
      message.error({
        content: 'No users found in your organization',
      });
    }
    const assignees = searchedAssignees.map(user => ({
      label: user[1],
      value: user[0],
    }));

    this.setState({ assignees });
    return assignees;
  }

  handleMarkCompleted = async () => {
    const { markDocumentAsCompleted } = this.props;
    const {
      assignedDocumentId,
      markComplete,
    } = this.state;

    if (markComplete) {
      await markDocumentAsCompleted(assignedDocumentId);

      if (window.location.pathname.includes('/app/view/')) {
        message.success({
          content: 'Document Assigned and Marked Complete Successfully. You\'ll be redirected to the assigned records page.',
        });

        // After 3 seconds, go to assigned records page.
        setTimeout(() => { this.context.router.history.push('/app/assigned'); }, 3000);
      } else {
        message.success({
          content: 'Document Assigned and Marked Complete Successfully',
        });

        window.location.reload();
      }
    } else {
      message.success({
        content: 'Document Assigned Successfully',
      });
    }
  }

  confirmAssign = async (assignedDocument) => {
    const { assignDocument } = this.props;
    await assignDocument(assignedDocument);

    const { error, hideModal } = this.props;
    const errorMessageContent = 'Error assigning document. Please try again.';
    if (!error) {
      hideModal();
      this.handleMarkCompleted();
    } else {
      hideModal();
      message.error({
        content: errorMessageContent,
      });
    }
  }

  assignDocument = async () => {
    const { assignDocument } = this.props;
    const {
      sendToken,
      assignee,
      note,
      selectedDocumentStatus,
      parentType,
    } = this.state;
    let parent = '';
    if (parentType === 'received_fax') {
      parent = 'ReceivedFax';
    } else if (parentType === 'receiver') {
      parent = 'Receiver';
    } else {
      parent = 'EmailAttachment';
    }

    let assignedDocument = {
      send_token: sendToken,
      note,
      assignee_id: assignee.value,
      parent_type: parent,
      document_status_id: selectedDocumentStatus,
      force_assign: false,
    };
    await assignDocument(assignedDocument);

    const { error, hideModal, isDepartmentUser } = this.props;
    const errorMessageContent = 'Error assigning document. Please try again.';
    if (!error) {
      if (!isDepartmentUser) {
        confirm({
          title: 'This person doesn\'t have access!',
          okText: 'Yes',
          cancelText: 'No',
          content: 'This user doesn\'t currently have access to the document. Would you like to grant them access to complete this assignment?',
          onOk: () => {
            assignedDocument.force_assign = true;
            return this.confirmAssign(assignedDocument);
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        hideModal();
        this.handleMarkCompleted();
      }
    } else {
      hideModal();
      message.error({
        content: errorMessageContent,
      });
    }
  }

  renderModal = () => {
    const { isLoading } = this.props;
    const {
      documentStatuses,
      selectedDocumentStatus,
      assignee,
      assignees,
    } = this.state;

    const handleAssignToChange = (e) => {
      const assignee = this.state.assignees.find(user => user.value === e.value);
      this.setState({ assignee: assignee });
    };

    const handleChange = (value) => {
      this.setState({ selectedDocumentStatus: value });
    };

    if (isLoading) {
      return (<span> Assigning Record, Please wait... </span>);
    }

    return (
      <React.Fragment>
        <Form
          onFinish={this.assignDocument}
          id="notifyUser"
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              layout="vertical"
              onFinish={this.update}
              id="updateDocumentStatus"
              style={{ flex: 0.5 }}
            >
              Document Status:
              <Select
                onChange={handleChange}
                placeholder="Document Status"
                value={selectedDocumentStatus}
              >
                {documentStatuses.map(docStatus => <Select.Option key={docStatus.id} value={docStatus.id}>{docStatus.name}</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="Assigned_to"
              style={{ flex: 0.5 }}
            >
              Medsender User to Assign To:
              <DebounceSelect
                showSearch
                allowClear
                value={assignee}
                placeholder="Search for Medsender users"
                fetchOptions={this.fetchUserList}
                onChange={handleAssignToChange}
                options={(assignees || []).map(user => ({
                  value: user[0],
                  label: user[1],
                }))}
              />
            </Form.Item>
          </div>
          <Form.Item
            hasFeedback
            name="note"
            style={{ flex: 1 }}
          >
            Note:
            <TextArea
              onChange={event => this.setState({ note: event.target.value })}
              rows={2}
            />
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const { selectedDocumentStatus, assignee } = this.state;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.assignDocument}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={isLoading || !selectedDocumentStatus || !assignee}
        modalTitle="Assign Document Within Medsender"
        form="assignDocument"
      >
        {this.renderModal()}
      </ModalWrapper>
    );
  }
}

AssignRecord.contextTypes = {
  router: PropTypes.object.isRequired,
};

AssignRecord.defaultProps = {
  error: false,
  errorCode: null,
  departmentUsers: null,
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
};

AssignRecord.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  assignDocument: PropTypes.func.isRequired,
  markDocumentAsCompleted: PropTypes.func.isRequired,
  paramTools: PropTypes.object,
  allDocumentStatuses: PropTypes.object.isRequired,
  departmentUsers: PropTypes.array,
  getDocumentStatuses: PropTypes.func.isRequired,
  getUserDepartmentsByQuery: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isDepartmentUser: PropTypes.bool,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  error: state.records.error,
  errorCode: state.records.errorCode,
  allDocumentStatuses: state.userProfile.allDocumentStatuses,
  departmentUsers: state.userProfile.departmentUsers,
  isDepartmentUser: state.records.isDepartmentUser,
}))(AssignRecord);
