/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import {
  UserOutlined,
  TeamOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  FileTextOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import Button from '../../components/Button';
import { loginAdmin } from '../../actions/userProfile';
import { logout } from '../../actions/auth';

const MobileOptions = ({ ...props }) => {
  const {
    loginAdmin, adminLoginSuccess, loginErrorMessage, logout,
  } = props;

  const currentYear = new Date().getFullYear();

  const bottomBorder = {
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
    marginBottom: '5px',
  };

  const loginAsAdmin = async () => {
    await loginAdmin();

    if (adminLoginSuccess) {
      const win = window.open(`${window.location.origin}/admin`, '_blank');
      win.opener = null;
    } else {
      /* global toastr */
      toastr.error(loginErrorMessage);
    }
  };

  const linkToSupportSite = () => {
    const win = window.open('https://medsender.com/support', '_blank');
    win.opener = null;
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        icon={<UserOutlined />}
        onClick={() => window.location.assign('/app/user')}
        className="ant-dropdown-menu-item"
      >
        Profile
      </Menu.Item>
      <Menu.Item key="1" icon={<TeamOutlined />} onClick={loginAsAdmin}>
        Admin Login (User Management)
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<QuestionCircleOutlined />}
        style={bottomBorder}
        className="ant-dropdown-menu-item"
        onClick={linkToSupportSite}
      >
        Contact/Help
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<FileTextOutlined />}
        style={bottomBorder}
        className="ant-dropdown-menu-item"
        onClick={() => window.location.assign('/app/guide')}
      >
        Guides
      </Menu.Item>
      <Menu.Item key="4" icon={<PoweroffOutlined />} onClick={logout}>
        Sign out
      </Menu.Item>
      <p style={{ textAlign: 'center', fontSize: '.7em' }}>
        &copy;
        {' '}
        {currentYear}
        {' '}
        Medsender, Inc.
      </p>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      overlayStyle={{ zIndex: 1500 }}
    >
      <Button icon={<MenuOutlined />} className="icon-btn tertiary small" />
    </Dropdown>
  );
};

MobileOptions.propTypes = {
  adminLoginSuccess: PropTypes.bool.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
  loginAdmin: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(state => ({
  loginErrorMessage: state.userProfile.loginErrorMessage,
  adminLoginSuccess: state.userProfile.loginSuccess,
}),
{
  loginAdmin,
  logout,
})((MobileOptions));
