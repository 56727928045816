/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { parse } from 'qs';
import {
  archiveRecords,
  archiveReceivedRecords,
  getAssignedRecord,
} from '../../actions/records';
import { verifyFeatureAccess } from '../../global/featureFlags';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';
import './newRecordViewer.scss';
import {
  CaretDoubleRightSvg,
  PaperPlaneTiltSvg,
} from '../../assets/img/redesign/svgPaths';
import DownloadDropdown from './Dropdowns/DownloadDropdown';
import {
  ArchiveButton,
  ExpandButton,
  ForwardToEMRButton,
  NotifyButton,
  PrintButton,
  PublishButton,
  ReplyButton,
  SecureLinkButton,
  SignDocButton,
  SMSButton,
  SplitRecordButton,
  UpdateAssignedDocumentButton,
  UpdateReferralStatusButton,
} from './Buttons';
// import ArchiveButton from './Buttons/ArchiveButton';
import EMRActionsDropdown from './Dropdowns/EMRActionsDropdown';
import CloudUploadDropdown from './Dropdowns/CloudUploadDropdown';
import MarkCompleteDropdown from './Dropdowns/MarkCompleteDropdown';
import { openModal } from '../../actions/modal';

const ViewerToolbar = ({
  closeViewer,
  currentTransmission,
  currentPage,
  recordBlobObject,
  checkPatientDetails,
  beginSaveAnnotationsOnRecord,
  ...props
}) => {
  const { isFormDocument, isFormSubmission, isEmailSubmission } = props;

  const { fileToken, sendToken } = props;

  const isForm = isFormDocument || isFormSubmission || isEmailSubmission;
  const inboxLink = window.location.pathname;
  const [isPrinting, setIsPrinting] = useState(false);
  const [canForwardToEMR, setCanForwardToEMR] = useState(false);

  useEffect(() => {
    const checkEMRAccess = async () => {
      const hasAccess = await verifyFeatureAccess('manual_upload_emr');
      setCanForwardToEMR(hasAccess);
    };
    checkEMRAccess();
  }, []);

  useEffect(() => {
    const fetchAssignedDocument = async () => {
      await props.getAssignedRecord(currentTransmission.slug);
    };

    if (currentTransmission.slug) {
      fetchAssignedDocument();
    }
  }, [currentTransmission]);

  const beginArchiveRecord = async () => {
    const { archiveErrorMessage, archiveRecords, archiveReceivedRecords } = props;
    // for the edge case of archiving records sent to the user by the user, we need to get the sendToken from the url
    const searchParams = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    // get sendToken from the searchparams for received records sent to self
    // The file token changes to edited record when in record viewer if there are edits; if that's the case, send in both tokens so it disappears from the inbox too
    if (inboxLink === '/app/uploads') {
      await archiveRecords([fileToken]);
    } else {
      await archiveReceivedRecords([sendToken || searchParams.sendToken]);
    }
    if (!archiveErrorMessage) {
      message.success({
        content: 'Record successfully archived',
      });
      window.location.reload();
    } else {
      message.error({
        content:
          'We were unable to archive the selected records. Please try again',
      });
    }
  };

  const linkDialogVisible = async (canPublishForm = false) => {
    const { openModal } = props;
    const flag = canPublishForm ? 'publish_forms' : 'secure_link';
    const canCopyLink = await verifyFeatureAccess(flag);
    if (canCopyLink) {
      const modalType = {
        type: 'SECURE_LINK',
        data: {
          fileToken,
          isFormDocument,
        },
      };
      return openModal(modalType);
    }
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: flag,
    };
    openModal(modalType);
  };

  return (
    <div className="toolbar">
      <div className="toolbar-content">
        <Tooltip title="Close">
          <Button
            className="tertiary medium icon-btn"
            icon={CaretDoubleRightSvg}
            onClick={closeViewer}
          />
        </Tooltip>
        <Button
          type="tertiary"
          size="small"
          leftIcon={PaperPlaneTiltSvg}
          label="Send"
          onClick={() => checkPatientDetails(false, false)}
        />
      </div>
      <div className="toolbar-content">
        <DownloadDropdown
          currentPage={currentPage}
          beginArchiveRecord={beginArchiveRecord}
          isPrinting={isPrinting}
        />
        <ReplyButton
          currentPage={currentPage}
          isEmailSubmission={isEmailSubmission}
          checkPatientDetails={checkPatientDetails}
        />
        <SecureLinkButton
          isForm={isForm}
          linkDialogVisible={linkDialogVisible}
        />
        <PublishButton
          isFormDocument={isFormDocument}
          linkDialogVisible={linkDialogVisible}
        />
        <NotifyButton
          isFormDocument={isFormDocument}
          currentPage={currentPage}
        />
        <ForwardToEMRButton currentPage={currentPage} />
        <SMSButton currentPage={currentPage} />
        <SignDocButton />
        <SplitRecordButton currentPage={currentPage} isForm={isForm} />
        <UpdateReferralStatusButton
          currentPage={currentPage}
          recordBlobObject={recordBlobObject}
          beginSaveAnnotationsOnRecord={beginSaveAnnotationsOnRecord}
        />
        <UpdateAssignedDocumentButton currentPage={currentPage} recordBlobObject={recordBlobObject} beginSaveAnnotationsOnRecord={beginSaveAnnotationsOnRecord} />
        {canForwardToEMR
          && (
            <EMRActionsDropdown
              currentPage={currentPage}
              recordBlobObject={recordBlobObject}
              beginSaveAnnotationsOnRecord={beginSaveAnnotationsOnRecord}
            />
          )}
        <CloudUploadDropdown currentPage={currentPage} />
        <MarkCompleteDropdown
          currentPage={currentPage}
          recordBlobObject={recordBlobObject}
          beginSaveAnnotationsOnRecord={beginSaveAnnotationsOnRecord}
        />
        <PrintButton isPrinting={isPrinting} setIsPrinting={setIsPrinting} />
        <ArchiveButton inboxLink={inboxLink} />
        <ExpandButton inboxLink={inboxLink} />
      </div>
    </div>
  );
};

ViewerToolbar.defaultProps = {
  recordBlobObject: {},
  archiveErrorMessage: '',
  sendToken: '',
  isFormSubmission: false,
  isEmailSubmission: false,
};

ViewerToolbar.propTypes = {
  closeViewer: PropTypes.func.isRequired,
  currentTransmission: PropTypes.object.isRequired,
  currentPage: PropTypes.string.isRequired,
  recordBlobObject: PropTypes.object,
  checkPatientDetails: PropTypes.func.isRequired,
  beginSaveAnnotationsOnRecord: PropTypes.func.isRequired,

  fileToken: PropTypes.string.isRequired,
  sendToken: PropTypes.string,
  archiveErrorMessage: PropTypes.string,
  isFormDocument: PropTypes.bool.isRequired,
  isFormSubmission: PropTypes.bool,
  isEmailSubmission: PropTypes.bool,
  canSendRecord: PropTypes.bool.isRequired,

  archiveRecords: PropTypes.func.isRequired,
  archiveReceivedRecords: PropTypes.func.isRequired,
  getAssignedRecord: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    fileToken: state.records.fileToken,
    sendToken: state.records.sendToken,
    currentTransmission: state.records.currentTransmission,
    archiveErrorMessage: state.records.archiveErrorMessage,
    isFormDocument: state.records.isFormDocument,
    isFormSubmission: state.records.isFormSubmission,
    isEmailSubmission: state.records.isEmailSubmission,
    canSendRecord: state.records.canSend,
  }),
  {
    archiveRecords,
    archiveReceivedRecords,
    getAssignedRecord,
    openModal,
  },
)(ViewerToolbar);
