import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import './select.scss';

const Select = ({ ...props }) => {
  return (
    <AntSelect
      className={props.disabled && 'disabled'}
      {...props}
    />
  );
};

Select.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.oneOf(['', 'error', 'warning']),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  })),
};

Select.defaultProps = {
  defaultValue: '',
  disabled: false,
  status: '',
  options: [{}],
};

export default Select;
