import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToolbarButton from './ToolbarButton';
import { ArrowBendUpLeftSvg } from '../../../assets/img/redesign/svgPaths';
import { PAGES } from '../../shared/constants';

const ReplyButton = ({
  currentPage, isEmailSubmission, checkPatientDetails, ...props
}) => {
  const { canSendRecord } = props;
  const hideButton = !canSendRecord
        || currentPage === PAGES.UPLOADS
        || currentPage === PAGES.SENT
        || currentPage === PAGES.ASSIGNED
        || isEmailSubmission;

  return (
    <ToolbarButton
      title="Reply"
      icon={ArrowBendUpLeftSvg}
      hide={hideButton}
      onClick={() => checkPatientDetails(false, true)}
    />
  );
};

ReplyButton.propTypes = {
  canSendRecord: PropTypes.bool.isRequired,
  currentPage: PropTypes.string.isRequired,
  isEmailSubmission: PropTypes.bool.isRequired,
  checkPatientDetails: PropTypes.func.isRequired,
};

export default connect(state => ({
  canSendRecord: state.records.canSend,
}))(ReplyButton);
